import React, { useEffect, useState, useContext } from 'react';
import './footer.css';
import { useGlobalContext } from '../../../context/AppContext';
import { setPageCookie } from '../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../utils/fetchResponses';

function LoggedFooter({
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const tag = document.querySelector('.footer-img');
    const tag2 = document.querySelector('.footer-text-fade-in-right');
    if (tag || tag2) {
      const rect = tag.getBoundingClientRect();
      const rect2 = tag2.getBoundingClientRect();
      if (rect.top < window.innerHeight || rect2.top < window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const ContactFooterClicked = () => {
    setPageCookie('sectionContact');
    window.location = '/';
  };

  const FeaturesFooterClicked = () => {
    setPageCookie('sectionFeatures');
    window.location = '/';
  };

  const FaqFooterClicked = () => {
    setPageCookie('sectionFaq');
    window.location = '/';
  };

  const ProductsFooterClicked = () => {
    setPageCookie('sectionProducts');
    window.location = '/';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var submitBtn = document.getElementById('submitBtn');
    submitBtn.disabled = true;

    try {
      const form = event.target;
      const formData = new FormData(form);

      const response = await fetch('/subscribe-to-newsletter', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();

      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        submitBtn.disabled = false;
        setIsModalClosed(false);
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription('Thank you for joining our news letter');
        } else {
          setModalDescription('תודה על הצטרפותכם לניוזלטר שלנו');
        }

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      submitBtn.disabled = false;

      setIsModalClosed(false);
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  return (
    <footer className="footer" id="footer">
      <div className="row-1">
        <div className="footer-banner">
          <div className={`img footer-img ${isVisible ? 'visible' : ''}`}>
            <img src="logo-no-background.png" alt="Milly" loading="lazy" />
          </div>
          <h2
            className={`footer-text-fade-in-right ${
              isVisible ? 'visible' : ''
            }`}
          >
            {languageFile.headerTitle1}{' '}
            <span className="header-banner-specific">
              {languageFile.headerTitle2}{' '}
            </span>
            {languageFile.headerTitle3}
            <span className="header-banner-specific">
              {languageFile.headerTitle4}
            </span>{' '}
            {languageFile.headerTitle5}
          </h2>
          <p
            dir="auto"
            className={`footer-text-fade-in-right ${
              isVisible ? 'visible' : ''
            }`}
          >
            {languageFile.footerSubTitle}
          </p>
        </div>
      </div>
      <div className="row-2">
        <div
          className={`footer-sitemap-links footer-text-fade-in-right ${
            isVisible ? 'visible' : ''
          }`}
        >
          <p className="section-title">{languageFile.sitemapTitle}</p>
          <ul className={`footer-sitemap-list`}>
            <li>
              <a className="footer-link" href="/">
                {languageFile.navbarHome}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/profile">
                {languageFile.profile}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/settings">
                {languageFile.settings}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/feedback">
                {languageFile.contactTitle}
              </a>
            </li>
          </ul>
        </div>
        <div
          className={`footer-documents-links footer-text-fade-in-right ${
            isVisible ? 'visible' : ''
          }`}
        >
          <p className="section-title">{languageFile.legalTitle}</p>
          <ul className="footer-documents-list">
            <li>
              <a
                className="footer-link"
                target="_blank"
                rel="noreferrer"
                href="/terms-of-service"
              >
                {languageFile.acceptTosSubmit2}
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                target="_blank"
                rel="noreferrer"
                href="/privacy-policy"
              >
                {languageFile.privacyPolicy}
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                target="_blank"
                rel="noreferrer"
                href="/cookies-policy"
              >
                {languageFile.cookiesPolicy}
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                target="_blank"
                rel="noreferrer"
                href="/security-policy"
              >
                {languageFile.securityPolicy}
              </a>
            </li>
          </ul>
        </div>
        {selectedLanguage === 'en' ? (
          <div
            className={`section-content newsletter-content footer-text-fade-in-right ${
              isVisible ? 'visible' : ''
            }`}
          >
            <form
              className="nform"
              id="nform"
              method="POST"
              onSubmit={handleSubmit}
            >
              <p className="section-title section-title-newsletter">
                {languageFile.newsletterTitle}
              </p>
              <div className="input-line"></div>
              <div className="form-row cell1">
                <input
                  type="email"
                  className="footer-input"
                  name="email"
                  id="nemail"
                  required
                  maxLength="80"
                />
                <label htmlFor="nemail" className="footer-label">
                  {languageFile.emailPlaceholder} *
                </label>
                <div className="form-row form-row-condition">
                  <label className="form-switch">
                    <input
                      id="form-accept-tos"
                      type="checkbox"
                      name="accept_tos"
                      data-gtm-form-interact-field-id="0"
                      required
                    />
                    <span className="slider round"></span>
                  </label>
                  <label dir="auto" className="form-label condition-label">
                    <a
                      href="/terms-of-service"
                      target="_blank"
                    >{`${languageFile.acceptEmails} *`}</a>
                  </label>
                </div>
              </div>
              <div className="form-row cell2 nform-submit">
                <input
                  id="submitBtn"
                  className="btn btn-hollow"
                  type="submit"
                  value={languageFile.newsletterSubscribe}
                />
              </div>
            </form>
          </div>
        ) : (
          <div
            className={`section-content newsletter-content footer-text-fade-in-right ${
              isVisible ? 'visible' : ''
            }`}
          >
            <form
              className="nform"
              id="nform"
              method="POST"
              onSubmit={handleSubmit}
            >
              <p className="section-title">{languageFile.newsletterTitle}</p>
              <div className="input-line input-line-hebrew"></div>
              <div className="form-row cell1">
                <input
                  type="email"
                  className="footer-input"
                  name="email"
                  id="nemail"
                  maxLength="80"
                  required
                />
                <label htmlFor="nemail" className="footer-label">
                  {languageFile.emailPlaceholder} *
                </label>
                <div className="form-row form-row-condition">
                  <label className="form-switch">
                    <input
                      id="form-accept-tos"
                      type="checkbox"
                      name="accept_tos"
                      data-gtm-form-interact-field-id="0"
                      required
                    />
                    <span className="slider round"></span>
                  </label>
                  <label dir="auto" className="form-label condition-label">
                    <a
                      href="/terms-of-service"
                      target="_blank"
                    >{`${languageFile.acceptEmails}`}</a>
                  </label>
                </div>
              </div>
              <div className="form-row cell2 nform-submit">
                <input
                  id="submitBtn"
                  className="btn btn-hollow"
                  type="submit"
                  value={languageFile.newsletterSubscribe}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="row-3">
        <p
          dir="auto"
          className={`footer-text-fade-in-right ${isVisible ? 'visible' : ''}`}
        >
          &copy; {languageFile.footerCopyright}
        </p>
      </div>
    </footer>
  );
}

export default LoggedFooter;
