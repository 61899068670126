import Cookies from 'js-cookie';

export const clearMemorizeMode = () => {
  Cookies.set('questionsStart', '', { expires: 0 });
  Cookies.set('questionsEnd', '', { expires: 0 });
  Cookies.set('questionId', '', { expires: 0 });

  localStorage.removeItem('finishedQuestions', '');
  localStorage.removeItem('examTime', '');
  localStorage.removeItem('examStopper', 0);
  localStorage.removeItem('currentQuestions');
};

export const clearExamMode = () => {
  Cookies.set('questionsStart', '', { expires: 0 });
  Cookies.set('questionsEnd', '', { expires: 0 });
  Cookies.set('questionIdExam', '', { expires: 0 });

  Cookies.set('finishedQuestionsCounter', '', { expires: 0 });
  Cookies.set('examCorrectQuestionsCounter', '', { expires: 0 });
  Cookies.set('examIncorrectQuestionsCounter', '', { expires: 0 });
  Cookies.set('originalRExamID', '', { expires: 0 });

  localStorage.removeItem('isExamFromProgress', '', { expires: 0 });
  localStorage.removeItem('examPaused');
  localStorage.removeItem('examEnded');
  localStorage.removeItem('examStarted');
  localStorage.removeItem('studyMode');
  localStorage.removeItem('currentQuestions');
  localStorage.removeItem('examSelectedAnswers');
  localStorage.removeItem('examSelectedAnswersCheck');
  localStorage.removeItem('examSelectedClassifications');
  localStorage.removeItem('finishedQuestions');
  localStorage.removeItem('examTime');
  localStorage.removeItem('examStopper');
};

export const disableCookiesUsage = () => {
  // Not important Cookies:
  Cookies.set('finished_units', '', { expires: 0 });

  // Important Cookies:
  // Cookies.set('examCorrectQuestionsCounter', '', { expires: 0 });
  // Cookies.set('examIncorrectQuestionsCounter', '', { expires: 0 });
  // Cookies.set('finishedQuestionsCounter', '', { expires: 0 });
  // Cookies.set('lastExamLoadedID', '', { expires: 0 });
  // Cookies.set('memorizationClassification', '', { expires: 0 });
  // Cookies.set('originalRExamID', '', { expires: 0 });
  // Cookies.set('questionId', '', { expires: 0 });
  // Cookies.set('questionIdExam', '', { expires: 0 });
  // Cookies.set('questionsStart', '', { expires: 0 });
  // Cookies.set('questionsEnd', '', { expires: 0 });
  // Cookies.set('sessToken', '', { expires: 0 });

  // Still not used
  Cookies.set('customUnitWordList', '', { expires: 0 });
};

export const setLanguageCookie = (language) => {
  localStorage.setItem('language', language);
};

export const getLanguageCookie = () => {
  const localStorageValue = localStorage.getItem('language');

  if (localStorageValue) return localStorageValue;

  return 'he';
};

export const setPageCookie = (page) => {
  localStorage.setItem('page', page);
};

export const getPageCookie = () => {
  const localStorageValue = localStorage.getItem('page');

  if (localStorageValue) return localStorageValue;

  return '';
};

export const setStudyExamTime = (time) => {
  localStorage.setItem('examTime', time);
};

export const getStudyExamTime = () => {
  return localStorage.getItem('examTime');
};

export const setExamStopper = (time) => {
  localStorage.setItem('examStopper', time);
};

export const getExamStopper = () => {
  return localStorage.getItem('examStopper');
};

export const setExamStartedCookie = (value) => {
  if (value || value === 'true') {
    localStorage.setItem('examStarted', true);
  } else {
    localStorage.setItem('examStarted', false);
  }
};

export const getExamStartedCookie = () => {
  const cookie = localStorage.getItem('examStarted');
  if (cookie || cookie === 'true') return true;
  return false;
};

export const setExamPauseCookie = (value) => {
  if (value || value === 'true') {
    localStorage.setItem('examPaused', true);
  } else {
    localStorage.setItem('examPaused', false);
  }
};

export const getExamPauseCookie = () => {
  const cookie = localStorage.getItem('examPaused');
  if (cookie || cookie === 'true') return true;
  return false;
};

export const setExamEndCookie = (value) => {
  if (value || value === 'true') {
    localStorage.setItem('examEnded', true);
  } else {
    localStorage.setItem('examEnded', false);
  }
};

export const getExamEndCookie = () => {
  const cookie = localStorage.getItem('examEnded');
  if (cookie || cookie === 'true') return true;
  return false;
};

export const setExamOriginalIDCookie = (value) => {
  // Important functionality cookie
  Cookies.set('originalRExamID', parseInt(value, 10), { expires: 365 });
};

export const getExamOriginalIDCookie = () => {
  return Cookies.get('originalRExamID');
};

export const setExamSelectedAnswerJSONCookie = (value) => {
  localStorage.setItem('examSelectedAnswers', JSON.stringify(value));
};

export const getExamSelectedAnswerJSONCookie = () => {
  const localStorageValue = localStorage.getItem('examSelectedAnswers');

  if (localStorageValue) return JSON.parse(localStorageValue);

  return {};
};

export const setExamSelectedClassificationJSONCookie = (value) => {
  localStorage.setItem('examSelectedClassifications', JSON.stringify(value));
};

export const getExamSelectedClassificationJSONCookie = () => {
  const localStorageValue = localStorage.getItem('examSelectedClassifications');

  if (localStorageValue) return JSON.parse(localStorageValue);

  return {};
};

export const setExamSelectedAnswerCheckJSONCookie = (value) => {
  localStorage.setItem('examSelectedAnswersCheck', JSON.stringify(value));
};

export const getExamSelectedAnswerCheckJSONCookie = () => {
  const localStorageValue = localStorage.getItem('examSelectedAnswersCheck');

  if (localStorageValue) return JSON.parse(localStorageValue);
  return {};
};

export const deletePageCookie = () => {
  localStorage.removeItem('page');
};

export const getAgreementCookie = () => {
  return localStorage.getItem('allowCookies');
};

export const IsAgreementCookieAgreed = () => {
  const agreementCookie = localStorage.getItem('allowCookies');
  if (!agreementCookie || agreementCookie === 'false') return false;
  return true;
};

export const setAgreementCookie = (action) => {
  localStorage.setItem('allowCookies', action);
};

export const getSessionCookie = () => {
  return Cookies.get('sessToken');
};

export const setSessionCookie = (token) => {
  // Important functionality cookie
  Cookies.set('sessToken', token, { expires: 2 });
};

export const deleteSessionCookie = () => {
  Cookies.remove('sessToken');
};

export const verifySessionCookie = () => {
  const token = getSessionCookie();

  if (!token) {
    deleteSessionCookie();
    return false;
  }

  const parts = token.split('.');

  if (parts.length !== 3) {
    // console.log('Token is not valid');
    deleteSessionCookie();
    return false;
  }

  const payload = JSON.parse(atob(parts[1]));
  const expirationTime = payload.exp;
  const currentTime = Math.floor(Date.now() / 1000);

  if (expirationTime >= currentTime) {
    // console.log('Token is still valid');
    return true;
  }

  // console.log('Token has expired');
  deleteSessionCookie();

  return false;
};

export const getLoginCookie = () => {
  const localStorageValue = localStorage.getItem('logged');

  if (localStorageValue) return localStorageValue;

  return false;
};

export const setLoginCookie = (value) => {
  localStorage.setItem('logged', value);
};

export const getTopicCookie = () => {
  const localStorageValue = localStorage.getItem('topic');

  if (localStorageValue) return localStorageValue;

  return 'psychometry';
};

export const setTopicCookie = (value) => {
  localStorage.setItem('topic', value);
};

export const getClientName = () => {
  const token = getSessionCookie();
  const is_token_valid = verifySessionCookie();
  let clientname = '';

  if (!is_token_valid) {
    clientname = 'Guest';
  } else {
    const parts = token.split('.');
    const payload = JSON.parse(atob(parts[1]));

    clientname = payload.name;
  }

  return clientname;
};

export const getClientEmail = () => {
  const token = getSessionCookie();
  const is_token_valid = verifySessionCookie();
  var email = '';

  if (!is_token_valid) {
    email = '';
  } else {
    const parts = token.split('.');
    const payload = JSON.parse(atob(parts[1]));

    email = payload.email;
  }

  return email;
};

export const getClientPlan = () => {
  const token = getSessionCookie();
  const is_token_valid = verifySessionCookie();
  var plan = '';

  if (!is_token_valid) {
    plan = '';
  } else {
    const parts = token.split('.');
    const payload = JSON.parse(atob(parts[1]));

    plan = payload.plan;
  }

  return plan;
};

export const getClientIsMobileVerified = () => {
  const token = getSessionCookie();
  const is_token_valid = verifySessionCookie();
  let isMobileVerified = false;

  if (!is_token_valid) {
    isMobileVerified = false;
  } else {
    const parts = token.split('.');
    const payload = JSON.parse(atob(parts[1]));

    isMobileVerified = payload.imv;
  }

  return isMobileVerified;
};

export const setStudyModeCookie = (studyMode) => {
  localStorage.setItem('studyMode', studyMode);
};

export const getStudyModeCookie = () => {
  const localStorageValue = localStorage.getItem('studyMode');

  if (localStorageValue) return localStorageValue;

  return '';
};

export const deleteStudyModeCookie = () => {
  localStorage.removeItem('studyMode');
};

export const setStudyUnitCookie = (studyUnit) => {
  localStorage.setItem('studyUnit', studyUnit);
};

export const getStudyUnitCookie = () => {
  const localStorageValue = localStorage.getItem('studyUnit');

  if (localStorageValue) return localStorageValue;

  return 'unit-1';
};

export const setLastExamLoadedID = (data) => {
  // Important functionality cookie
  Cookies.set('lastExamLoadedID', data, { expires: 365 });
};

export const getLastExamLoadedID = () => {
  const cookie = Cookies.get('lastExamLoadedID');
  if (cookie && parseInt(cookie, 10)) return parseInt(cookie, 10);

  return 1;
};

export const deleteStudyUnitCookie = () => {
  localStorage.removeItem('studyUnit');
};

export const setQuestionIdCookie = (questionId) => {
  // Important functionality cookie
  Cookies.set('questionId', questionId, { expires: 365 });
};

export const getQuestionIdCookie = () => {
  let cookie = Cookies.get('questionId');
  if (cookie === 'q-undefined' || cookie === undefined) {
    cookie = '1';
    setQuestionIdCookie('q-1');
  }

  return cookie;
};

export const deleteQuestionIdCookie = () => {
  Cookies.remove('questionId');
};

export const setQuestionIdExamCookie = (questionId) => {
  // Important functionality cookie
  Cookies.set('questionIdExam', questionId, { expires: 365 });
};

export const getQuestionIdExamCookie = () => {
  let cookie = Cookies.get('questionIdExam');
  if (cookie === 'q-undefined' || cookie === undefined) {
    cookie = '1';
    setQuestionIdExamCookie('q-1');
  }

  return cookie;
};

export const setIsExamFromProgress = (isExamFromProgress) => {
  localStorage.setItem('isExamFromProgress', isExamFromProgress);
};

export const getIsExamFromProgress = () => {
  let cookie = localStorage.getItem('isExamFromProgress');
  return cookie;
};

export const deleteQuestionIdExamCookie = () => {
  Cookies.remove('questionIdExam');
};

export const getQuestionsStartCookie = () => {
  return Cookies.get('questionsStart');
};

export const getQuestionsEndCookie = () => {
  return Cookies.get('questionsEnd');
};

export const setQuestionsStartCookie = (questionsStart) => {
  // Important functionality cookie
  Cookies.set('questionsStart', questionsStart, { expires: 365 });
};

export const setQuestionsEndCookie = (questionsEnd) => {
  // Important functionality cookie
  Cookies.set('questionsEnd', questionsEnd, { expires: 365 });
};

export const getFinishedUnitsCookie = () => {
  if (IsAgreementCookieAgreed()) {
    if (Cookies.get('finished_units')) {
      return Cookies.get('finished_units');
    }
  }
  return '';
};

export const setFinishedUnitsCookie = (finishedUnits) => {
  // Not Important functionality cookie
  if (IsAgreementCookieAgreed())
    Cookies.set('finished_units', finishedUnits, { expires: 365 });
};

export const getMemorizationClassificationCookie = () => {
  return Cookies.get('memorizationClassification');
};

export const setMemorizationClassificationCookie = (
  memorizationClassification
) => {
  // Important functionality cookie
  Cookies.set('memorizationClassification', memorizationClassification, {
    expires: 365,
  });
};

export const getCurrectQuestionCookie = () => {
  const localStorageValue = localStorage.getItem('currentQuestions');

  if (localStorageValue) {
    const jsonArray = JSON.parse(localStorageValue);
    return jsonArray;
  }
};

export const setCurrectQuestionCookie = (data) => {
  localStorage.setItem('currentQuestions', JSON.stringify(data));
};

export const setSettingsCookie = (data) => {
  if (
    data === 'settingsBilling' ||
    data === 'settingsCookies' ||
    data === 'settingsProfile'
  ) {
    localStorage.setItem('settingsCookie', data);
  }
};

export const getSettingsCookie = () => {
  const localStorageValue = localStorage.getItem('settingsCookie');

  if (localStorageValue) return localStorageValue;

  return '';
};

export const getFinishedQuestions = () => {
  const cookie = localStorage.getItem('finishedQuestions');

  if (cookie === undefined) return [];

  const parsedArray = JSON.parse(cookie);

  if (Array.isArray(parsedArray)) return parsedArray;
  return [];
};

export const setFinishedQuestions = (data) => {
  localStorage.setItem('finishedQuestions', JSON.stringify(data));
};

export const getFinishedQuestionsCounter = () => {
  const cookie = Cookies.get('finishedQuestionsCounter');
  if (cookie) return parseInt(cookie, 10);
  return 0;
};

export const setFinishedQuestionsCounterCookie = (data) => {
  // Important functionality cookie

  if (typeof data === 'number')
    Cookies.set('finishedQuestionsCounter', parseInt(data, 10), {
      expires: 365,
    });
};

export const setExamCorrectQuestionsCounter = (value) => {
  // Important functionality cookie
  Cookies.set('examCorrectQuestionsCounter', value, { expires: 365 });
};

export const getExamCorrectQuestionsCounter = () => {
  const cookie = Cookies.get('examCorrectQuestionsCounter');
  if (cookie) return cookie;
  return 0;
};

export const setExamIncorrectQuestionsCounter = (value) => {
  // Important functionality cookie
  Cookies.set('examIncorrectQuestionsCounter', value, { expires: 365 });
};

export const getExamIncorrectQuestionsCounter = () => {
  const cookie = Cookies.get('examIncorrectQuestionsCounter');
  if (cookie) return cookie;
  return 0;
};

export const setStartTutorialCookie = (value) => {
  localStorage.setItem('startTutorial', value);
};

export const getStartTutorialCookie = () => {
  const localStorageValue = localStorage.getItem('startTutorial');

  if (localStorageValue) {
    try {
      const value = localStorageValue;
      return value;
    } catch (error) {
      return 0;
    }
  }

  return 0;
};

export const setCurrentTutorialStepCookie = (value) => {
  localStorage.setItem('tutorialStep', value);
};

export const getCurrentTutorialStepCookie = () => {
  const localStorageValue = localStorage.getItem('tutorialStep');

  if (localStorageValue) {
    try {
      return localStorageValue;
    } catch (error) {
      return '';
    }
  }

  return '';
};

// yet to be used
export const getNewUnitCookie = () => {
  return Cookies.get('customUnitWordList');
};

export const setNewUnitCookie = (value) => {
  Cookies.set('customUnitWordList', value);
};
