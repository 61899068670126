import React, { useContext } from 'react';
import './css/style.css';
import { useGlobalContext } from '../../../context/AppContext';
import CookieAgreement from '../../../utils/CookieAgreement';

function BadRequest() {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  return (
    <React.Fragment>
      <CookieAgreement />
      <div id="notfound">
        <div class="notfound">
          {languageFile === 'en' ? (
            <React.Fragment>
              <div class="notfound-404">
                <h3>Bad Request</h3>
                <h1>
                  <span>4</span>
                  <span>0</span>
                  <span>0</span>
                </h1>
              </div>
              <h2>
                we are sorry, but your request returned an error on your side
              </h2>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div class="notfound-404">
                <h3>הבקשה נכשלה</h3>
                <h1>
                  <span>4</span>
                  <span>0</span>
                  <span>0</span>
                </h1>
              </div>
              <h2>אנו מתנצלים, אבל הבקשה נכשלה מהצד שלכם</h2>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default BadRequest;
