import './StudyCard.css';

function StudyCard({ currentQuestionTitle, currentQuestionTranslate, onCardClick }) {
    return (
        <div className='study-card' onClick={onCardClick}>
            <div className='study-card-back'>
                <div className='study-card-text'>
                    {currentQuestionTranslate}
                </div>
            </div>
            <div className='study-card-front'>
                <div className='study-card-text'>
                    {currentQuestionTitle}
                </div>
            </div>
        </div>
    )

}

export default StudyCard;