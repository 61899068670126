import React, { useState, useEffect, useContext } from 'react';
import './PasswordRequirementsModal.css';
import { useGlobalContext } from '../../context/AppContext';
import { fetchResponseHandler } from '../../utils/fetchResponses';

function PasswordRequirementsModal({
  isRegister,
  setPasswordMissingRules,
  passwordMissingRules,
  setShowPasswordRequirements,
  showPasswordRequirements,
}) {
  const getMessage = (value) => {
    const messages = {
      isTenOrMoreChars:
        'Please make sure to have a password length of minimum 10 characters',
      hasLowercaseLetter:
        'Please make sure to have a password with at least one lowercase character',
      hasUppercaseLetter:
        'Please make sure to have a password with at least one uppercase character',
      hasDigit:
        'Please make sure to have a password with at least one digit character',
      hasSpecialChar:
        'Please make sure to have a password with at least one special character',
      doPasswordsMatch: 'Please make sure both passwords are the same',
      hasSpace: 'Please make sure to have a password with no space character',
    };

    if (messages.hasOwnProperty(value)) {
      return messages[value];
    }
  };

  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  if (Object.keys(passwordMissingRules).length === 0) {
    setShowPasswordRequirements(false);
  }

  return (
    <div
      className={`alert-overlay ${
        isRegister && 'register-password-modal'
      } password-modal-overlay fade-in-bottom-alert ${
        showPasswordRequirements ? 'visible' : ''
      }`}
    >
      <div
        id="passwordRequirementsModal"
        className={`alert-modal password-modal`}
      >
        <div className="alert-modal-icon-container password-icon-container">
          <i className="fa-solid fa-exclamation"></i>
        </div>
        <p
          dir="auto"
          className={`alert-modal-type alert-modal-type-information `}
        >
          {languageFile.badPassword}
        </p>
        <h3
          dir="auto"
          className={`alert-modal-description-regular alert-modal-type-password`}
        >
          {languageFile.passwordRules}
        </h3>
        <ul dir="auto" className={`alert-modal-information-list`}>
          {Object.entries(passwordMissingRules).map(([key, value]) => (
            <li className="alert-modal-information" key={key}>
              {languageFile === 'en'
                ? getMessage(key)
                : `${
                    key !== undefined && fetchResponseHandler(getMessage(key))
                  }`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PasswordRequirementsModal;
