import React, { useState, useEffect, useContext, useRef } from 'react';
import './mobile-verification.css';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import { useGlobalContext } from '../../../context/AppContext';
import HashLoader from 'react-spinners/HashLoader';
function MobileVerification({
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  openMobileVerification,
  setOpenMobileVerification,
}) {
  const { selectedLanguage } = useGlobalContext();

  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [showInput, setShowInput] = useState(false);
  const [showSendCode, setShowSendCode] = useState(true);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/settings/mobile', {
          method: 'POST',
        });

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (response.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setCurrentPhoneNumber(responseData.data);
          setMobileValue(currentPhoneNumber);
          setShowSendCode(true);
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    };

    fetchData(); // Call the async function
  }, [selectedLanguage, currentPhoneNumber]);

  const useVerificationCode = async () => {
    setShowCodeInput(true);
    setShowSendCode(false);
    setEditPhoneNumber(false);
  };

  const sendVerificationCode = async () => {
    setShowCodeInput(true);
    setShowSendCode(false);
    setEditPhoneNumber(false);

    // const loadingSpinner = document.getElementById("loading-spinner-register3");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('action', 'get_code');
      formData.append('mobile', currentPhoneNumber);

      const response = await fetch('/verify-mobile', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setShowCodeInput(false);
        setShowSendCode(true);
        setEditPhoneNumber(false);
      }

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('שגיאה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);

      setShowCodeInput(false);
      setShowSendCode(true);
      setEditPhoneNumber(false);
    } finally {
      setShowAnimation(false);
    }
  };

  const updateMobileValue = (event) => {
    setMobileValue(event.target.value);
  };

  const handleEditPhoneNumber = () => {
    setEditPhoneNumber(!editPhoneNumber);
    setShowCodeInput(false);
    setShowSendCode(false);
  };

  const handleUpdateMobileValue = async () => {
    const patternIsrael = /^(0[23489]|050|051|052|053|054|055|058)[0-9]{7}$/;

    const isMobileNumberRegexValid = patternIsrael.test(mobileValue);

    if (isMobileNumberRegexValid) {
      // const loadingSpinner = document.getElementById("loading-spinner-register3");
      // loadingSpinner.style.display = "flex";
      setShowAnimation(true);

      var submitBtn = document.getElementsByClassName('fa-check');
      submitBtn.disabled = true;

      try {
        const formData = new FormData();
        formData.append('mobile', mobileValue);

        const response = await fetch('/settings/mobile', {
          method: 'PATCH',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // loadingSpinner.style.display = "none";
        // resetReCAPTCHA()

        // You can handle the server response here if needed
        const responseData = await response.json();

        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setCurrentPhoneNumber(mobileValue);
          setEditPhoneNumber(false);

          setModalType('success');
          setModalTitle('success');
          setModalInformation('');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('הצלחה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } catch (error) {
        // resetReCAPTCHA()
        // loadingSpinner.style.display = "none";
        submitBtn.disabled = false;

        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } finally {
        setShowAnimation(false);
      }
    } else {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Please make sure to use a valid mobile number');
      } else {
        setModalDescription('אנא השתמשו במספר טלפון תקין');
        setModalTitle('שגיאה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const cancelEditPhoneNumber = () => {
    setMobileValue(currentPhoneNumber);
    setEditPhoneNumber(false);
    setShowSendCode(true);
  };

  const cancelVerificationAttempt = () => {
    setShowCodeInput(false);
    setShowSendCode(true);
    setEditPhoneNumber(false);
  };

  const [codeValue, setCodeValue] = useState('');
  const handleCodeValue = (event) => {
    setCodeValue(event.target.value.toUpperCase());
  };

  const handleVerifyMobile = async () => {
    // const loadingSpinner = document.getElementById("loading-spinner-register3");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('fa-check');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('code', codeValue);
      formData.append('action', 'verify');
      formData.append('mobile', currentPhoneNumber);

      const response = await fetch('/verify-mobile', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();

      if (responseData.success === true) {
        setShowCodeInput(false);
        setShowSendCode(true);
        setEditPhoneNumber(true);
        setOpenMobileVerification(false);
      }
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('שגיאה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);

      setShowCodeInput(false);
      setShowSendCode(true);
      setEditPhoneNumber(true);
    } finally {
      setShowAnimation(false);
    }
  };

  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <React.Fragment>
      {openMobileVerification && (
        <div
          className={`mobile-verification ${
            openMobileVerification ? 'visible' : ''
          }`}
        >
          {editPhoneNumber ? (
            <div className="phone-number-div">
              <i
                onClick={cancelEditPhoneNumber}
                className="fa-solid fa-xmark"
              ></i>
              <input
                type="tel"
                id="updateMobile"
                name="mobile"
                onChange={updateMobileValue}
                value={mobileValue}
                pattern="/^(0[23489]|050|051|052|053|054|055|058)[0-9]{7}$/"
                maxLength="35"
              />
              <i
                onClick={handleUpdateMobileValue}
                className="fa-solid fa-check"
              ></i>
            </div>
          ) : (
            <div className="phone-number-div">
              <p>{currentPhoneNumber}</p>
              <i
                onClick={handleEditPhoneNumber}
                className="fa-solid fa-pen-to-square"
              ></i>
            </div>
          )}
          {showCodeInput && (
            <React.Fragment>
              <input
                type="text"
                id="codeInput"
                name="code"
                onChange={handleCodeValue}
                value={codeValue}
                maxLength="6"
              />
              <button
                className="btn mobile-verify-btn"
                onClick={handleVerifyMobile}
              >
                {languageFile.verifyNow}
              </button>
            </React.Fragment>
          )}
          {showSendCode && (
            <React.Fragment>
              <button
                className="btn mobile-verify-btn"
                onClick={sendVerificationCode}
              >
                {languageFile.sendCode}
              </button>
              <button
                className="btn mobile-verify-btn"
                onClick={useVerificationCode}
              >
                {languageFile.useCode}
              </button>
            </React.Fragment>
          )}
          {/* <div className={`animation-parent`} id='loading-spinner-register3'>
                        <div className={`animation animation-circle`}></div>
                    </div> */}
          <div id="animationScreen" className={`loading-animation-general`}>
            <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default MobileVerification;
