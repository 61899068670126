import React, { createContext, useState, useEffect, useContext } from 'react';
import { getLanguageCookie } from '../utils/cookieUtils';

const GlobalContext = createContext();

// Easy to use function to access the context
export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({ children }) => {
  // States
  const [selectedLanguage, setSelectedLanguage] = useState('he'); // Default language

  // Effects
  useEffect(() => {
    // change stored language
    const storedLanguage = getLanguageCookie();
    if (storedLanguage) setSelectedLanguage(storedLanguage);
  }, []);

  // Returns
  return (
    <GlobalContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
