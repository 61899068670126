import React, { useState, useEffect, useContext, useRef } from 'react';
import './contactSection.css';
import { useGlobalContext } from '../../../context/AppContext';
import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import HashLoader from 'react-spinners/HashLoader';

function ContactSection({
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  id,
  className,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const captchaKey = '6LdQ1ssoAAAAAFngYC0GewMPQEFWqxs2IRH61Fj_';
  const recaptchaRef = useRef(null);
  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var submitBtn = document.getElementById('contactSubmit');
    submitBtn.disabled = true;

    // const loadingSpinner = document.getElementById("loading-spinner-login");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    try {
      const form = event.target;
      const formData = new FormData(form);
      formData.append('category', selectedCategory);

      const response = await fetch('/contact', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      resetReCAPTCHA();
      // loadingSpinner.style.display = "none";

      const responseData = await response.json();

      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        submitBtn.disabled = false;
        setIsModalClosed(false);
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription(
            'Thank you for contacting Milly. We will reach out soon'
          );
        } else {
          setModalTitle('הצלחה');
          setModalDescription('תודה שיצרתם קשר איתנו. נחזור אליכם בהקדם');
        }

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setIsModalClosed(false);
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('נכשל');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const tag = document.querySelector('.contact-title');
    if (tag) {
      const rect = tag.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Contact' : 'יצירת קשר'
    }`; // Set the title to "Milly | Contact"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const contactClicked = () => {
    const targetElement = document.getElementById('sectionContact');
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const [selectedCategory, setSelectedCategory] = useState('other');

  const handleCategoryChange = (newCategory) => {
    setSelectedCategory(newCategory);
    setSelectClicked(!selectClicked);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'var(--gray-200)',
      boxShadow: 'none',
      caretColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',
        backgroundColor: 'var(--gray-100)',
        transition: 'all 0.2s ease-in',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--primary-400)' : 'transparent',
      color: state.isFocused ? 'white' : 'black',
    }),
  };

  const categoryOptionsEnglish = [
    { code: 'security_and_bugs', label: 'Security and Bugs' },
    { code: 'billing', label: 'Billing' },
    { code: 'technical_support', label: 'Technical Support' },
    { code: 'account_management', label: 'Account Management' },
    { code: 'sales_and_marketing', label: 'Sales and Marketing' },
    { code: 'legal_and_compliance', label: 'Legal and Compliance' },
    { code: 'customer_service', label: 'Customer Service' },
    { code: 'other', label: 'Other' },
  ];

  const categoryOptionsHebrew = [
    { code: 'security_and_bugs', label: 'באגים ואבטחת מידע' },
    { code: 'billing', label: 'חיוביים' },
    { code: 'technical_support', label: 'תמיכה טכנית' },
    { code: 'account_management', label: 'ניהול משתמש' },
    { code: 'sales_and_marketing', label: 'מכירות ושיווק' },
    { code: 'legal_and_compliance', label: 'משפט וחוק' },
    { code: 'customer_service', label: 'שרות לקוחות' },
    { code: 'other', label: 'אחר' },
  ];

  const [selectClicked, setSelectClicked] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <section
      id={id}
      className={`section fade-in-right ${className ? className : ''} ${
        isVisible ? 'visible' : ''
      }`}
    >
      <p dir="auto" className="section-title contact-title">
        <a onClick={contactClicked}>{languageFile.contactTitle}</a>
      </p>
      <div className="title-underline"></div>
      <h1 dir="auto" className="section-sub-title contact-sub-title">
        {languageFile.contactSubTitle1}{' '}
        <span className="highlight-text">{languageFile.contactSubTitle2}</span>?
      </h1>
      <p dir="auto" className="section-info contact-info">
        {languageFile.contactAbout1}{' '}
        <span className="highlight-text">{languageFile.contactAbout2}</span>.{' '}
        {languageFile.contactAbout3}
      </p>
      <div className="section-content contact-content">
        <form
          className={`cform ${
            selectedLanguage === 'he'
              ? 'form-items-reverse cform-reverse-items'
              : ''
          }`}
          id="cform"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="form-row cform-cell1">
            <input
              maxLength="80"
              className="form-input"
              type="email"
              id="cemail"
              name="email"
              required
              placeholder={`${languageFile.emailPlaceholder} *`}
            />
          </div>
          <div
            className={`form-row cform-cell2 ${
              selectedLanguage === 'en' ? 'form-cell-english' : ''
            }`}
          >
            <input
              maxLength="35"
              className="form-input"
              type="text"
              id="cfname"
              name="first_name"
              required
              placeholder={`${languageFile.firstNamePlaceholder} *`}
            />
          </div>
          <div
            className={`form-row cform-cell3 ${
              selectedLanguage === 'en' ? 'form-cell-english' : ''
            }`}
          >
            <input
              maxLength="35"
              className="form-input"
              type="text"
              id="clname"
              name="last_name"
              required
              placeholder={`${languageFile.lastNamePlaceholder} *`}
            />
          </div>
          <div className="form-row cform-cell4">
            <textarea
              maxLength="300"
              className="form-textarea"
              id="cmessage"
              name="message"
              rows="4"
              cols="20"
              required
              placeholder={`${languageFile.messagePlaceholder} *`}
            ></textarea>
          </div>
          <div className="form-row cform-cell5">
            {selectedLanguage === 'en' ? (
              <Select
                id="contactCategory"
                value={categoryOptionsEnglish.find(
                  (option) => option.code === selectedCategory
                )}
                onChange={(selectedOption) =>
                  handleCategoryChange(selectedOption.code)
                }
                options={categoryOptionsEnglish}
                styles={customStyles}
                isSearchable={false}
              ></Select>
            ) : (
              <Select
                id="contactCategory"
                value={categoryOptionsHebrew.find(
                  (option) => option.code === selectedCategory
                )}
                onChange={(selectedOption) =>
                  handleCategoryChange(selectedOption.code)
                }
                options={categoryOptionsHebrew}
                styles={customStyles}
                isSearchable={false}
              ></Select>
            )}
          </div>
          <div className="recpatcha-container recaptcha-container-contactus">
            <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} />
          </div>
          <div className="form-row cform-cell5">
            <input
              className="btn btn-hollow cform-cell5 btn-block"
              type="submit"
              value={languageFile.contactSubmit}
              id="contactSubmit"
            />
          </div>
          {/* <div className={`animation-parent animation-parent-login`} id='loading-spinner-login'>
              <div className={`animation animation-circle`}></div>
            </div> */}
          <div id="animationScreen" className={`loading-animation-general`}>
            <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactSection;
