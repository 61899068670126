import React, { useState, useEffect, useContext } from 'react';
import './custom-unit-wordlist.css';
import { useGlobalContext } from '../../../context/AppContext';
import { getNewUnitCookie, setNewUnitCookie } from '../../../utils/cookieUtils';
import SearchBar from '../SearchBar/SearchBar';
import Results from '../SearchBar/Results/Results';

function CustomUnitWordList({
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  showAddNewWordMenu,
  setShowAddNewWordMenu,
  newWordsArray,
  setNewWordsArray,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const closeModal = () => {
    setShowAddNewWordMenu(!showAddNewWordMenu);
  };

  const [selectedWords, setSelectedWords] = useState([]);
  const appendWord = (event) => {
    const current_element = event.target;
    const current_element_parent = event.target.parentNode;
    // if (current_element.localName === "i" && current_element_parent.localName === "span"){
    //   if (newWordsArray.includes(item)) {
    //     // If the item is already in the array, remove it
    //     setMyArray(newWordsArray.filter((element) => element !== item));
    //   } else {
    //     // If the item is not in the array, add it
    //     setMyArray([...newWordsArray, item]);
    //   }
    // }
  };

  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm.length > 0) {
      // fetch(`/users/search/${searchTerm}`)
      //   .then((response) => response.json())
      //   .then((data) => setResults(data))
    }
  }, [searchTerm]);

  const handleWordAppend = () => {
    setShowAddNewWordMenu(false);
  };

  return (
    <div
      id="CustomWordListModal"
      className={`alert-modal ${showAddNewWordMenu ? 'visible' : ''}`}
    >
      <i
        id="closeWordListModal"
        className="fa-solid fa-xmark"
        onClick={closeModal}
      ></i>
      <p className="unit-title">{languageFile.selectWords}</p>
      <div className="search-bar-parent">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Results results={results} appendWord={appendWord} />
      </div>
      <button
        type="button"
        className="btn btn-search-new-words"
        onClick={handleWordAppend}
      >
        {languageFile.selectWordsButton}
      </button>
    </div>
  );
}

export default CustomUnitWordList;
