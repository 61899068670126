import React from 'react'

function ClassificationStudy({isFetching, currentQuestionClassification, finishedQuestions, currentQuestionId, handleClassificationClick }) {
    const matchingFinishedQuestion = finishedQuestions.find(item => item.id == currentQuestionId);

    const currentWordClassification = matchingFinishedQuestion ? matchingFinishedQuestion.classification : currentQuestionClassification;

    
    return (
        <ul className='classification-list'>
            <li onClick={handleClassificationClick} id="classifySad" className={`btn-classification-emoji ${isFetching ? "not-allowed-class" : "allowed-class"} ${currentWordClassification == 2 ? 'selected-classification' : 'grayed-out-classification'}`} ><i className="fa-solid fa-face-frown"></i></li>
            <li onClick={handleClassificationClick} id="classifyMeh" className={`btn-classification-emoji ${isFetching ? "not-allowed-class" : "allowed-class"} ${currentWordClassification == 1 ? 'selected-classification' : 'grayed-out-classification'}`}><i className="fa-solid fa-face-meh"></i></li>
            <li onClick={handleClassificationClick} id="classifyHappy" className={`btn-classification-emoji ${isFetching ? "not-allowed-class" : "allowed-class"} ${currentWordClassification == 0 ? 'selected-classification' : 'grayed-out-classification'}`}><i className="fa-solid fa-face-smile"></i></li>
        </ul>
    )
}

export default ClassificationStudy