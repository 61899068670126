import React, { useContext, useState, useEffect } from 'react';
import './PauseScreen.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  getQuestionsEndCookie,
  setExamSelectedAnswerCheckJSONCookie,
  setExamEndCookie,
  clearExamMode,
  setExamStopper,
  setExamPauseCookie,
  setExamStartedCookie,
  setExamCorrectQuestionsCounter,
  setExamIncorrectQuestionsCounter,
  setExamSelectedClassificationJSONCookie,
  getTopicCookie,
  setCurrentTutorialStepCookie,
} from '../../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';

function PauseScreen({
  setStepIndex,
  stepIndex,
  examSelectedClassificationJSON,
  setExamSelectedClassificationJSON,
  setShowAnimation,
  setQuestionsCounterCorrectAnswers,
  setQuestionsCounterIncorrectAnswers,
  setExamAnswersResults,
  setLoadedAnswers,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  examSelectedAnswerJSON,
  topic,
  setOpenStartScreen,
  setOpenEndScreen,
  stopperInterval,
  stopperCookieInterval,
  questionsCounter,
  setOpenPauseScreen,
  setCurrentStopper,
  currentStopper,
  setRunStopper,
  setStartExam,
  examTime,
  finishedQuestionsCounter,
  unit,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  const returnExamHandler = () => {
    if (buttonWorking) {
      setRunStopper(true);
      setOpenPauseScreen(false);
      setStartExam(true);

      setExamPauseCookie(false);
      setExamStartedCookie(true);
    }
  };

  const endExamHandler = async () => {
    if (finishedQuestionsCounter !== parseInt(getQuestionsEndCookie(), 10)) {
      setModalInformation('');
      setModalType('warning-exam');
      setModalTitle('exam warning');

      if (selectedLanguage === 'en') {
        setModalDescription(
          'please make sure to answer all the questions before finishing the exam'
        );
      } else {
        setModalDescription(
          'אנא ווידאו שאתם מסיימים את כל השאלות לפני סיום הבחינה'
        );
        setModalTitle('שימו לב');
      }

      setStepIndex(parseInt(stepIndex, 10) === 13 ? 14 : stepIndex);
      setCurrentTutorialStepCookie(
        parseInt(stepIndex, 10) === 13 ? 14 : stepIndex
      );

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);

      return;
    }

    setShowAnimation(true);

    try {
      const response = await fetch(`/word/exam/${topic}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: Object.fromEntries(
            Object.entries(examSelectedAnswerJSON).filter(
              ([key, value]) => value !== null
            )
          ),
        }),
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setExamSelectedAnswerCheckJSONCookie(responseData.data);
        setExamAnswersResults(responseData.data);
        setLoadedAnswers(true);

        let correctCounter = 0;
        let incorrectCounter = 0;

        Object.entries(responseData.data).forEach(([key, value]) => {
          if (value[0]) {
            examSelectedClassificationJSON[key] = value[1];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            correctCounter++;
          } else {
            examSelectedClassificationJSON[key] = value[2];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            incorrectCounter++;
          }
        });
        setQuestionsCounterCorrectAnswers(correctCounter);
        setQuestionsCounterIncorrectAnswers(incorrectCounter);
        setExamCorrectQuestionsCounter(correctCounter);
        setExamIncorrectQuestionsCounter(incorrectCounter);

        setExamEndCookie(true);
        setOpenEndScreen(true);
        setExamPauseCookie(false);
        setOpenPauseScreen(false);
        setExamStartedCookie(false);
        setOpenStartScreen(false);
        setStartExam(false);
      }
    } catch (error) {
      console.error(error);

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [buttonWorking, setButtonWorking] = useState(true);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const storedTopic = getTopicCookie();

  const abortExamHandler = async () => {
    try {
      setShowAnimation(true);
      const response = await fetch(
        `/update-classification-counter/${storedTopic}`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setModalInformation('');
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription('the exam was aborted successfully');
        } else {
          setModalDescription('הבחינה נמחקה בהצלחה');
          setModalTitle('בהצלחה');
        }

        setIsModalClosed(false);
        clearExamMode();
        clearInterval(stopperInterval);
        clearInterval(stopperCookieInterval);
        setExamStopper(0);

        setTimeout(() => {
          setIsModalClosed(true);
          window.location = '/environment';
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  return (
    <div id="examPauseScreen">
      <div id="pauseScreenPanel">
        <h1 dir="auto">{languageFile.pauseExam}</h1>
        <div id="examEndedData">
          <div id="examDetails">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examDetails}
            </p>
            <ul>
              {examTime === '0' ? (
                <li dir="auto" id="timeLimit">
                  {languageFile.noTimeLimit}
                </li>
              ) : (
                <li dir="auto" id="timeLimit">
                  {languageFile.unitExamTitle} {examTime} {languageFile.minutes}
                </li>
              )}
              <li dir="auto" id="questionsCounter">
                {questionsCounter} {languageFile.questions}
              </li>
              <li dir="auto" id="questionsUnit">
                {languageFile.unit} {unit.substring(5)}
              </li>
            </ul>
          </div>
          <div id="examDetails">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examProgress}
            </p>
            <ul>
              <li dir="auto" id="timeStopper">
                {formatTime(currentStopper)} {languageFile.passed}
              </li>
              <li dir="auto" id="questionsCompleted">
                {finishedQuestionsCounter} {languageFile.finishedQuestions}
              </li>
            </ul>
          </div>
        </div>

        <div id="pauseScreenButtons">
          <button
            id="startExamBtn"
            onClick={returnExamHandler}
            className={`btn btn-start-screen ${
              buttonWorking ? '' : 'gray-out-btn'
            }`}
          >
            {languageFile.returnExam}
          </button>
          <button
            id="endExamBtn"
            onClick={endExamHandler}
            className={`btn btn-end-exam ${
              buttonWorking ? '' : 'gray-out-btn'
            }`}
          >
            {languageFile.endExam}
          </button>
        </div>
        <p className="abort-exam-p" onClick={abortExamHandler}>
          {languageFile.stopTheExam}
        </p>
      </div>
    </div>
  );
}

export default PauseScreen;
