import React, { useState, useEffect, useContext, useRef } from 'react';
import ProgressBarMemorize from './ProgressBarExam/ProgressBarExam';
import { useGlobalContext } from '../../../context/AppContext';
import {
  getExamEndCookie,
  getExamSelectedAnswerCheckJSONCookie,
  setExamEndCookie,
  setExamSelectedAnswerCheckJSONCookie,
  getFinishedQuestionsCounter,
  setFinishedQuestionsCounterCookie,
  setExamSelectedAnswerJSONCookie,
  getExamSelectedAnswerJSONCookie,
  getExamPauseCookie,
  setExamStartedCookie,
  setExamPauseCookie,
  getExamStartedCookie,
  getExamStopper,
  setExamStopper,
  setFinishedQuestions,
  getStudyModeCookie,
  getStudyUnitCookie,
  getQuestionIdExamCookie,
  getMemorizationClassificationCookie,
  getCurrectQuestionCookie,
  getFinishedQuestions,
  setExamCorrectQuestionsCounter,
  setExamIncorrectQuestionsCounter,
  setQuestionIdExamCookie,
  setStartTutorialCookie,
  setCurrentTutorialStepCookie,
  getStartTutorialCookie,
  getCurrentTutorialStepCookie,
} from '../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import Classification from '../Classification/Classification';
import PauseScreen from './PauseScreen/PauseScreen';
import StartScreen from './StartScreen/StartScreen';
import EndScreen from './EndScreen/EndScreen';
import Association from '../Association/Association';
import './ExamMode.css';
import data from '../../Tutorial/TutorialSteps.json';
import data2 from '../../Tutorial/TutorialStepsHebrew.json';
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from 'react-joyride';

function ExamMode({
  showAnimation,
  questionsJsonArray,
  numberOfQuestions,
  setShowNextPrevButtons,
  showNextPrevButtons,
  checkAnswers,
  examSelectedClassificationJSON,
  setExamSelectedClassificationJSON,
  setShowAnimation,
  setQuestionsCounterCorrectAnswers,
  setQuestionsCounterIncorrectAnswers,
  questionsCounterCorrectAnswers,
  questionsCounterIncorrectAnswers,
  loadedAnswers,
  setLoadedAnswers,
  examAnswersResults,
  setExamAnswersResults,
  topic,
  openEndScreen,
  setOpenEndScreen,
  examSelectedAnswerJSON,
  setSxamSelectedAnswerJSON,
  setCurrentSelectedOptionAnswer,
  stopRefInterval,
  setStopRefInterval,
  setStopperCookieInterval,
  stopperCookieInterval,
  stopperInterval,
  setStopperInterval,
  startExam,
  setStartExam,
  openPauseScreen,
  setOpenPauseScreen,
  openStartScreen,
  setOpenStartScreen,
  currentStopper,
  setCurrentStopper,
  runStopper,
  setRunStopper,
  finishedQuestionsCounter,
  setFinishedQuestionsCounter,
  questionsLoaded,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  showClassification,
  setShowClassification,
  finishedQuestions,
  currentQuestionID,
  setCurrentQuestionID,
  openProgressBar,
  setOpenProgressBar,
  handleOpenProgressBar,
  currentQuestions,
  currentQuestionTitle,
  setCurrentQuestionTitle,
  currentQuestionAnswers,
  setCurrentQuestionAnswers,
  questionsCounter,
  setQuestionsCounter,
  examTime,
  setExamTime,
  unit,
  setUnit,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const currentUnit = getStudyUnitCookie();
  const learningMode = getStudyModeCookie();

  const [correctAnswerSelected, setCorrectAnswerSelected] = useState(false);

  const handleOptionClick = (event) => {
    const options_parent = event.target.parentNode.children;
    const clicked_element = event.target;
    const elementId = clicked_element.id;

    let isTheSame = false;

    Array.from(options_parent).forEach((element) => {
      if (element.id !== elementId) element.classList.remove('selected');
      else {
        const tempArray = [...element.classList];
        if (tempArray.includes('selected')) {
          isTheSame = true;

          element.classList.remove('selected');
          setCurrentSelectedOptionAnswer('');

          const originalID = findGetInArray(
            getCurrectQuestionCookie(),
            currentQuestionID.substring(2)
          ).original_id;
          if (Object.keys(examSelectedAnswerJSON).includes(`${originalID}`)) {
            const newQuestionCounter = finishedQuestionsCounter - 1;
            setFinishedQuestionsCounter(newQuestionCounter);
            setFinishedQuestionsCounterCookie(newQuestionCounter);

            delete examSelectedAnswerJSON[originalID];

            setSxamSelectedAnswerJSON(examSelectedAnswerJSON);
            setExamSelectedAnswerJSONCookie(examSelectedAnswerJSON);

            document
              .getElementById(currentQuestionID)
              .children[0].classList.remove('done-question');
            // setShowNextPrevButtons(false);
          }
        }
      }
    });

    if (isTheSame) return;

    clicked_element.classList.add('selected');
    setCurrentSelectedOptionAnswer(elementId);

    const originalID = findGetInArray(
      getCurrectQuestionCookie(),
      currentQuestionID.substring(2)
    ).original_id;

    if (!Object.keys(examSelectedAnswerJSON).includes(`${originalID}`)) {
      const newQuestionCounter = finishedQuestionsCounter + 1;
      setFinishedQuestionsCounter(newQuestionCounter);
      setFinishedQuestionsCounterCookie(newQuestionCounter);
    }

    examSelectedAnswerJSON[originalID] = clicked_element.innerHTML;
    setSxamSelectedAnswerJSON(examSelectedAnswerJSON);
    setExamSelectedAnswerJSONCookie(examSelectedAnswerJSON);

    document
      .getElementById(currentQuestionID)
      .children[0].classList.add('done-question');

    document.getElementById('btnBack').classList.add('next-btn-animation');
    setTimeout(() => {
      document.getElementById('btnBack').classList.remove('next-btn-animation');
    }, 3000);

    // setShowNextPrevButtons(true);
  };

  const findIndexInArray = (array, questionID) => {
    const newQuestionID = parseInt(questionID, 10);
    const element = array.find((json) => json.question_id === newQuestionID);
    const index = array.indexOf(element);
    return index;
  };

  const findGetInArray = (array, questionID) => {
    const newQuestionID = parseInt(questionID, 10);
    return array.find((json) => json.question_id === newQuestionID);
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (!openEndScreen && openStartScreen) setShowAnimation(true);
    isMounted.current = true;
  }, []);

  // const [openStartScreen, setOpenStartScreen] = useState(false);

  useEffect(() => {
    if (startExam && openEndScreen === false) {
      setOpenStartScreen(false);
      // setRunStopper(true);
    }
  }, [setOpenStartScreen, setRunStopper, startExam]);

  let stopperIntervalRef = null;

  useEffect(() => {
    if (runStopper && openEndScreen === false) {
      let time = null;

      stopperIntervalRef = setInterval(() => {
        const newTime = (seconds) => seconds + 1;
        setCurrentStopper(newTime); // Update currentStopper with the new function
        time = currentStopper;
        setExamStopper(newTime(time)); // Update examStopper with the current value
      }, 1000);

      setStopperInterval(stopperIntervalRef);
    } else if (
      runStopper === false &&
      openPauseScreen === false &&
      startExam === false
    ) {
      clearInterval(stopperInterval);
      clearInterval(stopperIntervalRef);
      // setCurrentStopper(0);
      // setExamStopper(0);
    }

    return () => {
      clearInterval(stopperInterval);
      clearInterval(stopperIntervalRef);
    };
  }, [runStopper, currentStopper, openPauseScreen, startExam, stopRefInterval]);

  const pauseScreenHandler = () => {
    setStepIndex(parseInt(stepIndex, 10) === 10 ? 11 : stepIndex);
    setCurrentTutorialStepCookie(
      parseInt(stepIndex, 10) === 10 ? 11 : stepIndex
    );

    setRunStopper(false);
    setOpenPauseScreen(true);
    setStartExam(false);
    setExamPauseCookie(true);
    setExamStartedCookie(false);

    clearInterval(stopperInterval);
    clearInterval(stopperCookieInterval);
    // clearInterval(cookieInterval);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const syncStopper = async (currentStop) => {
    const filteredArray = Object.entries(getExamSelectedAnswerJSONCookie())
      .filter(([key, value]) => key !== null && value !== null)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    setExamSelectedAnswerJSONCookie(filteredArray);
    const response = await fetch(
      `/start-exam/${topic}?unit_id=${currentUnit}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stopper: currentStop,
          answered_questions_json_array: filteredArray,
          current_question_id: parseInt(
            getQuestionIdExamCookie().substring(2),
            10
          ),
        }),
      }
    );
  };

  useEffect(() => {
    if (examTime * 60 === 0) {
      if (currentStopper % 5 === 0 && currentStopper > 0) {
        syncStopper(currentStopper);
      }

      return;
    }

    const timeLeftMinutes = parseInt((examTime * 60 - currentStopper) / 60, 10);
    const timeLeftSeconds = parseInt(examTime * 60 - currentStopper, 10);

    let lessThanMinute = false;

    if (timeLeftMinutes === 0 && timeLeftSeconds > 0) {
      lessThanMinute = true;
    }

    if (currentStopper % 5 === 0 && currentStopper > 0) {
      syncStopper(currentStopper);
    }

    switch (currentStopper) {
      case parseInt(0.25 * examTime * 60, 10):
      case parseInt(0.5 * examTime * 60, 10):
      case parseInt(0.75 * examTime * 60, 10):
        setModalInformation('');
        setModalType('success');
        if (selectedLanguage === 'en') {
          setModalTitle('Timer');
        } else {
          setModalTitle('טיימר');
        }

        if (selectedLanguage === 'en') {
          setModalDescription(
            lessThanMinute
              ? `${timeLeftSeconds} seconds left`
              : `${timeLeftMinutes} minutes left`
          );
        } else {
          setModalDescription(
            lessThanMinute
              ? `${timeLeftSeconds} שניות נותרו`
              : `${timeLeftMinutes} דקות נותרו`
          );
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 3000);

        break;

      case parseInt(1 * examTime * 60, 10):
        if (getExamEndCookie()) {
          return;
        }

        setModalInformation('');
        setModalType('warning');
        if (selectedLanguage === 'en') {
          setModalTitle('Timer');
        } else {
          setModalTitle('טיימר');
        }

        if (selectedLanguage === 'en') {
          setModalDescription(
            `Time's up, you are being redirected to the results section`
          );
        } else {
          setModalDescription(`הסתיים הזמן, אתם מועברים לחלון התוצאות`);
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 3000);

        clearInterval(stopperInterval);
        clearInterval(stopperIntervalRef);

        checkAnswers();

        break;

      default:
        break;
    }
  }, [currentStopper, examTime]);

  useEffect(() => {
    if (openEndScreen === true) {
      setOpenStartScreen(false);
      setOpenPauseScreen(false);
    }
  }, [openEndScreen]);

  let isFunctionRunningOpenQuestion = false;
  let isSelected = false;

  const handleNextQuestionClick = () => {
    const nextQuestion = parseInt(currentQuestionID.substring(2), 10) + 1;
    const currentQuestionsCookieTemp = getCurrectQuestionCookie();

    if (nextQuestion >= currentQuestionsCookieTemp.length + 1) {
      document.getElementById('btnBack').classList.add('gray-out-button');
      document.getElementById('btnNext').classList.remove('gray-out-button');
      return;
    }

    if (nextQuestion >= currentQuestionsCookieTemp.length) {
      document.getElementById('btnBack').classList.add('gray-out-button');
      document.getElementById('btnNext').classList.remove('gray-out-button');
    } else {
      document.getElementById('btnBack').classList.remove('gray-out-button');
      document.getElementById('btnNext').classList.remove('gray-out-button');
    }

    finishedQuestions = getFinishedQuestions();

    isFunctionRunningOpenQuestion = true;

    const questionsClass = 'progress-bar-item';

    let questionsCollection = document.getElementsByClassName(questionsClass);
    let questionsArray = Array.from(questionsCollection);
    questionsArray.forEach((element) => {
      element.classList.add('disable-progress-bar');
      element.classList.remove('current-question');
    });

    document
      .getElementById(`q-${nextQuestion}`)
      .children[0].classList.add('current-question');
    setCurrentQuestionID(`q-${nextQuestion}`);
    setQuestionIdExamCookie(`q-${nextQuestion}`);

    let questionAnswersCollection =
      document.getElementsByClassName('question-option');
    Array.from(questionAnswersCollection).forEach((element) => {
      element.classList.remove('selected');
    });

    const currentOriginalID = findGetInArray(
      getCurrectQuestionCookie(),
      parseInt(nextQuestion, 10)
    ).original_id;

    if (
      Object.keys(examSelectedAnswerJSON).includes(currentOriginalID.toString())
    ) {
      Array.from(questionAnswersCollection).forEach((element) => {
        setTimeout(() => {
          if (element.innerHTML === examSelectedAnswerJSON[currentOriginalID]) {
            element.classList.add('selected');
            setCurrentSelectedOptionAnswer(element.id);

            isSelected = true;
          }
        }, 0);
      });
    }

    // setTimeout(() => {
    //   if (isSelected)
    //     setShowNextPrevButtons(true);
    //   else
    //     setShowNextPrevButtons(false);
    // }, 0);

    try {
      const currentQuestionsCookie = getCurrectQuestionCookie();

      if (currentQuestionsCookie) {
        const matchingItem = findInArray(currentQuestionsCookie, nextQuestion);

        if (matchingItem) {
          const getItem = findGetInArray(currentQuestionsCookie, nextQuestion);
          setCurrentQuestionTitle(getItem.question);
          setCurrentQuestionAnswers(getItem.answers);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFunctionRunningOpenQuestion = false;
      setShowAnimation(false);
      questionsArray.forEach((element) => {
        element.classList.remove('disable-progress-bar');
      });
    }
  };

  const handlePrevQuestionClick = () => {
    const prevQuestion = parseInt(currentQuestionID.substring(2), 10) - 1;
    if (prevQuestion <= 0) {
      document.getElementById('btnNext').classList.add('gray-out-button');
      document.getElementById('btnBack').classList.remove('gray-out-button');
      return;
    }

    if (prevQuestion <= 1) {
      document.getElementById('btnNext').classList.add('gray-out-button');
      document.getElementById('btnBack').classList.remove('gray-out-button');
    } else {
      document.getElementById('btnNext').classList.remove('gray-out-button');
      document.getElementById('btnBack').classList.remove('gray-out-button');
    }

    finishedQuestions = getFinishedQuestions();

    isFunctionRunningOpenQuestion = true;

    const questionsClass = 'progress-bar-item';

    let questionsCollection = document.getElementsByClassName(questionsClass);
    let questionsArray = Array.from(questionsCollection);
    questionsArray.forEach((element) => {
      element.classList.add('disable-progress-bar');
      element.classList.remove('current-question');
    });

    document
      .getElementById(`q-${prevQuestion}`)
      .children[0].classList.add('current-question');
    setCurrentQuestionID(`q-${prevQuestion}`);
    setQuestionIdExamCookie(`q-${prevQuestion}`);

    let questionAnswersCollection =
      document.getElementsByClassName('question-option');
    Array.from(questionAnswersCollection).forEach((element) => {
      element.classList.remove('selected');
    });

    const currentOriginalID = findGetInArray(
      getCurrectQuestionCookie(),
      parseInt(prevQuestion, 10)
    ).original_id;
    if (
      Object.keys(examSelectedAnswerJSON).includes(currentOriginalID.toString())
    ) {
      Array.from(questionAnswersCollection).forEach((element) => {
        setTimeout(() => {
          if (element.innerHTML === examSelectedAnswerJSON[currentOriginalID]) {
            element.classList.add('selected');
            setCurrentSelectedOptionAnswer(element.id);

            isSelected = true;
          }
        }, 0);
      });
    }

    // setTimeout(() => {
    //   if (isSelected)
    //     setShowNextPrevButtons(true);
    //   else
    //     setShowNextPrevButtons(false);
    // }, 0);

    try {
      const currentQuestionsCookie = getCurrectQuestionCookie();

      if (currentQuestionsCookie) {
        const matchingItem = findInArray(currentQuestionsCookie, prevQuestion);

        if (matchingItem) {
          const getItem = findGetInArray(currentQuestionsCookie, prevQuestion);
          setCurrentQuestionTitle(getItem.question);
          setCurrentQuestionAnswers(getItem.answers);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFunctionRunningOpenQuestion = false;
      setShowAnimation(false);
      questionsArray.forEach((element) => {
        element.classList.remove('disable-progress-bar');
      });
    }
  };

  const findInArray = (array, questionID) => {
    const verify = array.find((json) => json.question_id === questionID);

    if (verify) return true;
    return false;
  };

  // Tutorial (joyride)
  const [tutorialStep, setTutorialStep] = useState(
    parseInt(getCurrentTutorialStepCookie(), 10)
  );

  const [startTutorial, setStartTutorial] = useState(getStartTutorialCookie());
  const [jsonData, setJsonData] = useState(
    selectedLanguage === 'en' ? data : data2
  );
  const [steps, setSteps] = useState(jsonData['1.4']);
  const [stepIndex, setStepIndex] = useState(parseInt(tutorialStep, 10));
  const [run, setRun] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const [reloadEffect, setReloadEffect] = useState(false);

  useEffect(() => {
    if (!reloadEffect) return;

    setTimeout(() => {
      setRun(true);
      setReloadEffect(false);
    }, 800);
  }, [reloadEffect]);

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      return;
    }

    switch (startTutorial) {
      case '1': // Exam Mode
        if (openPauseScreen && parseInt(stepIndex, 10) < 11) {
          break;
        }

        if (showAnimation) {
          break;
        }

        if (parseInt(stepIndex, 10) === 4) {
          setStepIndex(0);
          setCurrentTutorialStepCookie(0);
          setReloadEffect(true);
        } else if (parseInt(stepIndex, 10) === 0) {
          setTimeout(() => {
            setRun(true);
          }, 800);
        } else {
          setTimeout(() => {
            setRun(true);
          }, 800);
        }

        break;

      case '2': // Study Mode
        break;

      case '3': // Memorize Mode
        break;

      default:
        break;
    }
  }, [startTutorial, pageLoaded, openPauseScreen, showAnimation]);

  useEffect(() => {
    if (!pageLoaded) setPageLoaded(true);
  }, [pageLoaded]);

  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      switch (startTutorial) {
        case '1': // Exam Mode
          if (index === 0) {
            setStepIndex(nextStep);
            setCurrentTutorialStepCookie(nextStep);

            setTimeout(() => {
              setRun(true);
            }, 400);
          } else {
            setStepIndex(nextStep);
            setCurrentTutorialStepCookie(nextStep);
          }

          break;

        case '2': // Study Mode
          break;

        case '3': // Memorize Mode
          break;

        default:
          break;
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setStepIndex(0);
      setCurrentTutorialStepCookie(0);
      setStartTutorial('-1');
      setStartTutorialCookie('-1');
    }
  };

  const defaultJoyOptions = {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#f04',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    width: undefined,
    zIndex: 100,
  };
  return (
    <React.Fragment>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showSkipButton
        stepIndex={stepIndex}
        disableOverlayClose={true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10600,
            primaryColor: '#1A56DB',
          },
        }}
      />

      {openStartScreen && (
        <StartScreen
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          questionsJsonArray={questionsJsonArray}
          numberOfQuestions={numberOfQuestions}
          setShowAnimation={setShowAnimation}
          stopperInterval={stopperInterval}
          stopperCookieInterval={stopperCookieInterval}
          setOpenStartScreen={setOpenStartScreen}
          setRunStopper={setRunStopper}
          questionsLoaded={questionsLoaded}
          openStartScreen={openStartScreen}
          setStartExam={setStartExam}
          unit={unit}
          questionsCounter={questionsCounter}
          examTime={examTime}
        />
      )}
      {openPauseScreen && (
        <PauseScreen
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          examSelectedClassificationJSON={examSelectedClassificationJSON}
          setExamSelectedClassificationJSON={setExamSelectedClassificationJSON}
          setShowAnimation={setShowAnimation}
          setQuestionsCounterCorrectAnswers={setQuestionsCounterCorrectAnswers}
          setQuestionsCounterIncorrectAnswers={
            setQuestionsCounterIncorrectAnswers
          }
          setLoadedAnswers={setLoadedAnswers}
          setExamAnswersResults={setExamAnswersResults}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          examSelectedAnswerJSON={examSelectedAnswerJSON}
          topic={topic}
          setOpenStartScreen={setOpenStartScreen}
          setOpenEndScreen={setOpenEndScreen}
          stopperInterval={stopperInterval}
          stopperCookieInterval={stopperCookieInterval}
          setOpenPauseScreen={setOpenPauseScreen}
          setCurrentStopper={setCurrentStopper}
          currentStopper={currentStopper}
          setRunStopper={setRunStopper}
          setStartExam={setStartExam}
          unit={unit}
          questionsCounter={questionsCounter}
          finishedQuestionsCounter={finishedQuestionsCounter}
          examTime={examTime}
        />
      )}
      {openEndScreen && (
        <EndScreen
          setShowAnimation={setShowAnimation}
          examSelectedClassificationJSON={examSelectedClassificationJSON}
          setExamSelectedClassificationJSON={setExamSelectedClassificationJSON}
          examSelectedAnswerJSON={examSelectedAnswerJSON}
          loadedAnswers={loadedAnswers}
          setLoadedAnswers={setLoadedAnswers}
          examAnswersResults={examAnswersResults}
          stopperInterval={stopperInterval}
          stopperCookieInterval={stopperCookieInterval}
          findGetInArray={findGetInArray}
          currentQuestionID={currentQuestionID}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          questionsCounterCorrectAnswers={questionsCounterCorrectAnswers}
          questionsCounterIncorrectAnswers={questionsCounterIncorrectAnswers}
          setOpenPauseScreen={setOpenPauseScreen}
          setCurrentStopper={setCurrentStopper}
          currentStopper={currentStopper}
          setRunStopper={setRunStopper}
          setStartExam={setStartExam}
          unit={unit}
          questionsCounter={questionsCounter}
          finishedQuestionsCounter={finishedQuestionsCounter}
          examTime={examTime}
        />
      )}
      <button className="btn btn-start-screen2" onClick={pauseScreenHandler}>
        {languageFile.pauseExamBtn}
      </button>
      <i
        className="fa-solid fa-hand btn-start-screen2-icon"
        onClick={pauseScreenHandler}
      ></i>
      <div className="open-progress-btn">
        <i
          className="fa-solid fa-list-check"
          onClick={handleOpenProgressBar}
        ></i>
      </div>
      <div id="study-container" className="study-container">
        <div className="study-container-main">
          <div id="examStopperTextHolder" className="exam-stopper-text-holder">
            {examTime * 60 > 0 ? (
              <React.Fragment>
                <h1 className="examStopperText">
                  {formatTime(examTime * 60 - currentStopper)[0]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(examTime * 60 - currentStopper)[1]}
                </h1>
                <h1 className="examStopperTextSep">
                  {formatTime(examTime * 60 - currentStopper)[2]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(examTime * 60 - currentStopper)[3]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(examTime * 60 - currentStopper)[4]}
                </h1>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h1 className="examStopperText">
                  {formatTime(currentStopper)[0]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(currentStopper)[1]}
                </h1>
                <h1 className="examStopperTextSep">
                  {formatTime(currentStopper)[2]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(currentStopper)[3]}
                </h1>
                <h1 className="examStopperText">
                  {formatTime(currentStopper)[4]}
                </h1>
              </React.Fragment>
            )}
          </div>
          {currentQuestionID && (
            <React.Fragment>
              <h1 dir="auto" className="study-word-title" id="wordTarget">
                {currentQuestionTitle}
              </h1>
              <div className={`questions-and-arrows`}>
                <div
                  onClick={handlePrevQuestionClick}
                  className={`study-arrows-container ${
                    showNextPrevButtons ? 'show' : ''
                  }`}
                >
                  <i
                    id="btnNext"
                    className="fa-solid fa-arrow-left study-arrows"
                  ></i>
                </div>
                <ul className="question-option-list">
                  <li
                    dir="auto"
                    onClick={handleOptionClick}
                    id="AnswerOption1"
                    className="question-option question-option-top"
                  >
                    {currentQuestionAnswers[0]}
                  </li>
                  <li
                    dir="auto"
                    onClick={handleOptionClick}
                    id="AnswerOption2"
                    className="question-option question-option-middle"
                  >
                    {currentQuestionAnswers[1]}
                  </li>
                  <li
                    dir="auto"
                    onClick={handleOptionClick}
                    id="AnswerOption3"
                    className="question-option question-option-end"
                  >
                    {currentQuestionAnswers[2]}
                  </li>
                </ul>
                <div
                  onClick={handleNextQuestionClick}
                  className={`study-arrows-container ${
                    showNextPrevButtons ? 'show' : ''
                  }`}
                >
                  <i
                    id="btnBack"
                    className="fa-solid fa-arrow-right study-arrows"
                  ></i>
                </div>
              </div>
            </React.Fragment>
          )}
          <a
            dir="auto"
            href="/feedback"
            target="_blank"
            className="found-a-mistake"
          >
            {languageFile.foundAMistake}?
          </a>
          <Association
            setIsModalClosed={setIsModalClosed}
            setModalInformation={setModalInformation}
            setModalType={setModalType}
            setModalTitle={setModalTitle}
            setModalDescription={setModalDescription}
            currentQuestionID={currentQuestionID}
          />
          <p dir="auto" id="pContinue" className={`p-continue`}>
            {languageFile.youCanContinue}..
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ExamMode;
