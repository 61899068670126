import React, { useEffect, useState, useContext } from 'react';
import './environment.css';
import {
  getClientPlan,
  setSettingsCookie,
  getSessionCookie,
  verifySessionCookie,
  getTopicCookie,
  setTopicCookie,
  setPageCookie,
  deleteSessionCookie,
  getClientName,
  getFinishedUnitsCookie,
  getCurrentTutorialStepCookie,
  getStartTutorialCookie,
  setStartTutorialCookie,
  setCurrentTutorialStepCookie,
} from '../../utils/cookieUtils';
import { useGlobalContext } from '../../context/AppContext';
import CookieAgreement from '../../utils/CookieAgreement';
import AlertModal from '../AlertModal/AlertModal';
import UnitOptionsModal from './UnitOptionsModal/UnitOptionsModal';
import NavBar from '../NavBar/NavBar';
import AddCustomUnit from './AddCustomUnit/AddCustomUnit';
import CustomUnitWordList from './CustomUnitWordList/CustomUnitWordList';
import MemorizationClassification from './MemorizationClassification/MemorizationClassification';
import LoggedFooter from './LoggedFooter/LoggedFooter';
import ExamTimeLimits from './ExamTimeLimits/ExamTimeLimits';
import data from '../Tutorial/TutorialSteps.json';
import data2 from '../Tutorial/TutorialStepsHebrew.json';
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from 'react-joyride';

function Environment() {
  const token = getSessionCookie();
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [validToken, setValidToken] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setValidToken(true);
  }, []);

  useEffect(() => {
    document.title = `Milly | ${selectedLanguage === 'en' ? 'Environment' : 'סביבת למידה'
      }`; // Set the title to "Milly | Home"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const storedTopic = getTopicCookie();
  const [modelEActive, setModelEActive] = useState(
    storedTopic === 'model_e' ? true : false
  );
  const [psychometryActive, setPsychometryActive] = useState(
    storedTopic === 'psychometry' ? true : false
  );
  const [customActive, setCustomActive] = useState(
    storedTopic === 'custom' ? true : false
  );

  const [tutorialStep, setTutorialStep] = useState(
    getCurrentTutorialStepCookie()
  );

  const [startTutorial, setStartTutorial] = useState(getStartTutorialCookie());
  const [jsonData, setJsonData] = useState(
    selectedLanguage === 'en' ? data : data2
  );
  const [steps, setSteps] = useState(jsonData[startTutorial]);
  const [stepIndex, setStepIndex] = useState(tutorialStep);
  const [run, setRun] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [reloadEffect, setReloadEffect] = useState(false);

  useEffect(() => {
    if (!reloadEffect) return;

    setTimeout(() => {
      setRun(true);
      setReloadEffect(false);
    }, 800);
  }, [reloadEffect]);

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      return;
    }

    switch (startTutorial) {
      case '1': // Exam Mode
        if (parseInt(stepIndex, 10) === 0) {
          setTimeout(() => {
            setRun(true);
          }, 800);
        } else {
          setStepIndex(0);
          setCurrentTutorialStepCookie(0);
          setReloadEffect(true);
        }

        break;

      case '2': // Study Mode
        break;

      case '3': // Memorize Mode
        break;

      default:
        break;
    }
  }, [startTutorial, pageLoaded]);

  useEffect(() => {
    if (!pageLoaded) setPageLoaded(true);
  }, [pageLoaded]);

  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      switch (startTutorial) {
        case '1': // Exam Mode
          if (index === 0) {
            setTimeout(() => {
              setRun(true);
            }, 400);
          } else if (index === 2) {
            setShowUnitOptionsModal(true);

            const target = document.getElementsByClassName(
              'unit-options-list-modes'
            );

            setStepIndex(nextStep);
            setCurrentTutorialStepCookie(nextStep);
          } else if (index == 4) {
            setCurrentTutorialStepCookie(4);
          } else {
            setStepIndex(nextStep);
            setCurrentTutorialStepCookie(nextStep);
          }

          break;

        case '2': // Study Mode
          break;

        case '3': // Memorize Mode
          break;

        default:
          break;
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      if (index !== 4) {
        setRun(false);
        setStepIndex(0);
        setCurrentTutorialStepCookie(0);
        setStartTutorial('-1');
        setStartTutorialCookie('-1');
      }
    }
  };

  const selectEnglishModelEHandler = () => {
    setModelEActive(true);
    setPsychometryActive(false);
    setCustomActive(false);

    setTopicCookie('model_e');
    setTimeout(() => {
      setIsVisibleBgImage(false);
    }, 100);

    setTimeout(() => {
      setIsHideBgImage(true);
    }, 100);

    const childrenCollection = document.getElementById('unitsList').children;
    const childrenArray = Array.from(childrenCollection);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    childrenArray.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    setTimeout(() => {
      setIsVisibleUnitsListModelETitle(true);
    }, 50);

    setTimeout(() => {
      setIsVisibleUnitsListModelE(true);
      setShowModelEBands(true);
    }, 150);

    setTimeout(() => {
      setIsVisibleTrainBagrutExams(true);
    }, 200);

    setIsVisibleUnitTitle(false);
    setIsVisibleUnitsList(false);
    setIsVisibleUnitsListCustomTitle(false);
    setIsVisibleUnitsListCustom(false);
    setShowModelECoresTitle(false);
    setShowModelECoresList(false);
    setShowModelECores(false);
    setShowModelEBand1Units(false);
    setShowCore1Units(false);
    setShowModelEBand1Units(false);
    setShowCore2Units(false);
  };

  const selectPsychometryHandler = () => {
    setRun(parseInt(stepIndex, 10) === 0 ? false : run);
    setStepIndex(parseInt(stepIndex, 10) === 0 ? 1 : stepIndex);
    setCurrentTutorialStepCookie(parseInt(stepIndex, 10) === 0 ? 1 : stepIndex);

    setModelEActive(false);
    setPsychometryActive(true);
    setCustomActive(false);

    // setIsVisibleUnitTitle(false)
    // setIsVisibleUnitsList(false)
    // setIsVisibleTrainBagrutExams(false)

    setIsVisibleUnitsListModelETitle(false);
    setIsVisibleUnitsListModelE(false);
    setShowModelEBands(false);
    setIsVisibleUnitsListCustomTitle(false);
    setIsVisibleUnitsListCustom(false);
    setShowModelECoresTitle(false);
    setShowModelECoresList(false);
    setShowModelECores(false);
    setShowModelEBand1Units(false);
    setShowModelEBand2Units(false);
    setShowModelEBand1Units(false);
    setShowCore1Units(false);
    setShowModelEBand1Units(false);
    setShowCore2Units(false);
    setIsVisibleTrainBagrutExams(false);

    setTopicCookie('psychometry');
    setTimeout(() => {
      setIsVisibleBgImage(false);
    }, 100);

    setTimeout(() => {
      setIsHideBgImage(true);
    }, 100);

    const childrenCollection = document.getElementById('unitsList').children;
    const childrenArray = Array.from(childrenCollection);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    childrenArray.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    setTimeout(() => {
      setIsVisibleUnitTitle(true);
    }, 50);

    setTimeout(() => {
      setIsVisibleUnitsList(true);
    }, 150);
  };

  const selectCustomHandler = () => {
    setModelEActive(false);
    setPsychometryActive(false);
    setCustomActive(true);

    setTopicCookie('custom');
    setTimeout(() => {
      setIsVisibleBgImage(false);
    }, 100);

    setTimeout(() => {
      setIsHideBgImage(true);
    }, 100);

    const childrenCollection = document.getElementById('unitsList').children;
    const childrenArray = Array.from(childrenCollection);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    childrenArray.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    setTimeout(() => {
      setIsVisibleUnitsListCustomTitle(true);
    }, 50);

    setTimeout(() => {
      setIsVisibleUnitsListCustom(true);
    }, 150);

    setIsVisibleUnitTitle(false);
    setIsVisibleUnitsList(false);
    setIsVisibleTrainBagrutExams(false);
    setIsVisibleUnitsListModelETitle(false);
    setIsVisibleUnitsListModelE(false);
    setShowModelEBands(false);
    setShowModelECoresTitle(false);
    setShowModelECoresList(false);
    setShowModelECores(false);
    setShowModelEBand1Units(false);
    setShowModelEBand2Units(false);
    setShowModelEBand1Units(false);
    setShowCore1Units(false);
    setShowModelEBand1Units(false);
    setShowCore2Units(false);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleBgImage, setIsVisibleBgImage] = useState(false);
  const [isVisibleUnitTitle, setIsVisibleUnitTitle] = useState(false);
  const [isVisibleUnitsList, setIsVisibleUnitsList] = useState(false);
  const [isVisibleUnitsListModelETitle, setIsVisibleUnitsListModelETitle] =
    useState(false);
  const [isVisibleUnitsListModelE, setIsVisibleUnitsListModelE] =
    useState(false);
  const [showModelEBands, setShowModelEBands] = useState(false);
  const [showModelECores, setShowModelECores] = useState(false);
  const [showModelECoresTitle, setShowModelECoresTitle] = useState(false);
  const [showModelECoresList, setShowModelECoresList] = useState(false);
  const [showModelEBand1Units, setShowModelEBand1Units] = useState(false);
  const [showModelEBand2Units, setShowModelEBand2Units] = useState(false);
  const [isVisibleUnitsListCustomTitle, setIsVisibleUnitsListCustomTitle] =
    useState(false);
  const [isVisibleUnitsListCustom, setIsVisibleUnitsListCustom] =
    useState(false);
  const [isVisibleTrainBagrutExams, setIsVisibleTrainBagrutExams] =
    useState(false);
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [isHideBgImage, setIsHideBgImage] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
    setTimeout(() => {
      setIsVisibleQuote(true);
    }, 400);
  }, []);

  useEffect(() => {
    if (
      storedTopic !== 'model_e' &&
      storedTopic !== 'psychometry' &&
      storedTopic !== 'custom'
    ) {
      setTimeout(() => {
        setIsHideBgImage(false);
        setIsVisibleBgImage(true);
      }, 100);
    } else {
      setTimeout(() => {
        setIsVisibleBgImage(false);
      }, 100);

      setTimeout(() => {
        setIsHideBgImage(true);
      }, 100);
    }

    if (storedTopic === 'psychometry') {
      setTimeout(() => {
        setIsVisibleUnitTitle(true);
      }, 200);

      setTimeout(() => {
        setIsVisibleUnitsList(true);
      }, 300);
    } else if (storedTopic === 'model_e') {
      setTimeout(() => {
        setIsVisibleUnitsListModelETitle(true);
        setIsVisibleTrainBagrutExams(true);
      }, 200);

      setTimeout(() => {
        setIsVisibleUnitsListModelE(true);
        setShowModelEBands(true);
      }, 300);
    } else if (storedTopic === 'custom') {
      setTimeout(() => {
        setIsVisibleUnitsListCustomTitle(true);
      }, 200);

      setTimeout(() => {
        setIsVisibleUnitsListCustom(true);
      }, 300);
    }
  }, [storedTopic]);

  const [action, setAction] = useState('');

  const handleUnitClick = async (event) => {
    // setRun(parseInt(stepIndex, 10) === 1 ? false : run);
    setStepIndex(parseInt(stepIndex, 10) === 1 ? 2 : stepIndex);
    setCurrentTutorialStepCookie(parseInt(stepIndex, 10) === 1 ? 2 : stepIndex);

    const clicked_element = event.target;
    const clicked_element_type = event.target.localName;
    const clicked_element_id = event.target.id;
    const clicked_element_parent = event.target.parentNode;
    const clicked_element_parent_id = event.target.parentNode.id;

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    let selectedUnit = '';

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    const childrenCollection = document.getElementById('unitsList').children;
    const childrenArray = Array.from(childrenCollection);

    if (
      clicked_element_type === 'div' &&
      clicked_element.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          selectedUnit = element.id;
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_id);
        }
      });
    } else if (
      clicked_element_type === 'p' &&
      clicked_element_parent.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_parent_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          selectedUnit = element.id;
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_parent_id);
        }
      });
    }

    try {
      const response = await fetch(
        `/update-classification-counter/${storedTopic}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            unit_id: selectedUnit.substring(5),
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
    } catch (error) {
      console.error(error);
    }
    setShowExamTimeLimits(false);
  };

  const handleUnitModelEClick = (event) => {
    const clicked_element = event.target;
    const clicked_element_type = event.target.localName;
    const clicked_element_id = event.target.id;
    const clicked_element_parent = event.target.parentNode;
    const clicked_element_parent_id = event.target.parentNode.id;

    const childrenCollectionOriginal =
      document.getElementById('unitsList').children;
    const childrenArrayOriginal = Array.from(childrenCollectionOriginal);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    childrenArrayOriginal.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    const childrenCollection =
      document.getElementById('unitsListBand1').children;
    const childrenArray = Array.from(childrenCollection);

    if (
      clicked_element_type === 'div' &&
      clicked_element.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_id);
        }
      });
    } else if (
      clicked_element_type === 'p' &&
      clicked_element_parent.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_parent_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_parent_id);
        }
      });
    }
  };
  const handleUnitModelE2Click = (event) => {
    const clicked_element = event.target;
    const clicked_element_type = event.target.localName;
    const clicked_element_id = event.target.id;
    const clicked_element_parent = event.target.parentNode;
    const clicked_element_parent_id = event.target.parentNode.id;

    const childrenCollectionOriginal =
      document.getElementById('unitsList').children;
    const childrenArrayOriginal = Array.from(childrenCollectionOriginal);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    childrenArrayOriginal.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    const childrenCollection =
      document.getElementById('unitsListBand2').children;
    const childrenArray = Array.from(childrenCollection);

    if (
      clicked_element_type === 'div' &&
      clicked_element.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_id);
        }
      });
    } else if (
      clicked_element_type === 'p' &&
      clicked_element_parent.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_parent_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_parent_id);
        }
      });
    }
  };

  const [showCore2Units, setShowCore2Units] = useState(false);
  const [showCore1Units, setShowCore1Units] = useState(false);

  const handleCore1ModelEClick = (event) => {
    setShowModelEBand2Units(false);
    setShowCore2Units(false);

    setTimeout(() => {
      setShowCore1Units(true);
    }, 150);

    const elements = Array.from(
      document.getElementsByClassName('special-cores-unit')
    );
    elements.forEach((element) => {
      element.classList.add('gray-out-modele-bands');
      element.classList.remove('selected-modele-band');
    });

    document
      .getElementById('unit-core-1')
      .classList.remove('gray-out-modele-bands');
    document
      .getElementById('unit-core-1')
      .classList.add('selected-modele-band');
  };

  const handleCore2ModelEClick = (event) => {
    setShowModelEBand1Units(false);
    setShowCore1Units(false);

    setTimeout(() => {
      setShowCore2Units(true);
    }, 150);

    const elements = Array.from(
      document.getElementsByClassName('special-cores-unit')
    );
    elements.forEach((element) => {
      element.classList.add('gray-out-modele-bands');
      element.classList.remove('selected-modele-band');
    });

    document
      .getElementById('unit-core-2')
      .classList.remove('gray-out-modele-bands');
    document
      .getElementById('unit-core-2')
      .classList.add('selected-modele-band');
  };

  const handleUnitClickModelEBand3Core1 = (event) => {
    const clicked_element = event.target;
    const clicked_element_type = event.target.localName;
    const clicked_element_id = event.target.id;
    const clicked_element_parent = event.target.parentNode;
    const clicked_element_parent_id = event.target.parentNode.id;

    const childrenCollectionOriginal =
      document.getElementById('unitsList').children;
    const childrenArrayOriginal = Array.from(childrenCollectionOriginal);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    childrenArrayOriginal.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    const childrenCollection =
      document.getElementById('unitsListBand3').children;
    const childrenArray = Array.from(childrenCollection);

    if (
      clicked_element_type === 'div' &&
      clicked_element.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_id);
        }
      });
    } else if (
      clicked_element_type === 'p' &&
      clicked_element_parent.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_parent_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_parent_id);
        }
      });
    }
  };

  const handleUnitClickModelEBand3Core2 = (event) => {
    const clicked_element = event.target;
    const clicked_element_type = event.target.localName;
    const clicked_element_id = event.target.id;
    const clicked_element_parent = event.target.parentNode;
    const clicked_element_parent_id = event.target.parentNode.id;

    const childrenCollectionOriginal =
      document.getElementById('unitsList').children;
    const childrenArrayOriginal = Array.from(childrenCollectionOriginal);

    const childrenCollection2 =
      document.getElementById('unitsListBand1').children;
    const childrenArray2 = Array.from(childrenCollection2);

    const childrenCollection3 =
      document.getElementById('unitsListBand2').children;
    const childrenArray3 = Array.from(childrenCollection3);

    const childrenCollection4 =
      document.getElementById('unitsListBand3').children;
    const childrenArray4 = Array.from(childrenCollection4);

    childrenArrayOriginal.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray2.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray3.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    childrenArray4.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
    });

    setShowUnitOptionsModal(false);

    const childrenCollection = document.getElementById(
      'unitsListBand3Core2'
    ).children;
    const childrenArray = Array.from(childrenCollection);

    if (
      clicked_element_type === 'div' &&
      clicked_element.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_id);
        }
      });
    } else if (
      clicked_element_type === 'p' &&
      clicked_element_parent.classList.contains('environment-unit')
    ) {
      childrenArray.forEach((element) => {
        if (element.id !== clicked_element_parent_id) {
          if (!element.classList.contains('environment-unit-gray-out-effect')) {
            element.classList.add('environment-unit-gray-out-effect');
            element.classList.remove('environment-unit-selected-effect');
            element.children[0].classList.add(
              'environment-unit-p-gray-out-effect'
            );
          }
        } else {
          element.classList.remove('environment-unit-gray-out-effect');
          element.children[0].classList.remove(
            'environment-unit-p-gray-out-effect'
          );
          element.classList.add('environment-unit-selected-effect');

          setShowUnitOptionsModal(true);
          setAction(clicked_element_parent_id);
        }
      });
    }
  };

  const [showUnitOptionsModal, setShowUnitOptionsModal] = useState(false);
  const [showAddCustomUnitModal, setShowAddCustomUnitModal] = useState(false);

  const addUnitHandler = (event) => {
    setTimeout(() => {
      setShowAddCustomUnitModal(true);
    }, 50);
  };

  const handleBand3Click = () => {
    const elements = Array.from(
      document.getElementsByClassName('model-e-bands')
    );
    elements.forEach((element) => {
      element.classList.add('gray-out-modele-bands');
      element.classList.remove('selected-modele-band');
    });

    const elements2 = Array.from(
      document.getElementsByClassName('special-cores-unit')
    );
    elements2.forEach((element) => {
      element.classList.remove('selected-modele-band');
      element.classList.remove('gray-out-modele-bands');
    });

    document
      .getElementById('unit-band-3')
      .classList.remove('gray-out-modele-bands');

    setTimeout(() => {
      setShowModelEBands(!showModelEBands);
      setShowModelEBand1Units(false);
      setShowModelEBand2Units(false);
      setShowCore1Units(false);
      setShowCore2Units(false);
    }, 100);

    setTimeout(() => {
      setShowModelECores(!showModelECores);
      document
        .getElementById('unit-band-3')
        .classList.add('selected-modele-band');
    }, 150);

    setTimeout(() => {
      setShowModelECoresTitle(!showModelECoresTitle);
      setShowModelECoresList(!showModelECoresList);
    }, 200);
  };

  const handleBand1Click = () => {
    const elements = Array.from(
      document.getElementsByClassName('model-e-bands')
    );
    elements.forEach((element) => {
      element.classList.add('gray-out-modele-bands');
      element.classList.remove('selected-modele-band');
    });

    const elements2 = Array.from(
      document.getElementsByClassName('special-cores-unit')
    );
    elements2.forEach((element) => {
      element.classList.remove('selected-modele-band');
      element.classList.remove('gray-out-modele-bands');
    });

    document
      .getElementById('unit-band-1')
      .classList.remove('gray-out-modele-bands');
    document
      .getElementById('unit-band-1')
      .classList.add('selected-modele-band');

    setTimeout(() => {
      setShowModelECores(false);
      setShowModelECoresTitle(false);
      setShowModelECoresList(false);
      setShowModelEBand2Units(false);
      setShowCore1Units(false);
      setShowCore2Units(false);
    }, 100);

    setTimeout(() => {
      setShowModelEBand1Units(!showModelEBand1Units);
    }, 150);
  };

  const handleBand2Click = () => {
    const elements = Array.from(
      document.getElementsByClassName('model-e-bands')
    );
    elements.forEach((element) => {
      element.classList.add('gray-out-modele-bands');
      element.classList.remove('selected-modele-band');
    });

    const elements2 = Array.from(
      document.getElementsByClassName('special-cores-unit')
    );
    elements2.forEach((element) => {
      element.classList.remove('selected-modele-band');
      element.classList.remove('gray-out-modele-bands');
    });

    document
      .getElementById('unit-band-2')
      .classList.remove('gray-out-modele-bands');
    document
      .getElementById('unit-band-2')
      .classList.add('selected-modele-band');

    setTimeout(() => {
      setShowModelECores(false);
      setShowModelECoresTitle(false);
      setShowModelECoresList(false);
      setShowModelEBand1Units(false);
      setShowCore1Units(false);
      setShowCore2Units(false);
    }, 100);

    setTimeout(() => {
      setShowModelEBand2Units(!showModelEBand2Units);
    }, 150);
  };

  const [showAddNewWordMenu, setShowAddNewWordMenu] = useState(false);
  const [newWordsArray, setNewWordsArray] = useState([]);

  const client_name = getClientName();

  const finishedUnits = getFinishedUnitsCookie();
  let resultArray = [];

  let content = finishedUnits.match(/\[(.*?)\]/);
  if (content) {
    resultArray = content[1]
      .split('\\054 ')
      .map((element) => element.replace(/'/g, ''));
  }

  const [showMemorizationClassification, setShowMemorizationClassification] =
    useState(false);
  const [showExamTimeLimits, setShowExamTimeLimits] = useState(false);

  const upgradePremiumHandler = () => {
    setSettingsCookie('settingsBilling');
    window.location = '/settings';
  };

  return (
    <React.Fragment>
      <CookieAgreement />
      {showMemorizationClassification && (
        <MemorizationClassification
          showMemorizationClassification={showMemorizationClassification}
          setShowMemorizationClassification={setShowMemorizationClassification}
        />
      )}
      {showExamTimeLimits && (
        <ExamTimeLimits
          setStepIndex={setStepIndex}
          stepIndex={stepIndex}
          showExamTimeLimits={showExamTimeLimits}
          setShowExamTimeLimits={setShowExamTimeLimits}
        />
      )}
      {validToken && (
        <React.Fragment>
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            run={run}
            scrollToFirstStep
            showProgress
            showSkipButton
            stepIndex={stepIndex}
            disableOverlayClose={true}
            steps={steps}
            styles={{
              options: {
                zIndex: 10600,
                primaryColor: '#1A56DB',
              },
            }}
          />
          <NavBar
            classAdditional="special-sidebar"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggleAside={toggleAside}
            loggedUser={true}
          />
          <AlertModal
            isModalClosed={isModalClosed}
            setIsModalClosed={setIsModalClosed}
            messageType={modalType}
            title={modalTitle}
            description={modalDescription}
            information={modalInformation}
          />
          {showUnitOptionsModal && (
            <UnitOptionsModal
              setStepIndex={setStepIndex}
              stepIndex={stepIndex}
              setShowExamTimeLimits={setShowExamTimeLimits}
              showMemorizationClassification={showMemorizationClassification}
              setShowMemorizationClassification={
                setShowMemorizationClassification
              }
              action={action}
              isModalClosed={isModalClosed}
              setIsModalClosed={setIsModalClosed}
              setModalType={setModalType}
              setModalTitle={setModalTitle}
              setModalDescription={setModalDescription}
              setModalInformation={setModalInformation}
              showUnitOptionsModal={showUnitOptionsModal}
              setShowUnitOptionsModal={setShowUnitOptionsModal}
            />
          )}
          {showAddCustomUnitModal && (
            <AddCustomUnit
              isModalClosed={isModalClosed}
              setIsModalClosed={setIsModalClosed}
              setModalType={setModalType}
              setModalTitle={setModalTitle}
              setModalDescription={setModalDescription}
              setModalInformation={setModalInformation}
              showAddCustomUnitModal={showAddCustomUnitModal}
              setShowAddCustomUnitModal={setShowAddCustomUnitModal}
              showAddNewWordMenu={showAddNewWordMenu}
              setShowAddNewWordMenu={setShowAddNewWordMenu}
              newWordsArray={newWordsArray}
              setNewWordsArray={setNewWordsArray}
            />
          )}
          {showAddNewWordMenu && (
            <CustomUnitWordList
              isModalClosed={isModalClosed}
              setIsModalClosed={setIsModalClosed}
              setModalType={setModalType}
              setModalTitle={setModalTitle}
              setModalDescription={setModalDescription}
              setModalInformation={setModalInformation}
              showAddNewWordMenu={showAddNewWordMenu}
              setShowAddNewWordMenu={setShowAddNewWordMenu}
              newWordsArray={newWordsArray}
              setNewWordsArray={setNewWordsArray}
            />
          )}
          <CookieAgreement />
          <div className="environment">
            {selectedLanguage === 'en' ? (
              <p
                dir="ltr"
                id="environmentTitle"
                className={`text-fade-in-bottom ${isVisible ? 'visible' : ''}`}
              >
                {languageFile.environmentTitle1} {client_name},{' '}
                {languageFile.environmentTitle2} &#128526;
              </p>
            ) : (
              <p
                dir="rtl"
                id="environmentTitle"
                className={`text-fade-in-bottom ${isVisible ? 'visible' : ''}`}
              >
                {languageFile.environmentTitle1} {client_name},{' '}
                {languageFile.environmentTitle2} &#128526;
              </p>
            )}
            <p
              dir="auto"
              className={`p-fade-in-right environment-quote ${isVisibleQuote ? 'visible' : ''
                }`}
            >
              <i className="fa-solid fa-asterisk"></i>{' '}
              <span dir="auto">{languageFile.didYouKnow}? </span>
              {languageFile.quote1}.
            </p>
            <div className="environment-carousel">
              <div
                className={`environment-topics fade-in-right ${isVisible ? 'visible' : ''
                  }`}
              >
                <div
                  onClick={selectEnglishModelEHandler}
                  className={`environment-topic environment-topic-1 gray-out ${modelEActive ? 'environment-topic-active' : ''
                    }`}
                >
                  <p
                    dir="auto"
                    onClick={selectEnglishModelEHandler}
                    className={`${modelEActive ? 'environment-topic-active-p' : ''
                      }`}
                  >
                    {languageFile.englishTitleEnv}
                  </p>
                  <i
                    onClick={selectEnglishModelEHandler}
                    className={`fa-solid fa-book ${modelEActive ? 'environment-topic-active-p' : ''
                      }`}
                  ></i>
                </div>
                <div
                  onClick={selectPsychometryHandler}
                  className={`environment-topic environment-topic-2 ${psychometryActive ? 'environment-topic-active' : ''
                    }`}
                >
                  <p
                    dir="auto"
                    onClick={selectPsychometryHandler}
                    className={`${psychometryActive ? 'environment-topic-active-p' : ''
                      }`}
                  >
                    {languageFile.productsPsychTitle}
                  </p>
                  <i
                    onClick={selectPsychometryHandler}
                    className={`fa-solid fa-graduation-cap ${psychometryActive ? 'environment-topic-active-p' : ''
                      }`}
                  ></i>
                </div>
                <div
                  onClick={selectCustomHandler}
                  className={`environment-topic environment-topic-3 gray-out ${customActive ? 'environment-topic-active' : ''
                    }`}
                >
                  <p
                    dir="auto"
                    onClick={selectCustomHandler}
                    className={`${customActive ? 'environment-topic-active-p' : ''
                      }`}
                  >
                    {languageFile.productsPersonalTitle}
                  </p>
                  <i
                    onClick={selectCustomHandler}
                    className={`fa-solid fa-laptop ${customActive ? 'environment-topic-active-p' : ''
                      }`}
                  ></i>
                </div>
              </div>
            </div>
            <div
              id="topicBigLogo"
              className={`topic-big-logo ${isVisibleBgImage ? 'show-topic-big-logo' : ''
                } ${isHideBgImage ? 'hide-topic-big-logo' : ''}`}
            >
              <img
                src="logo-no-background.png"
                alt="MillyLearn.com"
                loading="lazy"
              ></img>
            </div>
            <div
              className={`environment-topics-units ${psychometryActive ? 'visible' : ''
                }`}
            >
              <h1
                dir="auto"
                className={`environment-topics-units-title p-fade-in-right ${isVisibleUnitTitle ? 'visible' : ''
                  }`}
              >
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsList"
                className={`units-list fade-in-right ${isVisibleUnitsList ? 'visible' : ''
                  } ${selectedLanguage === 'he' ? 'reverse-grid' : ''}`}
              >
                {/* <div id='unit-1' className='environment-unit environment-unit-1' onClick={handleUnitClick}> */}
                <div
                  id="unit-1"
                  className={`environment-unit environment-unit-1 ${resultArray.includes('unit-1') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit1}</p>
                </div>
                <div
                  id="unit-2"
                  className={`environment-unit environment-unit-2 ${resultArray.includes('unit-2') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit2}</p>
                </div>
                <div
                  id="unit-3"
                  className={`environment-unit environment-unit-3 ${resultArray.includes('unit-3') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit3}</p>
                </div>
                <div
                  id="unit-4"
                  className={`environment-unit environment-unit-4 ${resultArray.includes('unit-4') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit4}</p>
                </div>
                <div
                  id="unit-5"
                  className={`environment-unit environment-unit-5 ${resultArray.includes('unit-5') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit5}</p>
                </div>
                <div
                  id="unit-6"
                  className={`environment-unit environment-unit-6 ${resultArray.includes('unit-6') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit6}</p>
                </div>
                <div
                  id="unit-7"
                  className={`environment-unit environment-unit-7 ${resultArray.includes('unit-7') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit7}</p>
                </div>
                <div
                  id="unit-8"
                  className={`environment-unit environment-unit-8 ${resultArray.includes('unit-8') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit8}</p>
                </div>
                <div
                  id="unit-9"
                  className={`environment-unit environment-unit-9 ${resultArray.includes('unit-9') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit9}</p>
                </div>
                <div
                  id="unit-10"
                  className={`environment-unit environment-unit-10 ${resultArray.includes('unit-10') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit10}</p>
                </div>
                <div
                  id="unit-11"
                  className={`environment-unit environment-unit-11 ${resultArray.includes('unit-11') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit11}</p>
                </div>
                <div
                  id="unit-12"
                  className={`environment-unit environment-unit-12 ${resultArray.includes('unit-12') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit12}</p>
                </div>
                <div
                  id="unit-13"
                  className={`environment-unit environment-unit-13 ${resultArray.includes('unit-13') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit13}</p>
                </div>
                <div
                  id="unit-14"
                  className={`environment-unit environment-unit-14 ${resultArray.includes('unit-14') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit14}</p>
                </div>
                <div
                  id="unit-15"
                  className={`environment-unit environment-unit-15 ${resultArray.includes('unit-15') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit15}</p>
                </div>
                <div
                  id="unit-16"
                  className={`environment-unit environment-unit-16 ${resultArray.includes('unit-16') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit16}</p>
                </div>
                <div
                  id="unit-999"
                  className={`environment-unit environment-unit-999 ${resultArray.includes('unit-999') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit999}</p>
                </div>
                <div
                  id="unit-998"
                  className={`environment-unit environment-unit-998 ${resultArray.includes('unit-998') ? 'finished-units' : ''
                    }`}
                  onClick={handleUnitClick}
                >
                  <p dir="auto">{languageFile.unit998}</p>
                </div>
              </div>
            </div>

            <div
              className={`environment-topics-units ${modelEActive ? 'visible' : ''
                }`}
            >
              <h1
                dir="auto"
                className={`environment-topics-units-title p-fade-in-right ${isVisibleUnitsListModelETitle ? 'visible' : ''
                  }`}
              >
                {languageFile.chooseBand}
              </h1>
              <div
                id="unitsList"
                className={`units-list fade-in-right ${isVisibleUnitsListModelE ? 'visible' : ''
                  }`}
              >
                {/* <div id='unit-1' className='environment-unit environment-unit-1' onClick={handleUnitClick}> */}
                <div
                  id="unit-band-1"
                  className={`environment-unit environment-unit-1 model-e-bands ${showModelEBands ? 'visible' : ''
                    }`}
                  onClick={handleBand1Click}
                >
                  <p dir="auto">{languageFile.modelEband1}</p>
                </div>

                <div
                  id="unit-band-2"
                  className={`environment-unit environment-unit-2 model-e-bands ${showModelEBands ? 'visible' : ''
                    }`}
                  onClick={handleBand2Click}
                >
                  <p dir="auto">{languageFile.modelEband2}</p>
                </div>
                <div
                  id="unit-band-3"
                  className={`environment-unit environment-unit-3 model-e-bands ${showModelEBands ? 'visible' : ''
                    }`}
                  onClick={handleBand3Click}
                >
                  <p dir="auto">{languageFile.modelEband3}</p>
                </div>
              </div>
            </div>
            <div
              className={`environment-topics-units model-e-bandunits fade-in-right ${showModelEBand1Units ? 'visible' : ''
                }`}
            >
              <h1 dir="auto" className={`environment-topics-units-title`}>
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsListBand1"
                className={`units-list ${selectedLanguage === 'he' ? 'reverse-grid' : ''
                  }`}
              >
                <div
                  id="unit-band1-1"
                  className="cores-unit environment-unit"
                  onClick={handleUnitModelEClick}
                >
                  <p dir="auto">{languageFile.unit1}</p>
                </div>
                <div
                  id="unit-band1-2"
                  className="cores-unit environment-unit"
                  onClick={handleUnitModelEClick}
                >
                  <p dir="auto">{languageFile.unit2}</p>
                </div>
              </div>
            </div>
            <div
              className={`environment-topics-units model-e-core ${showModelECores ? 'visible' : ''
                }`}
            >
              <h1
                dir="auto"
                className={`environment-topics-units-title p-fade-in-right ${showModelECoresTitle ? 'visible' : ''
                  }`}
              >
                {languageFile.chooseCore}
              </h1>
              <div
                id="unitsList"
                className={`units-list-cores units-list fade-in-right ${showModelECoresList ? 'visible' : ''
                  }`}
              >
                <div
                  id="unit-core-1"
                  className="special-cores-unit cores-unit environment-unit"
                  onClick={handleCore1ModelEClick}
                >
                  <p dir="auto">{languageFile.modelEcore1}</p>
                </div>
                <div
                  id="unit-core-2"
                  className="special-cores-unit cores-unit environment-unit"
                  onClick={handleCore2ModelEClick}
                >
                  <p dir="auto">{languageFile.modelEcore2}</p>
                </div>
              </div>
            </div>
            <div
              className={`environment-topics-units model-e-bandunits fade-in-right ${showModelEBand2Units ? 'visible' : ''
                }`}
            >
              <h1 dir="auto" className={`environment-topics-units-title`}>
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsListBand2"
                className={`units-list ${selectedLanguage === 'he' ? 'reverse-grid' : ''
                  }`}
              >
                <div
                  id="unit-band2-1"
                  className="cores-unit environment-unit"
                  onClick={handleUnitModelE2Click}
                >
                  <p dir="auto">{languageFile.unit1}</p>
                </div>
                <div
                  id="unit-band2-2"
                  className="cores-unit environment-unit"
                  onClick={handleUnitModelE2Click}
                >
                  <p dir="auto">{languageFile.unit2}</p>
                </div>
              </div>
            </div>
            <div
              className={`environment-topics-units model-e-bandunits fade-in-right ${showCore1Units ? 'visible' : ''
                }`}
            >
              <h1 dir="auto" className={`environment-topics-units-title`}>
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsListBand3"
                className={`units-list ${selectedLanguage === 'he' ? 'reverse-grid' : ''
                  }`}
              >
                <div
                  id="unit-band3-core1-A"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core1}
                >
                  <p dir="auto">{languageFile.unitA}</p>
                </div>
                <div
                  id="unit-band3-core1-B"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core1}
                >
                  <p dir="auto">{languageFile.unitB}</p>
                </div>
                <div
                  id="unit-band3-core1-C"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core1}
                >
                  <p dir="auto">{languageFile.unitC}</p>
                </div>
                <div
                  id="unit-band3-core1-D"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core1}
                >
                  <p dir="auto">{languageFile.unitD}</p>
                </div>
              </div>
            </div>
            <div
              className={`environment-topics-units model-e-bandunits fade-in-right ${showCore2Units ? 'visible' : ''
                }`}
            >
              <h1 dir="auto" className={`environment-topics-units-title`}>
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsListBand3Core2"
                className={`units-list ${selectedLanguage === 'he' ? 'reverse-grid' : ''
                  }`}
              >
                <div
                  id="unit-band3-core2-A"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core2}
                >
                  <p dir="auto">{languageFile.unitA}</p>
                </div>
                <div
                  id="unit-band3-core2-B"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core2}
                >
                  <p dir="auto">{languageFile.unitB}</p>
                </div>
                <div
                  id="unit-band3-core2-C"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core2}
                >
                  <p dir="auto">{languageFile.unitC}</p>
                </div>
                <div
                  id="unit-band3-core2-D"
                  className="cores-unit environment-unit"
                  onClick={handleUnitClickModelEBand3Core2}
                >
                  <p dir="auto">{languageFile.unitD}</p>
                </div>
              </div>
            </div>
            {isVisibleTrainBagrutExams && (
              <a
                dir="auto"
                className={`p-fade-in-right btn train-bagrut-exams-btn visible`}
                href="/user/practice-model-e-exams"
              >
                {languageFile.trainBagrutExamsBtn}
              </a>
            )}

            <div
              className={`environment-topics-units ${customActive ? 'visible' : ''
                }`}
            >
              <h1
                dir="auto"
                className={`environment-topics-units-title p-fade-in-right ${isVisibleUnitsListCustomTitle ? 'visible' : ''
                  }`}
              >
                {languageFile.chooseUnit}
              </h1>
              <div
                id="unitsList"
                className={`units-list fade-in-right ${isVisibleUnitsListCustom ? 'visible' : ''
                  } ${selectedLanguage === 'he' ? 'reverse-grid' : ''}`}
              >
                {/* <div id='unit-1' className='environment-unit environment-unit-1' onClick={handleUnitClick}>
                      <p dir='auto'>{languageFile.modelEunit1}</p>
                    </div> */}
                <div
                  id="addUnitBtn"
                  className="environment-unit environment-unit-2"
                  onClick={addUnitHandler}
                >
                  <p dir="auto">{languageFile.addUnit}</p>
                </div>
              </div>
            </div>
            {getClientPlan() === 'free' && (
              <a
                id="upgradeToPremiumHome"
                onClick={upgradePremiumHandler}
                dir="auto"
                className="btn btn-block btn-upgrade-to-premium"
              >
                {languageFile.upgradeToPremium}!
              </a>
            )}
          </div>
        </React.Fragment>
      )}
      <LoggedFooter
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
      />
    </React.Fragment>
  );
}

export default Environment;
