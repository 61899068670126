import React, { useState, useEffect, useContext, useRef } from 'react';
import './profile.css';
import CookieAgreement from '../../utils/CookieAgreement';
import { useGlobalContext } from '../../context/AppContext';
import NavBar from '../NavBar/NavBar';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import {
  getClientIsMobileVerified,
  IsAgreementCookieAgreed,
  disableCookiesUsage,
  setAgreementCookie,
  getAgreementCookie,
  setSettingsCookie,
} from '../../utils/cookieUtils';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';

function Profile() {
  const [showSettingsCookies, setShowSettingsCookies] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  setTimeout(() => {
    setShowProfile(true);
  }, 100);

  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [isOpen, setIsOpen] = useState(false);
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Profile' : 'חשבון'
    }`; // Set the title to "Milly | Profile"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isCookiesChecked, setIsCookiesChecked] = useState(
    IsAgreementCookieAgreed()
  );

  const handleCookiesSwitchChange = async () => {
    if (isCookiesChecked) disableCookiesUsage();
    else setAgreementCookie(true);

    setIsCookiesChecked(!isCookiesChecked); // Toggle the checked state
  };

  const [userData, setUserData] = useState(null);
  const [formattedUserDate, setFormattedUserDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/profile', {
          method: 'POST',
        });

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // resetReCAPTCHA()

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          const user = responseData.data;
          setUserData({
            profileUserID: user.profileUserID,
            profileUserName: user.profileUserName,
            profileUserRole: user.profileUserRole,
            profileUserMobile: user.profileUserMobile,
            profileUserMobileVerification: user.profileUserMobileVerification,
            profileUserEmail: user.profileUserEmail,
            profileUserEmailVerification: user.profileUserEmailVerification,
            profileUserDateOfBirth: user.profileUserDateOfBirth,
            profileUserPlan: user.profileUserPlan,
            profileUserNewsLetter: user.profileUserNewsLetter,
            profileUserRegistrationDate: user.profileUserRegistrationDate,
          });

          var dateObject = new Date(user.profileUserDateOfBirth);

          var day = dateObject.getUTCDate();
          var month = dateObject.getUTCMonth() + 1;
          var year = dateObject.getUTCFullYear();

          day = day < 10 ? '0' + day : day;
          month = month < 10 ? '0' + month : month;

          setFormattedUserDate(day + '/' + month + '/' + year);
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    };

    fetchData();
  }, []);

  const upgradePremiumHandler = () => {
    setSettingsCookie('settingsBilling');
    window.location = '/settings';
  };

  return (
    <React.Fragment>
      <CookieAgreement />
      <NavBar
        classAdditional=""
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={true}
      />
      {showProfile && (
        <React.Fragment>
          {userData && (
            <div className="profile-main">
              <h1 dir="auto" id="userProfile" className="section-title">
                {userData.profileUserName}
              </h1>
              <div className={`settings-parent visible profile-parent`}>
                <div
                  className={`profile-info ${
                    selectedLanguage === 'he' ? 'reverse-to-he' : ''
                  }`}
                >
                  <div className="profile-info-cell">
                    <div className="profile-info-col1">
                      {languageFile.UserMobile}
                    </div>
                    <div
                      dir="auto"
                      id="profileUserMobile"
                      className="profile-info-col2"
                    >
                      {userData.profileUserMobile}
                    </div>
                  </div>
                  <div className="profile-info-cell2">
                    <div className="profile-info-col1">
                      {languageFile.UserMobileVerification}
                    </div>
                    {selectedLanguage === 'en' ? (
                      <div
                        dir="auto"
                        id="profileUserMobileVerification"
                        className={`profile-info-col2 ${
                          userData.profileUserMobileVerification
                            ? 'verification-verified'
                            : 'verification-failed'
                        }`}
                      >
                        {userData.profileUserMobileVerification
                          ? 'verified'
                          : 'not verified'}
                      </div>
                    ) : (
                      <div
                        dir="auto"
                        id="profileUserMobileVerification"
                        className={`profile-info-col2 ${
                          userData.profileUserMobileVerification
                            ? 'verification-verified'
                            : 'verification-failed'
                        }`}
                      >
                        {userData.profileUserMobileVerification
                          ? 'מאומת'
                          : 'לא מאומת'}
                      </div>
                    )}
                  </div>
                  <div className="profile-info-cell">
                    <div className="profile-info-col1">
                      {languageFile.UserEmail}
                    </div>
                    <div
                      dir="auto"
                      id="profileUserEmail"
                      className="profile-info-col2"
                    >
                      {userData.profileUserEmail}
                    </div>
                  </div>
                  <div className="profile-info-cell2">
                    <div className="profile-info-col1">
                      {languageFile.UserEmailVerification}
                    </div>
                    {selectedLanguage === 'en' ? (
                      <div
                        dir="auto"
                        id="profileUserEmailVerification"
                        className={`profile-info-col2 ${
                          userData.profileUserEmailVerification
                            ? 'verification-verified'
                            : 'verification-failed'
                        }`}
                      >
                        {userData.profileUserEmailVerification
                          ? 'verified'
                          : 'not verified'}
                      </div>
                    ) : (
                      <div
                        dir="auto"
                        id="profileUserEmailVerification"
                        className={`profile-info-col2 ${
                          userData.profileUserEmailVerification
                            ? 'verification-verified'
                            : 'verification-failed'
                        }`}
                      >
                        {userData.profileUserEmailVerification
                          ? 'מאומתת'
                          : 'לא מאומתת'}
                      </div>
                    )}
                  </div>
                  <div className="profile-info-cell">
                    <div className="profile-info-col1">
                      {languageFile.UserDateOfBirth}
                    </div>
                    <div
                      dir="auto"
                      id="profileUserDateOfBirth"
                      className="profile-info-col2"
                    >
                      {formattedUserDate}
                    </div>
                  </div>
                  <div className="profile-info-cell2">
                    <div className="profile-info-col1">
                      {languageFile.UserCurrentPlan}
                    </div>
                    <div
                      dir="auto"
                      id="profileUserPlan"
                      className="profile-info-col2"
                    >
                      {userData.profileUserPlan}
                    </div>
                  </div>
                </div>
                {userData.profileUserPlan === 'free' && (
                  <a
                    onClick={upgradePremiumHandler}
                    dir="auto"
                    className="btn btn-block btn-upgrade-to-premium"
                  >
                    {languageFile.upgradeToPremium}!
                  </a>
                )}
                <div
                  className={`animation-parent`}
                  id="loading-spinner-register2"
                >
                  <div className={`animation animation-circle`}></div>
                </div>
              </div>
            </div>
          )}
          <LoggedFooter
            isModalClosed={isModalClosed}
            setIsModalClosed={setIsModalClosed}
            setModalType={setModalType}
            setModalTitle={setModalTitle}
            setModalDescription={setModalDescription}
            setModalInformation={setModalInformation}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Profile;
