import React, { useContext } from 'react';
import { useGlobalContext } from '../../context/AppContext';
import {
  setLanguageCookie,
  IsAgreementCookieAgreed,
} from '../../utils/cookieUtils';
import './languageSelector.css';
import Select from 'react-select';

function LanguageSelector({ id }) {
  const { selectedLanguage, setSelectedLanguage } = useGlobalContext();

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    const cookiesAllowed = IsAgreementCookieAgreed();
    if (cookiesAllowed) {
      setLanguageCookie(newLanguage);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      caretColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',
        backgroundColor: 'var(--gray-100)',
        transition: 'all 0.2s ease-in',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--primary-400)' : 'transparent',
      color: state.isFocused ? 'white' : 'black',
    }),
  };

  const languageOptions = [
    { code: 'en', label: 'English' },
    { code: 'he', label: 'עברית' },
  ];

  return (
    <React.Fragment>
      <Select
        id={id}
        value={languageOptions.find(
          (option) => option.code === selectedLanguage
        )}
        onChange={(selectedOption) => handleLanguageChange(selectedOption.code)}
        options={languageOptions}
        styles={customStyles}
        isSearchable={false}
      ></Select>
    </React.Fragment>
  );
}

export default LanguageSelector;
