import React, { useState, useEffect, useContext, useRef } from 'react';
import './settings.css';
import CookieAgreement from '../../utils/CookieAgreement';
import { useGlobalContext } from '../../context/AppContext';
import NavBar from '../NavBar/NavBar';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import MobileVerification from './MobileVerification/MobileVerification';
import {
  getClientIsMobileVerified,
  IsAgreementCookieAgreed,
  disableCookiesUsage,
  setAgreementCookie,
  getAgreementCookie,
  getSettingsCookie,
  setSettingsCookie,
} from '../../utils/cookieUtils';
import EmailVerification from './EmailVerification/EmailVerification';
import AlertModal from '../AlertModal/AlertModal';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';
import PasswordRequirementsModal from '../PasswordRequirementsModal/PasswordRequirementsModal';
import HashLoader from 'react-spinners/HashLoader';

function Settings() {
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  const [showSettingsOption, setShowSettingsOption] = useState(false);
  const [showSettingsProfile, setShowSettingsProfile] = useState(false);
  const [showSettingsCookies, setShowSettingsCookies] = useState(false);
  const [showSettingsBilling, setShowSettingsBilling] = useState(false);
  const [selectedSettingsOption, setSelectedSettingsOption] =
    useState('settingsProfile');

  setTimeout(() => {
    setShowSettingsOption(true);
  }, 100);

  setTimeout(() => {
    setShowSettingsProfile(true);
  }, 200);

  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [isOpen, setIsOpen] = useState(false);
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Settings' : 'הגדרות'
    }`; // Set the title to "Milly | Home"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const settingsOptionClicked = (event) => {
    const clickedElement = event.target;
    const settingsOptions = Array.from(
      document.getElementsByClassName('settings-option-item')
    );
    settingsOptions.forEach((element) => {
      element.classList.remove('clicked');
    });

    if (clickedElement) {
      clickedElement.classList.toggle('clicked');
      setSelectedSettingsOption(clickedElement.id);

      if (clickedElement.id === 'settingsProfile') {
        setShowSettingsProfile(true);
        setShowSettingsCookies(false);
        setShowSettingsBilling(false);
        setSettingsCookie('settingsProfile');
      } else if (clickedElement.id === 'settingsCookies') {
        setShowSettingsProfile(false);
        setShowSettingsCookies(true);
        setShowSettingsBilling(false);
        setSettingsCookie('settingsCookies');
      } else if (clickedElement.id === 'settingsBilling') {
        setShowSettingsProfile(false);
        setShowSettingsCookies(false);
        setShowSettingsBilling(true);
        setSettingsCookie('settingsBilling');
      }
    }
  };

  const [showAnimation, setShowAnimation] = useState(false);

  const handleFirstNameUpdate = async (event) => {
    event.preventDefault();

    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";

    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('first_name', firstNameValue);

      const response = await fetch('/settings/first_name', {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      setModalInformation('');
      setModalType('success');
      setModalTitle('success');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('הצלחה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const handleLastNameUpdate = async (event) => {
    event.preventDefault();

    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('last_name', lastNameValue);

      const response = await fetch('/settings/last_name', {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      setModalInformation('');
      setModalType('success');
      setModalTitle('success');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('הצלחה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  let passwordRequirementsList = {};
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();

    if (!isPasswordInputVerified) {
      setShowPasswordRequirements(true);
      return;
    }

    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('password', passwordValue);
      formData.append('confirm_password', passwordConfirmValue);

      const response = await fetch('/settings/password', {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        submitBtn.disabled = false;

        if (responseData.type === 'password') {
          setModalType('warning');
          if (selectedLanguage === 'en') {
            setModalTitle('warning');
          } else {
            setModalTitle('שימו לב');
          }

          if (responseData.hasOwnProperty('data')) {
            for (const key in responseData.data) {
              if (responseData.data.hasOwnProperty(key)) {
                const value = responseData.data[key];
                if (value) {
                  if (selectedLanguage === 'en') {
                    passwordRequirementsList[key] = value;
                  } else {
                    passwordRequirementsList[key] = fetchResponseHandler(value);
                  }
                }
              }
            }
          }
          setModalDescription(JSON.stringify(passwordRequirementsList));
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
            setModalInformation('');
            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          } else {
            setIsModalClosed(false);
            setModalTitle('יש שגיאה');
            setModalInformation('');

            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);

            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          }
        }
      } else {
        setModalInformation('');
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('הצלחה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const handleDobUpdate = async (event) => {
    event.preventDefault();

    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('dob', dobValue);

      const response = await fetch('/settings/dob', {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      setModalInformation('');
      setModalType('success');
      setModalTitle('success');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('הצלחה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [firstNameValue, setFirstNameValue] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);

  const handleDivFirstNameClick = () => {
    setIsFirstNameFocused(true);
    setIsLastNameFocused(false);

    setIsPasswordFocused(false);
    setIsPasswordConfirmFocused(false);
    setIsDobFocused(false);

    document.getElementById('updateFirstName').focus();
  };

  const updateFirstNameValue = (event) => {
    setFirstNameValue(event.target.value);

    if (event.target.validity.valid && event.target.value !== '') {
      setIsFirstNameValid(true);
    } else {
      setIsFirstNameValid(false);
    }
  };

  const [lastNameValue, setLastNameValue] = useState('');
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);

  const handleDivLastNameClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(true);

    setIsPasswordFocused(false);
    setIsPasswordConfirmFocused(false);
    setIsDobFocused(false);

    document.getElementById('updateLastName').focus();
  };

  const updateLastNameValue = (event) => {
    setLastNameValue(event.target.value);

    if (event.target.validity.valid && event.target.value !== '') {
      setIsLastNameValid(true);
    } else {
      setIsLastNameValid(false);
    }
  };

  const [passwordValue, setPasswordValue] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleDivPasswordClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);

    setIsPasswordFocused(true);
    setIsPasswordConfirmFocused(false);
    setIsDobFocused(false);

    document.getElementById('updatePassword').focus();
  };

  const updatePasswordValue = (event) => {
    setPasswordValue(event.target.value);

    if (event.target.validity.valid && event.target.value !== '') {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const [passwordConfirmValue, setPasswordConfirmValue] = useState('');
  const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);
  const [isPasswordConfirmFocused, setIsPasswordConfirmFocused] =
    useState(false);

  const handleDivPasswordConfirmClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);

    setIsPasswordFocused(false);
    setIsPasswordConfirmFocused(true);
    setIsDobFocused(false);

    document.getElementById('updatePasswordConfirm').focus();
  };

  const updatePasswordConfirmValue = (event) => {
    setPasswordConfirmValue(event.target.value);

    if (event.target.validity.valid && event.target.value !== '') {
      setIsPasswordConfirmValid(true);
    } else {
      setIsPasswordConfirmValid(false);
    }
  };

  const [isDateOfBirthInputVerified, setIsDateOfBirthInputVerified] =
    useState(null);

  const dateOfBirthInputValidation = () => {
    const dateInput = document.getElementById('updateDob').value;

    // const datePattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    // const isDateRegexValid = datePattern.test(dateInput);
    // setIsDateOfBirthInputVerified(isDateRegexValid);

    dateInput !== null && dateInput !== ''
      ? setIsDateOfBirthInputVerified(true)
      : setIsDateOfBirthInputVerified(false);
  };

  const [dobValue, setDobValue] = useState('');
  const [isDobValid, setIsDobValid] = useState(false);
  const [isDobFocused, setIsDobFocused] = useState(false);

  const handleDivDobClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);

    setIsPasswordFocused(false);
    setIsPasswordConfirmFocused(false);
    setIsDobFocused(true);

    document.getElementById('updateDob').focus();
  };

  const updateDobValue = (event) => {
    setDobValue(event.target.value);

    if (event.target.validity.valid && event.target.value !== '') {
      setIsDobValid(true);
    } else {
      setIsDobValid(false);
    }
  };

  useEffect(() => {
    dateOfBirthInputValidation();
  }, [dobValue]);

  useEffect(() => {
    passwordInputValidation();
  }, [passwordValue, passwordConfirmValue]);

  const [isPasswordInputVerifiedNull, setIsPasswordInputVerifiedNull] =
    useState(true);

  const [isPasswordInputVerified, setIsPasswordInputVerified] = useState(false);
  const [passwordIsTenOrMoreChars, setPasswordIsTenOrMoreChars] =
    useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsDigit, setContainsDigit] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const passwordInputValidation = () => {
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialCharacters = '!@#$%^&*';

    const passwordInput = document.getElementById('updatePassword').value;
    const confirmPasswordInput = document.getElementById(
      'updatePasswordConfirm'
    ).value;

    const isTenOrMoreChars = passwordInput.length >= 10;
    const hasLowercaseLetter = [...lowercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasUppercaseLetter = [...uppercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasDigit = [...digits].some((char) => passwordInput.includes(char));
    const hasSpecialChar = [...specialCharacters].some((char) =>
      passwordInput.includes(char)
    );
    const hasSpace = passwordInput.includes(' ');
    let doPasswordsMatch;

    if (
      passwordInput === confirmPasswordInput &&
      passwordInput !== '' &&
      confirmPasswordInput !== ''
    ) {
      doPasswordsMatch = true;
    } else {
      doPasswordsMatch = false;
    }

    setPasswordIsTenOrMoreChars(isTenOrMoreChars);
    setContainsLowercaseLetter(hasLowercaseLetter);
    setContainsUppercaseLetter(hasUppercaseLetter);
    setContainsDigit(hasDigit);
    setContainsSpecialChar(hasSpecialChar);
    setPasswordsMatch(doPasswordsMatch);

    const isPasswordValid =
      isTenOrMoreChars &&
      hasLowercaseLetter &&
      hasUppercaseLetter &&
      hasDigit &&
      hasSpecialChar &&
      doPasswordsMatch &&
      !hasSpace;

    if (
      (!isPasswordValid && passwordInput !== '') ||
      confirmPasswordInput !== ''
    ) {
      let newPasswordMissingRules = {};

      if (!isTenOrMoreChars)
        newPasswordMissingRules['isTenOrMoreChars'] = isTenOrMoreChars;

      if (!hasLowercaseLetter)
        newPasswordMissingRules['hasLowercaseLetter'] = hasLowercaseLetter;

      if (!hasUppercaseLetter)
        newPasswordMissingRules['hasUppercaseLetter'] = hasUppercaseLetter;

      if (!hasDigit) newPasswordMissingRules['hasDigit'] = hasDigit;

      if (!hasSpecialChar)
        newPasswordMissingRules['hasSpecialChar'] = hasSpecialChar;

      if (!doPasswordsMatch)
        newPasswordMissingRules['doPasswordsMatch'] = doPasswordsMatch;

      if (hasSpace) newPasswordMissingRules['hasSpace'] = hasSpace;

      setPasswordMissingRules(newPasswordMissingRules);

      setShowPasswordRequirements(true);
    } else {
      setShowPasswordRequirements(false);
    }

    setIsPasswordInputVerified(isPasswordValid);
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      document.getElementById('updatePassword').type = 'password';
      document.getElementById('updatePasswordConfirm').type = 'password';
    } else {
      setIsPasswordVisible(true);
      document.getElementById('updatePassword').type = 'text';
      document.getElementById('updatePasswordConfirm').type = 'text';
    }
  };

  const [openMobileVerification, setOpenMobileVerification] = useState(false);
  const handleOpenMobileVerification = () =>
    setOpenMobileVerification(!openMobileVerification);

  const isMobileVerified = getClientIsMobileVerified();
  const [isCookiesChecked, setIsCookiesChecked] = useState(
    IsAgreementCookieAgreed()
  );

  const agreementCookie = getAgreementCookie();

  const handleCookiesSwitchChange = async () => {
    if (isCookiesChecked) disableCookiesUsage();
    else setAgreementCookie(true);

    setIsCookiesChecked(!isCookiesChecked); // Toggle the checked state
  };

  const [isNewsletterChecked, setIsNewsletterChecked] = useState(null);

  const handleNewsletterSwitchChange = async () => {
    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementsByClassName('update-icon');
    submitBtn.disabled = true;

    try {
      const formData = new FormData();
      formData.append('newsletter', isNewsletterChecked);

      const response = await fetch('/settings/newsletter', {
        method: 'PATCH',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      // resetReCAPTCHA()

      // You can handle the server response here if needed
      const responseData = await response.json();
      setModalInformation('');
      setModalType('success');
      setModalTitle('success');

      if (selectedLanguage === 'en') {
        setModalDescription(responseData.data);
      } else {
        let responseMsg = fetchResponseHandler(responseData.data);
        setModalDescription(responseMsg);
        setModalTitle('הצלחה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);

      if (responseData.success) setIsNewsletterChecked(!isNewsletterChecked); // Toggle the checked state
    } catch (error) {
      // resetReCAPTCHA()
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/settings/newsletter', {
          method: 'POST',
        });

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // resetReCAPTCHA()

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          const user = responseData.data;
          setIsNewsletterChecked(user);
        }
      } catch (error) {
        // resetReCAPTCHA()
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    };

    fetchData();
  }, []);

  const handleDeleteAccount = async () => {
    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    const submitBtn = document.getElementById('deleteAccount');
    setShowAnimation(true);

    try {
      submitBtn.disabled = true;

      const formData = new FormData();
      formData.append('action', 'get_code');

      const response = await fetch('/settings/delete-account', {
        method: 'DELETE',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setShowEmailVerification(true);
      }
    } catch (error) {
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  useEffect(() => {
    let cookieValue = getSettingsCookie();
    cookieValue = cookieValue ? cookieValue : 'settingsProfile';
    setSelectedSettingsOption(cookieValue);
    document.getElementById(cookieValue).classList.add('clicked');

    if (selectedSettingsOption === 'settingsProfile') {
      setShowSettingsProfile(true);
      setShowSettingsCookies(false);
      setShowSettingsBilling(false);
    } else if (selectedSettingsOption === 'settingsCookies') {
      setShowSettingsProfile(false);
      setShowSettingsCookies(true);
      setShowSettingsBilling(false);
    } else if (selectedSettingsOption === 'settingsBilling') {
      setShowSettingsProfile(false);
      setShowSettingsCookies(false);
      setShowSettingsBilling(true);
    }
  }, [selectedSettingsOption]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const [passwordMissingRules, setPasswordMissingRules] = useState({});

  return (
    <React.Fragment>
      {showEmailVerification && (
        <EmailVerification
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          showEmailVerification={showEmailVerification}
          setShowEmailVerification={setShowEmailVerification}
        />
      )}
      {showPasswordRequirements && (
        <PasswordRequirementsModal
          isRegister={false}
          setPasswordMissingRules={setPasswordMissingRules}
          passwordMissingRules={passwordMissingRules}
          setShowPasswordRequirements={setShowPasswordRequirements}
          showPasswordRequirements={showPasswordRequirements}
        />
      )}

      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <CookieAgreement />
      <NavBar
        classAdditional=""
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={true}
      />
      <div className="settings-main">
        <h1 id="userSettings" className="section-title">
          {languageFile.settings}
        </h1>
        <ul
          className={`settings-option ${showSettingsOption ? 'visible' : ''} ${
            selectedLanguage === 'he' ? 'reverse-list' : ''
          }`}
        >
          <li
            onClick={settingsOptionClicked}
            id="settingsProfile"
            className="settings-option-item"
          >
            {languageFile.profile}
          </li>
          <li
            onClick={settingsOptionClicked}
            id="settingsCookies"
            className="settings-option-item"
          >
            {languageFile.cookiesTitle}
          </li>
          <li
            onClick={settingsOptionClicked}
            id="settingsBilling"
            className="settings-option-item gray-out"
          >
            {languageFile.billingTitle}
          </li>
        </ul>
        {selectedSettingsOption === 'settingsProfile' && (
          <div
            className={`settings-parent ${
              showSettingsProfile ? 'visible' : ''
            }`}
          >
            <div className={`settings-profile`}>
              <div
                tabIndex="0"
                className={`register-form-input-row register-form-email-row ${
                  isFirstNameValid ? 'valid' : ''
                } ${isFirstNameFocused ? 'register-row-focused' : ''}`}
                id="registerRowFirstName"
                onClick={handleDivFirstNameClick}
              >
                <i
                  className={`form-register-input-icon fa-solid fa-user ${
                    isFirstNameFocused ? 'register-i-focused' : ''
                  }`}
                  onClick={handleDivFirstNameClick}
                ></i>
                <label
                  dir="auto"
                  className={`${
                    isFirstNameFocused ? 'register-label-focused' : ''
                  }`}
                  onClick={handleDivFirstNameClick}
                  htmlFor="updateFirstName"
                >
                  {languageFile.firstNamePlaceholder}
                </label>
                <input
                  type="text"
                  id="updateFirstName"
                  name="first_name"
                  onChange={updateFirstNameValue}
                  value={firstNameValue}
                  maxLength="35"
                />
                <i
                  onClick={handleFirstNameUpdate}
                  className={`update-icon fa-solid fa-square-check ${
                    firstNameValue !== '' ? 'show-update-icon' : ''
                  }`}
                ></i>
              </div>
              <div
                tabIndex="0"
                className={`register-form-input-row register-form-email-row ${
                  isLastNameValid ? 'valid' : ''
                } ${isLastNameFocused ? 'register-row-focused' : ''}`}
                id="registerRowLastName"
                onClick={handleDivLastNameClick}
              >
                <i
                  className={`form-register-input-icon fa-solid fa-user ${
                    isLastNameFocused ? 'register-i-focused' : ''
                  }`}
                  onClick={handleDivLastNameClick}
                ></i>
                <label
                  dir="auto"
                  className={`${
                    isLastNameFocused ? 'register-label-focused' : ''
                  }`}
                  onClick={handleDivLastNameClick}
                  htmlFor="updateLastName"
                >
                  {languageFile.lastNamePlaceholder}
                </label>
                <input
                  type="text"
                  id="updateLastName"
                  name="last_name"
                  onChange={updateLastNameValue}
                  value={lastNameValue}
                  maxLength="35"
                />
                <i
                  onClick={handleLastNameUpdate}
                  className={`update-icon fa-solid fa-square-check ${
                    lastNameValue !== '' ? 'show-update-icon' : ''
                  }`}
                ></i>
              </div>
              <div
                tabIndex="0"
                className={`register-form-input-row register-form-email-row ${
                  isDateOfBirthInputVerified ? 'valid' : ''
                } ${isDobFocused ? 'register-row-focused' : ''}`}
                id="registerRowEmail"
                onClick={handleDivDobClick}
              >
                <i
                  className={`form-register-input-icon fa-solid fa-calendar-days ${
                    isDobFocused ? 'register-i-focused' : ''
                  }`}
                  onClick={handleDivDobClick}
                ></i>
                <label
                  dir="auto"
                  className={`${isDobFocused ? 'register-label-focused' : ''}`}
                  onClick={handleDivDobClick}
                  htmlFor="updateDob"
                >
                  {languageFile.birthDatePlaceholder}
                </label>
                <input
                  type="date"
                  id="updateDob"
                  name="date_of_birth"
                  onChange={updateDobValue}
                  value={dobValue}
                  maxLength="35"
                />
                <i
                  onClick={handleDobUpdate}
                  className={`update-icon fa-solid fa-square-check ${
                    isDateOfBirthInputVerified ? 'show-update-icon' : ''
                  }`}
                ></i>
              </div>
            </div>
            <div
              tabIndex="0"
              className={`register-form-input-row register-form-password-row ${
                isPasswordInputVerified
                  ? 'valid'
                  : `${isPasswordInputVerifiedNull ? '' : 'not-valid'}`
              } ${isPasswordFocused ? 'register-row-focused' : ''}`}
              id="updateRowPassword"
              onClick={handleDivPasswordClick}
            >
              <i
                className={`form-register-input-icon fa-solid fa-user-lock ${
                  isPasswordFocused ? 'register-i-focused' : ''
                }`}
                onClick={handleDivPasswordClick}
              ></i>
              <label
                dir="auto"
                className={`${
                  isPasswordFocused ? 'register-label-focused' : ''
                }`}
                onClick={handleDivPasswordClick}
                htmlFor="updatePassword"
              >
                {languageFile.loginPassword}*
              </label>
              <input
                type="password"
                id="updatePassword"
                name="password"
                onChange={updatePasswordValue}
                value={passwordValue}
                maxLength="80"
              />
              {isPasswordVisible ? (
                <i
                  className="password-visibility fa-solid fa-eye-slash"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="password-visibility fa-solid fa-eye"
                  onClick={togglePassword}
                ></i>
              )}
            </div>
            <div
              tabIndex="0"
              className={`register-form-input-row register-form-password-row ${
                isPasswordInputVerified
                  ? 'valid'
                  : `${isPasswordInputVerifiedNull ? '' : 'not-valid'}`
              } ${isPasswordConfirmFocused ? 'register-row-focused' : ''}`}
              id="registerRowPasswordConfirm"
              onClick={handleDivPasswordConfirmClick}
            >
              <i
                className={`form-register-input-icon fa-solid fa-check-double ${
                  isPasswordConfirmFocused ? 'register-i-focused' : ''
                }`}
                onClick={handleDivPasswordConfirmClick}
              ></i>
              <label
                dir="auto"
                className={`${
                  isPasswordConfirmFocused ? 'register-label-focused' : ''
                }`}
                onClick={handleDivPasswordConfirmClick}
                htmlFor="updatePasswordConfirm"
              >
                {languageFile.confirmPasswordPlaceholder}*
              </label>
              <input
                type="password"
                id="updatePasswordConfirm"
                name="confirm_password"
                onChange={updatePasswordConfirmValue}
                value={passwordConfirmValue}
                maxLength="80"
              />
              {isPasswordVisible ? (
                <i
                  className="password-visibility fa-solid fa-eye-slash"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="password-visibility fa-solid fa-eye"
                  onClick={togglePassword}
                ></i>
              )}
              <i
                onClick={handlePasswordUpdate}
                className={`update-icon fa-solid fa-square-check ${
                  isPasswordInputVerified ? 'show-update-icon' : ''
                }`}
              ></i>
            </div>
            {!isMobileVerified && (
              <React.Fragment>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row`}
                  id="updateRowMobile"
                  onClick={handleOpenMobileVerification}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-phone`}
                  ></i>
                  <p>{languageFile.verifyYourMobile}</p>
                </div>
                {openMobileVerification && (
                  <MobileVerification
                    setIsModalClosed={setIsModalClosed}
                    setModalInformation={setModalInformation}
                    setModalType={setModalType}
                    setModalTitle={setModalTitle}
                    setModalDescription={setModalDescription}
                    openMobileVerification={openMobileVerification}
                    setOpenMobileVerification={setOpenMobileVerification}
                  />
                )}
              </React.Fragment>
            )}
            <div className="register-switches">
              <label dir="auto" className={``} htmlFor="settingsNewsletter">
                {languageFile.newsletterTitle}
              </label>
              <div className="form-row form-row-condition form-row-condition-register form-row-condition-cookies-settings">
                <label dir="auto" className="form-label condition-label">
                  <a
                    dir="auto"
                    href="/terms-of-service"
                    target="_blank"
                  >{`${languageFile.reject}`}</a>
                </label>
                <label className="form-switch">
                  <input
                    id="settingsNewsletter"
                    type="checkbox"
                    name="newsletter"
                    data-gtm-form-interact-field-id="0"
                    checked={isNewsletterChecked}
                    onChange={handleNewsletterSwitchChange}
                  />
                  <span className="slider round"></span>
                </label>
                <label dir="auto" className="form-label condition-label">
                  <a
                    dir="auto"
                    href="/terms-of-service"
                    target="_blank"
                  >{`${languageFile.accept}`}</a>
                </label>
              </div>
            </div>
            <button
              id="deleteAccount"
              onClick={handleDeleteAccount}
              className="delete-my-account"
            >
              {languageFile.deleteMyAccount}
            </button>
            {/* <div className={`animation-parent`} id='loading-spinner-register2'>
                            <div className={`animation animation-circle`}></div>
                        </div> */}
            <div id="animationScreen" className={`loading-animation-general`}>
              <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
            </div>
          </div>
        )}
        {selectedSettingsOption === 'settingsCookies' && (
          <div
            className={`settings-parent ${
              showSettingsCookies ? 'visible' : ''
            }`}
          >
            <div className={`settings-profile`}>
              <div
                dir="auto"
                className="policy-buttons settings-policy-buttons"
              >
                <a
                  target="_blank"
                  dir="auto"
                  className="btn privacy-policy-button"
                  href="/privacy-policy"
                >
                  {languageFile.cookieAgreementPolicyPrivacy}
                </a>
                <a
                  target="_blank"
                  dir="auto"
                  className="btn cookies-policy-button"
                  href="/cookies-policy"
                >
                  {languageFile.cookieAgreementPolicyCookies}
                </a>
              </div>
              <p dir="auto" className="settings-cookies-p">
                {languageFile.cookieAgreementAbout}
              </p>
              <div className="register-switches">
                <div className="form-row form-row-condition form-row-condition-register form-row-condition-cookies-settings">
                  <label dir="auto" className="form-label condition-label">
                    <a
                      dir="auto"
                      href="/terms-of-service"
                      target="_blank"
                    >{`${languageFile.cookieAgreementReject}`}</a>
                  </label>
                  <label className="form-switch">
                    <input
                      id="acceptContact"
                      type="checkbox"
                      name="accept_contact"
                      data-gtm-form-interact-field-id="0"
                      checked={isCookiesChecked}
                      onChange={handleCookiesSwitchChange}
                    />
                    <span className="slider round"></span>
                  </label>
                  <label dir="auto" className="form-label condition-label">
                    <a
                      dir="auto"
                      href="/terms-of-service"
                      target="_blank"
                    >{`${languageFile.cookieAgreementAccept}`}</a>
                  </label>
                </div>
              </div>
              {/* <div className={`animation-parent`} id='loading-spinner-register2'>
                                <div className={`animation animation-circle`}></div>
                            </div> */}
              <div id="animationScreen" className={`loading-animation-general`}>
                <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
              </div>
            </div>
          </div>
        )}
        {selectedSettingsOption === 'settingsBilling' && (
          <React.Fragment>
            <h1>settingsBilling</h1>
          </React.Fragment>
        )}
      </div>
      <LoggedFooter
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
      />
    </React.Fragment>
  );
}

export default Settings;
