import React, { useState, useEffect, useContext } from 'react';
import './unit-options-modal.css';
import { useGlobalContext } from '../../../context/AppContext';
import { string } from 'prop-types';
import {
  setStudyExamTime,
  clearMemorizeMode,
  setStudyModeCookie,
  setStudyUnitCookie,
  setCurrentTutorialStepCookie,
} from '../../../utils/cookieUtils';

function UnitOptionsModal({
  setStepIndex,
  stepIndex,
  setShowExamTimeLimits,
  showMemorizationClassification,
  setShowMemorizationClassification,
  action,
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  showUnitOptionsModal,
  setShowUnitOptionsModal,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  // const actionTitle = action.substring(5);
  let actionTitle = action;
  let backupTitle = action;
  if (selectedLanguage === 'en') {
    actionTitle = action.replace('-', ' ');
  } else {
    actionTitle = action.replace('-', ' ');
    actionTitle = actionTitle.replace('unit', 'יחידה');
  }

  const closeModal = () => {
    setShowUnitOptionsModal(!showUnitOptionsModal);

    const childrenCollection = document.getElementById('unitsList').children;
    const childrenArray = Array.from(childrenCollection);

    childrenArray.forEach((element) => {
      element.classList.remove('environment-unit-gray-out-effect');
      element.classList.remove('environment-unit-selected-effect');
      element.children[0].classList.remove(
        'environment-unit-p-gray-out-effect'
      );
      setShowUnitOptionsModal(false);
    });
  };

  const [showUnit1Explanation, setShowUnit1Explanation] = useState(false);
  const [showUnit2Explanation, setShowUnit2Explanation] = useState(false);
  const [showUnit3Explanation, setShowUnit3Explanation] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);

  const explanation1Hover = () => {
    setShowExplanation(true);

    setShowUnit1Explanation(true);
    setShowUnit2Explanation(false);
    setShowUnit3Explanation(false);
  };

  const explanation2Hover = () => {
    setShowExplanation(true);

    setShowUnit1Explanation(false);
    setShowUnit2Explanation(true);
    setShowUnit3Explanation(false);
  };

  const explanation3Hover = () => {
    setShowExplanation(true);

    setShowUnit1Explanation(false);
    setShowUnit2Explanation(false);
    setShowUnit3Explanation(true);
  };

  const explanationUnHover = () => {
    setShowExplanation(false);

    setShowUnit1Explanation(false);
    setShowUnit2Explanation(false);
    setShowUnit3Explanation(false);
  };

  const handleUnitLearn = (event) => {
    const currentUnit = action;
    const learningMode = event.target.id;

    setStudyUnitCookie(currentUnit);
    setStudyModeCookie(learningMode);

    window.location = '/platform';
  };

  const handleUnitMemorization = (event) => {
    const currentUnit = action;
    const learningMode = event.target.id;

    setStudyUnitCookie(currentUnit);
    setStudyModeCookie(learningMode);

    setShowMemorizationClassification(true);
  };

  const handleUnitExam = (event) => {
    const currentUnit = action;
    const learningMode = event.target.id;

    setStepIndex(parseInt(stepIndex, 10) === 2 ? 3 : stepIndex);
    setCurrentTutorialStepCookie(parseInt(stepIndex, 10) === 2 ? 3 : stepIndex);

    setStudyUnitCookie(currentUnit);
    setStudyModeCookie(learningMode);

    setShowExamTimeLimits(true);
    // window.location = "/platform";
  };

  return (
    <div
      id="UnitOptionsModal"
      className={`unit-options-modal alert-modal fade-in-bottom-alert ${
        showUnitOptionsModal ? 'visible' : ''
      }`}
    >
      <i
        id="closeUnitModal"
        className="fa-solid fa-xmark"
        onClick={closeModal}
      ></i>
      <p className="unit-title">{actionTitle}</p>
      <ul className="unit-options-list unit-options-list-modes">
        <li
          id="modeStudy"
          className="unit-option modeStudy"
          onClick={handleUnitLearn}
          onMouseEnter={explanation1Hover}
          onMouseLeave={explanationUnHover}
        >
          {languageFile.unitOption1}
        </li>
        <li
          id="modeMemorization"
          className="unit-option modeMemorization gray-out"
          onClick={handleUnitMemorization}
          onMouseEnter={explanation2Hover}
          onMouseLeave={explanationUnHover}
        >
          {languageFile.unitOption2}
        </li>
        <li
          id="modeExam"
          className="unit-option modeExam"
          onClick={handleUnitExam}
          onMouseEnter={explanation3Hover}
          onMouseLeave={explanationUnHover}
        >
          {languageFile.unitOption3}
        </li>
      </ul>
      <p className="unit-option-explain">
        {languageFile.unitOptionExplanation}
      </p>
      <div
        className={`unit-option-explanations ${
          showExplanation ? 'visible' : ''
        }`}
      >
        <p
          className={`unit-option-explanation ${
            showUnit1Explanation ? 'visible' : ''
          }`}
          dir="auto"
        >
          {languageFile.unitOption1Explanation}.
        </p>
        <p
          className={`unit-option-explanation ${
            showUnit2Explanation ? 'visible' : ''
          }`}
          dir="auto"
        >
          {languageFile.unitOption2Explanation}.
        </p>
        <p
          className={`unit-option-explanation ${
            showUnit3Explanation ? 'visible' : ''
          }`}
          dir="auto"
        >
          {languageFile.unitOption3Explanation}.
        </p>
      </div>
    </div>
  );
}

export default UnitOptionsModal;
