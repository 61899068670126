import React, { useEffect, useState, useContext } from 'react';
import NavBar from '../NavBar/NavBar';
import Header from './Header/Header';
import FeaturesSection from './FeaturesSection/FeaturesSection';
import ProductsSection from './ProductsSection/ProductsSection';
import FaqSection from './FaqSection/FaqSection';
import ContactSection from './ContactSection/ContactSection';
import Footer from './Footer/footer';
import { getPageCookie, deletePageCookie } from '../../utils/cookieUtils';
import CookieAgreement from '../../utils/CookieAgreement';
import { useGlobalContext } from '../../context/AppContext';
import AlertModal from '../AlertModal/AlertModal';

function HomePage() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const { selectedLanguage } = useGlobalContext();

  useEffect(() => {
    const pageCookie = getPageCookie();
    deletePageCookie(pageCookie);
    if (pageCookie) {
      const targetElement = document.getElementById(pageCookie);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);

  useEffect(() => {
    document.title = `Milly | ${selectedLanguage === 'en' ? 'Home' : 'בית'}`; // Set the title to "Milly | Home"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  return (
    <React.Fragment>
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <CookieAgreement />
      <NavBar
        classAdditional=""
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={false}
      />
      <Header isSubscribed={false} />
      <main className="main" id="main">
        <FeaturesSection id="sectionFeatures" className="section-features" />
        <ProductsSection id="sectionProducts" className="section-products" />
        <FaqSection id="sectionFaq" className="section-faq" />
        <ContactSection
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
          id="sectionContact"
          className="section-contact"
        />
      </main>
      <Footer
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
      />
    </React.Fragment>
  );
}

export default HomePage;
