import React, { useContext } from 'react';
import './css/style.css';
import { useGlobalContext } from '../../../context/AppContext';
import CookieAgreement from '../../../utils/CookieAgreement';

function NotFound() {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  return (
    <React.Fragment>
      <CookieAgreement />
      <div id="notfound">
        <div class="notfound">
          {languageFile === 'en' ? (
            <React.Fragment>
              <div class="notfound-404">
                <h3>Oops! Page not found</h3>
                <h1>
                  <span>4</span>
                  <span>0</span>
                  <span>4</span>
                </h1>
              </div>
              <h2>we are sorry, but the page you requested was not found</h2>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div class="notfound-404">
                <h3>אופס! העמוד לא נמצא</h3>
                <h1>
                  <span>4</span>
                  <span>0</span>
                  <span>4</span>
                </h1>
              </div>
              <h2>אנו מתנצלים, אבל העמוד המבוקש לא קיים</h2>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotFound;
