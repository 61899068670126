import React, { useState, useEffect, useContext, useRef } from 'react';
import './reset-password.css';
import { useGlobalContext } from '../../../context/AppContext';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import AlertModal from '../../AlertModal/AlertModal';
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordRequirementsModal from '../../PasswordRequirementsModal/PasswordRequirementsModal';
import HashLoader from 'react-spinners/HashLoader';

function ResetPassword({
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  email,
  showResetPassword,
  setShowResetPassword,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const captchaKey = '6LdQ1ssoAAAAAFngYC0GewMPQEFWqxs2IRH61Fj_';
  const recaptchaRef = useRef(null);
  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  const handleSubmit = async (event) => {
    // check if all fields are given
    event.preventDefault();

    // const loadingSpinner = document.getElementById("loading-spinner");
    // loadingSpinner.style.display = "block";
    setShowAnimation(true);

    var submitBtn = document.getElementById('resetPasswordNext');
    submitBtn.disabled = true;

    setResetPasswordCode('');
    setNewPassword('');
    setNewPasswordConfirm('');

    try {
      const form = event.target;
      const formData = new FormData(form);
      formData.append('action', 'get_code');

      const response = await fetch('/reset-password', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";

      // You can handle the server response here if needed
      const responseData = await response.json();

      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription('Please make sure to use a valid email');
          setModalInformation('');
          setIsModalClosed(false);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setModalDescription('בבקשה תוודאו שהזנתם מייל תקין');
          setModalInformation('');
          setIsModalClosed(false);
        }
      } else {
        setModalType('success');
        setModalTitle('success');
        setModalInformation('');
        setIsModalClosed(false);
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(
            'If the email is valid, please check your inbox for instructions'
          );
        } else {
          setModalDescription(
            'אם כתובת המייל תקינה, אנא בדקו את תיבת המיילים שלכם להמשך התהליך'
          );
        }

        var formPartOne = document.getElementById('formResetPartOne');
        var formPartTwo = document.getElementById('formResetPartTwo');
        var closeModalBtn = document.getElementById('closeModal');
        var goBackBtn = document.getElementById('goBackBtn');

        setTimeout(() => {
          formPartOne.style.display = 'none';
          formPartTwo.style.display = 'flex';
          closeModalBtn.style.display = 'none';
          goBackBtn.style.display = 'block';
        }, 3000);
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } catch (error) {
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalType('error');
      setModalTitle('an error has occured');
      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [showAnimation, setShowAnimation] = useState(false);

  let passwordRequirementsList = {};
  const handleSubmit2 = async (event) => {
    // check if all fields are given
    event.preventDefault();
    if (!isPasswordInputVerified) {
      setShowPasswordRequirements(true);
      return;
    }

    // const loadingSpinner = document.getElementById("loading-spinner");
    // loadingSpinner.style.display = "block";

    setShowAnimation(true);

    var submitBtn = document.getElementById('resetPasswordNext');
    submitBtn.disabled = true;

    try {
      const form = event.target;
      const formData = new FormData(form);
      formData.append('action', 'reset_password');
      formData.append('email', emailValue);

      const response = await fetch('/reset-password', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      resetReCAPTCHA();

      // You can handle the server response here if needed
      const responseData = await response.json();
      const responseMessage = responseData.data;

      submitBtn.disabled = false;
      if (responseData.success === false) {
        if (responseData.type === 'password') {
          setModalType('warning');
          if (selectedLanguage === 'en') {
            setModalTitle('warning');
          } else {
            setModalTitle('שימו לב');
          }

          if (responseData.hasOwnProperty('data')) {
            for (const key in responseData.data) {
              if (responseData.data.hasOwnProperty(key)) {
                const value = responseData.data[key];
                if (value) {
                  if (selectedLanguage === 'en') {
                    passwordRequirementsList[key] = value;
                  } else {
                    passwordRequirementsList[key] = fetchResponseHandler(value);
                  }
                }
              }
            }
          }
          setModalDescription(JSON.stringify(passwordRequirementsList));
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          if (selectedLanguage === 'en') {
            setModalType('error');
            setModalDescription(responseData.data);
            setModalInformation('');
            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          } else {
            setIsModalClosed(false);
            setModalTitle('יש שגיאה');
            setModalInformation('');
            setModalType('error');

            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);

            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          }
        }
      } else {
        setIsModalClosed(false);
        setModalInformation('');
        setModalType('error');

        if (selectedLanguage === 'en') {
          setModalDescription('Password was changed successfully');
        } else {
          setModalDescription('הסיסמה שונתה בהצלחה');
        }

        setTimeout(() => {
          setIsModalClosed(true);
          setShowResetPassword(false);
          setEmailValue('');
          setResetPasswordCode('');
          setNewPassword('');
          setNewPasswordConfirm('');
        }, 3000);
      }
    } catch (error) {
      resetReCAPTCHA();
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalType('error');
      setModalTitle('an error has occured');
      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const closeModal = () => {
    setShowResetPassword(!showResetPassword);
    setEmailValue('');
    setResetPasswordCode('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  const [emailValue, setEmailValue] = useState('');
  const [resetPasswordCode, setResetPasswordCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [isResetPasswordCodeValid, setIsResetPasswordCodeValid] =
    useState(false);

  const handleEmail = (event) => {
    setEmailValue(event.target.value);
  };

  const handleVerificationCode = (event) => {
    const resetCode = document.getElementById('forgotPasswordVerificationCode');
    setResetPasswordCode(event.target.value.toUpperCase());

    if (resetCode.validity.valid) {
      setIsResetPasswordCodeValid(true);
    } else {
      setIsResetPasswordCodeValid(false);
    }
  };

  const handlePassword = (event) => {
    setIsPasswordInputVerifiedNull(false);
    setNewPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setIsPasswordInputVerifiedNull(false);
    setNewPasswordConfirm(event.target.value);
  };

  const [isPasswordInputVerifiedNull, setIsPasswordInputVerifiedNull] =
    useState(true);
  const [isPasswordInputVerified, setIsPasswordInputVerified] = useState(false);
  const [passwordIsTenOrMoreChars, setPasswordIsTenOrMoreChars] =
    useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsDigit, setContainsDigit] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });

  const passwordInputValidation = () => {
    if (!isLoaded) {
      return;
    }

    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialCharacters = '!@#$%^&*';

    const passwordInput = document.getElementById(
      'forgotPasswordPassword'
    ).value;
    const confirmPasswordInput = document.getElementById(
      'forgotPasswordConfirmPassword'
    ).value;

    const isTenOrMoreChars = passwordInput.length >= 10;
    const hasLowercaseLetter = [...lowercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasUppercaseLetter = [...uppercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasDigit = [...digits].some((char) => passwordInput.includes(char));
    const hasSpecialChar = [...specialCharacters].some((char) =>
      passwordInput.includes(char)
    );
    const hasSpace = passwordInput.includes(' ');
    let doPasswordsMatch;

    if (
      passwordInput === confirmPasswordInput &&
      passwordInput !== '' &&
      confirmPasswordInput !== ''
    ) {
      doPasswordsMatch = true;
    } else {
      doPasswordsMatch = false;
    }

    setPasswordIsTenOrMoreChars(isTenOrMoreChars);
    setContainsLowercaseLetter(hasLowercaseLetter);
    setContainsUppercaseLetter(hasUppercaseLetter);
    setContainsDigit(hasDigit);
    setContainsSpecialChar(hasSpecialChar);
    setPasswordsMatch(doPasswordsMatch);

    const isPasswordValid =
      isTenOrMoreChars &&
      hasLowercaseLetter &&
      hasUppercaseLetter &&
      hasDigit &&
      hasSpecialChar &&
      doPasswordsMatch &&
      !hasSpace;

    if (
      (!isPasswordValid && passwordInput !== '') ||
      confirmPasswordInput !== ''
    ) {
      let newPasswordMissingRules = {};

      if (!isTenOrMoreChars)
        newPasswordMissingRules['isTenOrMoreChars'] = isTenOrMoreChars;

      if (!hasLowercaseLetter)
        newPasswordMissingRules['hasLowercaseLetter'] = hasLowercaseLetter;

      if (!hasUppercaseLetter)
        newPasswordMissingRules['hasUppercaseLetter'] = hasUppercaseLetter;

      if (!hasDigit) newPasswordMissingRules['hasDigit'] = hasDigit;

      if (!hasSpecialChar)
        newPasswordMissingRules['hasSpecialChar'] = hasSpecialChar;

      if (!doPasswordsMatch)
        newPasswordMissingRules['doPasswordsMatch'] = doPasswordsMatch;

      if (hasSpace) newPasswordMissingRules['hasSpace'] = hasSpace;

      setPasswordMissingRules(newPasswordMissingRules);

      setShowPasswordRequirements(true);
    } else {
      setShowPasswordRequirements(false);
    }

    setIsPasswordInputVerified(isPasswordValid);
  };

  useEffect(() => {
    passwordInputValidation();
  }, [newPassword, newPasswordConfirm]);

  const GoBackToPartOne = () => {
    var formPartOne = document.getElementById('formResetPartOne');
    var formPartTwo = document.getElementById('formResetPartTwo');
    var goBackBtn = document.getElementById('goBackBtn');
    var closeModalBtn = document.getElementById('closeModal');
    var submitBtn = document.getElementById('resetPasswordNext');

    formPartOne.style.display = 'flex';
    formPartTwo.style.display = 'none';
    goBackBtn.style.display = 'none';
    closeModalBtn.style.display = 'block';
    submitBtn.disabled = false;

    setResetPasswordCode('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      document.getElementById('forgotPasswordPassword').type = 'password';
      document.getElementById('forgotPasswordConfirmPassword').type =
        'password';
    } else {
      setIsPasswordVisible(true);
      document.getElementById('forgotPasswordPassword').type = 'text';
      document.getElementById('forgotPasswordConfirmPassword').type = 'text';
    }
  };

  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [passwordMissingRules, setPasswordMissingRules] = useState({});

  return (
    <React.Fragment>
      {showPasswordRequirements && (
        <PasswordRequirementsModal
          isRegister={false}
          setPasswordMissingRules={setPasswordMissingRules}
          passwordMissingRules={passwordMissingRules}
          setShowPasswordRequirements={setShowPasswordRequirements}
          showPasswordRequirements={showPasswordRequirements}
        />
      )}
      <div
        className={`alert-overlay fade-in-bottom-alert ${
          showResetPassword ? 'visible' : ''
        } reset-modal`}
      >
        <div id="alertModal" className={`alert-modal`}>
          <form
            onSubmit={handleSubmit}
            className="form-reset-password"
            id="formResetPartOne"
          >
            <i
              id="closeModal"
              className="fa-solid fa-xmark"
              onClick={closeModal}
            ></i>
            <div className="alert-modal-icon-container alert-modal-icon-container-reset-password">
              <i className="fa-solid fa-unlock"></i>
            </div>
            <p className={`alert-modal-type alert-modal-type-information`}>
              {languageFile.resetPassword}
            </p>
            <div className="rform-row rform-row-label">
              <label htmlFor="forgotPasswordEmail">
                {languageFile.emailPlaceholder}
              </label>
            </div>
            <div className="rform-row rform-row-input">
              <input
                id="forgotPasswordEmail"
                type="email"
                name="email"
                onChange={handleEmail}
                value={emailValue}
                maxLength="80"
              />
            </div>
            <div className="rform-row rform-row-submit">
              <button className="btn" id="resetPasswordNext" type="submit">
                {languageFile.resetPasswordNext}
              </button>
            </div>
            {/* <div className={`animation animation-circle`} id='loading-spinner'></div> */}
            <div id="animationScreen" className={`loading-animation-general`}>
              <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
            </div>
          </form>
          <form
            onSubmit={handleSubmit2}
            className="form-reset-password"
            id="formResetPartTwo"
          >
            <i
              className="fa-solid fa-arrow-rotate-right"
              id="goBackBtn"
              onClick={GoBackToPartOne}
            ></i>
            <div className="alert-modal-icon-container alert-modal-icon-container-reset-password">
              <i className="fa-solid fa-unlock"></i>
            </div>
            <p className={`alert-modal-type alert-modal-type-information`}>
              {languageFile.resetPassword}
            </p>
            <div className="rform-row rform-row-label">
              <label htmlFor="forgotPasswordVerificationCode">
                {languageFile.resetPasswordCode}
              </label>
            </div>
            <div className="rform-row rform-row-input">
              <input
                maxLength="7"
                id="forgotPasswordVerificationCode"
                type="text"
                name="code"
                onChange={handleVerificationCode}
                value={resetPasswordCode}
                className={`${
                  isResetPasswordCodeValid ? 'reset-password-valid' : ''
                }`}
              />
            </div>
            <div className="rform-row rform-row-label">
              <label htmlFor="forgotPasswordPassword">
                {languageFile.resetPasswordNew}
              </label>
            </div>
            <div className="rform-row rform-row-input">
              <input
                maxLength="80"
                id="forgotPasswordPassword"
                type="password"
                name="password"
                onChange={handlePassword}
                value={newPassword}
                className={`${
                  isPasswordInputVerified
                    ? 'reset-password-valid'
                    : `${
                        isPasswordInputVerifiedNull
                          ? ''
                          : 'reset-password-not-valid'
                      }`
                }`}
              />
              {isPasswordVisible ? (
                <i
                  className="password-visibility-reset fa-solid fa-eye-slash"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="password-visibility-reset fa-solid fa-eye"
                  onClick={togglePassword}
                ></i>
              )}
            </div>
            <div className="rform-row rform-row-label">
              <label htmlFor="forgotPasswordConfirmPassword">
                {languageFile.resetPasswordNewConfirm}
              </label>
            </div>
            <div className="rform-row rform-row-input">
              <input
                maxLength="80"
                id="forgotPasswordConfirmPassword"
                type="password"
                name="confirm_password"
                onChange={handleConfirmPassword}
                value={newPasswordConfirm}
                className={`${
                  isPasswordInputVerified
                    ? 'reset-password-valid'
                    : `${
                        isPasswordInputVerifiedNull
                          ? ''
                          : 'reset-password-not-valid'
                      }`
                }`}
              />
              {isPasswordVisible ? (
                <i
                  className="password-visibility-reset fa-solid fa-eye-slash"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="password-visibility-reset fa-solid fa-eye"
                  onClick={togglePassword}
                ></i>
              )}
            </div>
            <div className="recpatcha-container">
              <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} />
            </div>
            <div className="rform-row rform-row-submit">
              <button className="btn" id="resetPasswordNext" type="submit">
                {languageFile.resetPasswordFinal}
              </button>
            </div>
            {/* <div className={`animation animation-circle`} id='loading-spinner'></div> */}
            <div id="animationScreen" className={`loading-animation-general`}>
              <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword;
