import React, { useState, useEffect, useContext } from 'react';
import './Tutorial.css';
import NavBar from '../NavBar/NavBar';
import { useGlobalContext } from '../../context/AppContext';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';
import AlertModal from '../AlertModal/AlertModal';
import {
  setCurrentTutorialStepCookie,
  setStartTutorialCookie,
} from '../../utils/cookieUtils';
import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';

function Tutorial() {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [isOpen, setIsOpen] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const [showAnimation, setShowAnimation] = useState(false);

  const [currentTutorial, setCurrentTutorial] = useState(0);
  const [showedTutorials, setShowedTutorials] = useState([]);

  const navigate = useNavigate();

  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  const handleTutorialClick = (tutorialID) => {
    if (!tutorialID) return;

    try {
      tutorialID = tutorialID.substring(9);
    } catch (error) {
      return;
    }

    setStartTutorialCookie(tutorialID);

    switch (tutorialID) {
      case '1': // exam mode
      case '2': // study mode
      case '3': // memorize mode
        setCurrentTutorialStepCookie(0);
        navigate('/environment');

        break;

      case 4:
        // AI Assist
        break;

      default:
        break;
    }
  };

  const tutorials = [
    {
      id: 'tutorial-1',
      title: languageFile.examMode,
      description:
        selectedLanguage === 'en'
          ? 'Learn how to use the exams feature step by step.'
          : 'למדו כיצד להשתמש במצב הבחינה שלב אחר שלב.',
      icon: 'fa-solid fa-file-lines',
    },
    {
      id: 'tutorial-2',
      title: languageFile.studyMode,
      description:
        selectedLanguage === 'en'
          ? 'Learn how to use the study feature step by step.'
          : 'למדו כיצד להשתמש במצב הלמידה שלב אחר שלב.',
      icon: 'fa-solid fa-book',
    },
    {
      id: 'tutorial-3',
      title: languageFile.memorizeMode,
      description:
        selectedLanguage === 'en'
          ? 'Learn how to use the memorize feature step by step.'
          : 'למדו כיצד להשתמש במצב השינון שלב אחר שלב.',
      icon: 'fa-solid fa-sd-card',
    },
    {
      id: 'tutorial-4',
      title: languageFile.AIAssist,
      description:
        selectedLanguage === 'en'
          ? 'Learn how to use the AI Assist feature step by step.'
          : 'למדו כיצד להשתמש בתומך AI שלנו שלב אחר שלב.',
      icon: 'fa-solid fa-microchip',
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTutorial((prev) => (prev + 1) % tutorials.length);

      setShowedTutorials((prevTutorials) => [
        ...prevTutorials,
        currentTutorial,
      ]);
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentTutorial, tutorials.length, setShowedTutorials]);

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional=""
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={true}
      />
      <main id="main">
        <h1 id="sectionTitle" className="section-title">
          {languageFile.navbarTutorial}
        </h1>
        {selectedLanguage === 'en' ? (
          <p id="sectionBanner" className="section-banner" dir="auto">
            Learn how to use every feature in Milly step by step.
          </p>
        ) : (
          <p id="sectionBanner" className="section-banner" dir="auto">
            למדו כיצד להשתמש בכל דבר במילי שלב אחר שלב.
          </p>
        )}
        <div id="tutorials-container">
          {tutorials.map((tutorial, index) => (
            <div
              key={tutorial.id}
              className={`tutorial-container ${index > 0 && 'gray-out'} ${
                index % 2 === 0 ? 'inverted' : ''
              } ${
                index === currentTutorial || showedTutorials.includes(index)
                  ? 'active'
                  : ''
              }`}
            >
              <div className="tutorial-icon">
                <i className={tutorial.icon}></i>
              </div>
              <div id={tutorial.id} className="tutorial-banner">
                <h1 dir="auto">
                  #{index + 1} - <span>{tutorial.title}</span>
                </h1>
                <p dir="auto">{tutorial.description}</p>
                <button
                  onClick={() => handleTutorialClick(tutorial.id)}
                  className="btn btn-tutorial"
                >
                  {languageFile.startTutorial}
                </button>
              </div>
            </div>
          ))}
        </div>
      </main>
      <LoggedFooter
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
      />
    </React.Fragment>
  );
}

export default Tutorial;
