import React, { useState, useEffect, useContext } from 'react';
import './progress.css';
import NavBar from '../NavBar/NavBar';
import { useGlobalContext } from '../../context/AppContext';
import Dummy from './Dummy/Dummy';
import Exams from './Exams/Exams';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';
import AlertModal from '../AlertModal/AlertModal';

function Progress() {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [isOpen, setIsOpen] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [mainTopNavOption, setMainTopNavOption] = useState('mainTopNavExams');

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const [showAnimation, setShowAnimation] = useState(false);

  const [resetCompare, setResetCompare] = useState(false);

  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const handleTopNavClick = (event) => {
    if (!pageLoaded) {
      return;
    }

    setShowCompareButton(false);

    setResetCompare(true);

    const target = event.target;

    const questionsArray = [
      ...document.getElementsByClassName('mainTopNav-items'),
    ];
    questionsArray.forEach((element) => {
      element.classList.remove('selected');
    });

    target.classList.add('selected');
    setMainTopNavOption(target.id);
  };

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  const [showCompareButton, setShowCompareButton] = useState(false);

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional=""
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={true}
      />
      <main id="main">
        <div id="mainTopNav">
          <ul
            className={`${
              selectedLanguage === 'he' ? 'reverse-ul-hebrew' : ''
            }`}
          >
            <li
              id="mainTopNavExams"
              className="mainTopNav-items selected"
              onClick={handleTopNavClick}
            >
              {languageFile.examsTitleNav}
            </li>
            {/* <li
              id="mainTopNavDummy"
              className="mainTopNav-items"
              onClick={handleTopNavClick}
            >
              lorem
            </li>
            <li
              id="mainTopNavDummy2"
              className="mainTopNav-items"
              onClick={handleTopNavClick}
            >
              ipsum
            </li> */}
          </ul>
        </div>
        {mainTopNavOption && (
          <React.Fragment>
            {mainTopNavOption === 'mainTopNavExams' && (
              <Exams
                setShowCompareButton={setShowCompareButton}
                showCompareButton={showCompareButton}
                resetCompare={resetCompare}
                setResetCompare={setResetCompare}
                pageLoaded={pageLoaded}
                setShowAnimation={setShowAnimation}
                showAnimation={showAnimation}
                isModalClosed={isModalClosed}
                setIsModalClosed={setIsModalClosed}
                setModalType={setModalType}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
                setModalInformation={setModalInformation}
              />
            )}
            {mainTopNavOption === 'mainTopNavDummy' && (
              <Dummy pageLoaded={pageLoaded} />
            )}
            {mainTopNavOption === 'mainTopNavDummy2' && (
              <Dummy pageLoaded={pageLoaded} />
            )}
          </React.Fragment>
        )}
      </main>
      <LoggedFooter
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
      />
    </React.Fragment>
  );
}

export default Progress;
