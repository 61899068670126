import React, { useEffect, useContext, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import './security-policy.css';
import { useGlobalContext } from '../../context/AppContext';
import ContactSection from '../HomePage/ContactSection/ContactSection';
import AlertModal from '../AlertModal/AlertModal';
import {
  getLoginCookie,
  verifySessionCookie,
  getSessionCookie,
} from '../../utils/cookieUtils';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';

function SecurityPolicy() {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const loggedUser = verifySessionCookie(getSessionCookie());

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Security Policy' : 'מדיניות אבטחה'
    }`; // Set the title to "Milly | Security Policy"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={loggedUser}
      />
      {selectedLanguage === 'en' ? (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">
            Information Security Policy
          </h1>
          <div className="document-contents">
            <p dir="auto" className="update-date">
              {' '}
              Last updated: September 17, 2023
            </p>

            <h3 dir="auto">Overview</h3>
            <p dir="auto">
              Web application vulnerabilities account for the largest portion of
              attack vectors outside of malware. It is crucial that any web
              application be assessed for vulnerabilities and any
              vulnerabilities be remediated prior to production deployment.
            </p>

            <h3 dir="auto">Purpose</h3>
            <p dir="auto">
              The purpose of this policy is to define web application security
              assessments within MillyLearn. Web application assessments are
              performed to identify potential or realized weaknesses as a result
              of inadvertent mis-configuration, weak authentication,
              insufficient error handling, sensitive information leakage, etc.
              Discovery and subsequent mitigation of these issues will limit the
              attack surface of MillyLearn services available both internally
              and externally as well as satisfy compliance with any relevant
              policies in place
            </p>

            <h3 dir="auto">Scope</h3>
            <p dir="auto">
              This policy covers all web application security assessments
              requested by any individual, group or department for the purposes
              of maintaining the security posture, compliance, risk management,
              and change control of technologies in use at MillyLearn.
            </p>
            <p dir="auto">
              All web application security assessments will be performed by
              delegated security personnel either employed or contracted by
              MillyLearn. All findings are considered confidential and are to be
              distributed to persons on a “need to know” basis. Distribution of
              any findings outside of MillyLearn is strictly prohibited unless
              approved by the Chief Information Officer.
            </p>
            <p dir="auto">
              Any relationships within multi-tiered applications found during
              the scoping phase will be included in the assessment unless
              explicitly limited. Limitations and subsequent justification will
              be documented prior to the start of the assessment.
            </p>

            <h3 dir="auto">security assessments</h3>
            <ul>
              <li dir="auto">
                <strong>New or Major Application Release</strong> – will be
                subject to a full assessment prior to approval of the change
                control documentation and/or release into the live environment.
              </li>
              <li dir="auto">
                <strong>Third Party or Acquired Web Application</strong> – will
                be subject to full assessment after which it will be bound to
                policy requirements.
              </li>
              <li dir="auto">
                <strong>Point Releases</strong> – will be subject to an
                appropriate assessment level based on the risk of the changes in
                the application functionality and/or architecture.
              </li>
              <li dir="auto">
                <strong>Patch Releases</strong> – will be subject to an
                appropriate assessment level based on the risk of the changes to
                the application functionality and/or architecture.
              </li>
              <li dir="auto">
                <strong>Emergency Releases</strong> – An emergency release will
                be allowed to forgo security assessments and carry the assumed
                risk until such time that a proper assessment can be carried
                out. Emergency releases will be designated as such by the Chief
                Information Officer or an appropriate manager who has been
                delegated this authority.
              </li>
              <li dir="auto">
                <strong>Annual Review</strong> – all applications will be
                subject to a full annual review in its entirety to review
                potential risks of functionality and/or architecture.
              </li>
            </ul>

            <h3 dir="auto">Risk Levels</h3>
            <p dir="auto">
              All security issues that are discovered during assessments must be
              mitigated based upon the following risk levels. The Risk Levels
              are based on the OWASP Risk Rating Methodology. Remediation
              validation testing will be required to validate fix and/or
              mitigation strategies for any discovered issues of Medium risk
              level or greater.
            </p>
            <ul>
              <li dir="auto">
                <strong style={{ color: 'red' }}>Critical</strong> - Issues
                categorized as "Critical" pose an immediate and severe threat to
                the security of your application and data. These issues must be
                addressed urgently and with the highest priority. Failure to do
                so may result in significant breaches, data loss, or system
                compromise. Mitigation strategies should be implemented
                immediately, and thorough testing is essential to ensure their
                effectiveness.
              </li>
              <li dir="auto">
                <strong style={{ color: 'orange' }}>High</strong> – Any
                high-risk issue must be fixed immediately or other mitigation
                strategies must be put in place to limit exposure before
                deployment. Applications with high risk issues are subject to
                being taken off-line or denied release into the live
                environment.
              </li>
              <li dir="auto">
                <strong style={{ color: 'yellow' }}>Medium</strong> – Medium
                risk issues should be reviewed to determine what is required to
                mitigate and scheduled accordingly. Applications with medium
                risk issues may be taken off-line or denied release into the
                live environment based on the number of issues and if multiple
                issues increase the risk to an unacceptable level. Issues should
                be fixed in a patch/point release unless other mitigation
                strategies will limit exposure.
              </li>
              <li dir="auto">
                <strong style={{ color: 'blue' }}>Low</strong> – Issue should be
                reviewed to determine what is required to correct the issue and
                scheduled accordingly.
              </li>
              <li dir="auto">
                <strong style={{ color: 'green' }}>Informative</strong> - Any
                information that provides valuable information about potential
                security enhancements but do not represent an immediate threat.
                These findings offer insights into areas where improvements can
                be made but do not require immediate action. Consider addressing
                informative issues during regular security updates and
                improvements to enhance your application's overall security
                posture.
              </li>
            </ul>

            <h3 dir="auto">security assessment levels</h3>
            <p dir="auto">
              The following security assessment levels shall be established by
              the InfoSec organization or other designated organization that
              will be performing the assessments.
            </p>
            <ul>
              <li dir="auto">
                <strong>Full</strong> - A full assessment is comprised of tests
                for all known web application vulnerabilities using both
                automated and manual tools based on the OWASP Testing Guide. A
                full assessment will use manual penetration testing techniques
                to validate discovered vulnerabilities to determine the overall
                risk of any and all discovered.
              </li>
              <li dir="auto">
                <strong>Quick</strong> – A quick assessment will consist of a
                (typically) automated scan of an application for the OWASP Top
                Ten web application security risks at a minimum.
              </li>
              <li dir="auto">
                <strong>Targeted</strong> – A targeted assessment is performed
                to verify vulnerability remediation changes or new application
                functionality
              </li>
            </ul>

            <h3 dir="auto">Policy Compliance</h3>
            <p dir="auto">
              The following security assessment levels shall be established by
              the InfoSec organization or other designated organization that
              will be performing the assessments.
            </p>
            <ul>
              <li dir="auto">
                The Infosec team will verify compliance to this policy through
                various methods, including but not limited to, business tool
                reports, internal and external audits, and feedback to the
                policy owner.
              </li>
              <li dir="auto">
                Any exception to the policy must be approved by the Infosec team
                in advance.
              </li>
              <li dir="auto">
                An individual found to have violated this policy may be subject
                to disciplinary action, up to and including termination of the
                account. Web application assessments are a requirement of the
                change control process and are required to adhere to this policy
                unless found to be exempt. All application releases must pass
                through the change control process. Any web applications that do
                not adhere to this policy may be taken offline until such time
                that a formal assessment can be performed at the discretion of
                the Chief Information Officer.
              </li>
            </ul>

            <h3 dir="auto">Related Standards, Policies and Processes</h3>
            <p dir="auto">
              <a href="https://owasp.org/www-project-top-ten/">
                OWASP Top Ten Project
              </a>
            </p>
            <p dir="auto">
              <a href="https://owasp.org/www-project-web-security-testing-guide/">
                OWASP Web Security Testing Guide
              </a>
            </p>
            <p dir="auto">
              <a href="https://owasp.org/www-community/OWASP_Risk_Rating_Methodology">
                OWASP Risk Rating Methodology
              </a>
            </p>

            <h3 dir="auto">Definitions and Terms</h3>
            <p dir="auto">None.</p>

            <h3 dir="auto">authentication</h3>
            <p dir="auto">
              Logging into the system is possible using a personal email address
              and password.
            </p>
            <p dir="auto">
              We Lock your account after five incorrect logon attempts to
              prevent computer burglars from penetrating your account.
            </p>
            {/* <p dir='auto'>In a situation where the system is active, but it is not active for 10 minutes, the system will be automatically disconnected.</p> */}
            <p dir="auto">
              A variety of security and communication-level security components
              enable site control, identification and filtering.
            </p>
            <p dir="auto">
              We use advanced logging and monitoring technologies and
              techniques.
            </p>

            <h3 dir="auto">Rules for safe browsing / Email privacy</h3>
            <p dir="auto">
              The success of your activity and the confidentiality of your data
              depends on taking a few basic steps from the user. When you start
              any site, make sure that you follow the next rules:
            </p>
            <ul>
              <li dir="auto">
                The site correctly identified with the browser digital
                certificate (TLS), and the content of the information
                transmitted between it is encrypted. This can be ascertained by
                the closed lock image adjacent to the URL server (
                <i className="fa-solid fa-lock"></i>).
              </li>
              <li dir="auto">
                When choosing a password, you must set High quality password.
                Simple passwords such as birth dates, spouse name and the like
                should be avoided.
              </li>
              <li dir="auto">
                The system login password is personal and confidential, and no
                morals for anyone. Under no circumstances will you have to name
                your personal password on the phone, or email any party. If such
                a request is made - you must refuse.
              </li>
              <li dir="auto">
                When you are done with the site, make sure to disconnected and
                close the browser.
              </li>
              <li dir="auto">
                You must avoid clicking links in suspicious emails and
                responding to suspicious emails. These links often constitute
                impostor sites that want to make you provide your information.
              </li>
              <li dir="auto">
                Under no circumstances should credit card information be
                emailed. Email is not secure enough and subject to exposure by
                parties who are not allowed to do so.
              </li>
              <li dir="auto">
                You must have security measures such as: anti-virus software,
                firewall and spam email penetration software (ANTI SPAM) on your
                computer. Which means significantly reduce the possibility of
                receiving fake emails.
              </li>
            </ul>

            <h3 dir="auto">Safe use of the credit card</h3>
            <p dir="auto">
              The credit card is personal and should not be disclosed to another
              person, including family and friends.
            </p>
            <ul>
              <li dir="auto">
                You must sign the back of your credit card as soon as it is
                received near you.
              </li>
              <li dir="auto">
                You must ensure that your first name and last name in your
                passport / school and on your credit card are completely
                identical.
              </li>
              <li dir="auto">
                You must keep your credit card in your wallet / close to your
                body.
              </li>
              <li dir="auto">
                Never keep your secret code next door or with your card.
              </li>
              <li dir="auto">
                Do not give the card number to entities that are not confident
                in their identity.
              </li>
              <li dir="auto">
                When making a transaction, you should endeavor to maintain
                constant eye contact with the credit card.
              </li>
              <li dir="auto">
                You must notify customer service of any changes to a new address
                or telephone number so that a new card / detail pages reach your
                new address.
              </li>
              <li dir="auto">
                After receiving a replacement card, the old credit card must be
                destroyed in a way that is not recognizable.
              </li>
            </ul>

            <h3 dir="auto">contact our cybersecurity team</h3>
            <p dir="auto">
              If any suspicion arises ( strange phenomena, unusual reactions )
              regarding each stage of the joining or browsing process please
              contact us as soon as possible to resolve and identify the issue.
              Please explain the issue in detail. We will reach out as soon as
              possible and continue the process from there.
            </p>
            <ContactSection
              id="sectionContact"
              className="section-contact section-security-contact"
            />
          </div>
        </main>
      ) : (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">מדיניות אבטחת מידע</h1>
          <div className="document-contents">
            <p dir="auto" className="update-date">
              עודכן לאחרונה: 17 בספטמבר, 2023
            </p>
            <h3 dir="auto">סקירה כללית</h3>
            <p dir="auto">
              פגיעויות של יישומי אינטרנט מהוות את החלק הגדול ביותר של וקטורי
              ההתקפה מחוץ לתוכנות זדוניות. זה חיוני שכל יישום אינטרנט ייבדק לגבי
              פגיעויות וכל נקודת תורפה תתוקן לפני פריסת הייצור.
            </p>

            <h3 dir="auto">מטרה</h3>
            <p dir="auto">
              מטרת מדיניות זו היא להגדיר הערכות אבטחה של יישומי אינטרנט בתוך
              MillyLearn. הערכות יישומי אינטרנט מבוצעות כדי לזהות חולשות
              פוטנציאליות או מומשות כתוצאה מתצורה שגויה בשוגג, אימות חלש, טיפול
              לא מספק בשגיאות, דליפת מידע רגיש וכו'. גילוי והפחתה של בעיות אלו
              יגבילו את משטח ההתקפה של שירותי MillyLearn הזמינים הן פנימי
              וחיצוני וכן לספק ציות לכל מדיניות רלוונטית במקום.
            </p>

            <h3 dir="auto">תחום</h3>
            <p dir="auto">
              מדיניות זו מכסה את כל הערכות האבטחה של יישומי אינטרנט המתבקשות על
              ידי כל אדם, קבוצה או מחלקה למטרות שמירה על מצב האבטחה, תאימות,
              ניהול סיכונים ובקרת שינויים בטכנולוגיות הנמצאות בשימוש
              ב-MilliLearn.
            </p>
            <p dir="auto">
              כל הערכות האבטחה של יישומי אינטרנט יבוצעו על ידי אנשי אבטחה
              מוסמכים או מועסקים או בחוזה על ידי MillyLearn. כל הממצאים נחשבים
              חסויים ואמורים להיות מופצים לאנשים על בסיס "צריך לדעת". הפצה של כל
              ממצא מחוץ ל-MilliLearn אסורה בהחלט, אלא אם כן אושר על ידי קצין
              המידע הראשי.
            </p>
            <p dir="auto">
              כל מערכות יחסים בתוך יישומים מרובי-שכבות שנמצאו במהלך שלב ההיקפים
              ייכללו בהערכה אלא אם כן מוגבל במפורש. מגבלות והצדקה לאחר מכן
              יתועדו לפני תחילת ההערכה.
            </p>

            <h3 dir="auto">הערכות אבטחה</h3>
            <ul>
              <li dir="auto">
                <strong>שחרור אפליקציה חדשה או עידכון ראשי</strong> - יועבר
                לבדיקה מלאה לפני האישור של תיעוד בקרת השינויים ו/או השחרור
                לסביבה החיה.
              </li>
              <li dir="auto">
                <strong>אפליקצית אינטרנט של צד שלישי</strong> - תחויב לבדיקה
                מלאה לאחר ממנה תתחייב לדרישות המדיניות.
              </li>
              <li dir="auto">
                <strong>עדכוני נקודה</strong> - תחויב לרמת בדיקה מתאימה בהתבסס
                על הסיכון שבשינויים בפועל ו/או בארכיטקטורה של האפליקציה.
              </li>
              <li dir="auto">
                <strong>עדכוני תיקונים</strong> - תחויב לרמת בדיקה מתאימה בהתבסס
                על הסיכון שבשינויים בפועל ו/או בארכיטקטורה של האפליקציה.
              </li>
              <li dir="auto">
                <strong>שחרור חירום</strong> - שחרור חירום יתרחש ללא בדיקות
                אבטחה וישא בעונש הסיכון הניתן עד שתתבצע בדיקה מתאימה. שחרורים
                בחירום יוקצו כך על ידי מנכ"ל מידע ראשי או מנהל מתאים שניתן לו
                הרשות לכך.
              </li>
              <li dir="auto">
                <strong>בדיקה שנתית</strong> - כל אפליקציה תחויב לבדיקה שנתית
                מלאה במלואה על מנת לבדוק את הסיכונים הפוטנציאליים בפועל ו/או
                בארכיטקטורה.
              </li>
            </ul>

            <h3 dir="auto">רמות סיכון</h3>
            <p dir="auto">
              כל הבעיות בטחון שנגלו במהלך הערכות חייבות להימנע בהתבסס על פי רמות
              הסיכון הבאות. רמות הסיכון מבוססות על מתודולוגיית דירוג הסיכון של
              OWASP. נדרשת בדיקת אימות לתיקון ו/או אסטרטגיות הפחתת הסיכון לבעיות
              שנגלו ברמת סיכון בינונית או גבוהה יותר.
            </p>
            <ul>
              <li dir="auto">
                <strong style={{ color: 'red' }}>קריטי</strong> - בעיות מיוחסות
                כ "קריטי" מהוות איום מיידי וחמור לבטחון האפליקציה והנתונים שלך.
                עליהן להתמודד בחורף ועם הגבורה הגבוהה ביותר. הכישלון לעשות כך
                עשוי לגרום לפריצות משמעתית, אובדן נתונים, או פגיעה במערכת.
                אסטרטגיות הפחתת הסיכון צריכות להיות במידה המיידית, ובדיקות
                סובלניות הן חיוניות לוודא את היעילות שלהן.
              </li>
              <li dir="auto">
                <strong style={{ color: 'orange' }}>גבוה</strong> – כל בעיה
                בסיכון גבוה צריכה להיות מתוקנת באופן מיידי או ליישום אסטרטגיות
                אחרות להגברת הסיכון לפני השחרור. אפליקציות עם בעיות סיכון גבוה
                עשויות להינתק מהרשת או לא לשחרר אותן לסביבת הפריצה.
              </li>
              <li dir="auto">
                <strong style={{ color: 'yellow' }}>בינוני</strong> – בעיות
                בסיכון בינוניות נדרשות בבוחן כדי לקבוע את הצעדים הדרושים להפחתת
                הסיכון ולתכנון לפי כך. אפליקציות עם בעיות סיכון בינוניות עשויות
                להינתק מהרשת או לא לשחרר אותן לסביבת הפריצה בהתבסס על מספר
                הבעיות ואם הבעיות המרובות מגבירות את הסיכון לרמה אי-מכילה.
                הבעיות יש לתקן בעדכון תיקונים או עדכון נקודתי אלא אם אסטרטגיות
                אחרות יכולות להגביר את הסיכון.
              </li>
              <li dir="auto">
                <strong style={{ color: 'blue' }}>נמוך</strong> – יש לבחון את
                הבעיה כדי לקבוע מה דרוש לתיקון ולתכנון לפי כך.
              </li>
              <li dir="auto">
                <strong style={{ color: 'green' }}>מידע</strong> - כל מידע שמספק
                מידע שימושי על שדרוגי בטחון אפשריים אך אינם מהווים איום מיידי.
                הממצאים הללו מספקים תובנות לאפשרויות בהן ניתן לשדרג, אך אין צורך
                בפעולה מיידית. כדאי לשקול לטפח בעיות מידע בעת העדכונים הרגילים
                בטחונה ובשדרוגים כדי לשפר את יכולת האפליקציה לעמוד בטחון כללי.
              </li>
            </ul>

            <h3 dir="auto">רמות בדיקת אבטחה</h3>
            <p dir="auto">
              הארגון לאבטחת מידע או ארגון מוגדר אחר שיבצע את הבדיקות, יקבע את
              הרמות שונות לבדיקת האבטחה הבאות:
            </p>
            <ul>
              <li dir="auto">
                <strong>מלא</strong> - בדיקת מלאה מתוקנת ממבחנים לכל השוניות
                ברשימת הפגיעויות הידועות באפליקציות אינטרנט באמצעות כלי
                אוטומטיים וידניים בהתבסס על מדריך הבדיקה של OWASP. בדיקת מלאה
                תשתמש בטכניקות בדיקת חדירה ידניות לאמת פגיעויות שנגלו כדי לקבוע
                את הסיכון הכללי של כל פגיעות שנגלו.
              </li>
              <li dir="auto">
                <strong>מהיר</strong> – בדיקה מהירה תכיל בדיקה אוטומטית (בדרך
                כלל) של אפליקציה לפגיעויות העשויות להופיע ברשימת עשר הפגיעויות
                העיקריות של OWASP, לפחות.
              </li>
              <li dir="auto">
                <strong>ממוקד</strong> – בדיקת ממוקד נעשית לאימות שינויים
                בתיקוני פגיעויות או בפונקציונליות חדשה באפליקציה.
              </li>
            </ul>

            <h3 dir="auto">התאמה למדיניות</h3>
            <p dir="auto">
              הארגון לאבטחת מידע או ארגון מוגדר אחר שיבצע את הבדיקות, יקבע את
              הרמות שונות לבדיקת האבטחה הבאות:
            </p>
            <ul>
              <li dir="auto">
                צוות אבטחת המידע יוודא את ההתאמה למדיניות זו באמצעות מגוון
                אמצעים, כולל דיווחים מכלי עסקיים, בדיקות פנימיות וחיצוניות,
                ומשוב לבעלי המדיניות.
              </li>
              <li dir="auto">
                כל פרטי המענה על המדיניות חייבים לקבל אישור מראש מצוות אבטחת
                המידע.
              </li>
              <li dir="auto">
                פרט אישי שנמצא בפרקטיקה שפרץ במדיניות זו עשוי להיענש בפועל, כולל
                השעייה מחשבונך. בדיקות אפליקציות אינטרנט הן חובה במסגרת תהליך
                בקרת השינויים וחייבות להיתאר למדיניות זו אלא אם נמצא שהן פטורות.
                כל השחרורים של אפליקציות אינטרנט חייבים לעבור בתהליך בקרת
                השינויים. כל אפליקציות האינטרנט שאינן נעות למדיניות זו עשויות
                להינתק מהרשת עד שיופעלו בדיקות רשמיות על פי שיקולו של מנכ"ל מידע
                הראשי.
              </li>
            </ul>

            <h3 dir="auto">תקנים, מדיניות ותהליכים קשורים</h3>
            <p dir="auto">
              <a href="https://owasp.org/www-project-top-ten/">
                פרויקט OWASP Top Ten
              </a>
            </p>
            <p dir="auto">
              <a href="https://owasp.org/www-project-web-security-testing-guide/">
                מדריך בדיקת אבטחת אתרי האינטרנט של OWASP
              </a>
            </p>
            <p dir="auto">
              <a href="https://owasp.org/www-community/OWASP_Risk_Rating_Methodology">
                מתודולוגיית דירוג הסיכון של OWASP
              </a>
            </p>

            <h3 dir="auto">הגדרות ומונחים</h3>
            <p dir="auto">אין.</p>

            <h3 dir="auto">אימות</h3>
            <p dir="auto">
              ניתן להתחבר למערכת באמצעות כתובת הדואר האלקטרוני האישית והסיסמה.
            </p>
            <p dir="auto">
              אנחנו נחסום את חשבונך לאחר חמישה נסיונות כניסה שגויים למערכת, כדי
              למנוע פריצה לחשבונך.
            </p>
            <p dir="auto">
              מספר רב של רכיבי אבטחה ורמת סינון ברמת התקשורת מאפשרים שליטה,
              זיהוי וסינון באתר.
            </p>
            <p dir="auto">
              אנחנו משתמשים בטכנולוגיות וטכניקות מתקדמות בעריכת דוחות וניטור
              (logging and monitoring).
            </p>

            <h3 dir="auto">כללי גלישה בטוחה / פרטיות בדוא"ל</h3>
            <p dir="auto">
              הצלחת הפעילות שלך והסודיות של הנתונים שלך תלויים בצעדים בסיסיים
              מצד המשתמש. כאשר אתה מתחיל באתר כלשהו, וודא שאתה עוקב אחר הכללים
              הבאים:
            </p>
            <ul>
              <li dir="auto">
                האתר מזוהה בצורה תקינה עם אישור דיגיטלי של הדפדפן (TLS), ותוכן
                המידע שנשלח בין האתר והדפדפן מוצפן. ניתן לוודא זאת על ידי תמונת
                המנעול הנסגר ליד כתובת ה-URL (
                <i className="fa-solid fa-lock"></i>).
              </li>
              <li dir="auto">
                בעת בחירת סיסמה, עליך להגדיר סיסמה איכותית ברמה גבוהה. יש להימנע
                מסיסמאות פשוטות כמו תאריכי הלידה, שמות שותפיך וכדומה.
              </li>
              <li dir="auto">
                סיסמת הכניסה למערכת היא אישית וסודית ולא נרשמת לאף אחד. בשום
                תנאי לא תידרש לגלות את סיסמתך האישית בטלפון או לשלוח אותה בדוא"ל
                לצד אחר. אם התבצעה בקשה כזו - עליך לסרב.
              </li>
              <li dir="auto">
                כאשר סיימת את השימוש באתר, וודא שניתקת וסגרת את הדפדפן.
              </li>
              <li dir="auto">
                עליך להימנע מללחוץ על קישורים בדואר אלקטרוני חשוד ולא לענות
                לדואר אלקטרוני חשוד. קישורים אלה גם כן יכולים להיות אתרים מתחזים
                שרוצים לגנוב את המידע שלך.
              </li>
              <li dir="auto">
                בשום מצב לא תישלח מידע של כרטיס אשראי בדוא"ל. הדואר האלקטרוני
                אינו מאובטח מספיק וניתן לחשוף את המידע על ידי גורמים שאינם
                מורשים לכך.
              </li>
              <li dir="auto">
                עליך להתקין אמצעי אבטחה כמו תוכנות אנטי-וירוס, חומת אש ותוכנות
                נגיף דואר זבל (אנטי-ספאם) על המחשב שלך. זה אומר ירידה משמעותית
                באפשרותך לקבל דואר זבל מזוי.
              </li>
            </ul>

            <h3 dir="auto">שימוש בכרטיס אשראי בבטחה</h3>
            <p dir="auto">
              הכרטיס אשראי הוא אישי ואסור לחשוף אותו לפרט אחר, כולל משפחה
              וחברים.
            </p>
            <ul>
              <li dir="auto">
                עליך לחתום בגב הכרטיס האשראי שאתה מקבל כשהוא בקרבת ידך.
              </li>
              <li dir="auto">
                עליך לוודא ששם הפרטי ושם המשפחה בדרכון שלך / בתעודת בית הספר ועל
                הכרטיס האשראי שלך זהים לחלוטין.
              </li>
              <li dir="auto">
                עליך לשמור על הכרטיס האשראי בארנק שלך / בקרבת גופך.
              </li>
              <li dir="auto">
                לעולם לא לשמור את הקוד הסודי ליד הכרטיס או עם הכרטיס שלך.
              </li>
              <li dir="auto">
                לא לספק את מספר הכרטיס לגופים שאינם בטוחים בזהותם.
              </li>
              <li dir="auto">
                בעת ביצוע עסקה, עליך לשמור על קשר חזותי עם הכרטיס האשראי.
              </li>
              <li dir="auto">
                עליך להודיע לשירות הלקוחות על כל שינוי בכתובת או מספר טלפון
                חדשים כך שכרטיס אשראי חדש / דפים מפרטים יגיעו לכתובת החדשה שלך.
              </li>
              <li dir="auto">
                לאחר קבלת כרטיס אשראי חדש, עליך להשמיד את הכרטיס הישן בדרך שאינה
                ניתן לזהותו.
              </li>
            </ul>

            <h3 dir="auto">צור קשר עם צוות הסייבר ואבטחת המידע שלנו</h3>
            <p dir="auto">
              אם עולה חשש (תופעות מוזרות, תגובות לא רגילות) בכל שלב מתוך שלבי
              ההצטרפות או תהליך הגלישה, אנא צור קשר איתנו מהר ככל האפשר כדי
              לפתור ולזהות את הבעיה. אנא הסבר את הבעיה לעומק. אנחנו ניצור עמך
              קשר בהקדם ונמשיך משם בתהליך.
            </p>

            <ContactSection
              isModalClosed={isModalClosed}
              setIsModalClosed={setIsModalClosed}
              setModalType={setModalType}
              setModalTitle={setModalTitle}
              setModalDescription={setModalDescription}
              setModalInformation={setModalInformation}
              id="sectionContact"
              className="section-contact section-security-contact"
            />
          </div>
        </main>
      )}
      {loggedUser ? (
        <LoggedFooter
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      ) : (
        <Footer
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      )}
    </React.Fragment>
  );
}

export default SecurityPolicy;
