import React, { useEffect, useState, useContext } from 'react';
import {
  getAgreementCookie,
  setAgreementCookie,
  disableCookiesUsage,
} from './cookieUtils';
import './cookieAgreement.css';
import { useGlobalContext } from '../context/AppContext';
function CookieAgreement() {
  const agreementCookie = getAgreementCookie();

  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../languages/en.json')
      : require('../languages/he.json');

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!agreementCookie || agreementCookie === 'false') {
      disableCookiesUsage(true);
      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    } else {
      setIsVisible(false);
    }
  }, [agreementCookie]);

  const cookieAccept = () => {
    setAgreementCookie(true);
    setIsVisible(false);
  };

  const cookieReject = () => {
    setIsVisible(false);
  };

  return (
    <div
      id="cookieAgreement"
      className={`fade-in-top cookie-agreement cookie-agreement-show ${
        isVisible ? 'visible-cookies' : ''
      }`}
    >
      <h1 dir="auto" className="section-title">
        {languageFile.cookieAgreementTitle}
      </h1>
      <p dir="auto">{languageFile.cookieAgreementAbout}</p>
      <button
        dir="auto"
        className="btn btn-block cookies-buttons-allow"
        onClick={cookieAccept}
      >
        {languageFile.cookieAgreementAccept}
      </button>
      <button
        dir="auto"
        className="btn btn-block cookies-buttons-reject"
        onClick={cookieReject}
      >
        {languageFile.cookieAgreementReject}
      </button>
      <div dir="auto" className="policy-buttons">
        <a
          target="_blank"
          dir="auto"
          className="btn privacy-policy-button"
          href="/privacy-policy"
        >
          {languageFile.cookieAgreementPolicyPrivacy}
        </a>
        <a
          target="_blank"
          dir="auto"
          className="btn cookies-policy-button"
          href="/cookies-policy"
        >
          {languageFile.cookieAgreementPolicyCookies}
        </a>
      </div>
    </div>
  );
}

export default CookieAgreement;
