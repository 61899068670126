import React, { useEffect, useState, useContext } from 'react';
import './productsSection.css';
import { useGlobalContext } from '../../../context/AppContext';

function ProductsSection({ id, className }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const tag = document.querySelector('.products-title');
    if (tag) {
      const rect = tag.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Products' : 'מסלולים'
    }`; // Set the title to "Milly | Products"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const productsClicked = () => {
    const targetElement = document.getElementById('sectionProducts');
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <section
      id={id}
      className={`section fade-in-right ${className ? className : ''} ${
        isVisible ? 'visible' : ''
      }`}
    >
      <p dir="auto" className="section-title products-title">
        <a onClick={productsClicked}>{languageFile.productsTitle}</a>
      </p>
      <div className="title-underline"></div>
      <h1 className="section-sub-title products-sub-title">
        {languageFile.productsSubTitle1}{' '}
        <span className="highlight-text">{languageFile.productsSubTitle2}</span>
      </h1>
      <p dir="auto" className="section-info products-info">
        {languageFile.productsAbout1}{' '}
        <span className="highlight-text">{languageFile.productsAbout2}</span>{' '}
        {languageFile.productsAbout3}.
      </p>
      <div className="section-content products-content">
        <div className="product-card psychometry-card">
          <div className="product-card-banner">
            <h2>{languageFile.productsPsychTitle}</h2>
            <h1 className="free-product">{languageFile.productsPsychPrice}</h1>
            <p dir="auto">{languageFile.productsPsychSubTitle}</p>
          </div>
          <div className="product-card-description">
            <p dir="auto">{languageFile.productsPsychBenefitsTitle}</p>
            <ul>
              <li>
                <p dir="auto">{languageFile.productsPsychBenefitsAbout1}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPsychBenefitsAbout2}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPsychBenefitsAbout3}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPsychBenefitsAbout4}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
          <div className="product-card-enrollment">
            <a className="btn a-register" href="/register">
              {languageFile.productsPsychEnroll}
            </a>
            <a
              dir="auto"
              className="btn a-login a-login-psychometry"
              href="/login"
            >
              {languageFile.productsPsychLogin}?
            </a>
          </div>
        </div>
        <div className="product-card model-e-card gray-out">
          <div className="product-card-banner">
            <h2 dir="auto">{languageFile.productsBagrutTitle}</h2>
            <h1 className="free-product">{languageFile.productsPsychPrice}</h1>
            <p dir="auto">{languageFile.productsBagrutSubTitle}</p>
          </div>
          <div className="product-card-description">
            <p dir="auto">{languageFile.productsPsychBenefitsTitle}</p>
            <ul>
              <li>
                <p dir="auto">{languageFile.productsBagrutBenefitsAbout1}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsBagrutBenefitsAbout2}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsBagrutBenefitsAbout3}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsBagrutBenefitsAbout4}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
          <div className="product-card-enrollment">
            <a className="btn a-register" href="/register">
              {languageFile.productsPsychEnroll}
            </a>
            <a dir="auto" className="btn a-login" href="/login">
              {languageFile.productsPsychLogin}?
            </a>
          </div>
        </div>
        <div className="product-card custom-card gray-out">
          <div className="product-card-banner">
            <h2>{languageFile.productsPersonalTitle}</h2>
            <h1 className="free-product">{languageFile.productsPsychPrice}</h1>
            <p dir="auto">{languageFile.productsPersonalSubTitle}</p>
          </div>
          <div className="product-card-description">
            <p dir="auto">{languageFile.productsPsychBenefitsTitle}</p>
            <ul>
              <li>
                <p dir="auto">{languageFile.productsPersonalBenefitsAbout1}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPersonalBenefitsAbout2}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPersonalBenefitsAbout3}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
              <li>
                <p dir="auto">{languageFile.productsPersonalBenefitsAbout4}</p>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
          <div className="product-card-enrollment">
            <a className="btn a-register" href="/register">
              {languageFile.productsPsychEnroll}
            </a>
            <a dir="auto" className="btn a-login" href="/login">
              {languageFile.productsPsychLogin}?
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductsSection;
