import React, { useEffect, useContext, useState } from 'react';
import './ProgressBarStudy.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  getCurrectQuestionCookie,
  setCurrectQuestionCookie,
  getStudyModeCookie,
  getStudyUnitCookie,
  setQuestionIdCookie,
  getQuestionIdCookie,
  getQuestionsStartCookie,
  getQuestionsEndCookie,
  setQuestionsStartCookie,
  setQuestionsEndCookie,
  getMemorizationClassificationCookie,
} from '../../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';
import UnitOptionsModal from '../../../Environment/UnitOptionsModal/UnitOptionsModal';

function ProgressBarStudy({
  setLastUpdatedWordInDB,
  isPrevBtnStudyClicked,
  isNextBtnStudyClicked,
  setIsNextBtnStudyClicked,
  setIsPrevBtnStudyClicked,
  setIsValidNextBtnStudy,
  setIsValidPrevBtnStudy,
  firstWordId,
  SetFirstWordId,
  lastWordId,
  SetLastWordId,
  setShowFront,
  isFetching,
  setIsFetching,
  setCurrentQuestionClassification,
  currentQuestionId,
  setCurrentQuestionId,
  setShowAnimation,
  setStudyWordsCounter,
  setStudyCompletedWordsCounter,
  setCurrentQuestionTranslate,
  finishedQuestions,
  openProgressBar,
  setOpenProgressBar,
  currentQuestionID,
  setCurrentQuestionID,
  setCurrentQuestionTitle,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  // Array of all questions.
  const [questionsBody, setQuestionsBody] = useState([]);
  const [currentQuestionsBody, setCurrentQuestionsBody] = useState([]);

  const [isTopBatch, setIsTopBatch] = useState(false);
  const [isBottomBatch, setIsBottomBatch] = useState(false);

  // Ref to current unit.
  const currentUnit = getStudyUnitCookie();
  const [startQuestionId, setStartQuestionId] = useState(0);
  const [endQuestionId, setEndQuestionId] = useState(0);

  useEffect(() => {
    if (isPrevBtnStudyClicked) {
      handlePrevArrowBtnClicked();
      setIsPrevBtnStudyClicked(false);
    }
  }, [isPrevBtnStudyClicked]);

  useEffect(() => {
    if (isNextBtnStudyClicked) {
      handleNextArrowBtnClicked();
      setIsNextBtnStudyClicked(false);
    }
  }, [isNextBtnStudyClicked]);

  const handlePrevArrowBtnClicked = async () => {
    setShowFront(true);
    document.getElementById('pContinue').classList.remove('visible');
    if (parseInt(currentQuestionId) > parseInt(firstWordId)) {
      const clickedID = parseInt(currentQuestionId) - 1;
      const lowRange = parseInt(clickedID / 10) * 10 + 1;
      const highRange = (parseInt(clickedID / 10) + 1) * 10;
      // Check if the next question already fetched
      if (
        clickedID >= lowRange &&
        clickedID <= highRange &&
        clickedID >= startQuestionId
      ) {
        let prevQuestion = document.querySelector('.current-study-question');
        if (prevQuestion) {
          prevQuestion.classList.remove('current-study-question');
        }
        const clickedElement = document.getElementById(`q-${clickedID}`);
        if (clickedElement && clickedElement.firstChild) {
          clickedElement.firstChild.classList.add('current-study-question');
        } else {
          return;
        }
        setCurrentQuestionID(`q-${clickedID}`);
        setQuestionIdCookie(`q-${clickedID}`);

        let currentQuestion = findInArray(parseInt(clickedID));
        setTimeout(() => {
          if (currentQuestion) {
            setCurrentQuestionTitle(currentQuestion.question);
            setCurrentQuestionTranslate(currentQuestion.answer);
            setCurrentQuestionClassification(currentQuestion.classification);
          }
        }, 100);
        setCurrentQuestionId(clickedID);
        localStorage.setItem('currentStudyQuestionId', clickedID);
      } else {
        await handlePrevBtn();
      }

      if (clickedID != lastWordId) {
        setIsValidNextBtnStudy(true);
      } else {
        setIsValidNextBtnStudy(false);
      }

      if (clickedID != firstWordId) {
        setIsValidPrevBtnStudy(true);
      } else {
        setIsValidPrevBtnStudy(false);
      }
    }
  };

  const handleNextArrowBtnClicked = async () => {
    setShowFront(true);
    document.getElementById('pContinue').classList.remove('visible');
    if (parseInt(currentQuestionId) < parseInt(lastWordId)) {
      const clickedID = parseInt(currentQuestionId) + 1;
      const lowRange = parseInt((clickedID - 2) / 10) * 10 + 1;
      const highRange = (parseInt((clickedID - 2) / 10) + 1) * 10;

      // Check if the next question already fetched
      if (
        clickedID >= lowRange &&
        clickedID <= highRange &&
        clickedID <= endQuestionId
      ) {
        let prevQuestion = document.querySelector('.current-study-question');
        if (prevQuestion) {
          prevQuestion.classList.remove('current-study-question');
        }
        const clickedElement = document.getElementById(`q-${clickedID}`);
        if (clickedElement && clickedElement.firstChild) {
          clickedElement.firstChild.classList.add('current-study-question');
        } else {
          return;
        }
        setCurrentQuestionID(`q-${clickedID}`);
        setQuestionIdCookie(`q-${clickedID}`);

        let currentQuestion = findInArray(parseInt(clickedID));
        setTimeout(() => {
          if (currentQuestion) {
            setCurrentQuestionTitle(currentQuestion.question);
            setCurrentQuestionTranslate(currentQuestion.answer);
            setCurrentQuestionClassification(currentQuestion.classification);
          }
        }, 100);
        setCurrentQuestionId(clickedID);
        localStorage.setItem('currentStudyQuestionId', clickedID);
      } else {
        await handleNextBtn();
      }

      if (clickedID != lastWordId) {
        setIsValidNextBtnStudy(true);
      } else {
        setIsValidNextBtnStudy(false);
      }

      if (clickedID != firstWordId) {
        setIsValidPrevBtnStudy(true);
      } else {
        setIsValidPrevBtnStudy(false);
      }
    }
  };

  // todo: if fetch occures, try fetch again later
  const fetchData = async () => {
    try {
      setIsFetching(true);
      const unitId = parseInt(currentUnit.substring(5), 10);

      const response = await fetch(`/word/psychometry/study/units/${unitId}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      const responseData = await response.json();
      if (responseData.success === false) {
        if (selectedLanguage === 'en') {
          // alert(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          // alert(responseMsg);
        }
      } else {
        const responseDataPlain = responseData.data.data;
        setQuestionsBody(responseDataPlain);
        setCurrentQuestionsBody(responseDataPlain);
        setStartQuestionId(responseDataPlain[0].question_id);
        setCurrentQuestionTitle(responseDataPlain[0].question);
        setCurrentQuestionTranslate(responseDataPlain[0].answer);
        setCurrentQuestionClassification(responseDataPlain[0].classification);
        setEndQuestionId(
          responseDataPlain[responseDataPlain.length - 1].question_id
        );

        // Todo: change the current:
        //setCurrentQuestionId(responseData.data.current);
        setCurrentQuestionId(responseDataPlain[0].question_id);
        localStorage.setItem(
          'currentStudyQuestionId',
          responseDataPlain[0].question_id
        );
        setLastUpdatedWordInDB(responseDataPlain[0].question_id);
        setQuestionIdCookie(`q-${responseDataPlain[0].question_id}`);
        setCurrentQuestionID(`q-${responseDataPlain[0].question_id}`);
        SetFirstWordId(responseData.data.start);
        SetLastWordId(responseData.data.end);
        setStudyWordsCounter(
          responseData.data.end - responseData.data.start + 1
        );
        setStudyCompletedWordsCounter(responseData.data.num_of_classified);
        setIsValidNextBtnStudy(
          responseData.data.end != responseDataPlain[0].question_id
        );
        setIsValidPrevBtnStudy(
          responseData.data.start != responseDataPlain[0].question_id
        );
        //todo: dont use fixed size that ends with 10 (like 200), unit size may be 234 and currentId 229.
        // todo: check if currentId >= last/10*10 and <= than last. only then -> bottomBatch is true
        if (
          parseInt(responseDataPlain[0].question_id) - 10 <
          parseInt(responseData.data.start)
        ) {
          setIsTopBatch(true);
        }

        if (
          parseInt(responseDataPlain[0].question_id) + 10 >
          parseInt(responseData.data.end)
        ) {
          setIsBottomBatch(true);
        }

        setShowAnimation(false);
      }
    } catch (error) {
      console.error(error);
      if (selectedLanguage === 'en') {
        // alert("Submission Has Failed");
        console.log('Submission Has Failed');
      } else {
        // alert("הבקשה נכשלה");
        console.log('הבקשה נכשלה');
      }
    } finally {
      setIsFetching(false);
    }
  };

  const fetchPrevWords = async () => {
    if (!isFetching) {
      try {
        setIsFetching(true);
        const unitId = parseInt(currentUnit.substring(5), 10);
        const formData = new FormData();
        formData.append('word_id', parseInt(currentQuestionId) - 11);

        const response = await fetch(
          `/word/psychometry/study/next-words/${unitId}`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await response.json();
        if (responseData.success === false) {
          if (selectedLanguage === 'en') {
            alert(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            alert(responseMsg);
          }
        } else {
          const responseDataPlain = responseData.data.data;
          // setQuestionsBody([...questionsBody, responseDataPlain]);
          setQuestionsBody((prevQuestionsBody) => [
            ...responseDataPlain,
            ...prevQuestionsBody,
          ]);
          setCurrentQuestionsBody(responseDataPlain);
          const lastIndex = responseDataPlain.length - 1;
          setCurrentQuestionId(responseDataPlain[lastIndex].question_id);
          if (responseDataPlain[lastIndex].question_id != lastWordId) {
            setIsValidNextBtnStudy(true);
          }
          localStorage.setItem(
            'currentStudyQuestionId',
            responseDataPlain[lastIndex].question_id
          );
          setCurrentQuestionID(`q-${responseDataPlain[lastIndex].question_id}`);
          setCurrentQuestionTitle(responseDataPlain[lastIndex].question);
          setCurrentQuestionClassification(
            responseDataPlain[lastIndex].classification
          );
          setCurrentQuestionTranslate(responseDataPlain[lastIndex].answer);
          setStartQuestionId(responseDataPlain[0].question_id);
        }
      } catch (error) {
        if (selectedLanguage === 'en') {
          alert('Submission Has Failed');
        } else {
          alert('הבקשה נכשלה');
        }
      } finally {
        setIsFetching(false);
      }
    }
  };

  const fetchNextWords = async () => {
    if (!isFetching) {
      try {
        setIsFetching(true);
        const unitId = parseInt(currentUnit.substring(5), 10);
        const formData = new FormData();
        formData.append('word_id', parseInt(currentQuestionId) + 9);

        const response = await fetch(
          `/word/psychometry/study/next-words/${unitId}`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await response.json();
        if (responseData.success === false) {
          if (selectedLanguage === 'en') {
            alert(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            alert(responseMsg);
          }
        } else {
          const responseDataPlain = responseData.data.data;

          // setQuestionsBody([...questionsBody, responseDataPlain]);
          setQuestionsBody((prevQuestionsBody) => [
            ...prevQuestionsBody,
            ...responseDataPlain,
          ]);
          setCurrentQuestionsBody(responseDataPlain);
          setCurrentQuestionId(responseDataPlain[0].question_id);
          if (responseDataPlain[0].question_id != firstWordId) {
            setIsValidPrevBtnStudy(true);
          }
          localStorage.setItem(
            'currentStudyQuestionId',
            responseDataPlain[0].question_id
          );
          setCurrentQuestionID(`q-${responseDataPlain[0].question_id}`);
          setCurrentQuestionTitle(responseDataPlain[0].question);
          setCurrentQuestionClassification(responseDataPlain[0].classification);
          setCurrentQuestionTranslate(responseDataPlain[0].answer);
          setEndQuestionId(
            responseDataPlain[responseDataPlain.length - 1].question_id
          );
        }
      } catch (error) {
        if (selectedLanguage === 'en') {
          alert('Submission Has Failed');
        } else {
          alert('הבקשה נכשלה');
        }
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    setShowAnimation(true);
    fetchData();
  }, []);

  const openQuestion = async (event) => {
    setShowFront(true);
    document.getElementById('pContinue').classList.remove('visible');
    const clickedElement = event.target;
    const clickedID = parseInt(
      clickedElement.parentNode.id.replace('q-', ''),
      10
    );
    let prevQuestion = document.querySelector('.current-study-question');
    if (prevQuestion) {
      prevQuestion.classList.remove('current-study-question');
    }
    clickedElement.classList.add('current-study-question');
    setCurrentQuestionID(`q-${clickedID}`);
    setQuestionIdCookie(`q-${clickedID}`);

    let currentQuestion = findInArray(parseInt(clickedID));
    setTimeout(() => {
      if (currentQuestion) {
        setCurrentQuestionTitle(currentQuestion.question);
        setCurrentQuestionTranslate(currentQuestion.answer);
        setCurrentQuestionClassification(currentQuestion.classification);
      }
    }, 100);
    setCurrentQuestionId(clickedID);
    localStorage.setItem('currentStudyQuestionId', clickedID);
    if (clickedID == lastWordId) {
      setIsValidNextBtnStudy(false);
    } else {
      setIsValidNextBtnStudy(true);
    }
    if (clickedID == firstWordId) {
      setIsValidPrevBtnStudy(false);
    } else {
      setIsValidPrevBtnStudy(true);
    }
  };

  const handlePrevBtn = async () => {
    if (isFetching || isTopBatch) {
      // Fetch is already in progress, do nothing
      return;
    }
    setShowFront(true);
    document.getElementById('pContinue').classList.remove('visible');

    // todo: load prev 10 to current array. TAKE CARE OF FIRST!
    setIsBottomBatch(false);
    let newQuestionId;
    if (!(parseInt(currentQuestionId) % 10 === 0)) {
      newQuestionId = parseInt(currentQuestionId) - 10;
    } else {
      newQuestionId = parseInt(currentQuestionId) - 11;
    }

    if (newQuestionId >= firstWordId) {
      if (newQuestionId < firstWordId + 10) {
        // Disable the up button.
        setIsTopBatch(true);
      }

      if (newQuestionId >= startQuestionId && newQuestionId <= endQuestionId) {
        // The batch is already fetched.
        const startingQuestionId = parseInt(newQuestionId / 10) * 10;

        const mappedWords = questionsBody.filter((word) => {
          return (
            word.question_id > startingQuestionId &&
            word.question_id <= startingQuestionId + 10
          );
        });

        setCurrentQuestionsBody(mappedWords);
        const lastIndex = mappedWords.length - 1;
        if (mappedWords[lastIndex].question_id != lastWordId) {
          setIsValidNextBtnStudy(true);
        }
        setCurrentQuestionId(mappedWords[lastIndex].question_id);

        localStorage.setItem(
          'currentStudyQuestionId',
          mappedWords[lastIndex].question_id
        );

        setCurrentQuestionID(`q-${mappedWords[lastIndex].question_id}`);

        // Flipping card animation.
        setTimeout(() => {
          setCurrentQuestionTitle(mappedWords[lastIndex].question);
          setCurrentQuestionTranslate(mappedWords[lastIndex].answer);
          setCurrentQuestionClassification(
            mappedWords[lastIndex].classification
          );
        }, 100);
      } else {
        // Need to fetch.
        if (isFetching) {
          // Fetch is already in progress, do nothing
          return;
        }
        try {
          setShowAnimation(true);
          setIsFetching(true);
          await fetchPrevWords();
        } catch (error) {
          console.error('Fetch Error:', error);
        } finally {
          setIsFetching(false);
          setShowAnimation(false);
        }
      }
    }

    if (currentQuestionId != lastWordId) {
      setIsValidNextBtnStudy(true);
    }

    if (currentQuestionId != firstWordId) {
      setIsValidPrevBtnStudy(true);
    } else {
      setIsValidPrevBtnStudy(false);
    }
  };

  const handleNextBtn = async () => {
    if (isFetching || isBottomBatch) {
      // Fetch is already in progress, do nothing
      return;
    }
    setShowFront(true);
    document.getElementById('pContinue').classList.remove('visible');
    // todo: load next 10 to current array. TAKE CARE OF LAST
    setIsTopBatch(false);
    let newQuestionId;
    if (parseInt(currentQuestionId) % 2 == 0) {
      newQuestionId = parseInt(currentQuestionId) + 9;
    } else {
      newQuestionId = parseInt(currentQuestionId) + 10;
    }
    // todo: check if this fixed last batch bug.
    let lastBatchLowest = parseInt((lastWordId - 1) / 10) * 10 + 1;
    if (newQuestionId < lastWordId) {
      if (newQuestionId >= lastBatchLowest) {
        setIsBottomBatch(true);
      }
      if (newQuestionId >= startQuestionId && newQuestionId < endQuestionId) {
        // The batch is already fetched.
        const startingQuestionId = parseInt(newQuestionId / 10) * 10;

        const mappedWords = questionsBody.filter((word) => {
          return (
            word.question_id > startingQuestionId &&
            word.question_id <= startingQuestionId + 10
          );
        });

        setCurrentQuestionsBody(mappedWords);
        setCurrentQuestionId(mappedWords[0].question_id);
        localStorage.setItem(
          'currentStudyQuestionId',
          mappedWords[0].question_id
        );
        setCurrentQuestionID(`q-${mappedWords[0].question_id}`);

        if (mappedWords[0].question_id != firstWordId) {
          setIsValidPrevBtnStudy(true);
        }

        // Flipping card animation.
        setTimeout(() => {
          setCurrentQuestionTitle(mappedWords[0].question);
          setCurrentQuestionTranslate(mappedWords[0].answer);
          setCurrentQuestionClassification(mappedWords[0].classification);
        }, 100);
      } else {
        // Need to fetch.
        if (isFetching) {
          // Fetch is already in progress, do nothing
          return;
        }
        try {
          setIsFetching(true);
          setShowAnimation(true);
          await fetchNextWords();
        } catch (error) {
          console.error('Fetch Error:', error);
        } finally {
          setIsFetching(false);
          setShowAnimation(false);
        }
      }
    } else {
      setIsBottomBatch(true);
      //todo: optimize all those "if" statements
      if (lastWordId >= startQuestionId && lastWordId <= endQuestionId) {
        // The batch is already fetched.
        const startingQuestionId = parseInt(lastWordId / 10) * 10;

        const mappedWords = questionsBody.filter((word) => {
          return (
            word.question_id > startingQuestionId &&
            word.question_id <= lastWordId
          );
        });

        setCurrentQuestionsBody(mappedWords);
        setCurrentQuestionId(mappedWords[0].question_id);
        localStorage.setItem(
          'currentStudyQuestionId',
          mappedWords[0].question_id
        );
        setCurrentQuestionID(`q-${mappedWords[0].question_id}`);
        // setCurrentQuestionTitle(mappedWords[0].question);
        // setCurrentQuestionTranslate(mappedWords[0].answer);
        // setCurrentQuestionClassification(mappedWords[0].classification);

        // Flipping card animation.
        setTimeout(() => {
          setCurrentQuestionTitle(mappedWords[0].question);
          setCurrentQuestionTranslate(mappedWords[0].answer);
          setCurrentQuestionClassification(mappedWords[0].classification);
        }, 100);
      } else {
        // Need to fetch.
        if (isFetching) {
          // Fetch is already in progress, do nothing
          return;
        }
        try {
          setIsFetching(true);
          setShowAnimation(true);
          await fetchNextWords();
        } catch (error) {
          console.error('Fetch Error:', error);
        } finally {
          setIsFetching(false);
          setShowAnimation(false);
        }
      }
    }

    if (currentQuestionId != lastWordId) {
      setIsValidNextBtnStudy(true);
    } else {
      setIsValidNextBtnStudy(false);
    }

    if (currentQuestionId != firstWordId) {
      setIsValidPrevBtnStudy(true);
    }
  };

  // todo: when exited the study - update the number of words done - or the percentage.
  const handleDoneBtn = async () => {
    const unitID = getStudyUnitCookie();

    const response = await fetch(`/word/psychometry/done/${unitID}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Error sending data to the server');
    }

    // You can handle the server response here if needed
    const responseData = await response.json();

    window.location = '/environment';
  };

  const findInArray = (questionID) => {
    return currentQuestionsBody.find((json) => json.question_id === questionID);
  };

  return (
    <aside className={`progress-bar-study ${openProgressBar ? 'visible' : ''}`}>
      <ul id="progressListUl">
        <li className="arrow-up-btn">
          <i
            onClick={handlePrevBtn}
            className={`fa-solid fa-arrow-up ${
              isFetching ? 'not-allowed-class' : 'allowed-class'
            } ${isTopBatch ? 'study-prev-not-button' : 'study-prev-button'}`}
          ></i>
        </li>
        {currentQuestionsBody.map((question) => {
          const isCurrentQuestion = question.question_id == currentQuestionId;

          // Find the matching object in finishedQuestions based on question_id
          const matchingFinishedQuestion = finishedQuestions.find(
            (item) => item.id == question.question_id
          );

          // Get the classification from the matching object, or use the question's classification
          const classification = matchingFinishedQuestion
            ? matchingFinishedQuestion.classification
            : question.classification;

          // Check if the question is finished
          const isQuestionFinished = matchingFinishedQuestion !== undefined;

          return (
            <li key={question.question_id} id={`q-${question.question_id}`}>
              <div
                onClick={openQuestion}
                className={`progress-bar-item-study ${
                  isCurrentQuestion ? 'current-study-question' : ''
                } study-question-${classification} ${
                  isQuestionFinished ? 'done-question' : ''
                }`}
              >
                {parseInt(question.question_id) - firstWordId + 1}
              </div>
            </li>
          );
        })}
        <li className="arrow-down-btn">
          <i
            onClick={handleNextBtn}
            className={`fa-solid fa-arrow-down ${
              isFetching ? 'not-allowed-class' : 'allowed-class'
            } ${isBottomBatch ? 'study-next-not-button' : 'study-next-button'}`}
          ></i>
        </li>
      </ul>
    </aside>
  );
}

export default ProgressBarStudy;
