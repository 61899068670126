import React, { useEffect, useState, useContext, useRef } from 'react';
import { useGlobalContext } from '../../context/AppContext';
import './login.css';
import CookieAgreement from '../../utils/CookieAgreement';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import {
  getSessionCookie,
  setSessionCookie,
  verifySessionCookie,
  setLoginCookie,
} from '../../utils/cookieUtils';
import AlertModal from '../AlertModal/AlertModal';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import ResetPassword from './ResetPassword/ResetPassword';
import ReCAPTCHA from 'react-google-recaptcha';
import HashLoader from 'react-spinners/HashLoader';

function Login() {
  const token = getSessionCookie();
  // useEffect(() => {
  //   if (token){
  //     if (verifySessionCookie()){
  //       window.location = '/environment'
  //     }else{
  //       window.location = '/'
  //     }
  //   }
  // }, [token])

  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Login' : 'התחברות'
    }`; // Set the title to "Milly | Login"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleDivEmailClick = () => {
    setIsEmailFocused(true);
    setIsPasswordFocused(false);
    document.getElementById('loginEmail').focus();
  };

  const handleDivPasswordClick = () => {
    setIsPasswordFocused(true);
    setIsEmailFocused(false);
    document.getElementById('loginPassword').focus();
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      document.getElementById('loginPassword').type = 'password';
    } else {
      setIsPasswordVisible(true);
      document.getElementById('loginPassword').type = 'text';
    }
  };

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handleEmailInputChange = (event) => {
    const emailInput = document.getElementById('loginEmail');

    setEmail(event.target.value);

    if (emailInput.validity.valid) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handlePasswordInputChange = (event) => {
    const passwordInput = document.getElementById('loginPassword');

    setPassword(event.target.value);

    if (passwordInput.validity.valid) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!recaptchaRef.current.getValue()) {
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Please complete the recaptcha');
        setModalInformation('');
        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setIsModalClosed(false);
        setModalInformation('');
        setModalDescription('בבקשה מלאו את ה-recaptcha');

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
      return;
    }

    // const loadingSpinner = document.getElementById("loading-spinner-login");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementById('loginSubmit');
    submitBtn.disabled = true;

    try {
      const form = event.target;
      const formData = new FormData(form);

      const response = await fetch('/login', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      resetReCAPTCHA();

      // You can handle the server response here if needed
      const responseData = await response.json();

      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }

        if (responseData.type === 'verifyEmail') {
          setTimeout(() => {
            window.location = '/verify-email';
          }, 4000);
        }
      } else {
        setSessionCookie(responseData.token);
        setLoginCookie(true);
        window.location = '/environment';
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription(
            'Logged in successfully, redirecting to the platform . . .'
          );
        } else {
          setModalTitle('הצלחה');
          setModalDescription(
            'התחברתם בהצלחה, מעבירים אותכם למערכת הלימודית . . .'
          );
        }

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;
      resetReCAPTCHA();
      setIsModalClosed(false);

      setModalType('error');
      setModalTitle('an error has occured');
      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('נכשל');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [isVisibleEffect, setIsVisibleEffect] = useState(false);
  setTimeout(() => {
    setIsVisibleEffect(true);
  }, 50);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const [showResetPassword, setShowResetPassword] = useState(false);
  const forgotPasswordHandler = () => {
    setShowResetPassword(true);
  };

  const captchaKey = '6LdQ1ssoAAAAAFngYC0GewMPQEFWqxs2IRH61Fj_';
  const recaptchaRef = useRef(null);
  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <React.Fragment>
      <CookieAgreement />
      <ResetPassword
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
        email={email}
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
      />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional="navbar-login"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={false}
      />
      <div id="mainLogin" className="main main-login">
        <div className="login-container">
          <div className={`login-col-1 ${isVisibleEffect ? 'visible' : ''}`}>
            <img
              loading="lazy"
              src="/logo-no-background.png"
              className="login-logo"
              alt="MillyLearn"
            />
            <img
              loading="lazy"
              src="/login-wallpaper.jpg"
              className="img login-wallpaper"
              alt="MillyLearn"
            />
          </div>
          <div className={`login-col-2 ${isVisibleEffect ? 'visible' : ''}`}>
            <div className="login-banner">
              <h1>{languageFile.loginTitle}</h1>
              <p dir="auto">{languageFile.loginText}.</p>
            </div>
            <div className="login-body">
              <form
                className="login-form"
                id="loginForm"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div
                  tabIndex="0"
                  className={`login-form-input-row login-form-email-row ${
                    isEmailValid ? 'valid' : ''
                  } ${isEmailFocused ? 'login-row-focused' : ''}`}
                  id="loginRowEmail"
                  onClick={handleDivEmailClick}
                >
                  <i
                    className={`form-login-input-icon fa-regular fa-envelope ${
                      isEmailFocused ? 'login-i-focused' : ''
                    }`}
                    onClick={handleDivEmailClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${isEmailFocused ? 'login-label-focused' : ''}`}
                    onClick={handleDivEmailClick}
                    htmlFor="loginEmail"
                  >
                    {languageFile.loginEmail}
                  </label>
                  <input
                    type="email"
                    id="loginEmail"
                    name="email"
                    onChange={handleEmailInputChange}
                    value={email}
                    required
                    maxLength="80"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`login-form-input-row login-form-password-row ${
                    isPasswordValid ? 'valid' : ''
                  } ${isPasswordFocused ? 'login-row-focused' : ''}`}
                  id="loginRowPassword"
                  onClick={handleDivPasswordClick}
                >
                  <i
                    className={`form-login-input-icon fa-solid fa-user-lock ${
                      isPasswordFocused ? 'login-i-focused' : ''
                    }`}
                    onClick={handleDivPasswordClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isPasswordFocused ? 'login-label-focused' : ''
                    }`}
                    onClick={handleDivPasswordClick}
                    htmlFor="loginPassword"
                  >
                    {languageFile.loginPassword}
                  </label>
                  <input
                    type="password"
                    id="loginPassword"
                    name="password"
                    onChange={handlePasswordInputChange}
                    value={password}
                    required
                    maxLength="80"
                  />
                  {isPasswordVisible ? (
                    <i
                      className="password-visibility fa-solid fa-eye-slash"
                      onClick={togglePassword}
                    ></i>
                  ) : (
                    <i
                      className="password-visibility fa-solid fa-eye"
                      onClick={togglePassword}
                    ></i>
                  )}
                </div>
                <div className="recpatcha-container">
                  <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} />
                </div>
                <div
                  className={`login-form-btns ${
                    selectedLanguage === 'he' ? 'login-form-btns-hebrew' : ''
                  }`}
                >
                  <button
                    type="submit"
                    className="btn login-form-btn login-form-submit"
                    id="loginSubmit"
                  >
                    {languageFile.loginSubmit}
                  </button>
                  <a
                    type="button"
                    href="/register"
                    className="btn login-form-btn login-form-register"
                    id="loginRegisterBtn"
                  >
                    {languageFile.loginRegister}
                  </a>
                </div>
                <a
                  dir="auto"
                  type="button"
                  onClick={forgotPasswordHandler}
                  className="btn login-form-forgot-password"
                  id="loginForgotBtn"
                >
                  {languageFile.loginForgot}?
                </a>
                {/* <div className={`animation-parent animation-parent-login`} id='loading-spinner-login'>
                              <div className={`animation animation-circle`}></div>
                            </div> */}
                <div
                  id="animationScreen"
                  className={`loading-animation-general`}
                >
                  <HashLoader
                    color="#1A56DB"
                    loading={showAnimation}
                    size={60}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Login;
