import React, { useState, useEffect, useContext } from 'react';
import './alert-modal.css';
import { useGlobalContext } from '../../context/AppContext';

function AlertModal({
  isModalClosed,
  setIsModalClosed,
  messageType,
  title,
  description,
  information,
  checkAnswers,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const closeModal = () => {
    setIsModalClosed(!isModalClosed);
  };

  const runCheckAnswers = () => {
    setIsModalClosed(!isModalClosed);
    checkAnswers();
  };

  return (
    <div
      className={`alert-overlay fade-in-bottom-alert-only ${
        isModalClosed ? '' : 'visible'
      }`}
    >
      <div id="alertModal" className={`alert-modal btn-finish-exam-modal`}>
        <i
          id="closeModal"
          className="fa-solid fa-xmark"
          onClick={closeModal}
        ></i>
        <div className="alert-modal-icon-container">
          <i className="fa-solid fa-exclamation"></i>
        </div>
        {messageType === 'warning-exam' ? (
          <p
            dir="auto"
            className={`alert-modal-type alert-modal-type-warning-exam`}
          >
            warning
          </p>
        ) : (
          <p
            dir="auto"
            className={`alert-modal-type alert-modal-type-${messageType}`}
          >
            {messageType}
          </p>
        )}
        <h3 dir="auto" className={`alert-modal-description-regular`}>
          {description}.
        </h3>
        <span dir="auto" className={`alert-modal-information`}>
          {information}.
        </span>
        {messageType === 'warning-exam' && (
          <button
            id="endExamBtn"
            onClick={runCheckAnswers}
            className={`btn btn-finish-exam`}
          >
            {languageFile.endExam}
          </button>
        )}
      </div>
    </div>
  );
}

export default AlertModal;
