import React, { useEffect, useContext, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import './privacy-policy.css';
import ContactSection from '../HomePage/ContactSection/ContactSection';
import { useGlobalContext } from '../../context/AppContext';
import AlertModal from '../AlertModal/AlertModal';
import {
  getLoginCookie,
  verifySessionCookie,
  getSessionCookie,
} from '../../utils/cookieUtils';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';

function PrivacyPolicy() {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const loggedUser = verifySessionCookie(getSessionCookie());

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Privacy Policy' : 'מדיניות פרטיות'
    }`; // Set the title to "Milly | Privacy Policy"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={loggedUser}
      />
      {selectedLanguage === 'en' ? (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">Privacy Policy</h1>
          <div className="document-contents">
            <h3 dir="auto">Introduction</h3>
            <p dir="auto" className="update-date">
              {' '}
              Last updated: September 9, 2023
            </p>
            <p dir="auto">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p dir="auto">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>
            <h3 dir="auto">Interpretation and Definitions</h3>
            <p dir="auto">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <p dir="auto">For the purposes of this Privacy Policy:</p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>Account</strong> means a unique account created for
                  You to access our Service or parts of our Service.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Affiliate</strong> means an entity that controls, is
                  controlled by or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity
                  interest or other securities entitled to vote for election of
                  directors or other managing authority.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to Milly.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Cookies</strong> are small files that are placed on
                  Your computer, mobile device or any other device by a website,
                  containing the details of Your browsing history on that
                  website among its many uses.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Country</strong> refers to: Israel
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cellphone or a digital tablet.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Personal Data</strong> is any information that relates
                  to an identified or identifiable individual.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Service</strong> refers to the Website which is
                  MillyLearn.com.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Service Provider</strong> means any natural or legal
                  person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the
                  Company to facilitate the Service, to provide the Service on
                  behalf of the Company, to perform services related to the
                  Service or to assist the Company in analyzing how the Service
                  is used.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Usage Data</strong> refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Website</strong> refers to MillyLearn.com, accessible
                  from{' '}
                  <strong>
                    <a
                      rel="noreferrer"
                      href="https://www.MillyLearn.com"
                      target="_blank"
                    >
                      {' '}
                      here
                    </a>
                  </strong>
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </p>
              </li>
            </ul>
            <h3 dir="auto">Collecting and Using Your Personal Data</h3>
            <p dir="auto">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul>
              <li dir="auto">
                <p dir="auto">Email address</p>
              </li>
              <li dir="auto">
                <p dir="auto">First name and last name</p>
              </li>
              <li dir="auto">
                <p dir="auto">Phone number</p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  Address, State, Province, ZIP/Postal code, City
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">Usage Data</p>
              </li>
            </ul>
            <h3 dir="auto">Usage Data</h3>
            <p dir="auto">
              Usage Data is collected automatically when using the Service.
            </p>
            <p dir="auto">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p dir="auto">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p dir="auto">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h3 dir="auto">Tracking Technologies and Cookies</h3>
            <p dir="auto">
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <ul>
              <li dir="auto">
                <strong>Cookies or Browser Cookies.</strong> A cookie is a small
                file placed on Your Device. You can instruct Your browser to
                refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to
                use some parts of our Service. Unless you have adjusted Your
                browser setting so that it will refuse Cookies, our Service may
                use Cookies. Maker sure to read our{' '}
                <strong>
                  <a
                    rel="noreferrer"
                    href="https://www.MillyLearn.com/cookies-policy"
                    target="_blank"
                  >
                    {' '}
                    Cookies Policy
                  </a>
                </strong>
              </li>
              <li dir="auto">
                <strong>Web Beacons.</strong> Certain sections of our Service
                and our emails may contain small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </ul>
            <p dir="auto">
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. Learn more about cookies on the
              <a
                href="https://www.MillyLearn.com/cookies-policy"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Cookies Policy
              </a>
              .
            </p>
            <p dir="auto">
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>Necessary / Essential Cookies</strong>
                </p>
                <p dir="auto">Type: Session Cookies</p>
                <p dir="auto">Administered by: Us</p>
                <p dir="auto">
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>
                    <a
                      href="https://www.MillyLearn.com/cookies-policy"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {' '}
                      Cookies Policy
                    </a>{' '}
                    / Notice Acceptance Cookies
                  </strong>
                </p>
                <p dir="auto">Type: Persistent Cookies</p>
                <p dir="auto">Administered by: Us</p>
                <p dir="auto">
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>Functionality Cookies</strong>
                </p>
                <p dir="auto">Type: Persistent Cookies</p>
                <p dir="auto">Administered by: Us</p>
                <p dir="auto">
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </li>
            </ul>
            <p dir="auto">
              For more information about the cookies we use and your choices
              regarding cookies, please visit our{' '}
              <strong>
                <a
                  href="https://www.MillyLearn.com/cookies-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  {' '}
                  Cookies Policy
                </a>
              </strong>
              .
            </p>
            <h3 dir="auto">Use of Your Personal Data</h3>
            <p dir="auto">
              The Company may use Personal Data for the following purposes:
            </p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>To provide and maintain our Service</strong>,
                  including to monitor the usage of our Service.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>To manage Your Account:</strong> to manage Your
                  registration as a user of the Service. The Personal Data You
                  provide can give You access to different functionalities of
                  the Service that are available to You as a registered user.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>For the performance of a contract:</strong> the
                  development, compliance and undertaking of the purchase
                  contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>To contact You:</strong> To contact You by email,
                  telephone calls, SMS, or other equivalent forms of electronic
                  communication, such as a mobile application's push
                  notifications regarding updates or informative communications
                  related to the functionalities, products or contracted
                  services, including the security updates, when necessary or
                  reasonable for their implementation.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>To provide You</strong> with news, special offers and
                  general information about other goods, services and events
                  which we offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>To manage Your requests:</strong> To attend and manage
                  Your requests to Us.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>For business transfers:</strong> We may use Your
                  information to evaluate or conduct a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of Our assets, whether as a going
                  concern or as part of bankruptcy, liquidation, or similar
                  proceeding, in which Personal Data held by Us about our
                  Service users is among the assets transferred.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>For other purposes</strong>: We may use Your
                  information for other purposes, such as data analysis,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns and to evaluate and improve our Service,
                  products, services, marketing and your experience.
                </p>
              </li>
            </ul>
            <p dir="auto">
              We may share Your personal information in the following
              situations:
            </p>
            <ul>
              <li dir="auto">
                <strong>With Service Providers:</strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </li>
              <li dir="auto">
                <strong>For business transfers:</strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of Our business to another
                company.
              </li>
              <li dir="auto">
                <strong>With Affiliates:</strong> We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </li>
              <li dir="auto">
                <strong>With business partners:</strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li dir="auto">
                <strong>With other users:</strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside.
              </li>
              <li dir="auto">
                <strong>With Your consent</strong>: We may disclose Your
                personal information for any other purpose with Your consent.
              </li>
            </ul>
            <h3 dir="auto">Retention of Your Personal Data</h3>
            <p dir="auto">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p dir="auto">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h3 dir="auto">Transfer of Your Personal Data</h3>
            <p dir="auto">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p dir="auto">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p dir="auto">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h3 dir="auto">Delete Your Personal Data</h3>
            <p dir="auto">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </p>
            <p dir="auto">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </p>
            <p dir="auto">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </p>
            <p dir="auto">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
            <h3 dir="auto">Disclosure of Your Personal Data</h3>
            <h3 dir="auto">Business Transactions</h3>
            <p dir="auto">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h3 dir="auto">Law enforcement</h3>
            <p dir="auto">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h3 dir="auto">Other legal requirements</h3>
            <p dir="auto">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul>
              <li dir="auto">Comply with a legal obligation</li>
              <li dir="auto">
                Protect and defend the rights or property of the Company
              </li>
              <li dir="auto">
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li dir="auto">
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li dir="auto">Protect against legal liability</li>
            </ul>
            <h3 dir="auto">Security of Your Personal Data</h3>
            <p dir="auto">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <h3 dir="auto">Children's Privacy</h3>
            <p dir="auto">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>
            <p dir="auto">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
            <h3 dir="auto">Links to Other Websites</h3>
            <p dir="auto">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p dir="auto">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h3 dir="auto">Changes to this Privacy Policy</h3>
            <p dir="auto">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p dir="auto">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>
            <p dir="auto">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>
        </main>
      ) : (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">מדיניות פרטיות</h1>
          <div className="document-contents">
            <h3 dir="auto">מבוא</h3>
            <p dir="auto" className="update-date">
              עדכון אחרון: 9 בספטמבר, 2023
            </p>
            <p dir="auto">
              מדיניות פרטיות זו מתארת ​​את המדיניות והנהלים שלנו בנושא איסוף,
              שימוש וחשיפה של המידע שלך כאשר את/ה משתמש בשירות ומספר לך על
              זכויות הפרטיות שלך וכיצד החוק מגן עליך.
            </p>
            <p dir="auto">
              אנחנו משתמשים בנתונים האישיים שלך כדי לספק ולשפר את השירות. על ידי
              השימוש בשירות, אתה מסכים לאיסוף מידע ולשימוש בו בהתאם למדיניות
              פרטיות זו.
            </p>
            <h3 dir="auto">פרשנות והגדרות</h3>
            <p dir="auto">
              להגדרות הבאות תהיה אותה משמעות ללא קשר אם הם מופיעים ביחיד או
              ברבים וזכר או נקבה.
            </p>
            <p dir="auto">למטרות מדיניות פרטיות זו:</p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>חשבון</strong> פירושו חשבון ייחודי שנוצר עבורך כדי
                  לגשת לשירות שלנו או לחלקים מהשירות שלנו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>שותף</strong> פירושו ישות השולטת, הוא נשלט על ידי או
                  נמצא בשליטה משותפת עם צד, איפוא "שליטה" פירושה בעלות של 50% או
                  יותר מהמניות, ריבית הון או ניירות ערך אחרים הזכאים להצביע
                  לבחירה של דירקטורים או רשות מנהלת אחרת.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>חברה</strong> (המכונה "החברה", "אנחנו", "אנו", "אנחנו"
                  או "שלנו" בהסכם זה) מתייחס ל- Milly.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>קבצי עוגיות</strong> הם קבצים קטנים על מחשבך, המכשיר
                  הנייד שלך או כל מכשיר אחר על ידי אתר אינטרנט, המכילים את פרטי
                  ההיסטוריה של הדפדוף שלך באתר זה בין השימושים הרבים שלו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>מדינה</strong> מתייחס ל: ישראל
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>התקנים</strong> משמעו כל התקן שיכול לגשת לשירות, כמו
                  מחשב, טלפון סלולרי או טאבלט דיגיטלי.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>מידע אישי</strong> הוא כל מידע הקשור לפרט או לאדם
                  הניתן לזהותו או לזהותו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>שירות</strong> מתייחס לאתר האינטרנט MillyLearn.com.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>ספק שירות</strong> הוא כל פרט טבעי או משפטי שמעבד את
                  המידע בשמינה של החברה. זה מתייחס לחברות או אנשים צדדיים
                  שמועסקים על ידי החברה לצורף את השירות, לספק את השירות בשמינה
                  של החברה, לבצע שירותים הקשורים לשירות או לסייע לחברה בניתוח
                  כיצד השירות משמש.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>נתוני שימוש</strong> מתייחסים לנתונים שנאספים באופן
                  אוטומטי, הנוצרים על ידי השימוש בשירות או מהנדבך התשתית של
                  השירות עצמו (לדוגמה, משך הביקור בדף).
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>אתר </strong>מתייחס ל-MillyLearn.com, הנגיש מכאן:{' '}
                  <strong>
                    <a
                      rel="noreferrer"
                      href="https://www.MillyLearn.com"
                      target="_blank"
                    >
                      {' '}
                      כאן
                    </a>
                  </strong>
                  .
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>אתה</strong> מתייחס לאדם הנגש או השומש את השירות, או
                  לחברה, או ליישות משפטית אחרת בשמינה שלה נגש או משתמש אדם כזה,
                  כפי שנראה לנכון.
                </p>
              </li>
            </ul>
            <h3 dir="auto">איסוף ושימוש בנתונים אישיים שלך</h3>
            <p dir="auto">
              בזמן שאתה משתמש בשירות שלנו, יתכן שנבקש ממך לספק לנו מידע אישי
              מסוים שמזוהה אישית וניתן להשתמש בו ליצירת קשר או לזיהוי שלך. מידע
              מזוהה אישית עשוי לכלול, אך אינו מוגבל לכך:
            </p>
            <ul>
              <li dir="auto">
                <p dir="auto">כתובת דוא"ל</p>
              </li>
              <li dir="auto">
                <p dir="auto">שם פרטי ושם משפחה</p>
              </li>
              <li dir="auto">
                <p dir="auto">מספר טלפון</p>
              </li>
              <li dir="auto">
                <p dir="auto">כתובת, מדינה, מחוז, מיקוד דואר, עיר</p>
              </li>
              <li dir="auto">
                <p dir="auto">נתוני שימוש</p>
              </li>
            </ul>
            <h3 dir="auto">נתוני שימוש</h3>
            <p dir="auto">נתוני שימוש נאספים אוטומטית בעת שימוש בשירות.</p>
            <p dir="auto">
              נתוני שימוש עשויים לכלול מידע כמו כתובת הפרוטוקול האינטרנטי של
              מכשירך (לדוגמה, כתובת IP), סוג הדפדפן, גרסת הדפדפן, הדפים בשירות
              שלנו שביקרת בהם, הזמן והתאריך שבו ביקרת בדפים אלו, הזמן שבו הוצברת
              בהם, מזהי מכשיר ייחודיים ונתונים אחרים לצורך אבחון.
            </p>
            <p dir="auto">
              כאשר אתה גולש אל השירות באמצעות מכשיר נייד, ייתכן שנאסוף מידע
              מסוים באופן אוטומטי, כולל, אך לא מוגבל לכך, את סוג המכשיר הנייד
              שאתה משתמש בו, הזיהוי הייחודי של מכשירך הנייד, כתובת ה-IP של
              מכשירך הנייד, מערכת ההפעלה הניידת של מכשירך, סוג הדפדפן האינטרנטי
              הנייד שאתה משתמש בו, מזהי מכשירים ייחודיים ונתונים אחרים לצורך
              אבחון.
            </p>
            <p dir="auto">
              יתכן גם שנאסוף מידע שהדפדפן שלך שולח בכל פעם שאתה מבקר בשירות שלנו
              או כאשר אתה גולש אל השירות באמצעות מכשיר נייד.
            </p>

            <h3 dir="auto">טכנולוגיות מעקב ועוגיות (Cookies)</h3>
            <p dir="auto">
              אנחנו משתמשים בעוגיות וטכנולוגיות מעקב דומות כדי לעקוב אחר הפעילות
              בשירות שלנו ולאחסן מידע מסוים. הטכנולוגיות שמשמשות כוללות בייקונים
              (beacons), תגי ניתוב (tags), וסקריפטים לאיסוף ומעקב אחר מידע ולשם
              שיפור וניתוח של השירות שלנו. הטכנולוגיות שאנחנו משתמשים בהן עשויות
              לכלול:
            </p>
            <ul>
              <li dir="auto">
                <strong>עוגיות או עוגיות שולחן דפדפן (Browser Cookies).</strong>{' '}
                עוגיה היא קובץ קטן שמונח על מכשירך. אתה יכול להורות לדפדפן שלך
                לסרב לכל העוגיות או לציין כאשר עוגיה נשלחת. אם אתה לא מקבל
                עוגיות, יתכן שלא תהיה מסוגל להשתמש בחלק מהשירות. אלא אם הגדרת את
                ההגדרות של הדפדפן שלך כך שהוא יסרב עוגיות, השירות שלנו עשוי
                להשתמש בעוגיות. הקפידו לקרוא את{' '}
                <strong>
                  <a
                    rel="noreferrer"
                    href="https://www.MillyLearn.com/cookies-policy"
                    target="_blank"
                  >
                    מדיניות העוגיות (Cookies Policy)
                  </a>
                </strong>
                .
              </li>
              <li dir="auto">
                <strong>בייקונים (Web Beacons).</strong> חלקים מסוימים של השירות
                שלנו והודעות האימייל שלנו עשויים לכלול קבצים אלקטרוניים קטנים
                שנקראים בייקונים (גם נקראים כתגי Gifs ברורות, תגי פיקסל, ותגי
                פיקסל בודדים) שמאפשרים לחברה, לדוגמה, לספור משתמשים שביקרו
                בעמודים אלו או פתחו אימייל ולסטטיסטיקות אחרות שקשורות לאתר
                (לדוגמה, רישום הפופולריות של חלק מסוים ובדיקת התאמת המערכת
                ואינטגריטיות השרת).
              </li>
            </ul>
            <p dir="auto">
              עוגיות יכולות להיות "עוגיות עמידות" או "עוגיות סשן". עוגיות עמידות
              נשארות על מחשב האישי שלך או במכשיר הנייד שלך גם כאשר אתה מנותק
              מהרשת, בעוד שעוגיות סשן נמחקות מיד לאחר שאתה סוגר את דפדפן
              האינטרנט שלך. למידע נוסף על עוגיות, יש ללמוד יותר ב־
              <a
                href="https://www.MillyLearn.com/cookies-policy"
                target="_blank"
                rel="noreferrer"
              >
                מדיניות העוגיות (Cookies Policy)
              </a>
              .
            </p>
            <p dir="auto">
              אנחנו משתמשים גם בעוגיות סשן ועמידות למטרות שמפורטות למטה:
            </p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>
                    עוגיות הנחוצות / עוגיות עקרוניות (Necessary / Essential
                    Cookies)
                  </strong>
                </p>
                <p dir="auto">סוג: עוגיות סשן</p>
                <p dir="auto">מנוהלות על ידי: החברה</p>
                <p dir="auto">
                  מטרה: עוגיות אלו הן חיוניות כדי לספק לך את השירותים הזמינים
                  דרך האתר ולאפשר לך להשתמש בחלק מתכונותיו. הן עוזרות לאמת
                  משתמשים ולמנוע שימוש מרמה בחשבונות המשתמשים. בלעדייהן,
                  השירותים שביקשת אינם יכולים להיספק, ואנחנו משתמשים בעוגיות אלו
                  רק כדי לספק לך את השירותים האלו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>
                    <a
                      href="https://www.MillyLearn.com/cookies-policy"
                      rel="noreferrer"
                      target="_blank"
                    >
                      מדיניות עוגיות
                    </a>{' '}
                    / עוגיות לקבלת הודעה
                  </strong>
                </p>
                <p dir="auto">סוג: עוגיות קבועות</p>
                <p dir="auto">ניהלו על ידי: החברה</p>
                <p dir="auto">
                  מטרה: עוגיות אלו מזהות האם משתמשים הסכימו לשימוש בעוגיות באתר.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>עוגיות פונקציונליות</strong>
                </p>
                <p dir="auto">סוג: עוגיות קבועות</p>
                <p dir="auto">ניהלו על ידי: החברה</p>
                <p dir="auto">
                  מטרה: עוגיות אלו מאפשרות לנו לזכור את הבחירות שלך בעת שימושך
                  באתר, כמו לדוגמה את פרטי ההתחברות שלך או השפה שאתה מעדיף.
                  מטרתן של עוגיות אלו היא לספק לך חוויית משתמש אישית יותר ולמנוע
                  ממך להזין מחדש את ההעדפות שלך בכל פעם שאתה משתמש באתר.
                </p>
              </li>
            </ul>
            <p dir="auto">
              למידע נוסף על העוגיות שאנחנו משתמשים בהן ועל האפשרויות שלך לגבי
              עוגיות, בבקשה בקר ב-
              <strong>
                <a
                  href="https://www.MillyLearn.com/cookies-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  מדיניות העוגיות שלנו
                </a>
              </strong>
              .
            </p>
            <h3 dir="auto">שימוש בנתונים אישיים שלך</h3>
            <p dir="auto">החברה עשויה להשתמש בנתונים אישיים למטרות הבאות:</p>
            <ul>
              <li dir="auto">
                <p dir="auto">
                  <strong>לספק ולתחזק את השירות שלנו</strong>, כולל מעקב אחר
                  השימוש בשירות שלנו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>לנהל את החשבון שלך:</strong> לנהל את הרישום שלך כמשתמש
                  בשירות. הנתונים האישיים שאתה מספק יכולים להעניק לך גישה
                  לפונקציות שונות של השירות שזמינות לך כמשתמש רשום.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>למטרת ביצוע החוזה:</strong> לפתח, לבצע ולקיים את חוזה
                  הרכישה למוצרים, פריטים או שירותים שרכשת או לערוך חוזה אחר
                  איתנו דרך השירות.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>ליצור איתך קשר:</strong> ליצור איתך קשר באמצעות דוא"ל,
                  שיחות טלפון, הודעות SMS או דרכי תקשורת אלקטרוניות דומות, כמו
                  התראות דרך אפליקציות ניידות בנוגע לעדכונים או לתקשורות מידעית
                  הקשורה לפונקציות, למוצרים או לשירותים שנרכשו או נסכם לגביהם,
                  כולל עדכוני אבטחה, כאשר זה נחוץ או סביר למבנה הטכני של המימוש.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>לספק לך</strong> חדשות, הצעות מיוחדות ומידע כללי אודות
                  מוצרים, שירותים ואירועים אחרים שאנו מציעים, הדומים לאלו שכבר
                  רכשת או שאלת אודותם, אלא אם בחרת שלא לקבל מידע כזה.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>לנהל את הבקשות שלך:</strong> לטפל ולנהל את הבקשות שלך
                  אלינו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>לעבודות עסקיות:</strong> אנו עשויים להשתמש במידע כדי
                  להעריך או לבצע מיזוג, ארגון מחדש, פירוק או מכירה אחרת או העברה
                  של חלק מהנכסים שלנו או כולם, בין אם כעסק חי או במסגרת פשיטת
                  רגל, פירוק או הליך דומה. הנתונים האישיים המוחזקים על ידינו
                  לגבי משתמשי השירות שלנו הנכסים שהועברו.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>למטרות אחרות</strong>: אנו עשויים להשתמש במידע שלך
                  למטרות אחרות, כמו ניתוח נתונים, זיהוי מגמות שימוש, קביעת
                  התוצאות של מבצעי הפרסום שלנו ולצורך ביקורת ושיפור של השירות,
                  המוצרים, השירותים, השיווק והחוויה שלך.
                </p>
              </li>
            </ul>
            <p dir="auto">אנחנו עשויים לשתף את המידע האישי שלך במצבים הבאים:</p>
            <ul>
              <li dir="auto">
                <strong>עם ספקי שירות:</strong> אנחנו עשויים לשתף את המידע האישי
                שלך עם ספקי שירות כדי למנוע ולנתח את השימוש בשירות שלנו וכדי
                ליצור איתך קשר.
              </li>
              <li dir="auto">
                <strong>לעסקאות עסקיות:</strong> אנחנו עשויים לשתף או להעביר את
                המידע האישי שלך בהקשר, או במהלך משא ומתן בין רכישת נכסי החברה,
                מימון, או רכישה של כל או חלק מעסקנו לחברה אחרת.
              </li>
              <li dir="auto">
                <strong>עם חברות בת:</strong> אנחנו עשויים לשתף את המידע שלך עם
                חברות בת שלנו, תחת תנאי שהן תכבדו את מדיניות הפרטיות הזו. לחברות
                בת כוללות את חברת האם שלנו וכל חברות בת נוספות, שותפי ונותני
                שיתוף אחרים שבפועל ברשותנו או שבפועל נשלטות במשותף איתנו.
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>עם שותפי עסקים:</strong> אנחנו עשויים לשתף את המידע
                  שלך עם שותפי עסקינו כדי להציע לך מסוימים מוצרים, שירותים או
                  מבצעים.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>עם משתמשים אחרים:</strong> כאשר אתה משתף מידע אישי או
                  משתמש באופן אחר באזורים הציבוריים עם משתמשים אחרים, יתכן שמידע
                  כזה יהיה גלוי לכל המשתמשים ויוכל להיפצץ לציבור.
                </p>
              </li>
              <li dir="auto">
                <p dir="auto">
                  <strong>בהסכמתך</strong>: אנחנו עשויים לחשוף את המידע האישי
                  שלך למטרות אחרות בהסכמתך.
                </p>
              </li>
            </ul>
            <h3 dir="auto">שמירת המידע האישי שלך</h3>
            <p dir="auto">
              החברה תשמור על המידע האישי שלך רק כל עוד זה נחוץ למטרות שצוינו
              במדיניות הפרטיות הזו. נשמור ונשתמש במידע האישי שלך לפי הצורך כדי
              לעמוד בהתחייבויותינו המשפטיות (לדוגמה, אם יש לנו חובה משפטית לשמור
              על הנתונים שלך כדי לעמוד בחוקים החלים), לפתור סכסוכים ולאכוף את
              הסכמותינו ומדיניותינו המשפטיות.
            </p>
            <p dir="auto">
              החברה תשמור גם על מידע על שימוש לצורך ניתוח פנימי. מידע על שימוש
              נשמר כללית לתקופה קצרה יותר, אלא אם כי מידע זה נמצא בשימוש כדי
              לחזק את האבטחה או לשפר את פונקציונליות השירות שלנו, או אם יש חובה
              משפטית לשמור על מידע זה לתקופות זמן ארוכות יותר.
            </p>
            <h3 dir="auto">העברת המידע האישי שלך</h3>
            <p dir="auto">
              המידע שלך, כולל מידע אישי, מעובד במשרדי הפעולה של החברה ובכל מקום
              אחר שבו מעורכת העיבוד. זה אומר שהמידע הזה עשוי להיות מועבר —
              ולהיות מתוחזק — על מחשבים הנמצאים מחוץ למדינה שלך, מחוזך, ארצך או
              רשות ממשלתית אחרת, בה חוקי ההגנה על המידע עשויים להיות שונים מאלה
              במקום מגורך.
            </p>
            <p dir="auto">
              ההסכמה שלך למדיניות הפרטיות הזו, שבוויה תפנה אלינו את המידע האישי
              שלך, מהווה את ההסכמה שלך להעברה הזו.
            </p>
            <p dir="auto">
              החברה תקח את כל השלבים הרצויים והמתוקנים כדי להבטיח שהנתונים שלך
              יוטלו באופן מאובטח ובהתאם למדיניות הפרטיות הזו ולא תחדיר העברת
              מידע אישי שלך לארגון או מדינה אחרת אלא אם ישנם בפנים מנגנונים
              מאובטחים, כולל אבטחת המידע שלך ושל מידע אישי אחר.
            </p>
            <h3 dir="auto">מחיקת המידע האישי שלך</h3>
            <p dir="auto">
              יש לך את הזכות למחוק או לבקש שנעזור לך למחוק את המידע האישי שאספנו
              עליך.
            </p>
            <p dir="auto">
              שירותנו עשוי להעניק לך את האפשרות למחוק מידע מסוים עליך מתוך
              השירות עצמו.
            </p>
            <p dir="auto">
              ניתן לעדכן, לתקן או למחוק את המידע שלך בכל עת על ידי התחברות
              לחשבון שלך, אם יש לך כזה, וביקור בסעיף הגדרות החשבון שמאפשר לך
              לנהל את המידע האישי שלך.
            </p>
            <p dir="auto">
              יש לך גם אפשרות ליצור קשר איתנו כדי לבקש גישה, תיקון או מחיקה של
              מידע אישי שסיפקת לנו.
            </p>
            <p dir="auto">
              שים לב, אך, שיש יתרון משפטי או בסיס משפטי לשמור מידע מסוים.
            </p>
            <h3 dir="auto">חשיפת המידע האישי שלך</h3>
            <h3 dir="auto">עסקאות מסחריות</h3>
            <p dir="auto">
              אם החברה מעורבת במיזוג, רכישה או מכירת נכסים, מידע האישי שלך עשוי
              להיות מועבר. אנחנו נספק הודעה לפני שהמידע האישי שלך מועבר ונכנס
              לסמך מדיניות פרטיות שונה.
            </p>
            <h3 dir="auto">אכיפת החוק</h3>
            <p dir="auto">
              בנסיבות מסוימות, יתכן שהחברה תחויב לחשוף את המידע האישי שלך אם
              החוק מחייב זאת או לפי בקשות חוקיות מרשויות ציבוריות תוך כדי
              עניינים משפטיים (לדוגמה, בבית משפט או סוכנות ממשלתית).
            </p>

            <h3 dir="auto">דרישות משפטיות נוספות</h3>
            <p dir="auto">
              החברה עשויה לחשוף את המידע האישי שלך באמונה טובה שכזו פעולה היא
              נחוצה על מנת ל:
            </p>
            <ul>
              <li dir="auto">לעמוד בחובת משפטית</li>
              <li dir="auto">להגן על הזכויות או הרכוש של החברה</li>
              <li dir="auto">
                למנוע או לחקור התנהגות לא נכונה אפשרית בקשר עם השירות
              </li>
              <li dir="auto">
                להגן על בטיחותם האישית של המשתמשים בשירות או הציבור
              </li>
              <li dir="auto">להגן מפגיעות משפטית</li>
            </ul>
            <h3 dir="auto">אבטחת המידע האישי שלך</h3>
            <p dir="auto">
              אבטחת המידע האישי שלך חשובה לנו, אך יש לזכור שאף אמצעי ההעברה דרך
              האינטרנט או האחסון האלקטרוני אינם מאובטחים ב-100%. בעוד שאנו
              משתדלים להשתמש באמצעים מקצועיים ונכונים כדי להגן על המידע האישי
              שלך, אנו לא יכולים להבטיח את האבטחה המוחלטת שלו.
            </p>
            <h3 dir="auto">פרטיות הילדים</h3>
            <p dir="auto">
              השירות שלנו אינו מיועד לכל אדם בגיל 13 ומתחת. אנחנו לא מאגדים מידע
              המזהה אישית מאף אחד בגיל 13 ומתחת. אם אתה הורה או שומר ואתה מודע
              לכך שילדך סיפק לנו מידע אישי, אנא צור קשר איתנו. אם נזהה כי אספנו
              מידע אישי מאף אחד בגיל 13 ומתחת ללא אימות הסכמת הוריים, אנחנו נתקן
              פעולות להסרת המידע הזה משרתי השירות שלנו.
            </p>
            <p dir="auto">
              אם אנחנו נזדקק להסתמך על הסכמת הורייך כמוסמך משפטית לעניין עיבוד
              המידע שלך והמדינה שלך מחייבת הסכמת הורים, עשויה לדרוש הסכמת הורייך
              לפני שנאסוף ונשתמש במידע זה.
            </p>
            <h3 dir="auto">קישורים לאתרים אחרים</h3>
            <p dir="auto">
              השירות שלנו עשוי לכלול קישורים לאתרים אחרים שאינם מופעלים על
              ידינו. אם תלחץ על קישור של צד שלישי, תועבר לאתר של הצד השלישי הזה.
              אנחנו ממליצים בחום לך לבדוק את מדיניות הפרטיות של כל אתר שאתה מבקר
              בו.
            </p>
            <p dir="auto">
              אנחנו אין לנו שליטה ואין לנו כל סוג של אחריות לתוכן, למדיניות
              הפרטיות או לשיטות של אתרים או שירותים של צד שלישי.
            </p>
            <h3 dir="auto">שינויים במדיניות הפרטיות הזו</h3>
            <p dir="auto">
              אנו עשויים לעדכן את מדיניות הפרטיות שלנו מעת לעת. נודיע לך על כל
              שינוי על ידי פרסום מדיניות הפרטיות החדשה בדף זה.
            </p>
            <p dir="auto">
              נודיע לך גם באמצעות אימייל ו/או הודעה בולטת בשירות שלנו, לפני
              שהשינוי ייכנס לתוקף, ונעדכן את תאריך "עדכון אחרון" בראש מדיניות
              הפרטיות הזו.
            </p>
            <p dir="auto">
              ממליצים לך לסקור מדיניות הפרטיות הזו באופן מדוד מעת לעת לצורך
              זיהוי שינויים. שינויים במדיניות הפרטיות הם בתוקף כאשר הם נפרסמים
              בדף זה.
            </p>
          </div>
        </main>
      )}

      <ContactSection
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
        id="sectionContact"
        className="section-contact"
      />
      {loggedUser ? (
        <LoggedFooter
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      ) : (
        <Footer
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      )}
    </React.Fragment>
  );
}

export default PrivacyPolicy;
