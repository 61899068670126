import React, { useState, useEffect, useContext, useRef } from 'react';
import './email-verification.css';
import { useGlobalContext } from '../../../context/AppContext';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import ReCAPTCHA from 'react-google-recaptcha';

function EmailVerification({
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  showEmailVerification,
  setShowEmailVerification,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const handleSubmit = async (event) => {
    // check if all fields are given
    event.preventDefault();

    try {
      const form = event.target;
      const verificationCode = codes.join('').toUpperCase();
      const formData = new FormData(form);

      formData.append('code', verificationCode);
      formData.append('action', 'verify');

      var submitBtn = document.getElementById('emailVerifyBtn');
      submitBtn.disabled = true;

      const response = await fetch('/settings/delete-account', {
        method: 'DELETE',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      submitBtn.disabled = false;

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setModalType('success');
        setModalTitle('success');
        setModalInformation('');

        if (selectedLanguage === 'en') {
          setModalDescription(
            'We are sad to see you go.. we would appreciate if could write the reason behind it.'
          );
        } else {
          setModalTitle('הצלחה');
          setModalDescription(
            'אנו מתבאסים לראות אותכם עוזבים.. נעריך מאוד אם תוכלו לקחת דקה לכתוב את הסיבה.'
          );
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
          window.location = '/contact';
        }, 7000);
      }
    } catch (error) {
      submitBtn.disabled = false;

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const [codes, setCodes] = useState(['', '', '', '', '', '']);

  const handleChange = (event, value, index) => {
    const newCodes = [...codes];
    newCodes[index] = value;
    setCodes(newCodes);

    const inputElement = document.getElementById(`code-input-${index}`);

    if (value != '') {
      inputElement.classList.add('valid');
    } else {
      inputElement.classList.remove('valid');
    }

    // Auto-focus the next input if the current input is not empty and there is a next input
    if (value !== '' && index < codes.length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const onKeyDownHandler = (event, value, index) => {
    if (
      (value === '' && event.key === 'Backspace' && index > 0) ||
      (event.key === 'ArrowLeft' && index > 0)
    ) {
      const previousInput = document.getElementById(`code-input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className={`alert-overlay alert-overlay-email-verification ${
          showEmailVerification ? 'visible' : ''
        }`}
      >
        <div
          id="alertModal"
          className={`alert-modal fade-in-bottom-email-verification ${
            showEmailVerification ? 'visible' : ''
          }`}
        >
          <h1 className={`alert-modal-title`}>
            {languageFile.emailVerifyTitle}
          </h1>
          <div className="title-underline"></div>
          <h3 dir="auto" className={`alert-modal-description`}>
            {languageFile.emailVerifyBanner}.
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="verification-code">
              {codes.map((code, index) => (
                <input
                  type="text"
                  maxLength="1"
                  minLength="1"
                  className={`code-input`}
                  value={code}
                  onChange={(e) => handleChange(e, e.target.value, index)}
                  key={index}
                  onKeyDown={(e) => onKeyDownHandler(e, e.target.value, index)}
                  id={`code-input-${index}`} // Set a unique id for each input
                />
              ))}
            </div>
            <div className="email-verify-btn">
              <button
                type="submit"
                className="btn btn-email-verify"
                id="emailVerifyBtn"
              >
                {languageFile.emailVerifyButtonVerify}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EmailVerification;
