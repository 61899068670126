import React, { useEffect, useContext, useState } from 'react';
import './ProgressBarMemorize.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  clearMemorizeMode,
  getTopicCookie,
  getCurrectQuestionCookie,
  setCurrectQuestionCookie,
  getStudyModeCookie,
  getStudyUnitCookie,
  setQuestionIdCookie,
  getQuestionIdCookie,
  getQuestionsStartCookie,
  getQuestionsEndCookie,
  setQuestionsStartCookie,
  setQuestionsEndCookie,
  getMemorizationClassificationCookie,
  getFinishedQuestions,
} from '../../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';
import { array, arrayOf } from 'prop-types';
import UnitOptionsModal from '../../../Environment/UnitOptionsModal/UnitOptionsModal';

function ProgressBarMemorize({
  showAnimation,
  setShowAnimation,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  setShowClassification,
  openProgressBar,
  setOpenProgressBar,
  currentQuestionID,
  setCurrentQuestionID,
  currentQuestions,
  currentQuestionTitle,
  setCurrentQuestionTitle,
  setCurrentQuestionAnswers,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  const [questionCounter, setQuestionCounter] = useState(0);
  let finishedQuestions = getFinishedQuestions();

  let startCookie = null;
  let endCookie = null;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState('');

  const studyUnit = getStudyUnitCookie();

  const [questionsBody, setQuestionsBody] = useState([]);
  let isFunctionRunningOpenQuestion = false;
  let isFunctionRunning = false;

  const findInArray = (array, questionID) => {
    const verify = array.find((json) => json.question_id === questionID);

    if (verify) return true;
    return false;
  };

  const findGetInArray = (array, questionID) => {
    return array.find((json) => json.question_id === questionID);
  };

  const isLastInArray = (array, questionID) => {
    const lastItem = array[array.length - 1];
    const verify = lastItem && lastItem.question_id === questionID;

    if (verify) return true;
    return false;
  };

  const findGetIndex = (array, index) => {
    const item = array[index];
    if (item) return item;
    return null;
  };

  const openQuestion = async (event) => {
    // if (isFunctionRunningOpenQuestion) {
    //   return;
    // }

    if (isClickDisabled) {
      return;
    }

    finishedQuestions = getFinishedQuestions();

    isFunctionRunningOpenQuestion = true;
    // const loadingSpinner = document.getElementById("loading-spinner-login5");

    const clickedElement = event.target;
    let questionId = null;
    const questionsClass = 'progress-bar-item';

    let questionsCollection = document.getElementsByClassName(questionsClass);
    let questionsArray = Array.from(questionsCollection);
    questionsArray.forEach((element) => {
      element.classList.add('disable-progress-bar');
    });

    if (clickedElement.localName === 'div') {
      questionId = clickedElement.parentNode.id;

      questionsArray.forEach((element) => {
        element.classList.remove('current-question');
      });

      clickedElement.classList.add('current-question');

      setCurrentQuestionID(questionId);
      setQuestionIdCookie(questionId);
    } else if (clickedElement.localName === 'li') {
      questionId = clickedElement.id;

      questionsArray.forEach((element) => {
        element.classList.remove('current-question');
      });

      clickedElement.children[0].classList.add('current-question');
      setCurrentQuestionID(questionId);
      setQuestionIdCookie(questionId);
    }
    try {
      const currentQuestionsCookie = getCurrectQuestionCookie();
      let response = null;

      if (currentQuestionsCookie) {
        const matchingItem = findInArray(
          currentQuestionsCookie,
          parseInt(questionId.substring(2), 10)
        );

        if (matchingItem) {
          const getItem = findGetInArray(
            currentQuestionsCookie,
            parseInt(questionId.substring(2), 10)
          );
          setCurrentQuestionIndex(parseInt(questionId.substring(2), 10));
          setCurrentQuestionTitle(getItem.question);
          setCurrentQuestionAnswers(getItem.answers);

          if (finishedQuestions.includes(questionId)) {
            setShowClassification(true);
            // document.getElementById(questionId).children[0].classList.add("done-question");
          } else {
            setShowClassification(false);
          }

          const isLast = isLastInArray(
            currentQuestionsCookie,
            parseInt(questionId.substring(2), 10)
          );
          if (isLast && endCookie !== parseInt(questionId.substring(2), 10)) {
            const currentClassification = getMemorizationClassificationCookie();
            response = await fetch(
              `/word/memorize/${topic}?start_id=${
                parseInt(questionId.substring(2), 10) + 1
              }&classification=${currentClassification}&unit_id=${currentUnit}`,
              {
                method: 'GET',
              }
            );
            questionsArray.forEach((element) => {
              element.classList.add('disable-progress-bar');
            });

            if (!response.ok) {
              throw new Error('Error sending data to the server');
            }

            const responseData = await response.json();
            if (responseData.success === false) {
              isFunctionRunningOpenQuestion = false;

              setModalInformation('');
              setModalType('error');
              setModalTitle('an error has occured');

              if (selectedLanguage === 'en') {
                setModalDescription(responseData.data);
              } else {
                let responseMsg = fetchResponseHandler(responseData.data);
                setModalDescription(responseMsg);
                setModalTitle('שגיאה');
              }

              setIsModalClosed(false);
              setTimeout(() => {
                setIsModalClosed(true);
              }, 7000);
              questionsArray.forEach((element) => {
                element.classList.remove('disable-progress-bar');
              });
            } else {
              const serializedArray = responseData.data;
              setCurrectQuestionCookie(serializedArray);

              questionsArray.forEach((element) => {
                element.classList.remove('disable-progress-bar');
              });

              isFunctionRunningOpenQuestion = false;
            }
          }

          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
          isFunctionRunningOpenQuestion = false;
        } else {
          // loadingSpinner.style.display = "flex";
          setShowAnimation(true);

          const currentClassification = getMemorizationClassificationCookie();
          const responseAnother = await fetch(
            `/word/memorize/${topic}?start_id=${questionId.substring(
              2
            )}&classification=${currentClassification}&unit_id=${currentUnit}`,
            {
              method: 'GET',
            }
          );

          questionsArray.forEach((element) => {
            element.classList.add('disable-progress-bar');
          });

          isFunctionRunningOpenQuestion = false;

          if (!responseAnother.ok) {
            throw new Error('Error sending data to the server');
          }

          const responseData = await responseAnother.json();

          if (responseData.success === false) {
            isFunctionRunningOpenQuestion = false;

            setModalInformation('');
            setModalType('error');
            setModalTitle('an error has occured');

            if (selectedLanguage === 'en') {
              setModalDescription(responseData.data);
            } else {
              let responseMsg = fetchResponseHandler(responseData.data);
              setModalDescription(responseMsg);
              setModalTitle('שגיאה');
            }

            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
            questionsArray.forEach((element) => {
              element.classList.remove('disable-progress-bar');
            });

            // loadingSpinner.style.display = "none";
            setShowAnimation(false);
          } else {
            const serializedArray = responseData.data;
            setCurrectQuestionCookie(serializedArray);
            // const receivedQuestionAnother = serializedArray[parseInt(questionId.substring(2), 10) - 1].question;
            // const receivedAnswersAnother = serializedArray[parseInt(questionId.substring(2), 10) - 1].answers;
            const matchingItem = findInArray(
              serializedArray,
              parseInt(questionId.substring(2), 10)
            );

            if (matchingItem) {
              const getItem = findGetInArray(
                serializedArray,
                parseInt(questionId.substring(2), 10)
              );
              setCurrentQuestionIndex(parseInt(questionId.substring(2), 10));
              setCurrentQuestionTitle(getItem.question);
              setCurrentQuestionAnswers(getItem.answers);
            }

            if (finishedQuestions.includes(questionId)) {
              setShowClassification(true);
              // document.getElementById(questionId).children[0].classList.add("done-question");
            } else {
              setShowClassification(false);
            }

            isFunctionRunningOpenQuestion = false;
            questionsArray.forEach((element) => {
              element.classList.remove('disable-progress-bar');
            });

            setShowAnimation(false);
          }
        }
      } else {
        setShowAnimation(true);

        const currentClassification = getMemorizationClassificationCookie();
        const responseAnother = await fetch(
          `/word/memorize/${topic}?start_id=${questionId.substring(
            2
          )}&classification=${currentClassification}&unit_id=${currentUnit}`,
          {
            method: 'GET',
          }
        );

        questionsArray.forEach((element) => {
          element.classList.add('disable-progress-bar');
        });

        isFunctionRunningOpenQuestion = false;

        if (!responseAnother.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await responseAnother.json();
        if (responseData.success === false) {
          isFunctionRunningOpenQuestion = false;

          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
          setShowAnimation(false);
        } else {
          const serializedArray = responseData.data;
          setCurrectQuestionCookie(serializedArray);
          const matchingItem = findInArray(
            serializedArray,
            parseInt(questionId.substring(2), 10)
          );

          if (matchingItem) {
            const getItem = findGetInArray(
              serializedArray,
              parseInt(questionId.substring(2), 10)
            );
            setCurrentQuestionIndex(parseInt(questionId.substring(2), 10));
            setCurrentQuestionTitle(getItem.question);
            setCurrentQuestionAnswers(getItem.answers);
          }

          if (finishedQuestions.includes(questionId)) {
            setShowClassification(true);
            // document.getElementById(questionId).children[0].classList.add("done-question");
          } else {
            setShowClassification(false);
          }

          isFunctionRunningOpenQuestion = false;
          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
          setShowAnimation(false);
        }
      }
    } catch (error) {
      isFunctionRunningOpenQuestion = false;
      questionsArray.forEach((element) => {
        element.classList.remove('disable-progress-bar');
      });
      console.error(error);
      setShowAnimation(false);
    }

    questionsArray.forEach((element) => {
      element.classList.remove('disable-progress-bar');
    });
    setShowAnimation(false);

    questionsArray.forEach((element) => {
      element.classList.remove('disable-progress-bar');
    });
    setShowAnimation(false);
  };

  const handleDoneBtn = async () => {
    clearMemorizeMode();
    window.location = '/environment';
  };

  const currentUnit = getStudyUnitCookie();
  const learningMode = getStudyModeCookie();
  let topic = getTopicCookie().substring(6);

  const [isClickDisabled, setIsClickDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append(
          'classification',
          getMemorizationClassificationCookie()
        );
        formData.append('unit_id', currentUnit.substring(5));

        const response = await fetch(`/word/memorize/units/${topic}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          const responseDataPlain = responseData.data;
          setQuestionCounter(responseDataPlain);

          startCookie = 1;
          endCookie = responseData.rangeEnd;
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    };

    let isLoadedAlready = false;
    const fetchDataQuestionAnswers = async () => {
      if (isLoadedAlready) {
        return;
      }

      setShowClassification(false);

      isLoadedAlready = true;
      const questionsClass = 'progress-bar-item';

      let questionsCollection = document.getElementsByClassName(questionsClass);
      let questionsArray = Array.from(questionsCollection);
      questionsArray.forEach((element) => {
        element.classList.add('disable-progress-bar');
      });

      setShowAnimation(true);
      setIsClickDisabled(true);

      try {
        let response = null;

        const statusCurrentID =
          currentQuestionID === 'q-undefined' ||
          currentQuestionID === null ||
          parseInt(currentQuestionID.substring(2), 10) < startCookie;

        const currentQuestionsCookie = getCurrectQuestionCookie();

        if (currentQuestionsCookie) {
          const getFirstIndex = findGetIndex(currentQuestionsCookie, 0);
          let storedStudyUnit = null;

          if (getFirstIndex) {
            storedStudyUnit = `unit-${getFirstIndex.unit_id}`;
          }

          if (studyUnit === storedStudyUnit) {
            const matchingItem = findInArray(
              currentQuestionsCookie,
              parseInt(currentQuestionID.substring(2), 10)
            );
            if (matchingItem) {
              const getItem = findGetInArray(
                currentQuestionsCookie,
                parseInt(currentQuestionID.substring(2), 10)
              );
              setCurrentQuestionIndex(
                parseInt(currentQuestionID.substring(2), 10)
              );
              setCurrentQuestionTitle(getItem.question);
              setCurrentQuestionAnswers(getItem.answers);

              if (finishedQuestions.includes(currentQuestionID)) {
                setShowClassification(true);
                // document.getElementById(currentQuestionID).children[0].classList.add("done-question");
              } else {
                setShowClassification(false);
              }

              questionsArray.forEach((element) => {
                element.classList.remove('disable-progress-bar');
              });

              setShowAnimation(false);
              setIsClickDisabled(false);

              return;
            }
          }
        }

        const currentClassification = getMemorizationClassificationCookie();
        response = await fetch(
          `/word/memorize/${topic}?start_id=${currentQuestionID.substring(
            2
          )}&classification=${currentClassification}&unit_id=${currentUnit}`,
          {
            method: 'GET',
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);

          setShowAnimation(false);
          setIsClickDisabled(false);
          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
        } else {
          if (responseData.data.length === 0) {
            setModalInformation('');
            setModalType('warning');
            setModalTitle('warning');

            if (selectedLanguage === 'en') {
              setModalDescription(
                'you are being redirected, please return when you have classified at least one word in the current unit'
              );
            } else {
              setModalDescription(
                'אתם מועברים לעמוד הבית, אנא חזרו ברגע שתסווגו לפחות מילה אחת מהיחידה הנוכחית'
              );
              setModalTitle('שימו לב');
            }

            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
              window.location = '/';
            }, 7000);

            setShowAnimation(false);
            setIsClickDisabled(false);
            questionsArray.forEach((element) => {
              element.classList.remove('disable-progress-bar');
            });
          }
          const serializedArray = responseData.data;
          setCurrectQuestionCookie(serializedArray);

          const matchingItem = findInArray(
            serializedArray,
            parseInt(currentQuestionID.substring(2), 10)
          );

          if (matchingItem) {
            const getItem = findGetInArray(
              serializedArray,
              parseInt(currentQuestionID.substring(2), 10)
            );
            setCurrentQuestionIndex(
              parseInt(currentQuestionID.substring(2), 10)
            );
            setCurrentQuestionTitle(getItem.question);
            setCurrentQuestionAnswers(getItem.answers);
          }

          setShowAnimation(false);
          setIsClickDisabled(false);
          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);

        setShowAnimation(false);
        setIsClickDisabled(false);
        questionsArray.forEach((element) => {
          element.classList.remove('disable-progress-bar');
        });

        console.error(error);
      }
    };

    const pattern = /^unit-\d+$/;
    const match = pattern.test(currentUnit);

    if (match) {
      fetchData().then(() => {
        setQuestionsBody(renderList());
        fetchDataQuestionAnswers();
      });
    }
  }, []);

  const renderList = () => {
    const items = [];
    let indexCookie = '';

    if (startCookie === null || startCookie === undefined) {
      indexCookie = getQuestionsStartCookie();
    } else {
      indexCookie = startCookie;
    }
    const tempCookie = getQuestionIdCookie();
    const tempIndex = `${tempCookie}`;

    if (tempCookie) {
      setCurrentQuestionID(tempCookie);
      setQuestionIdCookie(tempCookie);
    } else {
      setCurrentQuestionID(`q-${indexCookie}`);
      setQuestionIdCookie(`q-${indexCookie}`);
    }

    for (let index = indexCookie; index <= endCookie; index++) {
      if (
        index === indexCookie &&
        (tempIndex === 'q-undefined' ||
          tempIndex === null ||
          parseInt(tempIndex.substring(2), 10) < indexCookie ||
          parseInt(tempIndex.substring(2), 10) > indexCookie)
      ) {
        const isCurrentQuestion =
          tempIndex !== undefined &&
          index === parseInt(tempIndex.substring(2), 10);

        items.push(
          <li key={index} id={`q-${index}`}>
            <div
              onClick={isClickDisabled ? null : openQuestion}
              className={`progress-bar-item ${
                isCurrentQuestion ? 'current-question' : ''
              } ${
                finishedQuestions.includes(`q-${index}`) ? 'done-question' : ''
              }`}
            >
              {index}
            </div>
          </li>
        );
      } else {
        const isCurrentQuestion =
          tempIndex !== 'q-undefined' &&
          index === parseInt(tempIndex.substring(2), 10);
        items.push(
          <li key={index} id={`q-${index}`}>
            <div
              onClick={isClickDisabled ? null : openQuestion}
              className={`progress-bar-item ${
                isCurrentQuestion ? 'current-question' : ''
              } ${
                finishedQuestions.includes(`q-${index}`) ? 'done-question' : ''
              }`}
            >
              {index}
            </div>
          </li>
        );
      }
    }

    return items;
  };

  return (
    <aside className={`progress-bar ${openProgressBar ? 'visible' : ''}`}>
      <ul id="progressListUl">
        {questionsBody}
        <li className="done-btn">
          <i
            onClick={handleDoneBtn}
            className="fa-solid fa-circle-check study-finish-button"
          ></i>
        </li>
      </ul>
    </aside>
  );
}

export default ProgressBarMemorize;
