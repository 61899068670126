import React, { useState, useEffect } from 'react';
import './ExamModal.css';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';
import {
  getTopicCookie,
  setCurrectQuestionCookie,
  setExamOriginalIDCookie,
  setExamStopper,
  setIsExamFromProgress,
  setStudyExamTime,
  setStudyModeCookie,
  setStudyUnitCookie,
  setTopicCookie,
} from '../../../../utils/cookieUtils';
import Classification from '../../../Platform/Classification/Classification';
import HashLoader from 'react-spinners/HashLoader';

function ExamModal({
  chosenTopic,
  examID,
  formatTime,
  formatDate,
  translateTopic,
  examData,
  languageFile,
  selectedLanguage,
  showExamModal,
  setShowExamModal,
  showAnimation,
  setShowAnimation,
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  setExamData,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [questionsBody, setQuestionsBody] = useState([]);
  const [allQuestionsLoaded, setAllQuestionsLoaded] = useState(false);

  let loadedExamQuestionsTemp = [];
  const [loadedExamQuestions, setLoadedExamQuestions] = useState([]);
  const handleClassificationClick = async (event) => {
    const clickedElement = event.target;
    let classificationID = '';
    let tempOriginalID = '';

    if (clickedElement.localName === 'i') {
      let tempChildList = clickedElement.parentNode.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    } else if (clickedElement.localName === 'li') {
      let tempChildList = clickedElement.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    }

    if (clickedElement.localName === 'i') {
      clickedElement.parentNode.classList.add('selected-classification');
      clickedElement.parentNode.classList.remove('grayed-out-classification');
      classificationID = clickedElement.parentNode.id;
      tempOriginalID = clickedElement.parentNode.parentNode.parentNode.id;
    } else if (clickedElement.localName === 'li') {
      clickedElement.classList.add('selected-classification');
      clickedElement.classList.remove('grayed-out-classification');
      classificationID = clickedElement.id;
      tempOriginalID = clickedElement.parentNode.parentNode.id;
    }

    const questionsClass = 'btn-classification-emoji';

    const questionsCollectionDisabledClassification =
      document.getElementsByClassName(questionsClass);
    const questionsArrayDisabledClassifications = [
      ...questionsCollectionDisabledClassification,
    ];

    try {
      questionsArrayDisabledClassifications.forEach((element) => {
        element.classList.add('disabled-classification');
      });

      const response = await fetch('/word/memorize/psychometry', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question_id: tempOriginalID.substring(4),
          classification: classificationID,
        }),
      });
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
      console.error(error);
    } finally {
      questionsArrayDisabledClassifications.forEach((element) => {
        element.classList.remove('disabled-classification');
      });
    }
  };

  const closeExamModal = () => {
    setShowExamModal(!showExamModal);
    setExamData({});
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      fetchExamQuestions();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && allQuestionsLoaded) {
      Object.entries(loadedExamQuestions).forEach(([key, value]) => {
        if (key === 'correct_answers') {
          value.forEach((element) => {
            if (element[3]) {
              const parentDiv = document.getElementById(`c-q-${element[0]}`);
              const elementWithClass = parentDiv.querySelector(
                '.classification-list'
              );
              const allChildren = Array.from(elementWithClass.children);

              allChildren.forEach((element2) => {
                element2.classList.remove('selected-classification');
                element2.classList.add('grayed-out-classification');

                if (element[3][1] === element2.id) {
                  element2.classList.add('selected-classification');
                  element2.classList.remove('grayed-out-classification');
                }
              });
            }
          });
        } else if (key === 'incorrect_answers') {
          value.forEach((element) => {
            if (element[4]) {
              const parentDiv = document.getElementById(`c-q-${element[0]}`);
              const elementWithClass = parentDiv.querySelector(
                '.classification-list'
              );
              const allChildren = Array.from(elementWithClass.children);

              allChildren.forEach((element2) => {
                element2.classList.remove('selected-classification');
                element2.classList.add('grayed-out-classification');

                if (element[4][1] === element2.id) {
                  element2.classList.add('selected-classification');
                  element2.classList.remove('grayed-out-classification');
                }
              });
            }
          });
        } else if (key === 'ignored_answers') {
          value.forEach((element) => {
            if (element[3]) {
              const parentDiv = document.getElementById(`c-q-${element[0]}`);
              const elementWithClass = parentDiv.querySelector(
                '.classification-list'
              );
              const allChildren = Array.from(elementWithClass.children);

              allChildren.forEach((element2) => {
                element2.classList.remove('selected-classification');
                element2.classList.add('grayed-out-classification');

                if (element[3][1] === element2.id) {
                  element2.classList.add('selected-classification');
                  element2.classList.remove('grayed-out-classification');
                }
              });
            }
          });
        }
      });
    }
  }, [isLoaded, allQuestionsLoaded, loadedExamQuestions]);

  let current_question_id = 1;
  const renderList = () => {
    const items = [];

    Object.entries(loadedExamQuestionsTemp).map(([key, value], index) => {
      if (key === 'correct_answers') {
        value.forEach((element) => {
          items.push(
            <div
              key={index}
              id={`c-q-${element[0]}`}
              className={`questions-status questions-status-${current_question_id} question-correct`}
            >
              <h2
                dir="auto"
                className={`questions-status-header question-correct-p`}
              >
                {languageFile.question} {current_question_id}
              </h2>
              <h3 dir="auto" className="questions-status-question">
                {element[1]}
              </h3>
              <div
                className={`chosen-answer ${
                  selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
                }`}
              >
                <p dir="auto">{languageFile.correctAnswer}:</p>
                <p dir="auto">{element[2]}</p>
              </div>
              <Classification
                handleClassificationClick={handleClassificationClick}
              />
            </div>
          );

          current_question_id++;
        });
      } else if (key === 'incorrect_answers') {
        value.forEach((element) => {
          items.push(
            <div
              key={index}
              id={`c-q-${element[0]}`}
              className={`questions-status questions-status-${current_question_id} question-wrong`}
            >
              <h2
                dir="auto"
                className={`questions-status-header question-wrong-p`}
              >
                {languageFile.question} {current_question_id}
              </h2>
              <h3 dir="auto" className="questions-status-question">
                {element[1]}
              </h3>
              <div
                className={`chosen-answer ${
                  selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
                }`}
              >
                <p dir="auto">{languageFile.chosenAnswer}:</p>
                <p dir="auto">{element[3]}</p>
              </div>
              <div
                className={`chosen-answer ${
                  selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
                }`}
              >
                <p dir="auto">{languageFile.correctAnswer}:</p>
                <p dir="auto">{element[2]}</p>
              </div>
              <Classification
                handleClassificationClick={handleClassificationClick}
              />
            </div>
          );
          current_question_id++;
        });
      } else if (key === 'ignored_answers') {
        value.forEach((element) => {
          items.push(
            <div
              key={index}
              id={`c-q-${element[0]}`}
              className={`questions-status questions-status-${current_question_id}`}
            >
              <h2 dir="auto" className={`questions-status-header`}>
                {languageFile.question} {current_question_id}
              </h2>
              <h3 dir="auto" className="questions-status-question">
                {element[1]}
              </h3>
              <div
                className={`chosen-answer ${
                  selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
                }`}
              >
                <p dir="auto">{languageFile.correctAnswer}:</p>
                <p dir="auto">{element[2]}</p>
              </div>
              <Classification
                handleClassificationClick={handleClassificationClick}
              />
            </div>
          );
          current_question_id++;
        });
      }
    });

    setAllQuestionsLoaded(true);
    return items;
  };

  const fetchExamQuestions = async () => {
    try {
      setShowAnimation(true);
      const response = await fetch(`/load-exams/${chosenTopic}?id=${examID}`, {
        method: 'POST',
      });

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        if (responseData.data !== 'failed') {
          loadedExamQuestionsTemp = responseData.data;
          setLoadedExamQuestions(responseData.data);
          setQuestionsBody(renderList());
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  let serverStoresAnExam = false;

  const grabRemoteStoredExam = async () => {
    try {
      const response = await fetch(
        `/start-exam/${examData.topic}?unit_id=${examData.unit_id}`,
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      const responseData = await response.json();
      if (responseData.success === false) {
        serverStoresAnExam = false;
      } else {
        serverStoresAnExam = true;
      }
    } catch (error) {}
  };

  const handleRetryExam = async () => {
    setShowAnimation(true);
    grabRemoteStoredExam().then(() => {
      setStudyModeCookie('modeExam');

      if (!serverStoresAnExam) {
        setExamOriginalIDCookie(examData.exam_id);
        setStudyUnitCookie(`unit-${examData.unit_id}`);
        setStudyModeCookie('modeExam');
        setStudyExamTime(examData.timer);
        setTopicCookie(examData.topic);
        setExamStopper(parseInt(examData.stopper, 10));
      }
      setShowAnimation(false);
      window.location = '/platform';
    });

    // try {
    //   const response = await fetch(
    //     `/word/exam/${
    //       examData.topic
    //     }?unit_id=${`unit-${examData.unit_id}`}&exam_id=${examData.exam_id}`,
    //     {
    //       method: 'GET',
    //     }
    //   );

    //   if (!response.ok) {
    //     throw new Error('Error sending data to the server');
    //   }

    //   // You can handle the server response here if needed
    //   const responseData = await response.json();
    //   if (responseData.success === false) {
    //     setModalInformation('');
    //     setModalType('error');
    //     setModalTitle('an error has occured');

    //     if (selectedLanguage === 'en') {
    //       setModalDescription(responseData.data);
    //     } else {
    //       let responseMsg = fetchResponseHandler(responseData.data);
    //       setModalDescription(responseMsg);
    //       setModalTitle('שגיאה');
    //     }

    //     setIsModalClosed(false);
    //     setTimeout(() => {
    //       setIsModalClosed(true);
    //     }, 7000);
    //   } else {
    //     setExamOriginalIDCookie(examData.exam_id);

    //     // Redirect to `/platform`.
    //     // If yes just go to platform.
    //     // If not then send a request to start an exam.

    //     const responseDataPlain = responseData.data;
    //     setCurrectQuestionCookie(responseDataPlain);
    //     setIsExamFromProgress(true);
    //     window.location = '/platform';
    //   }
    // } catch (error) {
    //   setModalInformation('');
    //   setModalType('error');
    //   setModalTitle('an error has occured');

    //   if (selectedLanguage === 'en') {
    //     setModalDescription('Submition Has Failed');
    //   } else {
    //     setModalTitle('שגיאה');
    //     setModalDescription('הבקשה נכשלה');
    //   }

    //   setIsModalClosed(false);
    //   setTimeout(() => {
    //     setIsModalClosed(true);
    //   }, 7000);
    // } finally {
    //   setShowAnimation(false);
    // }
  };

  return (
    <div id="ExamModalOverlay">
      <div id="endScreenPanel">
        <i
          id="closeModal"
          className="fa-solid fa-xmark"
          onClick={closeExamModal}
        ></i>
        <div id="examEndedData">
          <div id="examDetails">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examDetails}
            </p>
            <ul>
              {examData.time === '0' ? (
                <li dir="auto" id="timeLimit">
                  {languageFile.noTimeLimit}
                </li>
              ) : (
                <li dir="auto" id="timeLimit">
                  {languageFile.unitExamTitle}{' '}
                  {formatTime(parseInt(examData.timer, 10) * 60)}{' '}
                  {languageFile.minutes}
                </li>
              )}
              <li dir="auto" id="questionsCounter">
                {examData.ignored_answers_counter +
                  examData.incorrect_answers_counter +
                  examData.correct_answers_counter}{' '}
                {languageFile.questions}
              </li>
              <li dir="auto" id="questionsUnit">
                {languageFile.unit} {examData.unit_id}
              </li>
              <li dir="auto" id="questionsTopic">
                {languageFile.topic}: {translateTopic(examData.topic)}
              </li>
              <li dir="auto" id="questionsDateCompleted">
                {languageFile.submittedOn}{' '}
                {formatDate(Date.parse(examData.data_completed))}
              </li>
            </ul>
          </div>
          <div id="examProgress">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examResults}
            </p>
            <ul>
              <li dir="auto" id="timeStopper">
                {languageFile.passed}:{' '}
                {formatTime(parseInt(examData.stopper, 10))}
              </li>
              <li dir="auto" id="questionsCompleted">
                {examData.incorrect_answers_counter +
                  examData.correct_answers_counter}{' '}
                {languageFile.finishedQuestions}
              </li>
              <li dir="auto" id="questionsCompletedCorrect">
                {examData.correct_answers_counter}{' '}
                {languageFile.finishedQuestionsCorrect}
              </li>
              <li dir="auto" id="questionsCompletedIncorrect">
                {examData.incorrect_answers_counter}{' '}
                {languageFile.finishedQuestionsIncorrect}
              </li>
              <li dir="auto" id="questionsScore">
                {languageFile.examScore}: {examData.score}%
              </li>
            </ul>
          </div>
        </div>
        <p className="pause-menu-title" dir="auto">
          {languageFile.examQuestionsStatus}
        </p>
        <div className="questions-status-container">{questionsBody}</div>
        {allQuestionsLoaded && (
          <div className="exam-modal-buttons-div">
            <button className="btn btn-learn-the-unknown">
              {languageFile.LearnTheUnknown}
            </button>
            <button
              onClick={handleRetryExam}
              className="btn btn-retry-the-exam"
            >
              {languageFile.retryExam}
            </button>
          </div>
        )}
        <div id="animationScreen" className={`loading-animation-general`}>
          <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
        </div>
      </div>
    </div>
  );
}

export default ExamModal;
