import React, { useContext, useEffect, useState } from 'react';

import './header.css';
import { useGlobalContext } from '../../../context/AppContext';

function Header({ isSubscribed }) {
  const [isVisible, setIsVisible] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const featuresClicked = () => {
    const targetElement = document.getElementById('sectionFeatures');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <header className="header" id="header">
      <div className={`header-img img ${isVisible ? 'visible' : ''}`}>
        <img src="logo-no-background.png" alt="Milly" loading="lazy" />
      </div>
      <div className="header-banner">
        <h1 className={`text-fade-in-right ${isVisible ? 'visible' : ''}`}>
          {languageFile.headerTitle1}{' '}
          <span className="header-banner-specific">
            {languageFile.headerTitle2}{' '}
          </span>
          {languageFile.headerTitle3}
          <span className="header-banner-specific">
            {languageFile.headerTitle4}
          </span>{' '}
          {languageFile.headerTitle5}
        </h1>
      </div>
      <a
        className={`btn header-btn-join-now text-fade-in-right ${
          isVisible ? 'visible' : ''
        }`}
        href="/register"
      >
        {languageFile.headerJoinBtn}
      </a>
      <a
        className={`btn header-btn-login text-fade-in-right ${
          isVisible ? 'visible' : ''
        }`}
        onClick={featuresClicked}
      >
        {languageFile.headerLearnMore}
      </a>
    </header>
  );
}

export default Header;
