import React, { useContext, useState } from 'react';
import './StudyPauseScreen.css';
import { useGlobalContext } from '../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';

function StudyPauseScreen({
  updateLastWordId,
  setOpenPauseScreenStudy,
  studyCompletedWordsCounter,
  studyWordsCounter,
  unit,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');
  const navigate = useNavigate();

  const [buttonWorking, setButtonWorking] = useState(true);

  const returnStudyHandler = () => {
    if (buttonWorking) {
      setOpenPauseScreenStudy(false);
    }
  };

  const endStudyHandler = async () => {
    await updateLastWordId();
    navigate('/environment');
  };

  return (
    <div id="studyPauseScreen">
      <div id="studyScreenPanel">
        <h1 dir="auto">{languageFile.pauseStudy}</h1>
        <p className="pause-menu-title" dir="auto">
          {languageFile.studyDetails}
        </p>
        <ul>
          <li dir="auto" id="studyWordsCounter">
            {studyWordsCounter} {languageFile.questionsStudy}
          </li>
          <li dir="auto" id="questionsUnit">
            {languageFile.unit} {unit.substring(5)}
          </li>
        </ul>
        <p className="pause-menu-title" dir="auto">
          {languageFile.studyProgress}
        </p>
        <ul>
          <li dir="auto" id="questionsCompleted">
            {studyCompletedWordsCounter} {languageFile.finishedQuestionsStudy}
          </li>
        </ul>
        <button
          id="startExamStudy"
          onClick={returnStudyHandler}
          className={`btn btn-start-screen-study ${
            buttonWorking ? '' : 'gray-out-btn'
          }`}
        >
          {languageFile.returnStudy}
        </button>
        <button
          id="endExamStudy"
          onClick={endStudyHandler}
          className={`btn btn-end-exam ${buttonWorking ? '' : 'gray-out-btn'}`}
        >
          {languageFile.endStudy}
        </button>
        <p dir="auto" id="goodLuckText">
          {languageFile.goodLuckText}!
        </p>
      </div>
    </div>
  );
}

export default StudyPauseScreen;
