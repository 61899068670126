import React, { useContext, useState, useEffect } from 'react';
import './EndScreen.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  getExamSelectedClassificationJSONCookie,
  getQuestionsEndCookie,
  clearExamMode,
  getCurrectQuestionCookie,
  setExamStopper,
  setExamPauseCookie,
  setExamStartedCookie,
  getExamSelectedAnswerCheckJSONCookie,
  setExamSelectedClassificationJSONCookie,
  getTopicCookie,
  getStudyUnitCookie,
  getExamOriginalIDCookie,
} from '../../../../utils/cookieUtils';
import Classification from '../../Classification/Classification';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';

function EndScreen({
  setShowAnimation,
  examSelectedClassificationJSON,
  setExamSelectedClassificationJSON,
  examSelectedAnswerJSON,
  loadedAnswers,
  setLoadedAnswers,
  examAnswersResults,
  stopperInterval,
  stopperCookieInterval,
  findGetInArray,
  currentQuestionID,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  questionsCounterCorrectAnswers,
  questionsCounterIncorrectAnswers,
  questionsCounter,
  setOpenPauseScreen,
  setCurrentStopper,
  currentStopper,
  setRunStopper,
  setStartExam,
  examTime,
  finishedQuestionsCounter,
  unit,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  const storedTopic = getTopicCookie();
  const selectedUnit = getStudyUnitCookie();

  const endExamHandler = async (event) => {
    try {
      setShowAnimation(true);
      event.target.disabled = true;
      const response = await fetch(
        `/update-classification-counter/${storedTopic}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            unit_id: selectedUnit.substring(5),
            question_with_answers: JSON.stringify(
              getExamSelectedAnswerCheckJSONCookie()
            ),
            retried_original_exam_id: getExamOriginalIDCookie(),
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setModalInformation('');
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription('the exam was saved successfully');
        } else {
          setModalDescription('הבחינה נשמרה בהצלחה');
          setModalTitle('בהצלחה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
          clearExamMode();
          clearInterval(stopperInterval);
          clearInterval(stopperCookieInterval);
          setExamStopper(0);
          window.location = '/environment';
        }, 5000);
      }
    } catch (error) {
      event.target.disabled = false;
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  const [buttonWorking, setButtonWorking] = useState(true);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const [originalID, setOriginalID] = useState('');

  const handleClassificationClick = async (event) => {
    const clickedElement = event.target;
    let classificationID = '';
    let tempOriginalID = '';

    if (clickedElement.localName === 'i') {
      let tempChildList = clickedElement.parentNode.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    } else if (clickedElement.localName === 'li') {
      let tempChildList = clickedElement.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    }

    if (clickedElement.localName === 'i') {
      clickedElement.parentNode.classList.add('selected-classification');
      clickedElement.parentNode.classList.remove('grayed-out-classification');
      classificationID = clickedElement.parentNode.id;
      setOriginalID(clickedElement.parentNode.parentNode.parentNode.id);
      tempOriginalID = clickedElement.parentNode.parentNode.parentNode.id;
    } else if (clickedElement.localName === 'li') {
      clickedElement.classList.add('selected-classification');
      clickedElement.classList.remove('grayed-out-classification');
      classificationID = clickedElement.id;
      setOriginalID(clickedElement.parentNode.parentNode.id);
      tempOriginalID = clickedElement.parentNode.parentNode.id;
    }

    examSelectedClassificationJSON[tempOriginalID.substring(4)] =
      classificationID;
    setExamSelectedClassificationJSON(examSelectedClassificationJSON);
    setExamSelectedClassificationJSONCookie(examSelectedClassificationJSON);

    const questionsClass = 'btn-classification-emoji';

    const questionsCollectionDisabledClassification =
      document.getElementsByClassName(questionsClass);
    const questionsArrayDisabledClassifications = [
      ...questionsCollectionDisabledClassification,
    ];

    try {
      questionsArrayDisabledClassifications.forEach((element) => {
        element.classList.add('disabled-classification');
      });

      const response = await fetch('/word/memorize/psychometry', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question_id: tempOriginalID.substring(4),
          classification: classificationID,
        }),
      });
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
      console.error(error);
    } finally {
      questionsArrayDisabledClassifications.forEach((element) => {
        element.classList.remove('disabled-classification');
      });
    }
  };

  const findGetInArrayOriginal = (array, originalID) => {
    return array.find((json) => json.original_id === originalID);
  };

  const [questionsBody, setQuestionsBody] = useState([]);
  const findQuestionID = (array, originalID) => {
    const verify = array.find((json) => json.original_id === originalID);

    if (verify) return verify;
  };

  const renderList = () => {
    const items = [];
    Object.entries(examAnswersResults).map(([originalID, text], index) => {
      const questionID = findQuestionID(
        getCurrectQuestionCookie(),
        parseInt(originalID, 10)
      ).question_id;
      items.push(
        <div
          key={index}
          id={`c-q-${originalID}`}
          className={`questions-status questions-status-${index + 1} ${
            text[0] ? 'question-correct' : 'question-wrong'
          }`}
        >
          <h2
            dir="auto"
            className={`questions-status-header ${
              text[0] ? 'question-correct-p' : 'question-wrong-p'
            }`}
          >
            {languageFile.question} {index + 1}
          </h2>
          <h3 dir="auto" className="questions-status-question">
            {
              findGetInArrayOriginal(
                getCurrectQuestionCookie(),
                parseInt(originalID, 10)
              ).question
            }
          </h3>
          <div
            className={`chosen-answer ${
              selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
            }`}
          >
            <p dir="auto">{languageFile.chosenAnswer}:</p>
            <p dir="auto">{examSelectedAnswerJSON[originalID]}</p>
          </div>
          {!text[0] && (
            <div
              className={`chosen-answer ${
                selectedLanguage === 'he' ? 'reverse-chosen-answer' : ''
              }`}
            >
              <p dir="auto">{languageFile.correctAnswer}:</p>
              <p dir="auto">{text[1]}</p>
            </div>
          )}
          <Classification
            handleClassificationClick={handleClassificationClick}
          />
        </div>
      );
    });

    return items;
  };

  useEffect(() => {
    if (loadedAnswers) {
      setQuestionsBody(renderList());
      setPageLoaded(true);
    } else {
      if (examAnswersResults) {
        setLoadedAnswers(true);
      }
    }
  }, [loadedAnswers, examAnswersResults]);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (pageLoaded) {
      try {
        Object.entries(examSelectedClassificationJSON).forEach(
          ([key, value]) => {
            const parentDiv = document.getElementById(`c-q-${key}`);
            const elementWithClass = parentDiv.querySelector(
              '.classification-list'
            );
            const allChildren = Array.from(elementWithClass.children);

            allChildren.forEach((element) => {
              element.classList.remove('selected-classification');
              element.classList.add('grayed-out-classification');

              if (value === element.id) {
                element.classList.add('selected-classification');
                element.classList.remove('grayed-out-classification');
              }
            });
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [pageLoaded]);

  return (
    <div id="examEndScreen">
      <div id="endScreenPanel">
        <h1 dir="auto">{languageFile.theExamIsEnded}</h1>
        <div id="examEndedData">
          <div id="examDetails">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examDetails}
            </p>
            <ul>
              {examTime === '0' ? (
                <li dir="auto" id="timeLimit">
                  {languageFile.noTimeLimit}
                </li>
              ) : (
                <li dir="auto" id="timeLimit">
                  {languageFile.unitExamTitle} {examTime} {languageFile.minutes}
                </li>
              )}
              <li dir="auto" id="questionsCounter">
                {questionsCounter} {languageFile.questions}
              </li>
              <li dir="auto" id="questionsUnit">
                {languageFile.unit} {unit.substring(5)}
              </li>
            </ul>
          </div>
          <div id="examProgress">
            <p className="pause-menu-title" dir="auto">
              {languageFile.examResults}
            </p>
            <ul>
              <li dir="auto" id="timeStopper">
                {languageFile.passed}: {formatTime(currentStopper)}
              </li>
              <li dir="auto" id="questionsCompleted">
                {finishedQuestionsCounter} {languageFile.finishedQuestions}
              </li>
              <li dir="auto" id="questionsCompletedCorrect">
                {questionsCounterCorrectAnswers}{' '}
                {languageFile.finishedQuestionsCorrect}
              </li>
              <li dir="auto" id="questionsCompletedIncorrect">
                {questionsCounterIncorrectAnswers}{' '}
                {languageFile.finishedQuestionsIncorrect}
              </li>
              <li dir="auto" id="questionsScore">
                {languageFile.examScore}:{' '}
                {parseInt(
                  (questionsCounterCorrectAnswers / getQuestionsEndCookie()) *
                    100,
                  10
                )}
                %
              </li>
            </ul>
          </div>
        </div>
        <p className="pause-menu-title" dir="auto">
          {languageFile.examQuestionsStatus}
        </p>
        <div className="questions-status-container">
          {questionsBody}
          {loadedAnswers === false && (
            <div
              className={`animation-parent animation-parent-login`}
              id="loading-spinner-exam"
            >
              <div className={`animation animation-circle`}></div>
            </div>
          )}
        </div>
        <button
          id="endExamBtn"
          onClick={endExamHandler}
          className={`btn btn-start-screen btn-end-screen ${
            buttonWorking ? '' : 'gray-out-btn'
          }`}
        >
          {languageFile.endExamHome}
        </button>
      </div>
    </div>
  );
}

export default EndScreen;
