import React, { useEffect, useContext, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import './terms-of-service.css';
import ContactSection from '../HomePage/ContactSection/ContactSection';
import { useGlobalContext } from '../../context/AppContext';
import AlertModal from '../AlertModal/AlertModal';
import {
  getLoginCookie,
  verifySessionCookie,
  getSessionCookie,
} from '../../utils/cookieUtils';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';

function TermsOfService() {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const loggedUser = verifySessionCookie(getSessionCookie());

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Terms Of Service' : 'תנאי שימוש'
    }`; // Set the title to "Milly | Terms Of Service"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={loggedUser}
      />
      {selectedLanguage === 'en' ? (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">Terms of service</h1>
          <div className="document-contents">
            <h3>Introduction</h3>
            <p className="update-date"> Last updated: September 7, 2023</p>
            <p>
              Welcome to "www.MillyLearn.com", including its subdomains, owned
              and operated by Milly <br></br> (the "Company").
              <br></br>
              Our website primarily focuses on word learning through our web app
              (the "Site").<br></br>
              We also offer subscription Plans to access premium features and
              content, enhancing your learning experience (the "Plans").
            </p>

            <p>
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here.
            </p>
            <p>
              You must not use this Website if you disagree with any of these
              Website Standard Terms and Conditions.
            </p>

            <p>
              Minors or people below 18 years old are allowed to use this
              Website.
            </p>

            <h3>Intellectual Property Rights</h3>

            <p>
              Other than the content you own, under these Terms, the Company
              and/or its licensors own all the intellectual property rights and
              materials contained in this Website.
            </p>

            <p>
              You are granted limited license only for purposes of viewing the
              material contained on this Website.
            </p>

            <h3>Restrictions</h3>

            <p>You are specifically restricted from all of the following:</p>

            <ol>
              <li>publishing any Website material in any other media.</li>
              <li>
                selling, sublicensing and/or otherwise commercializing any
                Website material.
              </li>
              <li>publicly performing and/or showing any Website material.</li>
              <li>
                using this Website in any way that is or may be damaging to this
                Website.
              </li>
              <li>
                using this Website in any way that impacts user access to this
                Website.
              </li>
              <li>
                using this Website contrary to applicable laws and regulations,
                or in any way may cause harm to the Website,<br></br> or to any
                person or business entity.
              </li>
              <li>
                engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this Website.
              </li>
              <li>
                using this Website to engage in any advertising or marketing.
              </li>
            </ol>

            <p>
              Certain areas of this Website are restricted from being access by
              you and the Company may further restrict access by you to any
              areas of this Website, at any time, in absolute discretion.
              <br></br>
              Any user ID and password you may have for this Website are
              confidential and you must maintain confidentiality as well.
            </p>

            <h3>Your Content</h3>

            <p>
              In these Website Standard Terms and Conditions, "Your Content"
              shall mean any audio, video, text, images or other material you
              choose to display on this Website.
              <br></br>
              By displaying Your Content, you grant the Company a non-exclusive,
              worldwide irrevocable, sub licensable license to use, reproduce,
              adapt, publish, translate and distribute it in any and all media.
            </p>

            <p>
              Your Content must be your own and must not be invading any
              third-party's rights. The Company reserves the right to remove any
              of Your Content from this Website at any time without notice.
            </p>

            <h3>Privacy Policy</h3>

            <p>
              Certain website services and sections are exclusively available to
              subscribers and necessitate registration.<br></br>
              During registration, you'll need to provide specific personal
              details, including your name, address, contact information, email
              address, and personal preferences.
              <br></br>Mandatory fields will be clearly indicated.<br></br>
              Without completing these required fields, you won't be able to
              register as a subscriber and access the restricted services and
              sections.
            </p>
            <p>
              Some website services may require additional data, depending on
              the service's nature.<br></br>
              If your registration is approved, the information you provide
              during registration or for specific services will be stored in our
              database and handled in accordance with our privacy protection
              policy.<br></br>
              While you're not legally obligated to provide this information,
              it's essential for accessing services designed for registered
              users.
            </p>
            <p>
              The Company may decline registration applications at its
              discretion.<br></br>
              While using the website, data may be collected about your
              activities, purchased products or services, viewed content, and
              more, which will be stored in our database.<br></br>
              This data will be used in accordance with our privacy policy and
              applicable laws for the following purposes:
            </p>
            <ol>
              <li>Enabling your use of various website services.</li>
              <li>Creating personalized user areas on the site.</li>
              <li>Facilitating product and service purchases.</li>
              <li>
                Enhancing and customizing website services and content,
                including creating new offerings and gathering anonymous
                statistical data.
              </li>
              <li>Contacting registered subscribers and others as needed.</li>
              <li>
                Sending informational and marketing emails, with your consent.
              </li>
              <li>
                Utilizing contact information for analysis and statistics,
                without personal identification.
              </li>
              <li>
                Ensuring the proper operation and development of the site.
              </li>
              <li>
                Adapting ads based on your interests without identifying you
                personally.
              </li>
              <li>
                For any other purposes specified in our privacy policy or site
                terms.
              </li>
            </ol>
            <br></br>
            <p>
              The Company will not share your personally identifiable
              information and activity data from the website with third parties,
              except in the following cases:
            </p>
            <ol>
              <li>
                When purchasing products or services from the Company's
                partners, they will receive necessary information for
                transaction completion and communication.
              </li>
              <li>
                Upon receiving a court order to disclose your details or
                information.
              </li>
              <li>
                In legal disputes or claims involving you and the Company.
              </li>
              <li>
                In case of violations of website terms, agreements, or unlawful
                actions on or through the site.
              </li>
              <li>
                In the event of corporate reorganization, mergers, or changes in
                legal structure, where the new entity agrees to comply with this
                privacy policy.
              </li>
              <li>
                If necessary to prevent serious harm to you, third parties, or
                property.
              </li>
              <li>
                Transfer to affiliated companies or organizations, subject to
                this privacy policy.
              </li>
            </ol>

            <h3>No warranties</h3>

            <p>
              This Website is provided "as is", with all faults, and the Company
              express no representations or warranties, of any kind related to
              this Website or the materials contained on this Website. Also,
              nothing contained on this Website shall be interpreted as advising
              you.
            </p>

            <h3>Limitation of liability</h3>

            <p>
              In no event shall the Company, nor any of its officers, directors
              and employees, shall be held liable for anything arising out of or
              in any way connected with your use of this Website whether such
              liability is under contract. The Company, including its officers,
              directors and employees shall not be held liable for any indirect,
              consequential or special liability arising out of or in any way
              related to your use of this Website.
            </p>

            <h3>Indemnification</h3>

            <p>
              You hereby indemnify to the fullest extent the Company from and
              against any and/or all liabilities, costs, demands, causes of
              action, damages and expenses arising in any way related to your
              breach of any of the provisions of these Terms.
            </p>

            <h3>Severability</h3>

            <p>
              If any provision of these Terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>

            <h3>Variation of Terms</h3>

            <p>
              The Company is permitted to revise these Terms at any time as it
              sees fit, and by using this Website you are expected to review
              these Terms on a regular basis.
            </p>

            <h3>Assignment</h3>

            <p>
              The Company is allowed to assign, transfer, and subcontract its
              rights and/or obligations under these Terms without any
              notification. However, you are not allowed to assign, transfer, or
              subcontract any of your rights and/or obligations under these
              Terms.
            </p>

            <h3>Entire Agreement</h3>

            <p>
              These Terms constitute the entire agreement between the Company
              and you in relation to your use of this Website, and supersede all
              prior agreements and understandings.
            </p>

            <h3>Governing Law & Jurisdiction</h3>

            <p>
              These Terms will be governed by and interpreted in accordance with
              the laws of the State of Israel, and you submit to the
              non-exclusive jurisdiction of the state and federal courts located
              in af for the resolution of any disputes.
            </p>
          </div>
        </main>
      ) : (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">תנאי שימוש</h1>
          <div className="document-contents">
            <h3 dir="auto">הקדמה</h3>
            <p className="update-date" dir="auto">
              עודכן לאחרונה: 7 בספטמבר, 2023
            </p>
            <p dir="auto">
              תנאי השימוש באתר מנוסחים בלשון זכר אך האמור מתייחס לנשים וגברים
              כאחד.
              <br></br>
              ברוכים הבאים לאתר "www.MillyLearn.com" או לאחד מתתי הדומיין שלו
              (להלן "האתר"), המופעלים על ידי חברת Milly (להלן "החברה").
            </p>
            <p dir="auto">
              האתר שלנו מתמקד בעיקר בלמידת מילים דרך אפליקציית האינטרנט שלנו.
              בנוסף, אנו מציעים תוכניות למנויים המקנות גישה לתכונות פרימיום
              ותכנים מובחרים, תוך שיפור חווית הלמידה שלך (להלן "התוכניות").
            </p>
            <p dir="auto">
              תנאים אלה יחולו במלואם וישפיעו על השימוש שלך באתר זה. על ידי שימוש
              באתר זה, הסכמת לקבל את כל התנאים וההגבלות הכתובים כאן.
              <br></br>
              אין להשתמש באתר זה אם אינך מסכים עם אחד מהתנאים או ההגבלות של
              האתר.
              <br></br>
              קטינים או אנשים מתחת לגיל 18 רשאים להשתמש באתר.
            </p>

            <h3 dir="auto">זכויות קניין רוחני</h3>

            <p dir="auto">
              מלבד התוכן שבבעלותך, לפי תנאים אלה, החברה ו/או נותני הרישיונות שלה
              הם הבעלים של כל זכויות הקניין הרוחני והחומרים הכלול באתר זה.
            </p>

            <p dir="auto">
              ניתן לך רישיון מוגבל רק למטרות צפייה בתוכן הכלול באתר זה.
            </p>

            <h3 dir="auto">הגבלות</h3>

            <p dir="auto">אתה מוגבל במפורש מכל הדברים הבאים:</p>

            <ol>
              <li dir="auto">פרסום כל תוכן מהאתר בכל מדיה אחרת.</li>
              <li dir="auto">
                מכירה, רישוי משנה ו/או מסחור אחר של תוכן כלשהו מהאתר.
              </li>
              <li dir="auto">חשיפה ו/או הצגה בפומבי של תוכן כלשהו מהאתר.</li>
              <li dir="auto">
                שימוש באתר זה בכל דרך שהיא מזיקה או עשויה להזיק לאתר זה.
              </li>
              <li dir="auto">
                שימוש באתר זה בכל דרך המשפיעה על גישת המשתמש לאתר.
              </li>
              <li dir="auto">
                שימוש באתר זה בניגוד לחוקים ולתקנות החלים, או בכל דרך שהיא עלולה
                לגרום נזק לאתר, או לכל אדם או ישות עסקית.
              </li>
              <li dir="auto">
                עיסוק בכל כריית נתונים, איסוף נתונים, חילוץ נתונים או כל פעילות
                דומה אחרת ביחס לאתר זה.
              </li>
              <li dir="auto">שימוש באתר זה כדי לעסוק בכל פרסום או שיווק.</li>
            </ol>
            <br></br>
            <p dir="auto">
              אזורים מסוימים באתר זה מוגבלים לגישה שלך והחברה עשויה להגביל עוד
              יותר את הגישה שלך לכל תחום של האתר, בכל עת, לפי שיקול דעת מוחלט.
              <br></br>
              כל מזהה משתמש וסיסמה שיש לך לאתר זה חסויים וגם עליך לשמור אותם
              בסודיות.
            </p>

            <h3 dir="auto">התוכן שלך</h3>

            <p dir="auto">
              בתנאים וההגבלות האלה של האתר, "התוכן שלך" פירושו כל אודיו, וידאו,
              טקסט, תמונות או חומר אחר שתבחר להציג באתר זה.
            </p>
            <p dir="auto">
              על ידי הצגת התוכן שלך, אתה מעניק לחברה רישיון בינלאומי, לא בלעדי,
              הניתן להעברה ולמתן רישיונות משנה, לעשות שימוש, להעתיק, לערוך,
              לפרסם, לתרגם ולהפיץ אותו בכל מדיה. וזאת לצורך אספקת השירות וקיום
              תנאי שימוש אלה.
            </p>

            <p dir="auto">
              התוכן שלך חייב להיות שלך ואסור לו להפר את הזכויות של צד שלישי
              כלשהו. החברה שומרת לעצמה את הזכות להסיר כל חלק מהתוכן שלך מאתר זה
              בכל עת ללא הודעה מוקדמת.
            </p>

            <h3 dir="auto">מדיניות הגנת פרטיות</h3>

            <p dir="auto">
              שירותים ומדורים מסוימים באתר זמינים בלעדית למנויים ודורשים הרשמה.
              <br></br>שדות חובה יצוינו בבירור.<br></br>
              מבלי למלא את השדות הנדרשים הללו, לא תוכל להירשם כמנוי ולגשת
              לשירותים ולמדורים המוגבלים.
            </p>
            <p dir="auto">
              חלק משירותי האתר עשויים לדרוש נתונים נוספים, בהתאם לאופי השירות.
              <br></br>
              אם ההרשמה שלך תאושר, המידע שתספק במהלך ההרשמה או עבור שירותים
              ספציפיים יישמר במאגר המידע שלנו ויטופל בהתאם
              <a href="/privacy-policy" target="_blank" dir="auto">
                <strong> למדיניות הגנת הפרטיות שלנו</strong>
              </a>
              .<br></br>
              אמנם אינך מחויב על פי חוק לספק מידע זה, אך הוא חיוני לגישה
              לשירותים המיועדים למשתמשים רשומים.
            </p>
            <p dir="auto">
              החברה רשאית לדחות בקשות רישום לפי שיקול דעתה.<br></br>
              במהלך השימוש באתר, ייתכן שייאספו נתונים אודות פעילותך, מוצרים או
              שירותים שנרכשו, תכנים שנצפו ועוד, אשר יאוחסנו במאגר המידע שלנו.
              <br></br>
              הנתונים האלה ישמשו בהתאם{' '}
              <a href="/privacy-policy" target="_blank" dir="auto">
                <strong> למדיניות הגנת הפרטיות שלנו</strong>
              </a>{' '}
              ולחוקים החלים ולמטרות הבאות:
            </p>
            <p dir="auto">
              <ol dir="auto">
                <li>מתן אפשרות שימוש בשירותים שונים באתר.</li>
                <li>יצירת אזורי משתמש מותאמים אישית באתר.</li>
                <li>הקלה על רכישת מוצרים ושירותים.</li>
                <li>
                  שיפור והתאמה אישית של שירותים ותכנים באתר, כולל יצירת הצעות
                  חדשות ואיסוף נתונים סטטיסטיים אנונימיים.
                </li>
                <li>יצירת קשר עם מנויים רשומים ואחרים לפי הצורך.</li>
                <li>שליחת מיילים מידע ושיווקי, בהסכמתך.</li>
                <li>ניצול פרטי התקשרות לניתוח וסטטיסטיקה, ללא זיהוי אישי.</li>
                <li>הקפדה על תפעול ופיתוח תקין של האתר.</li>
                <li>
                  התאמת מודעות על סמך תחומי העניין שלך מבלי לזהות אותך אישית.
                </li>
                <li>
                  לכל מטרה אחרת המפורטת{' '}
                  <a href="/privacy-policy" target="_blank" dir="auto">
                    <strong> במדיניות הגנת הפרטיות </strong>
                  </a>{' '}
                  או בתנאי האתר שלנו.
                </li>
              </ol>
            </p>
            <br></br>
            <p dir="auto">
              החברה לא תשתף צדדים שלישיים במידע המאפשר זיהוי אישי ונתוני פעילות
              מהאתר, למעט במקרים הבאים:
            </p>
            <p dir="auto">
              <ol dir="auto">
                <li>
                  בעת רכישת מוצרים או שירותים משותפי החברה, הם יקבלו מידע הכרחי
                  לצורך השלמת העסקה ותקשורת.
                </li>
                <li>עם קבלת צו בית משפט לחשוף את פרטיך או המידע שלך.</li>
                <li>במחלוקות משפטיות או בתביעות הנוגעות לך ולחברה.</li>
                <li>
                  במקרה של הפרות של תנאי האתר, הסכמים או פעולות בלתי חוקיות באתר
                  או באמצעותו.
                </li>
                <li>
                  במקרה של ארגון מחדש של החברה, מיזוגים או שינויים במבנה המשפטי,
                  כאשר הישות החדשה מסכימה לציית למדיניות פרטיות זו.
                </li>
                <li>
                  במידת הצורך כדי למנוע נזק חמור לך, לצדדים שלישיים או לרכוש.
                </li>
                <li>
                  העברה לחברות או ארגונים קשורים, בכפוף למדיניות פרטיות זו.
                </li>
              </ol>
            </p>

            <h3 dir="auto">כתב התחייבות</h3>

            <p dir="auto">
              אתר זה מסופק "כפי שהוא", עם כל התקלות, אין החברה מביעה כל מצג או
              התחייבות, מכל סוג שהוא בכל הקשור לאתר זה או לחומרים הכלולים באתר.
              כמו כן, שום דבר באתר לא יתפרש כמייעץ לך.
            </p>

            <h3 dir="auto">הגבלת אחריות</h3>

            <p dir="auto">
              בשום מקרה החברה, ואף אחד מטעמה, הדירקטורים והעובדים בה, לא יישא
              באחריות לכל דבר הנובע או קשור בכל דרך שהיא לשימוש שלך באתר, בין אם
              חבות כזו היא במסגרת חוזה. החברה, לרבות נושאי המשרה, הדירקטורים
              והעובדים בה, לא תישא באחריות לכל אחריות עקיפה, תוצאתית או מיוחדת
              הנובעת או קשורה בכל דרך שהיא לשימוש שלך באתר זה.
            </p>

            <h3 dir="auto">שיפוי</h3>

            <p dir="auto">
              הנך משחרר בזאת את החברה במלואה מכל התחייבות, עלויות, דרישות, עילות
              תביעה, נזקים והוצאות הנובעות בכל דרך הקשורה להפרתך של כל אחד
              מההוראות ו/או תנאים אלה.
            </p>

            <h3 dir="auto">הפרדה</h3>

            <p dir="auto">
              בכל מקרה של סתירה ו/או אי התאמה, מכל סוג שהוא, בין תוכן האתר לבין
              הוראות תקנון זה, יגברו ויחולו הוראות התקנון.
            </p>

            <h3 dir="auto">שינויים בתנאים</h3>

            <p dir="auto">
              החברה רשאית לשנות תנאים אלה בכל עת לפי שיקול דעתה הבלעדי, ועל ידי
              שימוש באתר זה אתה צפוי לעיין בתנאים אלה על בסיס קבוע.
            </p>

            <h3 dir="auto">השמה</h3>

            <p dir="auto">
              החברה רשאית להמחות, להעביר, ולקבל בקבלנות משנה את זכויותיה ו/או
              התחייבויותיה לפי תנאים אלה ללא כל הודעה. עם זאת, אינך רשאי להמחות,
              להעביר או לקבל קבלן משנה של כל זכויות ו/או מחויבויות שלך על פי
              תנאים אלה.
            </p>

            <h3 dir="auto">ההסכם כולו</h3>

            <p dir="auto">
              תנאים אלה מהווים את ההסכם המלא בין החברה לבינך ביחס לשימושך באתר
              זה, ומחליפים את כל ההסכמות וההבנות הקודמות.
            </p>

            <h3 dir="auto">חוק וסמכות שיפוט</h3>

            <p dir="auto">
              בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם מסכימים
              להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק הישראלי,
              תוך שימוש במערכת בתי המשפט הישראליים בלבד, במחוז תל אביב.
            </p>
          </div>
        </main>
      )}

      <ContactSection
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
        id="sectionContact"
        className="section-contact"
      />
      {loggedUser ? (
        <LoggedFooter
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      ) : (
        <Footer
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      )}
    </React.Fragment>
  );
}

export default TermsOfService;
