import React, { useState, useEffect, useContext, useRef } from 'react';
import { useGlobalContext } from '../../../context/AppContext';
import {
  setFinishedQuestions,
  getStudyModeCookie,
  getStudyUnitCookie,
  getQuestionIdCookie,
  getMemorizationClassificationCookie,
  getCurrectQuestionCookie,
  getFinishedQuestions,
} from '../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import Classification from '../Classification/Classification';

function MemorizeMode({
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  showClassification,
  setShowClassification,
  finishedQuestions,
  currentQuestionID,
  setCurrentQuestionID,
  openProgressBar,
  setOpenProgressBar,
  handleOpenProgressBar,
  currentQuestions,
  currentQuestionTitle,
  setCurrentQuestionTitle,
  currentQuestionAnswers,
  setCurrentQuestionAnswers,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const currentUnit = getStudyUnitCookie();
  const learningMode = getStudyModeCookie();

  const [currentSelectedOptionAnswer, setCurrentSelectedOptionAnswer] =
    useState('');
  const [correctAnswerSelected, setCorrectAnswerSelected] = useState(false);

  const handleOptionClick = (event) => {
    if (!correctAnswerSelected) {
      const options_parent = event.target.parentNode.children;
      const clicked_element = event.target;
      const elementId = clicked_element.id;

      Array.from(options_parent).forEach((element) => {
        if (element.id !== elementId) element.classList.remove('selected');
      });

      clicked_element.classList.toggle('selected');
      setCurrentSelectedOptionAnswer(elementId);
    }
  };

  const handleClassificationClick = async (event) => {
    const clickedElement = event.target;
    let classificationID = '';

    const questionsClass = 'btn-classification-emoji';

    const questionsCollection = document.getElementsByClassName(questionsClass);
    const questionsArray = [...questionsCollection];

    questionsArray.forEach((element) => {
      element.classList.remove('selected-classification');
      // element.classList.add("grayed-out-classification");
    });

    if (clickedElement.localName === 'i') {
      clickedElement.parentNode.classList.add('selected-classification');
      // clickedElement.parentNode.classList.remove("grayed-out-classification");
      classificationID = clickedElement.parentNode.id;
    } else if (clickedElement.localName === 'li') {
      clickedElement.classList.add('selected-classification');
      // clickedElement.parentNode.classList.remove("grayed-out-classification");
      classificationID = clickedElement.id;
    }

    try {
      // const formData = new FormData();
      // formData.append("classification", classificationID);
      // formData.append("prev_classification", getMemorizationClassificationCookie());
      // formData.append("unit", currentUnit);
      const originalID = findGetInArray(
        getCurrectQuestionCookie(),
        currentQuestionID.substring(2)
      );
      const response = await fetch('/word/memorize/psychometry', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question_id: originalID.original_id,
          classification: classificationID,
        }),
      });
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        document.getElementById('pContinue').classList.add('visible');
        document
          .getElementById(currentQuestionID)
          .children[0].classList.add('done-question');

        const currentFinishedQuestions = getFinishedQuestions();
        currentFinishedQuestions.push(currentQuestionID);
        setFinishedQuestions(currentFinishedQuestions);
      }
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
      console.error(error);
    }
  };

  const findIndexInArray = (array, questionID) => {
    const newQuestionID = parseInt(questionID, 10);
    const element = array.find((json) => json.question_id === newQuestionID);
    const index = array.indexOf(element);
    return index;
  };

  const findGetInArray = (array, questionID) => {
    const newQuestionID = parseInt(questionID, 10);
    return array.find((json) => json.question_id === newQuestionID);
  };

  const handleVerifyQuestion = async () => {
    try {
      // const formData = new FormData();
      // formData.append("answer", document.getElementById(currentSelectedOptionAnswer).innerHTML);
      // formData.append("classification", getMemorizationClassificationCookie());
      // formData.append("unit", currentUnit);

      // const response = await fetch(`/word/psychometry/memorization/verify-answer/${currentQuestionID.substring(2)}`, {
      //     method: 'POST',
      //     body: formData
      // });
      const originalID = findGetInArray(
        getCurrectQuestionCookie(),
        currentQuestionID.substring(2)
      );

      const response = await fetch('/word/memorize/psychometry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON if needed
        },
        body: JSON.stringify({
          //   index_id: findIndexInArray(getCurrectQuestionCookie(), currentQuestionID.substring(2)),
          //   classification: getMemorizationClassificationCookie(),
          //   unit_id: currentUnit,
          answer: document.getElementById(currentSelectedOptionAnswer)
            .innerHTML,
          question_id: originalID.original_id,
          //   start_id: currentQuestionID.substring(2)
        }),
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      const responseData = await response.json();
      if (responseData.success === false) {
        document
          .getElementById(currentSelectedOptionAnswer)
          .classList.add('wrong-answer');
      } else {
        document
          .getElementById(currentSelectedOptionAnswer)
          .classList.remove('wrong-answer');
        document
          .getElementById(currentSelectedOptionAnswer)
          .classList.add('good-answer');
        setCorrectAnswerSelected(true);
        setTimeout(() => {
          setShowClassification(true);
        }, 100);
      }
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      document.getElementById('AnswerOption1').classList.remove('wrong-answer');
      document.getElementById('AnswerOption1').classList.remove('good-answer');
      document.getElementById('AnswerOption1').classList.remove('selected');

      document.getElementById('AnswerOption2').classList.remove('wrong-answer');
      document.getElementById('AnswerOption2').classList.remove('good-answer');
      document.getElementById('AnswerOption2').classList.remove('selected');

      document.getElementById('AnswerOption3').classList.remove('wrong-answer');
      document.getElementById('AnswerOption3').classList.remove('good-answer');
      document.getElementById('AnswerOption3').classList.remove('selected');

      setCorrectAnswerSelected(false);
      //   setShowClassification(false);
      document.getElementById('pContinue').classList.remove('visible');
    }
  }, [currentQuestionTitle]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <React.Fragment>
      <div className="open-progress-btn">
        <i
          className="fa-solid fa-list-check"
          onClick={handleOpenProgressBar}
        ></i>
      </div>
      <div id="study-container" className="study-container">
        {/* <div onClick={handleNextQuestion} className='study-arrows-container'><i id="btnNext" className="fa-solid fa-arrow-left study-arrows"></i></div> */}
        <div className="study-container-main">
          {currentQuestionID && (
            <React.Fragment>
              <h1 dir="auto" className="study-word-title" id="wordTarget">
                {currentQuestionTitle}
              </h1>
              <ul className="question-option-list">
                <li
                  onClick={handleOptionClick}
                  id="AnswerOption1"
                  className="question-option question-option-top"
                >
                  {currentQuestionAnswers[0]}
                </li>
                <li
                  onClick={handleOptionClick}
                  id="AnswerOption2"
                  className="question-option question-option-middle"
                >
                  {currentQuestionAnswers[1]}
                </li>
                <li
                  onClick={handleOptionClick}
                  id="AnswerOption3"
                  className="question-option question-option-end"
                >
                  {currentQuestionAnswers[2]}
                </li>
              </ul>
            </React.Fragment>
          )}

          {showClassification ? (
            <Classification
              handleClassificationClick={handleClassificationClick}
            />
          ) : (
            <button
              onClick={handleVerifyQuestion}
              className="btn btn-next-question"
            >
              {languageFile.checkAnswer}
            </button>
          )}
          <p dir="auto" id="pContinue" className={`p-continue`}>
            {languageFile.youCanContinue}..
          </p>
        </div>
        {/* <div className='study-arrows-container'><i id="btnBack" className="fa-solid fa-arrow-right study-arrows"></i></div> */}
      </div>
    </React.Fragment>
  );
}

export default MemorizeMode;
