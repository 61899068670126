import React from 'react';
import AppRouter from '../AppRouter';
import { IntlProvider } from 'react-intl';
import AppContext from '../context/AppContext';

function App() {
  return (
    <React.StrictMode>
      {/* change the strict mode to profiler and at production to fragment */}
      <AppContext>
        <IntlProvider locale="he">
          <AppRouter />
        </IntlProvider>
      </AppContext>
    </React.StrictMode>
  );
}

export default App;
