import React, { useContext, useState, useEffect } from 'react';
import './navBar.css';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useGlobalContext } from '../../context/AppContext';
import { setPageCookie } from '../../utils/cookieUtils';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AlertModal from '../AlertModal/AlertModal';

function NavBar({
  classAdditional,
  isOpen,
  setIsOpen,
  toggleAside,
  loggedUser,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [homeActive, setHomeActive] = useState(
    window.location.pathname === '/' || window.location.hash === '#'
  );
  const [featuresActive, setFeaturesActive] = useState(
    window.location.hash === '#sectionFeatures'
  );
  const [productsActive, setProductsActive] = useState(
    window.location.hash === '#sectionProducts'
  );
  const [faqActive, setFaqActive] = useState(
    window.location.hash === '#sectionFaq'
  );
  const [contactActive, setContactActive] = useState(
    window.location.hash === '#sectionContact'
  );
  const [navStatus, setNavStatus] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [environmentActive, setEnvironmentActive] = useState(
    window.location.pathname === '/environment' ||
      window.location.pathname === '/Environment'
  );
  const [myWordsActive, setMyWordsActive] = useState(
    window.location.pathname === '/progress'
  );
  const [analyticsActive, setAnalyticsActive] = useState(
    window.location.pathname === '/analytics'
  );
  const [cookiesTutorial, setCookiesTutorial] = useState(
    window.location.pathname === '/tutorial'
  );
  const [feedbackActive, setFeedbackActive] = useState(
    window.location.pathname === '/feedback'
  );

  const homeClicked = () => {
    const targetElement = document.getElementById('header');
    if (targetElement) {
      setHomeActive(true);
      setFeaturesActive(false);
      setProductsActive(false);
      setFaqActive(false);
      setContactActive(false);

      setIsOpen(false);
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const featuresClicked = () => {
    const targetElement = document.getElementById('sectionFeatures');
    if (targetElement) {
      setHomeActive(false);
      setFeaturesActive(true);
      setProductsActive(false);
      setFaqActive(false);
      setContactActive(false);

      setIsOpen(false);
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setPageCookie('sectionFeatures');
      window.location = '/';
    }
  };

  const productsClicked = () => {
    const targetElement = document.getElementById('sectionProducts');

    if (targetElement) {
      setHomeActive(false);
      setFeaturesActive(false);
      setProductsActive(true);
      setFaqActive(false);
      setContactActive(false);

      setIsOpen(false);
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setPageCookie('sectionProducts');
      window.location = '/';
    }
  };

  const faqClicked = () => {
    const targetElement = document.getElementById('sectionFaq');

    if (targetElement) {
      setHomeActive(false);
      setFeaturesActive(false);
      setProductsActive(false);
      setFaqActive(true);
      setContactActive(false);

      setIsOpen(false);
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setPageCookie('sectionFaq');
      window.location = '/';
    }
  };

  const contactClicked = () => {
    const targetElement = document.getElementById('sectionContact');

    if (targetElement) {
      setHomeActive(false);
      setFeaturesActive(false);
      setProductsActive(false);
      setFaqActive(false);
      setContactActive(true);

      setIsOpen(false);
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setPageCookie('sectionContact');
      window.location = '/';
    }
  };

  // window.addEventListener('scroll', () => {
  //     const currentScrollY = window.scrollY;

  //     if (currentScrollY > lastScrollY + 150) {
  //         setNavStatus(false);
  //         setIsOpen(false);
  //     } else {
  //         setNavStatus(true);
  //     }

  //     setLastScrollY(currentScrollY);
  // });

  const handleLogout = async () => {
    try {
      const response = await fetch('/logout', { method: 'POST' });
      if (!response.ok) {
        throw new Error('error');
      }

      const result = await response.json();
      if (result.success) window.location = '/';
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'he') {
        setModalTitle('שגיאה');
        setModalDescription('שגיאה');
      } else {
        setModalDescription('Error');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  if (!loggedUser) {
    // Guest
    return (
      <React.Fragment>
        <AlertModal
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          messageType={modalType}
          title={modalTitle}
          description={modalDescription}
          information={modalInformation}
        />
        <nav
          id="nav"
          className={`nav ${navStatus ? 'show-nav' : 'hide-nav'} ${
            classAdditional !== '' ? classAdditional : ''
          }`}
        >
          <div className="navbar-section navbar-brand">
            <div className="img navbar-img">
              <a href="/">
                <img loading="lazy" src="logo-no-background.png" alt="Milly" />
                {/* <span className="navbar-img-label">Milly</span> */}
              </a>
            </div>
          </div>
          <div className="navbar-section navbar-list">
            <ul
              className={`navbar-list-items ${
                selectedLanguage === 'he' ? 'navbar-list-items-reverse' : ''
              }`}
            >
              <li className={`nav-item ${homeActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${homeActive ? 'active' : ''}`}
                  href="/"
                  onClick={homeClicked}
                >
                  {languageFile.navbarHome}
                </a>
              </li>
              <li className={`nav-item ${featuresActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${featuresActive ? 'active' : ''}`}
                  onClick={featuresClicked}
                >
                  {languageFile.navbarFeatures}
                </a>
              </li>
              <li className={`nav-item ${productsActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${productsActive ? 'active' : ''}`}
                  onClick={productsClicked}
                >
                  {languageFile.navbarProducts}
                </a>
              </li>
              <li className={`nav-item ${faqActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${faqActive ? 'active' : ''}`}
                  onClick={faqClicked}
                >
                  {languageFile.navbarFaq}
                </a>
              </li>
              <li className={`nav-item ${contactActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${contactActive ? 'active' : ''}`}
                  onClick={contactClicked}
                >
                  {languageFile.navbarContact}
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-section navbar-toggle">
            <LanguageSelector id="languageSelect2" />
            {selectedLanguage === 'en' ? (
              <i className="fa-solid language-class language-class-usa"></i>
            ) : (
              <i className="fa-solid language-class language-class-israel"></i>
            )}
            <div className="btn-login-cnt2">
              <a href="/login" className="btn btn-login">
                {languageFile.loginNow} <i className="fa-regular fa-user"></i>
              </a>
            </div>
            <button
              id="navbarToggle"
              className="navbar-toggler"
              type="button"
              onClick={toggleAside}
            >
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
          </div>
        </nav>
        <aside
          className={`sidebar ${isOpen ? 'show-aside' : 'hide-aside'} ${
            classAdditional !== '' ? classAdditional : ''
          }`}
          id="sidebar"
        >
          <ul
            className={`navbar-toggle-items ${
              selectedLanguage === 'he' ? 'navbar-list-items-reverse' : ''
            }`}
          >
            <li className={`nav-item aside-item ${homeActive ? 'active' : ''}`}>
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/"
                onClick={homeClicked}
              >
                {languageFile.navbarHome}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${
                featuresActive ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                onClick={featuresClicked}
              >
                {languageFile.navbarFeatures}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${
                productsActive ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                onClick={productsClicked}
              >
                {languageFile.navbarProducts}
              </a>
            </li>
            <li className={`nav-item aside-item ${faqActive ? 'active' : ''}`}>
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                onClick={faqClicked}
              >
                {languageFile.navbarFaq}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${contactActive ? 'active' : ''}`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                onClick={contactClicked}
              >
                {languageFile.navbarContact}
              </a>
            </li>
            <div className="btn-login-cnt">
              <a href="/login" className="btn btn-login">
                {languageFile.loginNow} <i className="fa-regular fa-user"></i>
              </a>
            </div>
            <LanguageSelector id="languageSelect" />
          </ul>
        </aside>
      </React.Fragment>
    );
  } else {
    // Logged in user
    return (
      <React.Fragment>
        <AlertModal
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          messageType={modalType}
          title={modalTitle}
          description={modalDescription}
          information={modalInformation}
        />
        <nav id="nav2" className={`nav`}>
          <div className="navbar-section navbar-brand">
            <div className="img navbar-img">
              <a href="/">
                <img loading="lazy" src="logo-no-background.png" alt="Milly" />
                {/* <span className="navbar-img-label">Milly</span> */}
              </a>
            </div>
          </div>
          <div className="navbar-section navbar-list">
            <ul
              className={`navbar-list-items ${
                selectedLanguage === 'he' ? 'navbar-list-items-reverse' : ''
              }`}
            >
              <li className={`nav-item ${environmentActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${environmentActive ? 'active' : ''}`}
                  href="/environment"
                >
                  {languageFile.navbarHome}
                </a>
              </li>
              <li className={`nav-item ${myWordsActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${myWordsActive ? 'active' : ''}`}
                  href="/progress"
                >
                  {languageFile.navbarMyProgress}
                </a>
              </li>
              <li className={`nav-item ${analyticsActive ? 'active' : ''}`}>
                <a
                  className={`nav-link gray-out ${
                    analyticsActive ? 'active' : ''
                  }`}
                  href="/analytics"
                >
                  {languageFile.navbarAnalytics}
                </a>
              </li>
              <li className={`nav-item ${cookiesTutorial ? 'active' : ''}`}>
                <a
                  className={`nav-link ${cookiesTutorial ? 'active' : ''}`}
                  href="/tutorial"
                >
                  {languageFile.navbarTutorial}
                </a>
              </li>
              <li className={`nav-item ${feedbackActive ? 'active' : ''}`}>
                <a
                  className={`nav-link ${feedbackActive ? 'active' : ''}`}
                  href="/feedback"
                >
                  {languageFile.navbarContact}
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-section navbar-toggle">
            <LanguageSelector id="languageSelect2" />
            {selectedLanguage === 'en' ? (
              <i className="fa-solid language-class language-class-usa"></i>
            ) : (
              <i className="fa-solid language-class language-class-israel"></i>
            )}
            <div className="btn-login-cnt2">
              {/* <a href='/login' className='btn btn-account'>{languageFile.profile} </a> */}
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-variants-profile`}
                title={
                  <span
                    className={`dropdown-menu-custom-navbar ${
                      selectedLanguage === 'en' &&
                      'dropdown-menu-custom-navbar-reverse'
                    }`}
                  >
                    {languageFile.profile}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                }
              >
                <Dropdown.Item eventKey="1" href="/profile">
                  {languageFile.profileAnother}
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" href="/settings">
                  {languageFile.settings}
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" href="/feedback">
                  {languageFile.contactTitle}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4" onClick={handleLogout}>
                  {languageFile.logout}
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <button
              id="navbarToggle"
              className="navbar-toggler"
              type="button"
              onClick={toggleAside}
            >
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
          </div>
        </nav>
        <aside
          className={`sidebar sidebar-2 ${
            isOpen ? 'show-aside' : 'hide-aside'
          } ${classAdditional !== '' ? classAdditional : ''}`}
          id="sidebar"
        >
          <ul
            className={`navbar-toggle-items ${
              selectedLanguage === 'he' ? 'navbar-list-items-reverse' : ''
            }`}
          >
            <li
              className={`nav-item aside-item ${
                environmentActive ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/environment"
              >
                {languageFile.navbarHome}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${myWordsActive ? 'active' : ''}`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/progress"
                target="_blank"
              >
                {languageFile.navbarMyProgress}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${
                analyticsActive ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link gray-out ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/analytics"
                target="_blank"
              >
                {languageFile.navbarAnalytics}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${
                cookiesTutorial ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/tutorial"
                target="_blank"
              >
                {languageFile.navbarTutorial}
              </a>
            </li>
            <li
              className={`nav-item aside-item ${
                feedbackActive ? 'active' : ''
              }`}
            >
              <a
                className={`nav-link aside-link ${
                  selectedLanguage === 'he' ? 'aside-list-items-reverse' : ''
                }`}
                href="/feedback"
              >
                {languageFile.navbarContact}
              </a>
            </li>
            <div className="btn-login-cnt">
              {/* <a href='/login' className='btn btn-account'>{languageFile.profile} <i className="fa-solid fa-user"></i></a> */}
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-variants-profile`}
                title={
                  <span
                    className={`dropdown-menu-custom-navbar ${
                      selectedLanguage === 'en' &&
                      'dropdown-menu-custom-navbar-reverse'
                    }`}
                  >
                    {languageFile.profile}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                }
              >
                <Dropdown.Item eventKey="1" href="/profile">
                  {languageFile.profileAnother}
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" href="/settings">
                  {languageFile.settings}
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" href="/feedback">
                  {languageFile.contactTitle}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4" onClick={handleLogout}>
                  {languageFile.logout}
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <LanguageSelector id="languageSelect" />
          </ul>
        </aside>
      </React.Fragment>
    );
  }
}

export default NavBar;
