import React, { useContext } from 'react';
import './ExamTimeLimits.css';
import { useGlobalContext } from '../../../context/AppContext';
import {
  setStudyExamTime,
  setMemorizationClassificationCookie,
  setCurrentTutorialStepCookie,
} from '../../../utils/cookieUtils';

function ExamTimeLimits({
  setStepIndex,
  stepIndex,
  showExamTimeLimits,
  setShowExamTimeLimits,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const closeModal = () => {
    setShowExamTimeLimits(!showExamTimeLimits);
  };

  const handleMemorizationClassificationClick = (event) => {
    setStepIndex(parseInt(stepIndex, 10) === 3 ? 4 : stepIndex);
    setCurrentTutorialStepCookie(parseInt(stepIndex, 10) === 3 ? 4 : stepIndex);

    let targetValue = event.target.value;
    setStudyExamTime(targetValue);
    window.location = '/platform';
  };

  return (
    <div
      id="UnitExamModal"
      className={`exam-time-limits alert-modal fade-in-bottom-alert ${
        showExamTimeLimits ? 'visible' : ''
      }`}
    >
      <i
        id="closeExamModal"
        className="fa-solid fa-arrow-rotate-left"
        onClick={closeModal}
      ></i>
      <p className="unit-title">{languageFile.unitExamTitle}</p>
      <ul className="unit-options-list unit-options-list-times">
        <li
          dir="auto"
          id="examTimeLimit0"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
          value="0"
        >
          {languageFile.noLimit}
        </li>
        <li
          dir="auto"
          id="examTimeLimit45"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
          value="45"
        >
          45 {languageFile.minutes}
        </li>
        <li
          dir="auto"
          id="examTimeLimit90"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
          value="90"
        >
          90 {languageFile.minutes}
        </li>
      </ul>
    </div>
  );
}

export default ExamTimeLimits;
