import React, {
  useState,
  useEffect,
  useContext,
  useSyncExternalStore,
} from 'react';
import './exams.css';
import { useGlobalContext } from '../../../context/AppContext';
import HashLoader from 'react-spinners/HashLoader';
import ExamModal from './ExamModal/ExamModal';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import {
  getLastExamLoadedID,
  getStudyUnitCookie,
  getTopicCookie,
} from '../../../utils/cookieUtils';
import SortExams from './SortExams/SortExams';
import moment from 'moment';
import FilterExams from './FilterExams/FilterExams';
import ExamSplitModal from './ExamSplitModal/ExamSplitModal';

function Exams({
  showCompareButton,
  setShowCompareButton,
  resetCompare,
  setResetCompare,
  pageLoaded,
  showAnimation,
  setShowAnimation,
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [showExamModal, setShowExamModal] = useState(false);
  const [showExamSplitModal, setShowExamSplitModal] = useState(false);
  const [examBody, setExamBody] = useState([]);
  const [loadedExams, setLoadedExams] = useState([]);
  const storedTopic = getTopicCookie();
  const selectedUnit = getStudyUnitCookie();
  const [lastLoadedExamID, setLastLoadedExamID] = useState(
    getLastExamLoadedID()
  );
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [examsLoaded, setExamsLoaded] = useState(false);
  const [examData, setExamData] = useState({});
  const [examID, setExamID] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // asc, desc
  const [sortData, setSortData] = useState('date'); // date, score, unit
  const [filterData, setFilterData] = useState(''); // date, score, unit
  const [firstSort, setFirstSort] = useState(true);
  const [chosenTopic, setChosenTopic] = useState('');
  const [questionsBody, setQuestionsBody] = useState([]);
  const [loadedExamQuestions, setLoadedExamQuestions] = useState([]);

  let examsAddedForComparisonCounter = 0;
  let examsAddedForComparison = [];

  const [
    examsAddedForComparisonCounterState,
    setExamsAddedForComparisonCounterState,
  ] = useState(examsAddedForComparisonCounter);
  const [examsAddedForComparisonState, setExamsAddedForComparisonState] =
    useState(examsAddedForComparison);

  useState(() => {
    if (resetCompare) {
      examsAddedForComparisonCounter = 0;
      examsAddedForComparison = [];
      setResetCompare(false);
    }
  }, [resetCompare]);

  let loadedExamsTemp = [];

  const fetchExams = async (
    specFilterData,
    specSortDirection,
    load_more_exams
  ) => {
    try {
      setShowAnimation(true);
      const response = await fetch(
        `/load-exams/${storedTopic}?id=${lastLoadedExamID}&filtered_data=${specFilterData}&sort_direction=${specSortDirection}&load_exams=${load_more_exams}`,
        {
          method: 'GET',
        }
      );

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        if (responseData.data === 'failed') {
          setExamsLoaded(false);
        } else {
          const newExams = responseData.data;
          if (load_more_exams) {
            const newArray = loadedExams.concat(newExams);

            if (firstSort) {
              const sortedData = [...newArray].sort((a, b) => {
                const dateA = moment(a.data_completed);
                const dateB = moment(b.data_completed);

                return dateA.diff(dateB);
              });

              setLoadedExams(sortedData);
              setLastLoadedExamID(
                sortedData.length === 0 ? 1 : sortedData.length + 1
              );
              loadedExamsTemp = sortedData;

              // setLoadedExams(newArray);
              // setLastLoadedExamID(newArray.length === 0 ? 1 : newArray.length + 1);
              // loadedExamsTemp = newArray;

              setFirstSort(false);
            } else {
              const sortedData = [...newArray].sort((a, b) => {
                if (sortData === 'date') {
                  const dateA = moment(a.data_completed);
                  const dateB = moment(b.data_completed);

                  if (sortDirection === 'asc') return dateA.diff(dateB);
                  else if (sortDirection === 'desc') return dateB.diff(dateA);
                } else if (sortData === 'score') {
                  a.score = parseInt(a.score, 10);
                  b.score = parseInt(b.score, 10);

                  if (sortDirection === 'asc') return a.score - b.score;
                  else if (sortDirection === 'desc') return b.score - a.score;
                } else if (sortData === 'exam_id') {
                  a.exam_id = parseInt(a.exam_id, 10);
                  b.exam_id = parseInt(b.exam_id, 10);

                  if (sortDirection === 'asc') return a.exam_id - b.exam_id;
                  else if (sortDirection === 'desc')
                    return b.exam_id - a.exam_id;
                } else if (sortData === 'unit_id') {
                  a.unit_id = parseInt(a.unit_id, 10);
                  b.unit_id = parseInt(b.unit_id, 10);

                  if (sortDirection === 'asc') return a.unit_id - b.unit_id;
                  else if (sortDirection === 'desc')
                    return b.unit_id - a.unit_id;
                }
              });

              setLoadedExams(sortedData);
              setLastLoadedExamID(
                sortedData.length === 0 ? 1 : sortedData.length + 1
              );
              loadedExamsTemp = sortedData;

              // setLoadedExams(newArray);
              // setLastLoadedExamID(newArray.length === 0 ? 1 : newArray.length + 1);
              // loadedExamsTemp = newArray;
            }
          }

          setInitialLoaded(true);
          setExamsLoaded(true);

          setExamBody(renderList());
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
      examsAddedForComparisonCounter = examsAddedForComparisonCounterState;
      examsAddedForComparison = examsAddedForComparisonState;
    }
  };

  useEffect(() => {
    if (pageLoaded) {
      setInitialLoaded(true);
      fetchExams('', sortDirection, true).then(() => {
        // setExamBody(renderList());
        setShowAnimation(false);
      });
    }
  }, [pageLoaded]);

  const handleCompareClicked = (event) => {
    const element = event.target;
    if (!element.classList.contains('selected')) {
      if (examsAddedForComparisonCounter < 2) {
        const selectedExamID = parseInt(
          element.parentNode.parentNode.id.substring(5),
          10
        );

        element.classList.add('selected');
        examsAddedForComparisonCounter++;
        examsAddedForComparison.push(selectedExamID);
      }
    } else {
      if (examsAddedForComparisonCounter > 0) {
        const selectedExamID = parseInt(
          element.parentNode.parentNode.id.substring(5),
          10
        );

        element.classList.remove('selected');
        examsAddedForComparisonCounter--;
        examsAddedForComparison = examsAddedForComparison.filter(
          (item) => item !== selectedExamID
        );
      }
    }

    if (examsAddedForComparisonCounter === 2) {
      setShowCompareButton(true);
    } else {
      setShowCompareButton(false);
    }

    handleCompareClickedCallback(
      examsAddedForComparisonCounter,
      examsAddedForComparison
    );
  };

  const handleCompareClickedCallback = (
    examsAddedForComparisonCounter,
    examsAddedForComparison
  ) => {
    setExamsAddedForComparisonCounterState(examsAddedForComparisonCounter);
    setExamsAddedForComparisonState(examsAddedForComparison);
  };

  const renderList = () => {
    const items = [];
    let tempTimer = '-';
    loadedExamsTemp.forEach((element) => {
      items.push(
        <div
          key={element.exam_id}
          id={`exam-${element.exam_id}`}
          className="mainExams-exam"
        >
          <div className="mainExams-exam-number">
            <span dir="auto">{element.exam_id}</span>
          </div>
          <div class="mainExams-exan-top-parent">
            <div className="mainExams-exam-date-parent">
              <p
                dir="auto"
                className={`mainExams-exam-date ${
                  selectedLanguage === 'en' ? 'exam-ltr' : 'exam-rtl'
                }`}
              >
                <i className="fa-solid fa-calendar-days"></i>{' '}
                {formatDate(Date.parse(element.data_completed))}
              </p>
            </div>
            <div className="mainExams-exam-unit2-parent">
              <p dir="auto" className="mainExams-exam-unit2">
                <i className="fa-solid fa-list-check"></i> {languageFile.unit}{' '}
                {element.unit_id}
              </p>
            </div>
            <div className="mainExams-exam-topic-parent">
              <p dir="auto" className="mainExams-exam-score">
                <i className="fa-solid fa-bullseye"></i>{' '}
                {languageFile.examScoreAgain} {element.score}
              </p>
            </div>
          </div>
          <div className="compare-exams-icon">
            <i
              onClick={handleCompareClicked}
              className={`fa-solid fa-code-compare ${
                examsAddedForComparison.includes(parseInt(element.exam_id, 10))
                  ? 'selected'
                  : ''
              }`}
            ></i>
          </div>
          <div className="mainExams-exam-top">
            <p dir="auto" className="mainExams-exam-topic">
              <i className="fa-solid fa-graduation-cap"></i>{' '}
              {translateTopic(element.topic)}
            </p>
          </div>
          <div className="mainExams-exam-center">
            <p dir="auto" className="mainExams-exam-timer-short-answers">
              <span dir="auto" className="short-answers-correct">
                <i className="fa-solid fa-circle-check"></i>{' '}
                {element.correct_answers_counter}{' '}
                <span className="short-answers-sep">/</span>{' '}
              </span>
              <span dir="auto" className="short-answers-incorrect">
                <i className="fa-solid fa-circle-xmark"></i>{' '}
                {element.incorrect_answers_counter}{' '}
                <span className="short-answers-sep">/</span>{' '}
              </span>
              <span dir="auto" className="short-answers-ignored">
                <i className="fa-solid fa-circle-question"></i>{' '}
                {element.ignored_answers_counter}
              </span>
            </p>
          </div>
          <div className="mainExams-exam-bottom">
            {!parseInt(element.timer, 10) * 60 === 0 ? (
              <p
                className={`mainExams-exam-timer-stopper ${
                  selectedLanguage === 'en' ? 'exam-ltr' : 'exam-rtl'
                }`}
              >
                <i className="fa-solid fa-clock"></i>{' '}
                {formatTime(parseInt(element.stopper, 10))} /{' '}
                {formatTime(parseInt(element.timer, 10) * 60)}
              </p>
            ) : (
              <p
                className={`mainExams-exam-timer-stopper ${
                  selectedLanguage === 'en' ? 'exam-ltr' : 'exam-rtl'
                }`}
              >
                <i className="fa-solid fa-clock"></i>{' '}
                {formatTime(parseInt(element.stopper, 10))} / -
              </p>
            )}
          </div>
          <button
            onClick={handleOpenExam}
            dir="auto"
            className="btn mainExams-open-btn"
          >
            <i className="fa-solid fa-list-check"></i> {languageFile.watch}
          </button>
        </div>
      );
    });

    setLoadedExams(loadedExamsTemp);

    return items;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const translateTopic = (english) => {
    if (selectedLanguage !== 'en') {
      const translatedTopic = fetchResponseHandler(english);
      if (translatedTopic && translatedTopic !== 'הבקשה נכשלה')
        return translatedTopic;
    }
    return english;
  };

  const handleLoadMoreButton = async (event) => {
    const examButtons = [
      ...document.getElementsByClassName('mainExams-open-btn'),
    ];
    examButtons.forEach((element) => {
      element.disabled = true;
    });
    event.target.disabled = true;
    setShowAnimation(true);

    fetchExams('', sortDirection, true).then(() => {
      // setExamBody(renderList());
      setShowAnimation(false);
      examButtons.forEach((element) => {
        element.disabled = false;
      });
      event.target.disabled = false;
    });
  };

  const handleOpenExam = (element) => {
    const examButtons = [
      ...document.getElementsByClassName('mainExams-open-btn'),
    ];
    examButtons.forEach((element) => {
      element.disabled = true;
    });
    document.getElementsByClassName('mainExams-more-btn')[0].disabled = true;
    setShowAnimation(true);

    const examID = parseInt(element.target.parentNode.id.substring(5), 10);
    setExamID(examID);
    loadedExamsTemp.forEach((element) => {
      if (element.exam_id === examID) {
        setExamData(element);
        setChosenTopic(element.topic);
      }
    });

    setShowAnimation(false);
    examButtons.forEach((element) => {
      element.disabled = false;
    });
    document.getElementsByClassName('mainExams-more-btn')[0].disabled = false;
    setShowExamModal(true);
  };

  let loadedExamQuestionsTemp = [];

  let tempExamData = {};
  let temp_topic = '';

  const handleCompareExamsClick = async () => {
    // TODO: compare between the two exams
    //TODO: send a request to get the exam data for each exam
    const examButtons = [
      ...document.getElementsByClassName('mainExams-open-btn'),
    ];
    examButtons.forEach((element) => {
      element.disabled = true;
    });
    document.getElementsByClassName('mainExams-more-btn')[0].disabled = true;
    setShowAnimation(true);

    setExamID(examsAddedForComparisonState);

    loadedExams.forEach((element) => {
      if (
        examsAddedForComparisonState.includes(parseInt(element.exam_id, 10))
      ) {
        tempExamData[parseInt(element.exam_id, 10)] = element;
        if (temp_topic !== element.topic && temp_topic !== '') {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription('Please select exams from the same topic');
          } else {
            setModalDescription('אנא תבחרו בחנים מאותו הנושא');
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          document.getElementsByClassName(
            'mainExams-more-btn'
          )[0].disabled = false;

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);

          return;
        } else {
          temp_topic = element.topic;
        }
      }
    });

    setExamData(tempExamData);
    setChosenTopic(temp_topic);

    setShowAnimation(false);

    document.getElementsByClassName('mainExams-more-btn')[0].disabled = false;
    examButtons.forEach((element) => {
      element.disabled = false;
    });

    setShowExamSplitModal(true);
  };

  return (
    <React.Fragment>
      {showExamModal && (
        <ExamModal
          chosenTopic={chosenTopic}
          examID={examID}
          setShowAnimation={setShowAnimation}
          showAnimation={showAnimation}
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
          formatTime={formatTime}
          formatDate={formatDate}
          translateTopic={translateTopic}
          examData={examData}
          languageFile={languageFile}
          selectedLanguage={selectedLanguage}
          showExamModal={showExamModal}
          setShowExamModal={setShowExamModal}
          setExamData={setExamData}
        />
      )}
      {showExamSplitModal && (
        <ExamSplitModal
          chosenTopic={chosenTopic}
          examID={examID}
          setShowAnimation={setShowAnimation}
          showAnimation={showAnimation}
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
          formatTime={formatTime}
          formatDate={formatDate}
          translateTopic={translateTopic}
          examData={examData}
          languageFile={languageFile}
          selectedLanguage={selectedLanguage}
          showExamModal={showExamSplitModal}
          setShowExamModal={setShowExamSplitModal}
          setExamData={setExamData}
        />
      )}
      {showCompareButton && (
        <button
          onClick={handleCompareExamsClick}
          className="btn btn-compare-now"
        >
          {languageFile.compareNow}
        </button>
      )}
      <SortExams
        filterData={filterData}
        setShowAnimation={setShowAnimation}
        fetchExams={fetchExams}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortData={sortData}
        setSortData={setSortData}
        translateTopic={translateTopic}
        handleOpenExam={handleOpenExam}
        formatDate={formatDate}
        formatTime={formatTime}
        setLoadedExams={setLoadedExams}
        setExamBody={setExamBody}
        loadedExams={loadedExams}
        languageFile={languageFile}
        selectedLanguage={selectedLanguage}
      />
      {/* <FilterExams setShowAnimation={setShowAnimation} fetchExams={fetchExams} filterData={filterData} setFilterData={setFilterData} languageFile={languageFile} selectedLanguage={selectedLanguage} /> */}
      <div id="mainExams">
        {examBody}
        {!examsLoaded && (
          <div id="noExamsLoaded">
            {selectedLanguage === 'en' ? (
              <h1>We didn't find any saved exams</h1>
            ) : (
              <h1>לא מצאנו מבחנים שמורים</h1>
            )}
          </div>
        )}
        <button
          dir="auto"
          onClick={handleLoadMoreButton}
          className="btn mainExams-more-btn"
        >
          <i className="fa-solid fa-spinner"></i> {languageFile.loadMore}
        </button>
        <div id="animationScreen" className={`loading-animation-general`}>
          <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Exams;
