import React, {useState} from 'react'
import "./SortExams.css"
import moment from 'moment';

function SortExams({filterData, setShowAnimation, fetchExams, sortDirection, setSortDirection, sortData, setSortData, translateTopic, formatDate, formatTime, setLoadedExams, handleOpenExam, setExamBody, loadedExams, languageFile, selectedLanguage}) {
  let loadedExamsTemp =  null;

  const onClickDirection = event => {
    const element = event.target;

    const sortedData = [...loadedExams].sort((a, b) => {
      if (sortData === "date"){
        const dateA = moment(a.data_completed);
        const dateB = moment(b.data_completed);
    
        if (element.id === "asc")
          return dateA.diff(dateB);
        else if (element.id === "desc")
          return dateB.diff(dateA);
      }
      else if (sortData === "score"){
        a.score = parseInt(a.score, 10); 
        b.score = parseInt(b.score, 10);
      
        if (element.id === "asc")
          return a.score - b.score;
        else if (element.id === "desc")
          return b.score - a.score;
      }
      else if (sortData === "exam_id"){
        a.exam_id = parseInt(a.exam_id, 10); 
        b.exam_id = parseInt(b.exam_id, 10);
      
        if (element.id === "asc")
          return a.exam_id - b.exam_id;
        else if (element.id === "desc")
          return b.exam_id - a.exam_id;
      }
      else if (sortData === "unit_id"){
        a.unit_id = parseInt(a.unit_id, 10); 
        b.unit_id = parseInt(b.unit_id, 10);
      
        if (element.id === "asc")
          return a.unit_id - b.unit_id;
        else if (element.id === "desc")
          return b.unit_id - a.unit_id;
      }
    });

    loadedExamsTemp = sortedData;
    setLoadedExams(sortedData);
    
    setSortDirection(element.id);

    setExamBody(renderList());
  
    // fetchExams(filterData, element.id, false).then(() => {
    //   setShowAnimation(false);
    // });
  };

  const onClickData = event => {
    const element = event.target;

    const sortedData = [...loadedExams].sort((a, b) => {
      // data_completed: string, score: number, unit_id: number, exam_id: number
      if (element.id === "date"){
        const dateA = moment(a.data_completed);
        const dateB = moment(b.data_completed);
    
        if (sortDirection === "asc")
          return dateA.diff(dateB);
        else if (sortDirection === "desc")
          return dateB.diff(dateA);
      }
      else if (element.id === "score"){
        a.score = parseInt(a.score, 10); 
        b.score = parseInt(b.score, 10);
      
        if (sortDirection === "asc")
          return a.score - b.score;
        else if (sortDirection === "desc")
          return b.score - a.score;
      }
      else if (element.id === "exam_id"){
        a.exam_id = parseInt(a.exam_id, 10); 
        b.exam_id = parseInt(b.exam_id, 10);
      
        if (sortDirection === "asc")
          return a.exam_id - b.exam_id;
        else if (sortDirection === "desc")
          return b.exam_id - a.exam_id;
      }
      else if (element.id === "unit_id"){
        a.unit_id = parseInt(a.unit_id, 10); 
        b.unit_id = parseInt(b.unit_id, 10);
      
        if (sortDirection === "asc")
          return a.unit_id - b.unit_id;
        else if (sortDirection === "desc")
          return b.unit_id - a.unit_id;
      }
    });

    loadedExamsTemp = sortedData;
    setLoadedExams(sortedData);
    
    setSortData(element.id);

    setExamBody(renderList());
  };

  const renderList = () => {
    const items = [];
    let tempTimer = "-";
    loadedExamsTemp.forEach(element => {
      items.push(
        <div key={element.exam_id} id={`exam-${element.exam_id}`} className='mainExams-exam'>
          <div className='mainExams-exam-number'>
            <span dir='auto'>{element.exam_id}</span>
          </div>
          <div class='mainExams-exan-top-parent'>
            <div className='mainExams-exam-date-parent'>
              <p dir='auto' className={`mainExams-exam-date ${selectedLanguage === "en" ? "exam-ltr" : "exam-rtl"}`}><i className="fa-solid fa-calendar-days"></i> {formatDate(Date.parse(element.data_completed))}</p>
            </div>
            <div className='mainExams-exam-unit2-parent'>
              <p dir='auto' className='mainExams-exam-unit2'><i className="fa-solid fa-list-check"></i> {languageFile.unit} {element.unit_id}</p>
            </div>
            <div className='mainExams-exam-topic-parent'>
              <p dir='auto' className='mainExams-exam-score'><i className="fa-solid fa-bullseye"></i> {languageFile.examScoreAgain} {element.score}</p>
            </div>
          </div>
          <div className='mainExams-exam-top'>
            <p dir='auto' className='mainExams-exam-topic'><i className="fa-solid fa-graduation-cap"></i> {translateTopic(element.topic)}</p>
          </div>
          <div className='mainExams-exam-center'>
            <p dir='auto' className='mainExams-exam-timer-short-answers'>
              <span dir='auto' className='short-answers-correct'><i className="fa-solid fa-circle-check"></i> {element.correct_answers_counter} <span className='short-answers-sep'>/</span> </span> 
              <span dir='auto' className='short-answers-incorrect'><i className="fa-solid fa-circle-xmark"></i> {element.incorrect_answers_counter} <span className='short-answers-sep'>/</span> </span>
              <span dir='auto' className='short-answers-ignored'><i className="fa-solid fa-circle-question"></i> {element.ignored_answers_counter}</span>
            </p>
          </div>
          <div className='mainExams-exam-bottom'>
            { !parseInt(element.timer, 10) * 60 === 0 ? (
              <p className={`mainExams-exam-timer-stopper ${selectedLanguage === "en" ? "exam-ltr" : "exam-rtl"}`}><i className="fa-solid fa-clock"></i> {formatTime(parseInt(element.stopper, 10))} / {formatTime(parseInt(element.timer, 10) * 60)}</p>
            ) : (
              <p className={`mainExams-exam-timer-stopper ${selectedLanguage === "en" ? "exam-ltr" : "exam-rtl"}`}><i className="fa-solid fa-clock"></i> {formatTime(parseInt(element.stopper, 10))} / -</p>
            )}
          </div>
          <button onClick={handleOpenExam} dir='auto' className='btn mainExams-open-btn'><i className="fa-solid fa-list-check"></i> {languageFile.watch}</button>
        </div>
      );
    });
    
    return items
  };
 
  return (
    <div id='SortExamsDiv'>
      <p>{languageFile.orderBy}</p>
      <div>
        <ul id='direction'>
          <li id='asc' onClick={onClickDirection} className={`${sortDirection === "asc" ? "selected" : ""}`}>{languageFile.asc}</li>
          <li id='desc' onClick={onClickDirection} className={`${sortDirection === "desc" ? "selected" : ""}`}>{languageFile.desc}</li>
        </ul>
        <span className='sort-exams-sep sep'> | </span>
        <ul id='data'>
          <li id='date' onClick={onClickData} className={`${sortData === "date" ? "selected" : ""}`}>{languageFile.date}</li>
          <li id='score' onClick={onClickData} className={`${sortData === "score" ? "selected" : ""}`}>{languageFile.score}</li>
          <li id='unit_id' onClick={onClickData} className={`${sortData === "unit_id" ? "selected" : ""}`}>{languageFile.unit}</li>
          <li id='exam_id' onClick={onClickData} className={`${sortData === "exam_id" ? "selected" : ""}`}>{languageFile.exam_number}</li>
        </ul>
      </div>
    </div>
  )
}

export default SortExams