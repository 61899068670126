import React, { useEffect, useState, useContext, useRef } from 'react';
import { useGlobalContext } from '../../context/AppContext';
import './register.css';
import CookieAgreement from '../../utils/CookieAgreement';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import {
  getSessionCookie,
  setSessionCookie,
  verifySessionCookie,
} from '../../utils/cookieUtils';
import AlertModal from '../AlertModal/AlertModal';
import EmailVerification from './EmailVerification/EmailVerification';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordRequirementsModal from '../PasswordRequirementsModal/PasswordRequirementsModal';
import HashLoader from 'react-spinners/HashLoader';

function Register() {
  const token = getSessionCookie();
  // useEffect(() => {
  //   if (token){
  //     if (verifySessionCookie()){
  //       window.location = '/environment'
  //     }else{
  //       window.location = '/'
  //     }
  //   }
  // }, [token])

  const captchaKey = '6LdQ1ssoAAAAAFngYC0GewMPQEFWqxs2IRH61Fj_';
  const recaptchaRef = useRef(null);
  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const [showEmailVerification, setShowEmailVerification] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Register' : 'הרשמה'
    }`; // Set the title to "Milly | Register"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isMobileNumberFocused, setIsMobileNumberFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const [isDateOfBirthFocused, setIsDateOfBirthFocused] = useState(false);

  const handleDivFirstNameClick = () => {
    setIsFirstNameFocused(true);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerFirstName').focus();
  };

  const handleDivLastNameClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(true);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerLastName').focus();
  };

  const handleDivEmailClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(true);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerEmail').focus();
  };

  const handleDivMobileNumberClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(true);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerMobileNumber').focus();
  };

  const handleDivPasswordClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(true);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerPassword').focus();
  };

  const handleDivConfirmPasswordClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(true);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerConfirmPassword').focus();
  };

  const handleDivDateOfBirthClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(true);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerDateOfBirth').focus();
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      document.getElementById('registerPassword').type = 'password';
      document.getElementById('registerConfirmPassword').type = 'password';
    } else {
      setIsPasswordVisible(true);
      document.getElementById('registerPassword').type = 'text';
      document.getElementById('registerConfirmPassword').type = 'text';
    }
  };

  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);

  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setIsLastNameValid] = useState(false);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [mobileNumber, setMobileNumber] = useState('');
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(false);

  const handleFirstNameInputChange = (event) => {
    const firstNameInput = document.getElementById('registerFirstName');
    setFirstName(event.target.value);

    if (firstNameInput.validity.valid) {
      setIsFirstNameValid(true);
    } else {
      setIsFirstNameValid(false);
    }
  };

  const handleLastNameInputChange = (event) => {
    const lastNameInput = document.getElementById('registerLastName');
    setLastName(event.target.value);

    if (lastNameInput.validity.valid) {
      setIsLastNameValid(true);
    } else {
      setIsLastNameValid(false);
    }
  };

  const handleEmailInputChange = (event) => {
    const emailInput = document.getElementById('registerEmail');
    setIsEmailInputVerifiedNull(false);
    setEmail(event.target.value);

    if (emailInput.validity.valid) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handleMobileNumberInputChange = (event) => {
    const mobileNumberInput = document.getElementById('registerMobileNumber');
    setIsMobileInputVerifiedNull(false);
    setMobileNumber(event.target.value);

    if (mobileNumberInput.validity.valid) {
      setIsMobileNumberValid(true);
    } else {
      setIsMobileNumberValid(false);
    }
  };

  const handlePasswordInputChange = (event) => {
    setIsPasswordInputVerifiedNull(false);
    setPassword(event.target.value);
  };

  const handleConfirmPasswordInputChange = (event) => {
    setIsPasswordInputVerifiedNull(false);
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    mobileInputValidation();
  }, [mobileNumber]);

  useEffect(() => {
    emailInputValidation();
  }, [email]);

  useEffect(() => {
    dateOfBirthInputValidation();
  }, [dateOfBirth]);

  useEffect(() => {
    passwordInputValidation();
  }, [password, confirmPassword]);

  const [isPasswordInputVerifiedNull, setIsPasswordInputVerifiedNull] =
    useState(true);
  const [isEmailInputVerifiedNull, setIsEmailInputVerifiedNull] =
    useState(true);
  const [isDateOfBirthInputVerifiedNull, setIsDateOfBirthInputVerifiedNull] =
    useState(true);
  const [isMobileInputVerifiedNull, setIsMobileInputVerifiedNull] =
    useState(true);

  const [isPasswordInputVerified, setIsPasswordInputVerified] = useState(false);
  const [passwordIsTenOrMoreChars, setPasswordIsTenOrMoreChars] =
    useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsDigit, setContainsDigit] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const passwordInputValidation = () => {
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialCharacters = '!@#$%^&*';

    const passwordInput = document.getElementById('registerPassword').value;
    const confirmPasswordInput = document.getElementById(
      'registerConfirmPassword'
    ).value;

    const isTenOrMoreChars = passwordInput.length >= 10;
    const hasLowercaseLetter = [...lowercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasUppercaseLetter = [...uppercaseLetters].some((char) =>
      passwordInput.includes(char)
    );
    const hasDigit = [...digits].some((char) => passwordInput.includes(char));
    const hasSpecialChar = [...specialCharacters].some((char) =>
      passwordInput.includes(char)
    );
    const hasSpace = passwordInput.includes(' ');
    let doPasswordsMatch;

    if (
      passwordInput === confirmPasswordInput &&
      passwordInput !== '' &&
      confirmPasswordInput !== ''
    ) {
      doPasswordsMatch = true;
    } else {
      doPasswordsMatch = false;
    }

    setPasswordIsTenOrMoreChars(isTenOrMoreChars);
    setContainsLowercaseLetter(hasLowercaseLetter);
    setContainsUppercaseLetter(hasUppercaseLetter);
    setContainsDigit(hasDigit);
    setContainsSpecialChar(hasSpecialChar);
    setPasswordsMatch(doPasswordsMatch);

    const isPasswordValid =
      isTenOrMoreChars &&
      hasLowercaseLetter &&
      hasUppercaseLetter &&
      hasDigit &&
      hasSpecialChar &&
      doPasswordsMatch &&
      !hasSpace;

    if (
      (!isPasswordValid && passwordInput !== '') ||
      confirmPasswordInput !== ''
    ) {
      let newPasswordMissingRules = {};

      if (!isTenOrMoreChars)
        newPasswordMissingRules['isTenOrMoreChars'] = isTenOrMoreChars;

      if (!hasLowercaseLetter)
        newPasswordMissingRules['hasLowercaseLetter'] = hasLowercaseLetter;

      if (!hasUppercaseLetter)
        newPasswordMissingRules['hasUppercaseLetter'] = hasUppercaseLetter;

      if (!hasDigit) newPasswordMissingRules['hasDigit'] = hasDigit;

      if (!hasSpecialChar)
        newPasswordMissingRules['hasSpecialChar'] = hasSpecialChar;

      if (!doPasswordsMatch)
        newPasswordMissingRules['doPasswordsMatch'] = doPasswordsMatch;

      if (hasSpace) newPasswordMissingRules['hasSpace'] = hasSpace;

      setPasswordMissingRules(newPasswordMissingRules);

      setShowPasswordRequirements(true);
    } else {
      setShowPasswordRequirements(false);
    }

    setIsPasswordInputVerified(isPasswordValid);
  };

  const [isMobileInputVerified, setIsMobileInputVerified] = useState(null);
  const [isEmailInputVerified, setIsEmailInputVerified] = useState(null);
  const [isDateOfBirthInputVerified, setIsDateOfBirthInputVerified] =
    useState(null);

  const mobileInputValidation = () => {
    // const patternInternational = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const patternIsrael = /^(0[23489]|050|051|052|053|054|055|058)[0-9]{7}$/;

    const mobileInput = document.getElementById('registerMobileNumber').value;

    const isMobileNumberRegexValid = patternIsrael.test(mobileInput);

    setIsMobileInputVerified(isMobileNumberRegexValid);
  };

  const dateOfBirthInputValidation = () => {
    const dateInput = document.getElementById('registerDateOfBirth').value;

    // const datePattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    // const isDateRegexValid = datePattern.test(dateInput);
    // setIsDateOfBirthInputVerified(isDateRegexValid);

    dateInput !== null && dateInput !== ''
      ? setIsDateOfBirthInputVerified(true)
      : setIsDateOfBirthInputVerified(false);
  };

  const emailInputValidation = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailInput = document.getElementById('registerEmail').value;

    const isEmailRegexValid = emailPattern.test(emailInput);

    setIsEmailInputVerified(isEmailRegexValid);
  };

  const handleDateOfBirthInputChange = (event) => {
    const dateOfBirthInput = document.getElementById('registerDateOfBirth');
    setIsDateOfBirthInputVerifiedNull(false);
    setDateOfBirth(event.target.value);

    if (dateOfBirthInput.validity.valid) {
      setIsDateOfBirthValid(true);
    } else {
      setIsDateOfBirthValid(false);
    }
  };

  const [submissionStatus, setSubmissionStatus] = useState(true);
  const [responseMessage, setResponseMessage] = useState(true);
  const [formSubmitTriggered, setFormSubmitTriggered] = useState(false);

  const [isConactChecked, setIsContactChecked] = useState(false);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);

  const handleNewsletterSwitchChange = () => {
    setIsNewsletterChecked(!isNewsletterChecked); // Toggle the checked state
  };

  const handleContactSwitchChange = () => {
    setIsContactChecked(!isConactChecked); // Toggle the checked state
  };

  let passwordRequirementsList = {};

  const handleSubmit = async (event) => {
    // check if all fields are given
    event.preventDefault();

    if (!isPasswordInputVerified) {
      setShowPasswordRequirements(true);
      return;
    }

    // const loadingSpinner = document.getElementById("loading-spinner-register2");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementById('registerSubmit');
    submitBtn.disabled = true;

    try {
      const form = event.target;
      const formData = new FormData(form);
      formData.append('plan_billing_rate', planBillingDuration);

      const response = await fetch('/register', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";
      resetReCAPTCHA();

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setSubmissionStatus(false);
        submitBtn.disabled = false;

        if (responseData.type === 'password') {
          setModalType('warning');
          if (selectedLanguage === 'en') {
            setModalTitle('warning');
          } else {
            setModalTitle('שימו לב');
          }

          if (responseData.hasOwnProperty('data')) {
            for (const key in responseData.data) {
              if (responseData.data.hasOwnProperty(key)) {
                const value = responseData.data[key];
                if (value) {
                  if (selectedLanguage === 'en') {
                    passwordRequirementsList[key] = value;
                  } else {
                    passwordRequirementsList[key] = fetchResponseHandler(value);
                  }
                }
              }
            }
          }
          setModalDescription(JSON.stringify(passwordRequirementsList));
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
            setModalInformation('');
            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          } else {
            setIsModalClosed(false);
            setModalTitle('יש שגיאה');
            setModalInformation('');

            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);

            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          }
        }
      } else {
        setShowAnimation(true);
        setShowEmailVerification(true);
      }

      setFormSubmitTriggered(false);
    } catch (error) {
      resetReCAPTCHA();
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = false;

      setModalType('error');
      setModalTitle('an error has occured');
      if (selectedLanguage === 'en') {
        setModalDescription('Submission Has Failed');
      } else {
        setModalTitle('יש שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [isPlanEnterPriseSelected, setIsPlanEnterPriseSelected] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState(''); // free, premium, enterprise
  const [isVisibleEffect3, setIsVisibleEffect3] = useState(false);
  const [isVisibleEffect2, setIsVisibleEffect2] = useState(false);
  const [isVisibleEffect, setIsVisibleEffect] = useState(false);
  const navbarElement = document.getElementById('nav');
  const targetElement = document.getElementById('registerContainer');
  const targetElement2 = document.getElementById('registerPlans');

  setTimeout(() => {
    setIsVisibleEffect(true);
  }, 50);

  const freePlanSelected = () => {
    setIsPlanSelected(true);
    setSelectedPlan('free');
    setTimeout(() => {
      if (targetElement) {
        setIsVisibleEffect2(true);
        navbarElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const premiumPlanSelected = () => {
    setIsPlanSelected(true);
    setSelectedPlan('premium');
    setTimeout(() => {
      if (targetElement) {
        setIsVisibleEffect2(true);
        navbarElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const unselectPlan = () => {
    setIsPlanSelected(false);
    setIsPlanEnterPriseSelected(false);
    setSelectedPlan('');
    setIsVisibleEffect3(false);
    setIsVisibleEffect2(false);
    setIsVisibleEffect(false);

    setTimeout(() => {
      if (targetElement) {
        setIsVisibleEffect(true);
        navbarElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);

    setFirstName('');
    setLastName('');
    setEmail('');
    setMobileNumber('');
    setPassword('');
    setConfirmPassword('');
    setDateOfBirth('');
    setCompany('');
    setParticipants('');
    setLeadMessage('');

    setIsMobileInputVerified(null);
    setIsEmailInputVerified(null);
    setIsDateOfBirthInputVerified(null);
    setIsPasswordInputVerified(false);

    setIsPasswordInputVerifiedNull(true);
    setIsEmailInputVerifiedNull(true);
    setIsDateOfBirthInputVerifiedNull(true);
    setIsMobileInputVerifiedNull(true);

    setIsFirstNameValid(false);
    setIsLastNameValid(false);
    setIsCompanyValid(false);
    setIsParticipantsValid(false);
    setIsLeadMessageValid(false);

    setPlan1CellVisited(false);
    setPlan2CellVisited(true);
    setPlan3CellVisited(false);

    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
  };

  const enterprisePlanSelected = () => {
    setIsPlanEnterPriseSelected(true);
    setSelectedPlan('enterprise');

    setTimeout(() => {
      if (targetElement) {
        setIsVisibleEffect3(true);
        navbarElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const [plan1CellVisited, setPlan1CellVisited] = useState(false);
  const [plan2CellVisited, setPlan2CellVisited] = useState(true);
  const [plan3CellVisited, setPlan3CellVisited] = useState(false);

  const plan1CellVisitedFunc = () => {
    setPlan1CellVisited(true);
    setPlan2CellVisited(false);
    setPlan3CellVisited(false);
  };

  const plan2CellVisitedFunc = () => {
    setPlan1CellVisited(false);
    setPlan2CellVisited(true);
    setPlan3CellVisited(false);
  };

  const plan3CellVisitedFunc = () => {
    setPlan1CellVisited(false);
    setPlan2CellVisited(false);
    setPlan3CellVisited(true);
  };

  const [planBillingDuration, setPlanBillingDuration] = useState('Yearly'); // Monthly / Yearly
    // * For now the price is free
  // const [planPremiumPrice, setPlanPremiumPrice] = useState(816); // 80 / 816
  const [planPremiumPrice, setPlanPremiumPrice] = useState(0); // 80 / 816
  const [isPlanBillingDurationChecked, setIsPlanBillingDurationChecked] =
    useState(true); // Monthly / Yearly

  const changeplanBilling = () => {
    const newBillingDuration =
      planBillingDuration === 'Monthly' ? 'Yearly' : 'Monthly';
    // const newPlanPremiumPrice = planPremiumPrice === 80 ? 816 : 80;
    // * For now the price is free
    const newPlanPremiumPrice = 0;

    setPlanBillingDuration(newBillingDuration);
    setPlanPremiumPrice(newPlanPremiumPrice);
    setIsPlanBillingDurationChecked(!isPlanBillingDurationChecked);
  };

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const [showAnimation, setShowAnimation] = useState(false);

  const [company, setCompany] = useState('');
  const [isCompanyValid, setIsCompanyValid] = useState(false);

  const [isCompanyFocused, setIsCompanyFocused] = useState(false);
  const handleDivCompanyClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(true);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(false);
    document.getElementById('registerCompany').focus();
  };

  const handleCompanyInputChange = (event) => {
    const companyInput = document.getElementById('registerCompany');
    setCompany(event.target.value);

    if (companyInput.validity.valid) {
      setIsCompanyValid(true);
    } else {
      setIsCompanyValid(false);
    }
  };

  const [participants, setParticipants] = useState(0);
  const [isParticipantsValid, setIsParticipantsValid] = useState(false);

  const [isParticipantsFocused, setIsParticipantsFocused] = useState(false);
  const handleDivParticipantsClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(true);
    setIsLeadMessageFocused(false);
    document.getElementById('registerParticipants').focus();
  };

  const handleParticipantsInputChange = (event) => {
    const participantsInput = document.getElementById('registerParticipants');
    setParticipants(event.target.value);

    if (participantsInput.validity.valid) {
      setIsParticipantsValid(true);
    } else {
      setIsParticipantsValid(false);
    }
  };

  const [leadMessage, setLeadMessage] = useState('');
  const [isLeadMessageValid, setIsLeadMessageValid] = useState(false);

  const [isLeadMessageFocused, setIsLeadMessageFocused] = useState(false);
  const handleDivLeadMessageClick = () => {
    setIsFirstNameFocused(false);
    setIsLastNameFocused(false);
    setIsEmailFocused(false);
    setIsMobileNumberFocused(false);
    setIsPasswordFocused(false);
    setIsConfirmPasswordFocused(false);
    setIsDateOfBirthFocused(false);
    setIsCompanyFocused(false);
    setIsParticipantsFocused(false);
    setIsLeadMessageFocused(true);
    document.getElementById('registerLeadMessage').focus();
  };

  const handleLeadMessageInputChange = (event) => {
    const leadMessageInput = document.getElementById('registerLeadMessage');
    setLeadMessage(event.target.value);

    if (leadMessageInput.validity.valid) {
      setIsLeadMessageValid(true);
    } else {
      setIsLeadMessageValid(false);
    }
  };

  const handleSubmitEnterprise = async (event) => {
    // check if all fields are given
    event.preventDefault();

    if (!isPasswordInputVerified) {
      setShowPasswordRequirements(true);
      return;
    }

    // const loadingSpinner = document.getElementById("loading-spinner-register");
    // loadingSpinner.style.display = "flex";
    setShowAnimation(true);

    var submitBtn = document.getElementById('registerSubmit');
    submitBtn.disabled = true;

    setEmailVerificationAction('lead');

    try {
      const form = event.target;
      const formData = new FormData(form);

      const response = await fetch('/register-lead', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // loadingSpinner.style.display = "none";

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setSubmissionStatus(false);
        submitBtn.disabled = false;

        if (responseData.type === 'password') {
          setModalType('warning');
          if (selectedLanguage === 'en') {
            setModalTitle('warning');
          } else {
            setModalTitle('שימו לב');
          }

          if (responseData.hasOwnProperty('data')) {
            for (const key in responseData.data) {
              if (responseData.data.hasOwnProperty(key)) {
                const value = responseData.data[key];
                if (value) {
                  if (selectedLanguage === 'en') {
                    passwordRequirementsList[key] = value;
                  } else {
                    passwordRequirementsList[key] = fetchResponseHandler(value);
                  }
                }
              }
            }
          }
          setModalDescription(JSON.stringify(passwordRequirementsList));
          setModalInformation('');
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
            setModalInformation('');
            setIsModalClosed(false);
            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          } else {
            setIsModalClosed(false);
            setModalTitle('יש שגיאה');
            setModalInformation('');

            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);

            setTimeout(() => {
              setIsModalClosed(true);
            }, 7000);
          }
        }
      } else {
        setShowAnimation(true);
        setShowEmailVerification(true);
      }

      setFormSubmitTriggered(false);
    } catch (error) {
      // loadingSpinner.style.display = "none";
      submitBtn.disabled = true;

      setModalType('error');
      setModalTitle('an error has occured');
      if (selectedLanguage === 'en') {
        setModalDescription('Submission Has Failed');
      } else {
        setModalTitle('יש שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const [emailVerificationAction, setEmailVerificationAction] =
    useState('user');

  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [passwordMissingRules, setPasswordMissingRules] = useState({});

  return (
    <React.Fragment>
      <CookieAgreement />
      <EmailVerification
        email={email}
        showEmailVerification={showEmailVerification}
        setShowEmailVerification={setShowEmailVerification}
        action={emailVerificationAction}
      />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      {/* <a dir='auto'lertModal isModalClosed={isModalClosed} setIsModalClosed={setIsModalClosed} messageType="error" title="an error has occured" description="Please make sure to fill all the fields" information=""/> */}
      <CookieAgreement />
      <NavBar
        classAdditional="navbar-login"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={false}
      />
      <div id="mainRegister" className="main main-register">
        <div
          id="registerEnterprise"
          className={`fade-in-top ${
            isVisibleEffect3 ? 'visible' : ''
          } register-enterprise ${
            isPlanEnterPriseSelected
              ? 'register-enterprise-show'
              : 'register-enterprise-hide'
          }`}
        >
          {showPasswordRequirements && (
            <PasswordRequirementsModal
              isRegister={true}
              setPasswordMissingRules={setPasswordMissingRules}
              passwordMissingRules={passwordMissingRules}
              setShowPasswordRequirements={setShowPasswordRequirements}
              showPasswordRequirements={showPasswordRequirements}
            />
          )}
          <div
            className={`enterprise-col-1 ${isVisibleEffect3 ? 'visible' : ''}`}
          >
            <img
              loading="lazy"
              src="/logo-no-background.png"
              className="enterprise-logo"
              alt="MillyLearn"
            />
            <img
              loading="lazy"
              src="/login-wallpaper.jpg"
              className="img enterprise-wallpaper"
              alt="MillyLearn"
            />
          </div>
          <div
            className={`enterprise-col-2 ${isVisibleEffect3 ? 'visible' : ''}`}
          >
            <a dir="auto" className="register-go-back" onClick={unselectPlan}>
              <i className="fa-solid fa-rotate-left"></i>
            </a>
            <div className="register-banner">
              <h1 dir="auto">{languageFile.registerEnterpriseTitle}</h1>
              <p dir="auto">{languageFile.registerEnterpriseText}!</p>
            </div>
            <div className="register-body">
              <form
                className="register-form"
                id="registerForm"
                method="POST"
                onSubmit={handleSubmitEnterprise}
              >
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isFirstNameValid ? 'valid' : ''
                  } ${isFirstNameFocused ? 'register-row-focused' : ''}`}
                  id="registerRowFirstName"
                  onClick={handleDivFirstNameClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-user ${
                      isFirstNameFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivFirstNameClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isFirstNameFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivFirstNameClick}
                    htmlFor="registerFirstName"
                  >
                    {languageFile.firstNamePlaceholder}*
                  </label>
                  <input
                    type="text"
                    id="registerFirstName"
                    name="first_name"
                    onChange={handleFirstNameInputChange}
                    value={firstName}
                    maxLength="35"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isLastNameValid ? 'valid' : ''
                  } ${isLastNameFocused ? 'register-row-focused' : ''}`}
                  id="registerRowLastName"
                  onClick={handleDivLastNameClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-user ${
                      isLastNameFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivLastNameClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isLastNameFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivLastNameClick}
                    htmlFor="registerLastName"
                  >
                    {languageFile.lastNamePlaceholder}*
                  </label>
                  <input
                    type="text"
                    id="registerLastName"
                    name="last_name"
                    onChange={handleLastNameInputChange}
                    value={lastName}
                    maxLength="35"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isEmailInputVerified
                      ? 'valid'
                      : `${isEmailInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isEmailFocused ? 'register-row-focused' : ''}`}
                  id="registerRowEmail"
                  onClick={handleDivEmailClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-envelope ${
                      isEmailFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivEmailClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isEmailFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivEmailClick}
                    htmlFor="registerEmail"
                  >
                    {languageFile.loginEmail}*
                  </label>
                  <input
                    type="email"
                    id="registerEmail"
                    name="email"
                    onChange={handleEmailInputChange}
                    value={email}
                    maxLength="80"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isMobileInputVerified
                      ? 'valid'
                      : `${isMobileInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isMobileNumberFocused ? 'register-row-focused' : ''}`}
                  id="registerRowMobileNumber"
                  onClick={handleDivMobileNumberClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-phone ${
                      isMobileNumberFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivMobileNumberClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isMobileNumberFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivMobileNumberClick}
                    htmlFor="registerMobileNumber"
                  >
                    {languageFile.mobileNumberPlaceholder}*
                  </label>
                  <input
                    type="tel"
                    id="registerMobileNumber"
                    name="mobile_number"
                    onChange={handleMobileNumberInputChange}
                    value={mobileNumber}
                    maxLength="13"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-password-row ${
                    isCompanyValid ? 'valid' : ''
                  } ${isCompanyFocused ? 'register-row-focused' : ''}`}
                  id="registerRowCompany"
                  onClick={handleDivCompanyClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-building ${
                      isCompanyFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivCompanyClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isCompanyFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivCompanyClick}
                    htmlFor="registerCompany"
                  >
                    {languageFile.companyValue}*
                  </label>
                  <input
                    type="text"
                    id="registerCompany"
                    name="company"
                    onChange={handleCompanyInputChange}
                    value={company}
                    maxLength="80"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-password-row ${
                    isParticipantsValid ? 'valid' : ''
                  } ${isParticipantsFocused ? 'register-row-focused' : ''}`}
                  id="registerRowParticipants"
                  onClick={handleDivParticipantsClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-person-circle-plus ${
                      isParticipantsFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivParticipantsClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isParticipantsFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivParticipantsClick}
                    htmlFor="registerParticipants"
                  >
                    {languageFile.participantsValue}*
                  </label>
                  <input
                    type="number"
                    id="registerParticipants"
                    name="participants"
                    onChange={handleParticipantsInputChange}
                    value={participants}
                    maxLength="80"
                  />
                </div>
                {/* <p dir='auto'>the password must be at least 10 characters, and contain at least one digit, one symbol, one uppercase letter, and one lowercase letter.</p> */}
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-password-row ${
                    isLeadMessageValid ? 'valid' : ''
                  } ${isLeadMessageFocused ? 'register-row-focused' : ''}`}
                  id="registerRowLeadMessage"
                  onClick={handleDivLeadMessageClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-message ${
                      isLeadMessageFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivLeadMessageClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isLeadMessageFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivLeadMessageClick}
                    htmlFor="registerLeadMessage"
                  >
                    {languageFile.leadMessageValue}*
                  </label>
                  <textarea
                    maxLength="300"
                    id="registerLeadMessage"
                    name="message"
                    onChange={handleLeadMessageInputChange}
                    value={leadMessage}
                    rows="2"
                    cols="20"
                  />
                </div>
                <div className="register-switches">
                  <div className="form-row form-row-condition form-row-condition-register">
                    <label className="form-switch">
                      <input
                        id="acceptContact"
                        type="checkbox"
                        name="accept_contact"
                        data-gtm-form-interact-field-id="0"
                        checked={isConactChecked}
                        onChange={handleContactSwitchChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label dir="auto" className="form-label condition-label">
                      <a dir="auto" href="/terms-of-service" target="_blank">
                        {`${languageFile.acceptRegisterEmails}`}*
                      </a>
                    </label>
                  </div>
                </div>
                <input
                  maxLength="35"
                  type="hidden"
                  name="current_plan"
                  value={selectedPlan}
                />
                {selectedLanguage === 'en' ? (
                  <p dir="auto" className="register-tos">
                    {languageFile.registerAgreementMessage2}{' '}
                    <a dir="auto" href="/terms-of-service" target="_blank">
                      <strong>{languageFile.acceptTosSubmit2}</strong>
                    </a>{' '}
                    {languageFile.acceptTosSubmit3}{' '}
                    <a dir="auto" href="/privacy-policy" target="_blank">
                      <strong>{languageFile.acceptTosSubmit4}</strong>
                    </a>
                  </p>
                ) : (
                  <p dir="auto" className="register-tos">
                    {languageFile.registerAgreementMessage2}
                    <a dir="auto" href="/terms-of-service" target="_blank">
                      <strong>{languageFile.acceptTosSubmit2}</strong>
                    </a>{' '}
                    <a dir="auto" href="/privacy-policy" target="_blank">
                      <strong>{languageFile.acceptTosSubmit3}</strong>
                    </a>
                  </p>
                )}
                <div className="recpatcha-container recaptcha-container-register">
                  <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} />
                </div>
                <div
                  className={`register-form-btns ${
                    selectedLanguage === 'he' ? 'register-form-btns-hebrew' : ''
                  }`}
                >
                  <button
                    type="submit"
                    dir="auto"
                    className="btn register-form-btn register-form-submit"
                    id="registerSubmit"
                  >
                    {languageFile.LetsTalk}
                  </button>
                </div>
                {/* <div className={`animation-parent`} id='loading-spinner-register'>
                      <div className={`animation animation-circle`}></div>
                    </div> */}
                <div
                  id="animationScreen"
                  className={`loading-animation-general`}
                >
                  <HashLoader
                    color="#1A56DB"
                    loading={showAnimation}
                    size={60}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          id="registerContainer"
          className={`fade-in-top ${
            isVisibleEffect2 ? 'visible' : ''
          } register-container ${
            isPlanSelected ? 'register-show' : 'register-hide'
          }`}
        >
          {showPasswordRequirements && (
            <PasswordRequirementsModal
              isRegister={true}
              setPasswordMissingRules={setPasswordMissingRules}
              passwordMissingRules={passwordMissingRules}
              setShowPasswordRequirements={setShowPasswordRequirements}
              showPasswordRequirements={showPasswordRequirements}
            />
          )}
          <div
            className={`register-col-1 ${isVisibleEffect2 ? 'visible' : ''}`}
          >
            <img
              loading="lazy"
              src="/logo-no-background.png"
              className="register-logo"
              alt="MillyLearn"
            />
            <img
              loading="lazy"
              src="/login-wallpaper.jpg"
              className="img register-wallpaper"
              alt="MillyLearn"
            />
          </div>
          <div
            className={`register-col-2 ${isVisibleEffect2 ? 'visible' : ''}`}
          >
            <a dir="auto" className="register-go-back" onClick={unselectPlan}>
              <i className="fa-solid fa-rotate-left"></i>
            </a>
            <div className="register-banner">
              <h1 dir="auto">{languageFile.registerTitle}</h1>
              <p dir="auto">{languageFile.registerText}!</p>
            </div>
            <div className="register-body">
              <form
                className="register-form"
                id="registerForm"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isFirstNameValid ? 'valid' : ''
                  } ${isFirstNameFocused ? 'register-row-focused' : ''}`}
                  id="registerRowFirstName"
                  onClick={handleDivFirstNameClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-user ${
                      isFirstNameFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivFirstNameClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isFirstNameFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivFirstNameClick}
                    htmlFor="registerFirstName"
                  >
                    {languageFile.firstNamePlaceholder}*
                  </label>
                  <input
                    type="text"
                    id="registerFirstName"
                    name="first_name"
                    onChange={handleFirstNameInputChange}
                    value={firstName}
                    maxLength="35"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isLastNameValid ? 'valid' : ''
                  } ${isLastNameFocused ? 'register-row-focused' : ''}`}
                  id="registerRowLastName"
                  onClick={handleDivLastNameClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-user ${
                      isLastNameFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivLastNameClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isLastNameFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivLastNameClick}
                    htmlFor="registerLastName"
                  >
                    {languageFile.lastNamePlaceholder}*
                  </label>
                  <input
                    type="text"
                    id="registerLastName"
                    name="last_name"
                    onChange={handleLastNameInputChange}
                    value={lastName}
                    maxLength="35"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isEmailInputVerified
                      ? 'valid'
                      : `${isEmailInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isEmailFocused ? 'register-row-focused' : ''}`}
                  id="registerRowEmail"
                  onClick={handleDivEmailClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-envelope ${
                      isEmailFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivEmailClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isEmailFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivEmailClick}
                    htmlFor="registerEmail"
                  >
                    {languageFile.loginEmail}*
                  </label>
                  <input
                    type="email"
                    id="registerEmail"
                    name="email"
                    onChange={handleEmailInputChange}
                    value={email}
                    maxLength="80"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isMobileInputVerified
                      ? 'valid'
                      : `${isMobileInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isMobileNumberFocused ? 'register-row-focused' : ''}`}
                  id="registerRowMobileNumber"
                  onClick={handleDivMobileNumberClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-phone ${
                      isMobileNumberFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivMobileNumberClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isMobileNumberFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivMobileNumberClick}
                    htmlFor="registerMobileNumber"
                  >
                    {languageFile.mobileNumberPlaceholder}*
                  </label>
                  <input
                    type="tel"
                    id="registerMobileNumber"
                    name="mobile_number"
                    onChange={handleMobileNumberInputChange}
                    value={mobileNumber}
                    maxLength="13"
                  />
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-password-row ${
                    isPasswordInputVerified
                      ? 'valid'
                      : `${isPasswordInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isPasswordFocused ? 'register-row-focused' : ''}`}
                  id="registerRowPassword"
                  onClick={handleDivPasswordClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-user-lock ${
                      isPasswordFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivPasswordClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isPasswordFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivPasswordClick}
                    htmlFor="registerPassword"
                  >
                    {languageFile.loginPassword}*
                  </label>
                  <input
                    type="password"
                    id="registerPassword"
                    name="password"
                    onChange={handlePasswordInputChange}
                    value={password}
                    maxLength="80"
                  />
                  {isPasswordVisible ? (
                    <i
                      className="password-visibility fa-solid fa-eye-slash"
                      onClick={togglePassword}
                    ></i>
                  ) : (
                    <i
                      className="password-visibility fa-solid fa-eye"
                      onClick={togglePassword}
                    ></i>
                  )}
                </div>
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-password-row ${
                    isPasswordInputVerified
                      ? 'valid'
                      : `${isPasswordInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isConfirmPasswordFocused ? 'register-row-focused' : ''}`}
                  id="registerRowConfirmPassword"
                  onClick={handleDivConfirmPasswordClick}
                >
                  <i
                    className={`form-register-input-icon fa-solid fa-check-double ${
                      isConfirmPasswordFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivConfirmPasswordClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isConfirmPasswordFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivConfirmPasswordClick}
                    htmlFor="registerConfirmPassword"
                  >
                    {languageFile.confirmPasswordPlaceholder}*
                  </label>
                  <input
                    type="password"
                    id="registerConfirmPassword"
                    name="confirm_password"
                    onChange={handleConfirmPasswordInputChange}
                    value={confirmPassword}
                    maxLength="80"
                  />
                  {isPasswordVisible ? (
                    <i
                      className="password-visibility fa-solid fa-eye-slash"
                      onClick={togglePassword}
                    ></i>
                  ) : (
                    <i
                      className="password-visibility fa-solid fa-eye"
                      onClick={togglePassword}
                    ></i>
                  )}
                </div>
                {/* <p dir='auto'>the password must be at least 10 characters, and contain at least one digit, one symbol, one uppercase letter, and one lowercase letter.</p> */}
                <div
                  tabIndex="0"
                  className={`register-form-input-row register-form-email-row ${
                    isDateOfBirthInputVerified
                      ? 'valid'
                      : `${isDateOfBirthInputVerifiedNull ? '' : 'not-valid'}`
                  } ${isDateOfBirthFocused ? 'register-row-focused' : ''}${
                    isDateOfBirthFocused ? 'register-row-focused' : ''
                  }`}
                  id="registerRowDateOfBirth"
                  onClick={handleDivDateOfBirthClick}
                >
                  <i
                    className={`form-register-input-icon fa-regular fa-calendar-days ${
                      isDateOfBirthFocused ? 'register-i-focused' : ''
                    }`}
                    onClick={handleDivDateOfBirthClick}
                  ></i>
                  <label
                    dir="auto"
                    className={`${
                      isDateOfBirthFocused ? 'register-label-focused' : ''
                    }`}
                    onClick={handleDivDateOfBirthClick}
                    htmlFor="registerDateOfBirth"
                  >
                    {languageFile.birthDatePlaceholder}*
                  </label>
                  <input
                    type="date"
                    id="registerDateOfBirth"
                    name="date_of_birth"
                    onChange={handleDateOfBirthInputChange}
                    value={dateOfBirth}
                    dir="ltr"
                    maxLength="10"
                  />
                </div>
                <div className="register-switches">
                  <div className="form-row form-row-condition form-row-condition-register">
                    <label className="form-switch">
                      <input
                        id="acceptContact"
                        type="checkbox"
                        name="accept_contact"
                        data-gtm-form-interact-field-id="0"
                        checked={isConactChecked}
                        onChange={handleContactSwitchChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label dir="auto" className="form-label condition-label">
                      <a dir="auto" href="/terms-of-service" target="_blank">
                        {`${languageFile.acceptRegisterEmails}`}*
                      </a>
                    </label>
                  </div>
                  <div className="form-row form-row-condition form-row-condition-register">
                    <label className="form-switch">
                      <input
                        id="acceptNewsLetter"
                        type="checkbox"
                        name="accept_newsletter"
                        checked={isNewsletterChecked}
                        onChange={handleNewsletterSwitchChange}
                        data-gtm-form-interact-field-id="0"
                      />
                      <span className="slider round"></span>
                    </label>
                    <label dir="auto" className="form-label condition-label">
                      <a
                        dir="auto"
                        href="/terms-of-service"
                        target="_blank"
                      >{`${languageFile.newsletterWarning}`}</a>
                    </label>
                  </div>
                </div>
                <input
                  maxLength="35"
                  type="hidden"
                  name="current_plan"
                  value={selectedPlan}
                />
                {selectedLanguage === 'en' ? (
                  <p dir="auto" className="register-tos">
                    {languageFile.registerAgreementMessage1}{' '}
                    <a dir="auto" href="/terms-of-service" target="_blank">
                      <strong>{languageFile.acceptTosSubmit2}</strong>
                    </a>{' '}
                    {languageFile.acceptTosSubmit3}{' '}
                    <a dir="auto" href="/privacy-policy" target="_blank">
                      <strong>{languageFile.acceptTosSubmit4}</strong>
                    </a>
                  </p>
                ) : (
                  <p dir="auto" className="register-tos">
                    {languageFile.registerAgreementMessage1}
                    <a dir="auto" href="/terms-of-service" target="_blank">
                      <strong>{languageFile.acceptTosSubmit2}</strong>
                    </a>{' '}
                    <a dir="auto" href="/privacy-policy" target="_blank">
                      <strong>{languageFile.acceptTosSubmit3}</strong>
                    </a>
                  </p>
                )}
                <div className="recpatcha-container recaptcha-container-register">
                  <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} />
                </div>
                <div
                  className={`register-form-btns ${
                    selectedLanguage === 'he' ? 'register-form-btns-hebrew' : ''
                  }`}
                >
                  <button
                    type="submit"
                    className="btn register-form-btn register-form-submit"
                    id="registerSubmit"
                  >
                    {languageFile.registerNowButton}
                  </button>
                  <a
                    dir="auto"
                    type="button"
                    href="/login"
                    className="btn register-form-btn register-form-register"
                    id="registerRegisterBtn"
                  >
                    {languageFile.registerHaveAnAccount}?
                  </a>
                </div>
                {/* <div className={`animation-parent`} id='loading-spinner-register2'>
                          <div className={`animation animation-circle`}></div>
                        </div> */}
                <div
                  id="animationScreen"
                  className={`loading-animation-general`}
                >
                  <HashLoader
                    color="#1A56DB"
                    loading={showAnimation}
                    size={60}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          id="registerPlans"
          className={`fade-in-top ${
            isVisibleEffect ? 'visible' : ''
          } register-plans-container ${
            isPlanSelected || isPlanEnterPriseSelected
              ? 'register-plans-hide'
              : 'register-plans-show'
          }`}
        >
          <div className="section section-register-plans">
            <div className="section-banner">
              <h1 dir="auto" className="section-title">
                {languageFile.chooseAPlan}
              </h1>
              <div className="title-underline"></div>
              <p dir="auto">{languageFile.chooseAPlanText}.</p>
            </div>
            <div className="section-plan-billing-duration">
              <p dir="auto">{languageFile.chooseAPlanDiscount}.</p>
              <div className="form-row form-row-condition section-plan-billing-duration-switch">
                <p dir="auto">{languageFile.chooseAPlanDiscountMonthly}</p>
                <label className="form-switch">
                  <input
                    id="billingType"
                    type="checkbox"
                    name="billingType"
                    data-gtm-form-interact-field-id="0"
                    checked={isPlanBillingDurationChecked}
                    onChange={changeplanBilling}
                  />
                  <span className="slider round"></span>
                </label>
                <p dir="auto">{languageFile.chooseAPlanDiscountYearly}</p>
              </div>
            </div>
            <div className="section-body register-plans">
              <div
                className={`register-plan plan-cell1 gray-out${
                  plan1CellVisited ? 'visited' : ''
                }`}
                onClick={plan1CellVisitedFunc}
              >
                <h1 dir="auto" className="section-title plan-title">
                  {languageFile.freePlanTitle}
                </h1>
                <p dir="auto">{languageFile.freePlanBanner}.</p>
                <h1 dir="auto" className="section-title">
                  {languageFile.freePlanFeaturesTitle}
                </h1>
                <ul>
                  <li dir="auto">
                    {languageFile.freePlanFeatures1}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.freePlanFeatures2}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.freePlanFeatures3}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.freePlanFeatures4}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.freePlanFeatures5}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.freePlanFeatures6}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                </ul>
                <a
                  dir="auto"
                  className="btn btn-plan"
                  onClick={freePlanSelected}
                >
                  {languageFile.freePlanJoin}
                </a>
              </div>
              <div
                className={`register-plan plan-cell2 ${
                  plan2CellVisited ? 'visited' : ''
                }`}
                onClick={plan2CellVisitedFunc}
              >
                <i className="fa-solid fa-crown"></i>
                <h1 dir="auto" className="section-title plan-title">
                  {languageFile.premiumPlanTitle}
                </h1>
                <div className="plan-price">
                  <span>
                    <p dir="auto">{planPremiumPrice}</p>
                    <i className="fa-solid fa-shekel-sign"></i>
                  </span>
                </div>
                <p dir="auto">{languageFile.premiumPlanBanner}.</p>
                <h1 dir="auto" className="section-title">
                  {languageFile.premiumPlanFeaturesTitle}
                </h1>
                <ul>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures1}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures2}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures3}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures4}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures5}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.premiumPlanFeatures6}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                </ul>
                <a
                  dir="auto"
                  className="btn btn-plan"
                  onClick={premiumPlanSelected}
                >
                  {languageFile.premiumPlanJoin}
                </a>
              </div>
              <div
                className={`register-plan plan-cell3 gray-out ${
                  plan3CellVisited ? 'visited' : ''
                }`}
                onClick={plan3CellVisitedFunc}
              >
                <h1 dir="auto" className="section-title plan-title">
                  {languageFile.enterprisePlanTitle}
                </h1>
                <p dir="auto">{languageFile.enterprisePlanBanner}.</p>
                <h1 dir="auto" className="section-title">
                  {languageFile.enterprisePlanFeaturesTitle}
                </h1>
                <ul>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures1}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures2}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures3}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures4}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures5}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                  <li dir="auto">
                    {languageFile.enterprisePlanFeatures6}{' '}
                    <i className="fa-solid fa-square-check"></i>
                  </li>
                </ul>
                <a
                  dir="auto"
                  className="btn btn-plan"
                  onClick={enterprisePlanSelected}
                >
                  {languageFile.enterprisePlanJoin}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`form-alert-register form-alert cform-response ${
            submissionStatus ? 'form-alert-success' : 'form-alert-error'
          }`}
        >
          <p dir="auto">{responseMessage}</p>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Register;
