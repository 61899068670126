import React, { useState, useContext } from 'react';
import { useGlobalContext } from '../../../context/AppContext';
import { fetchResponseHandler } from '../../../utils/fetchResponses';

function Association({
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  currentQuestionID,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [showAssociations, setShowAssociations] = useState(false);
  const [associationsData, setAssociationsData] = useState('');
  const [isAssociationHovered, setIsAssociationHovered] = useState(false);

  const handleMouseEnterAssociation = () => {
    setIsAssociationHovered(true);
  };

  const handleMouseLeaveAssociation = () => {
    setIsAssociationHovered(false);
  };

  let isFunctionRunningAssociations = false;

  const handleAssociationClick = async () => {
    if (isFunctionRunningAssociations) {
      return;
    }

    isFunctionRunningAssociations = true;

    if (showAssociations) setShowAssociations(false);
    else {
      try {
        const response = await fetch(
          `/word/psychometry/associations/${currentQuestionID.substring(2)}`,
          {
            method: 'GET',
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await response.json();
        if (responseData.success === false) {
          setShowAssociations(false);
          setShowAssociationsEdit(true);

          selectedLanguage === 'en'
            ? setAssociationsDataTemp('please enter your association')
            : setAssociationsDataTemp('אנא הכניסו את האסוציאציה שלכם');

          isFunctionRunningAssociations = false;
        } else {
          setShowAssociations(true);
          setAssociationsData(responseData.data);
          setShowAssociationsEdit(false);
          isFunctionRunningAssociations = false;
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
        isFunctionRunningAssociations = false;
      }
    }
  };

  const [showAssociationsEdit, setShowAssociationsEdit] = useState(false);
  const handleEditAssociation = async () => {
    setShowAssociations(false);
    setShowAssociationsEdit(true);
  };

  const handleAssociationEdit = async () => {
    try {
      const formData = new FormData();
      formData.append('association', associationsDataTemp);

      const response = await fetch(
        `/word/psychometry/associations/${currentQuestionID.substring(2)}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setShowAssociations(true);
        setShowAssociationsEdit(false);
        setAssociationsData(associationsDataTemp);
      }
    } catch (error) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const [associationsDataTemp, setAssociationsDataTemp] =
    useState(associationsData);
  const handleAssociationEditInput = (event) => {
    setAssociationsDataTemp(event.target.value);
  };

  const handleAssociationEditExit = () => {
    setShowAssociations(true);
    setShowAssociationsEdit(false);
    setAssociationsDataTemp(associationsData);
  };

  return (
    <div className="associations-parent">
      <div
        onClick={handleAssociationClick}
        className={`association-header gray-out ${
          selectedLanguage === 'en' ? 'reverse-elements' : ''
        }`}
        onMouseEnter={handleMouseEnterAssociation}
        onMouseLeave={handleMouseLeaveAssociation}
      >
        {selectedLanguage === 'en' ? (
          <React.Fragment>
            <i
              class={`${
                isAssociationHovered ? 'fa-solid' : 'fa-regular'
              } fa-heart`}
            ></i>
            <span>associations</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>אסוציאציות</span>
            <i
              class={`${
                isAssociationHovered ? 'fa-solid' : 'fa-regular'
              } fa-heart`}
            ></i>
          </React.Fragment>
        )}
      </div>
      {showAssociations && (
        <div className="association-text visible">
          <p>{associationsData}</p>
          <i
            onClick={handleEditAssociation}
            className="edit-association fa-solid fa-pencil"
          ></i>
        </div>
      )}
      {showAssociationsEdit && (
        <div className="association-edit association-text visible">
          <i
            onClick={handleAssociationEditExit}
            className="exit-association fa-solid fa-xmark"
          ></i>
          <input
            name="association-edit"
            id="associationEditInput"
            maxLength="100"
            onChange={handleAssociationEditInput}
            value={associationsDataTemp}
          />
          <i
            onClick={handleAssociationEdit}
            className="confirm-association fa-solid fa-check"
          ></i>
        </div>
      )}
    </div>
  );
}

export default Association;
