export function fetchResponseHandler(responseData) {
  // English --> Hebrew
  const regexPatternEmailVerification = /^please wait (\d+) seconds before requesting the code again$/;
  const regexPatternEmailVerificationMinutes = /^please wait (\d+) minutes before requesting the code again$/;
  const match = responseData.match(regexPatternEmailVerification);
  const match2 = responseData.match(regexPatternEmailVerificationMinutes);

  // Check if the response data matches the regex pattern
  if (match) {
    const seconds = parseInt(match[1], 10);

    const message = `אנא המתינו ${seconds} שניות לפני שאתם שולחים בקשה לקוד נוסף`;

    return message;
  }else if (match2) {
    const minutes = parseInt(match2[1], 10);

    const message = `אנא המתינו ${minutes} דקות לפני שאתם שולחים בקשה לקוד נוסף`;

    return message;
  }

  switch (responseData) {
    case "psychometry":
    case "Psychometry":
      return "פסיכומטרי";

    case "bagrut":
      case "Bagrut":
        return "פסיכומטרי";

    case "could not fetch words":
      return "הפעולה להשגת המילים לא הצליחה";

    case "error starting the exam":
      return "שגיאה בהתחלת הבחינה"

    case "Please make sure to accept our terms of service":
      return "בבקשה תוודאו שאישרתם את התנאים שלנו"   

    case "Please make sure to use a valid email":
      return "בבקשה תוודאו שהזנתם מייל תקין"
  
    case "Please make sure to use a valid first name":
      return "בבקשה תוודאו שהזנתם שם פרטי תקין"

    case "Please make sure to use a valid last name":
      return "בבקשה תוודאו שהזנתם שם משפחה תקין"

    case "Please make sure to use a valid mobile number":
      return "בבקשה תוודאו שהזנתם מספר טלפון תקין"

    case "Please make sure all fields are valid":
      return "בבקשה תוודאו שהזנתם כל מה שצריך"

    case "Email already taken":
      return "כתובת המייל תפוסה"

    case "Mobile number already taken":
      return "מספר הטלפון כבר תפוס"

    case "Please make sure to use a valid message":
      return "בבקשה תוודאו שהזנתם הודעה תקינה"

    case "Wrong email or password":
      return "כתובת המייל או הסיסמה לא נכונים"

    case "Please make sure to choose a valid plan":
      return "בבקשה תוודאו שבחרתם בחבילה קיימת"

    case "The request did'nt work with the provided code":
      return "הבקשה לא הצליחה עם הקוד שהזנתם"

    case "Please follow the password guidelines and don't use the current password":
      return "בבקשה עקבו אחר מדיניות יצירת הסיסמה ואל תשתמשו בסיסמה הנוכחית"

    case "The request did'nt work with the provided code, please try again with a new code":
      return "הבקשה לא הצליחה עם הקוד שהזנתם, אנא נסו שוב עם קוד חדש"

    case "Please make sure both passwords are the same":
      return "בבקשה תוודאו ששתי הסיסמאות תואמות"

    case "Please make sure to use a valid birth date":
      return "בבקשה תוודאו שהזנתם תאריך תקין"

    case "Please try again later":
      return "אנא נסו שוב מאוחר יותר"

    case "Account was created successfully":
      return "חשבון נוצר בהצלחה"

    case "Password was changed successfully":
      return "הסיסמה שונתה בהצלחה"

    case "If the email is valid, please check your inbox for instructions":
      return "אם כתובת המייל תקינה, אנא בדקו את תיבת המייל שלכם"

    case "If the mobile is valid, please check your inbox for the code":
      return "אם מספר הטלפון תקין, אנא בדקו את תיבת ההודעות שלכם"

    case "Email address has been verified, does'nt exist or the provided code is wrong":
      return "כתובת המייל כבר אומתה, לא קיימת, או הקוד שהזנתם לא תקין"

    case "Mobile has been verified, does'nt exist or the provided code is wrong":
      return "מספר הטלפון כבר אומת, לא קיים, או הקוד שהזנתם לא תקין"

    case "The code has been sent again successfully":
      return "הקוד נשלח מחדש בהצלחה"

    case "You have been blocked, please try again in 15 minutes":
      return "חשבונכם נחסם, אנא נסו שוב בעוד 15 דקות"

    case "You are blocked, please contact our support team":
      return "חשבונכם חסום, אנא צרו קשר עם הצוות שלנו"

    case "Email address has been verified":
      return "כתובת המייל אומתה בהצלחה"

    case "Mobile has been verified":
      return "מספר הטלפון אומת בהצלחה"

    case "Your account has been unblocked, please login again":
      return "חשבונכם פעיל, אנא התחברו מחדש"

    case "login was successful":
      return "התחברתם בהצלחה"

    case "Signed successfully":
      return "הצטרפתם בהצלחה"

    case "Your message was sent successfully":
      return "ההודעה נשמרה בהצלחה"

    case "Please make sure to use a valid company name":
      return "בבקשה תוודאו שהזנתם שם חברה תקינה"

    case "Please make sure to use a valid number of participants":
      return "בבקשה תוודאו שהזנתם מספר משתמשים תקין"

    case "Thank you for your interest, we will contact you soon":
      return "תודה על ההתעניינות שלכם, ניצור קשר בהקדם"

    case "Please make sure to use a valid category":
      return "בבקשה תוודאו שהזנתם קטגוריה תקינה"

    case "You are blocked, please try again in 15 minutes":
      return "אתם חסומים, בבקשה נסו שוב בעוד 15 דקות"

    case "The captcha has failed":
      return "בדיקת הcaptcha נכשלה"

    case "Something went wrong with the CSRF token, please refresh the page":
      return "בדיקת הCSRF נכשלה, אנא רעננו את העמוד"

    case "CSRF Token is missing, please refresh the page":
      return "הCSRF חסר, אנא רעננו את העמוד"

    case "Bad Token":
      return "מזהה התחברות לא תקינה"

    case "Authorized":
      return "מזהה התחברות תקין"

    case "Unauthorized":
      return "לא מורשה";

    case "Changed Successfully":
      return "השינוי בוצע בהצלחה";

    case "Failed, please try again later":
      return "הבקשה נכשלה, אנא נסו שוב מאוחר יותר";

    case "Phone number is taken, not valid, or not supported":
      return "מספר הטלפון תפוס, לא זמין, או לא נתמך";

    case "The user is either blocked, not verified, or does'nt exist":
      return "החשבון או חסום, לא מאומת, או לא קיים";

    case "The code is no longer available, please try again later":
      return "הקוד לא זמין יותר, אנא נסו שוב מאוחר יותר";

    case "The User is deleted":
      return "החשבון נמחק";

    case "Correct answer":
      return "תשובה נכונה";

    case "Wrong answer":
      return "תשובה לא נכונה";

    case "error classifying":
      return "שגיאה בתהליך הסיווג";

    case "classified successfully":
      return "סווג בהצלחה";

    case "could not classify word":
      return "הסיווג נכשל";

    case "no such classification":
      return "אין סיווג כזה";

    case "no stored associations":
      return "אין אסוציאציות שמורות";

    case "changed successfully":
      return "שונה בהצלחה";

    case "added successfully":
      return "נוסף בהצלחה";

    case "Words yet to be classified":
      return "לא נמצאו מילים מסווגות";

    case "Please make sure to have a password with only English, special, and digit characters":
      return "אנא וודאו שהסיסמה שלכם מכילה אך ורק אותיות באנגלית, סימנים, ומספרים";

    case "Please make sure to have a password length of minimum 10 characters":
      return "אנא וודאו שהסיסמה שלכם באורך של לפחות 10 תווים";  

    case "Please make sure to have a password length of maximum 35 characters":
      return "אנא וודאו שהסיסמה שלכם מכילה עד 35 תווים";

    case "Please make sure to have a password with at least one uppercase character":
      return "אנא וודאו שהסיסמה שלכם מכילה לפחות אות גדולה אחת באנגלית";  

    case "Please make sure to have a password with at least one lowercase character":
      return "אנא וודאו שהסיסמה שלכם מכילה לפחות אות קטנה אחת";

    case "Please make sure to have a password with at least one digit character":
      return "אנא וודאו שהסיסמה שלכם מכילה לפחות מספר אחד";  

    case "Please make sure to have a password with at least one special character":
      return "אנא וודאו שהסיסמה שלכם מכילה לפחות אות מיוחדת אחת";

    case "Please make sure to have a password with no space character":
      return "אנא וודאו שהסיסמה שלכם אינה מכילה רווחים";  

    case "please verify your email address before continuing":
      return "אנא אמתו את כתובת המייל שלכם לפני השימוש במערכת";

    default:
      return "הבקשה נכשלה"
  } 
}