import React, { useState, useEffect, useContext } from 'react';
import './add-custom-unit.css';
import { useGlobalContext } from '../../../context/AppContext';

function AddCustomUnit({
  isModalClosed,
  setIsModalClosed,
  setModalType,
  setModalTitle,
  setModalDescription,
  setModalInformation,
  showAddCustomUnitModal,
  setShowAddCustomUnitModal,
  showAddNewWordMenu,
  setShowAddNewWordMenu,
  newWordsArray,
  setNewWordsArray,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const closeModal = () => {
    setShowAddCustomUnitModal(!showAddCustomUnitModal);
  };

  const [unitTitle, setUnitTitle] = useState('');
  const handleUnitTitle = (event) => {
    setUnitTitle(event.target.value);
  };

  const [isShareChecked, setIsShareChecked] = useState(false);

  const handleShareSwitchChange = () => {
    setIsShareChecked(!isShareChecked); // Toggle the checked state
  };

  const removeWord = (element) => {
    const current_element = element.target;
    const current_element_parent = element.target.parentNode;

    if (
      current_element.localName === 'i' &&
      current_element_parent.localName === 'span'
    ) {
      current_element_parent.parentNode.removeChild(current_element_parent);
    } else {
      current_element.parentNode.removeChild(current_element);
    }
  };

  const openAddNewWordMenu = () => {
    setShowAddNewWordMenu(true);
  };

  return (
    <div
      id="CustomUnitModal"
      className={`alert-modal ${showAddCustomUnitModal ? 'visible' : ''}`}
    >
      <i
        id="closeUnitModal"
        className="fa-solid fa-xmark"
        onClick={closeModal}
      ></i>
      <p className="unit-title">{languageFile.newUnit}</p>
      <form className="custom-main">
        <div className="rform-row rform-row-label new-unit-title">
          <label htmlFor="UnitTitle">{languageFile.unitTitle}</label>
        </div>
        <div className="rform-row rform-row-input new-unit-title-input">
          <input
            id="UnitTitle"
            type="text"
            name="unitTitle"
            onChange={handleUnitTitle}
            value={unitTitle}
            maxLength="15"
          />
        </div>
        <div className="rform-row rform-row-label new-unit-title">
          <label htmlFor="UnitShareable">{languageFile.UnitShareable}</label>
        </div>
        <div
          id="new-unit-checkbox"
          className="form-row form-row-condition form-row-condition-register"
        >
          <label className="form-switch">
            <input
              id="acceptContact"
              type="checkbox"
              name="shareUnit"
              data-gtm-form-interact-field-id="0"
              checked={isShareChecked}
              onChange={handleShareSwitchChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="rform-row rform-row-label new-unit-title">
          <label htmlFor="UnitNewWordsList">{languageFile.UnitShareable}</label>
        </div>
        <div id="UnitNewWordsList" className="new-unit-words">
          {newWordsArray.map((word, index) => (
            <span className="new-word" onClick={removeWord} key={index}>
              {word}
              <i className="fa-solid fa-xmark delete-word"></i>
            </span>
          ))}
          <span
            className="new-word new-word-menu-btn-add"
            onClick={openAddNewWordMenu}
          >
            <i className="fa-solid fa-plus add-word-menu-btn"></i>
          </span>
        </div>
        <button className="btn new-unit-btn" type="submit">
          {languageFile.submitNewUnit}
        </button>
      </form>
    </div>
  );
}

export default AddCustomUnit;
