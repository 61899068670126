import React, { useContext, useState, useEffect } from 'react';
import './StartScreen.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  clearExamMode,
  setExamInProgressCookie,
  setExamStopper,
  setExamStartedCookie,
  getTopicCookie,
  getQuestionIdExamCookie,
  getStudyUnitCookie,
  getCurrectQuestionCookie,
  setCurrentTutorialStepCookie,
} from '../../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';

function StartScreen({
  stepIndex,
  setStepIndex,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  questionsJsonArray,
  numberOfQuestions,
  setShowAnimation,
  stopperInterval,
  stopperCookieInterval,
  setRunStopper,
  questionsLoaded,
  openStartScreen,
  setOpenStartScreen,
  setStartExam,
  unit,
  questionsCounter,
  examTime,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  const storedTopic = getTopicCookie();
  const selectedUnit = getStudyUnitCookie();

  const startExamHandler = async () => {
    if (buttonWorking) {
      setStepIndex(parseInt(stepIndex, 10) === 1 ? 2 : stepIndex);
      setCurrentTutorialStepCookie(
        parseInt(stepIndex, 10) === 1 ? 2 : stepIndex
      );

      setStartExam(true);
      setExamStartedCookie(true);
      setRunStopper(true);

      setShowAnimation(false);
    }
  };

  const [buttonWorking, setButtonWorking] = useState(false);

  useEffect(() => {
    if (questionsLoaded) {
      setButtonWorking(true);
      setShowAnimation(false);
    }
  }, [questionsLoaded]);

  const abortExamHandler = async () => {
    try {
      setShowAnimation(true);
      const response = await fetch(
        `/update-classification-counter/${storedTopic}`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setModalInformation('');
        setModalType('success');
        setModalTitle('success');

        if (selectedLanguage === 'en') {
          setModalDescription('the exam was aborted successfully');
        } else {
          setModalDescription('הבחינה נמחקה בהצלחה');
          setModalTitle('בהצלחה');
        }

        setIsModalClosed(false);
        clearExamMode();
        clearInterval(stopperInterval);
        clearInterval(stopperCookieInterval);
        setExamStopper(0);

        setTimeout(() => {
          setIsModalClosed(true);
          window.location = '/environment';
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  return (
    <div id="examStartScreen">
      <div id="startScreenPanel" className="start-screen-panel">
        <h1 dir="auto">{languageFile.unitOption3}</h1>
        <h3 dir="auto">{languageFile.examRule}</h3>
        <p dir="auto">{languageFile.examDetails}</p>
        <ul>
          {examTime === '0' ? (
            <li dir="auto" id="timeLimit">
              {languageFile.noTimeLimit}
            </li>
          ) : (
            <li dir="auto" id="timeLimit">
              {examTime} {languageFile.minutes}
            </li>
          )}
          <li dir="auto" id="questionsCounter">
            {questionsCounter} {languageFile.questions}
          </li>
          <li dir="auto" id="questionsUnit">
            {languageFile.unit} {unit.substring(5)}
          </li>
        </ul>
        <button
          id="startExamBtn"
          onClick={startExamHandler}
          className={`btn btn-start-screen btn-start-specific-screen ${
            buttonWorking ? '' : 'gray-out-btn'
          }`}
        >
          {languageFile.startExam}
        </button>
        <p className="abort-exam-p-start" onClick={abortExamHandler}>
          {languageFile.stopTheExam}
        </p>

        <p dir="auto" id="goodLuckText">
          {languageFile.goodLuckText}!
        </p>
      </div>
    </div>
  );
}

export default StartScreen;
