import React, { useEffect, useContext, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import './cookies-policy.css';
import ContactSection from '../HomePage/ContactSection/ContactSection';
import { useGlobalContext } from '../../context/AppContext';
import AlertModal from '../AlertModal/AlertModal';
import {
  getLoginCookie,
  verifySessionCookie,
  getSessionCookie,
} from '../../utils/cookieUtils';
import CookieAgreement from '../../utils/CookieAgreement';
import LoggedFooter from '../Environment/LoggedFooter/LoggedFooter';

function CookiesPolicy() {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useGlobalContext();
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Cookies Policy' : 'מדיניות עוגיות'
    }`; // Set the title to "Milly | Cookies Policy"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  const loggedUser = verifySessionCookie(getSessionCookie());

  return (
    <React.Fragment>
      <CookieAgreement />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <NavBar
        classAdditional
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={loggedUser}
      />
      {selectedLanguage === 'en' ? (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">Cookies Policy</h1>
          <div className="document-contents">
            <p className="update-date"> Last updated: September 9, 2023</p>

            <p>
              Please read this cookie policy (“cookie policy”, “cookies policy”,
              "policy") carefully before using MillyLearn website (“website”,
              "service") operated by Milly Team ("us", 'we", "our").
            </p>

            <h3>What are cookies?</h3>
            <p>
              Cookies are simple text files that are stored on your computer or
              mobile device by a website’s server. Each cookie is unique to your
              web browser. It will contain some anonymous information such as a
              unique identifier, website’s domain name, and some digits and
              numbers.
            </p>

            <h3>What types of cookies do we use?</h3>
            <p>
              <strong>Necessary cookies</strong> allow us to offer you the best
              possible experience when accessing and navigating through our
              website and using its features. For example, these cookies let us
              recognize that you have created an account and have logged into
              that account.
            </p>
            <p>
              <strong>Functionality cookies</strong> let us operate the site in
              accordance with the choices you make. For example, we will
              recognize your username and remember how you customized the site
              during future visits.
            </p>
            {/* <p><strong>Analytical cookies</strong> enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the website.</p> */}
            {/* <p><strong>Marketing cookies</strong> enable us to track user behavior and provide personalized content and advertisements. These cookies may be used for targeted marketing efforts.</p> */}

            <h3>How to delete cookies?</h3>
            <p>
              If you want to restrict or block the cookies that are set by our
              website, you can do so through your browser setting.
              Alternatively, you can visit www.internetcookies.com, which
              contains comprehensive information on how to do this on a wide
              variety of browsers and devices. You will find general information
              about cookies and details on how to delete cookies from your
              device.
            </p>

            <h3>Consent</h3>
            <p>
              By using our website, you consent to the use of cookies as
              described in this policy. You also have the option to manage your
              cookie preferences.
            </p>
          </div>
        </main>
      ) : (
        <main className="main-document" id="main">
          <h1 className="section-title document-title">מדיניות עוגיות</h1>
          <div className="document-contents">
            <p className="update-date">עודכן לאחרונה: 9 בספטמבר, 2023</p>

            <p dir="auto">
              אנא קראו מדיניות זו בנושא שימוש בעוגיות ("מדיניות קובצי Cookie",
              "מדיניות קובצי עוגיות", "מדיניות עוגיות", "מדיניות") בעיון לפני
              השימוש באתר MillyLearn ("אתר", "שירות") המופעל על ידי צוות Milly
              ("אנחנו", "אנחנו", "שלנו" ).
            </p>

            <h3 dir="auto">מה הן עוגיות?</h3>
            <p dir="auto">
              קובצי עוגיות הן קבצי טקסט פשוטים המאוחסנים במחשב או במכשיר הנייד
              שלך על ידי שרת של אתר אינטרנט. כל עוגיה היא ייחודית לדפדפן
              האינטרנט שלך. הוא יכיל מידע אנונימי כמו מזהה ייחודי, שם הדומיין של
              האתר וכמה ספרות ומספרים.
            </p>

            <h3 dir="auto">אילו סוגי עוגיות אנחנו משתמשים?</h3>
            <p dir="auto">
              <strong>עוגיות הכרחיות</strong> מאפשרות לנו להציע לך את החוויה
              הטובה ביותר האפשרית בעת גישה וניווט באתר שלנו ושימוש בתכונות שלו.
              לדוגמה, קובצי עוגיות אלה מאפשרים לנו לזהות שיצרת חשבון ונכנסת
              לחשבון זה.
            </p>
            <p dir="auto">
              <strong>עוגיות פונקציונליות</strong> מאפשרות לנו להפעיל את האתר
              בהתאם לבחירות שאתה עושה. לדוגמה, נזהה את שם המשתמש שלך ונזכור כיצד
              התאמת את האתר בביקורים עתידיים.
            </p>
            {/* <p><strong>עוגיות אנליטיות</strong> מאפשרות לנו ולשירותי צד שלישי לאסוף נתונים מצטברים למטרות סטטיסטיות על האופן שבו המבקרים שלנו משתמשים באתר. עוגיות אלו אינן מכילות מידע אישי כגון שמות וכתובות דוא"ל ומשמשות כדי לעזור לנו לשפר את חווית המשתמש שלך באתר.</p> */}
            {/* <p><strong>עוגיות שיווקיות</strong> מאפשרות לנו לעקוב אחר התנהגות של משתמשים ולספק תוכן ופרסומות מותאמים אישית. קובצי עוגיות אלה עשויים לשמש למאמצי שיווק ממוקדים.</p> */}

            <h3 dir="auto">כיצד ניתן למחוק עוגיות?</h3>
            <p dir="auto">
              אם ברצונך להגביל או לחסום את העוגיות המוגדרות על ידי האתר שלנו,
              תוכל לעשות זאת דרך הגדרת הדפדפן שלך. לחלופין, אתה יכול לבקר באתר
              הבא, www.internetcookies.com, המכיל מידע מקיף כיצד לעשות זאת
              במגוון רחב של דפדפנים ומכשירים. תמצא מידע כללי על קובצי עוגיות
              ופרטים כיצד למחוק קובצי עוגיות מהמכשיר שלך.
            </p>

            <h3 dir="auto">הסכמה</h3>
            <p dir="auto">
              על ידי שימוש באתר שלנו, אתה מסכים לשימוש בעוגיות כמתואר במדיניות
              זו. יש לך גם אפשרות לנהל את העדפות העוגיות שלך.
            </p>
          </div>
        </main>
      )}

      <ContactSection
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        setModalType={setModalType}
        setModalTitle={setModalTitle}
        setModalDescription={setModalDescription}
        setModalInformation={setModalInformation}
        id="sectionContact"
        className="section-contact"
      />
      {loggedUser ? (
        <LoggedFooter
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      ) : (
        <Footer
          isModalClosed={isModalClosed}
          setIsModalClosed={setIsModalClosed}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setModalInformation={setModalInformation}
        />
      )}
    </React.Fragment>
  );
}

export default CookiesPolicy;
