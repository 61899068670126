import React, { useState, useEffect, useContext, useRef } from 'react';
import './platform.css';
import CookieAgreement from '../../utils/CookieAgreement';
import { useGlobalContext } from '../../context/AppContext';
import {
  clearExamMode,
  getExamSelectedAnswerCheckJSONCookie,
  getTopicCookie,
  getExamEndCookie,
  getExamSelectedAnswerJSONCookie,
  getFinishedQuestionsCounter,
  setExamPauseCookie,
  setExamStartedCookie,
  getExamStartedCookie,
  getExamPauseCookie,
  getExamStopper,
  getStudyExamTime,
  getStudyModeCookie,
  getStudyUnitCookie,
  getQuestionIdCookie,
  getMemorizationClassificationCookie,
  getFinishedQuestions,
  getExamCorrectQuestionsCounter,
  getExamIncorrectQuestionsCounter,
  getExamSelectedClassificationJSONCookie,
  getQuestionIdExamCookie,
  setExamEndCookie,
  setExamCorrectQuestionsCounter,
  setExamIncorrectQuestionsCounter,
  setExamSelectedAnswerCheckJSONCookie,
  setExamSelectedClassificationJSONCookie,
  setCurrentTutorialStepCookie,
  getStartTutorialCookie,
  getCurrentTutorialStepCookie,
  setStartTutorialCookie,
} from '../../utils/cookieUtils';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import MemorizeMode from './MemorizeMode/MemorizeMode';
import StudyMode from './StudyMode/StudyMode';
import ExamMode from './ExamMode/ExamMode';
import ProgressBarStudy from './StudyMode/ProgressBarStudy/ProgressBarStudy';
import ProgressBarExam from './ExamMode/ProgressBarExam/ProgressBarExam';
import ProgressBarMemorize from './MemorizeMode/ProgressBarMemorize/ProgressBarMemorize';
import AlertModal from '../AlertModal/AlertModal';
import HashLoader from 'react-spinners/HashLoader';

function Platform() {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');
  const currentUnit = getStudyUnitCookie();

  const [currentQuestionID, setCurrentQuestionID] = useState(
    getQuestionIdCookie()
  );
  const [currentQuestionIDExam, setCurrentQuestionIDExam] = useState(
    getQuestionIdExamCookie()
  );
  const [finishedQuestions, setFinishedQuestions] = useState([]);
  const [studyCompletedWordsCounter, setStudyCompletedWordsCounter] =
    useState(0);
  const [studyWordsCounter, setStudyWordsCounter] = useState(0);
  // Numeric question id.
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [lastUpdatedWordInDB, setLastUpdatedWordInDB] = useState(0);
  // Classification of current word
  const [currentQuestionClassification, setCurrentQuestionClassification] =
    useState(0);
  const [isFetching, setIsFetching] = useState(false);
  // FlippableCard in study
  const [showFront, setShowFront] = useState(true);
  // Study range of current unit words
  const [firstWordId, SetFirstWordId] = useState(0);
  const [lastWordId, SetLastWordId] = useState(0);
  // StudyMode arrows
  const [isValidPrevBtnStudy, setIsValidPrevBtnStudy] = useState(true);
  const [isValidNextBtnStudy, setIsValidNextBtnStudy] = useState(true);
  // A listener for next and prev arrows in study
  const [isPrevBtnStudyClicked, setIsPrevBtnStudyClicked] = useState(false);
  const [isNextBtnStudyClicked, setIsNextBtnStudyClicked] = useState(false);

  useEffect(() => {
    if (currentStudyMode === 'modeStudy') {
      const intervalId = setInterval(() => {
        updateLastWordId();
      }, 15000); // 15 seconds interval

      return () => clearInterval(intervalId);
    }
  }, [lastUpdatedWordInDB]);

  useEffect(() => {
    localStorage.setItem('isFetching', JSON.stringify(isFetching));
  }, [isFetching]);

  const updateLastWordId = async () => {
    // todo: set last word_id, total_words_classified
    // todo: if Fetching - wait till done and don't skip the handle before unload.
    if (localStorage.getItem('isFetching') === 'false') {
      const currentQuestionIdTemp = parseInt(
        localStorage.getItem('currentStudyQuestionId')
      );
      if (
        currentQuestionIdTemp !== lastUpdatedWordInDB &&
        lastUpdatedWordInDB !== 0
      ) {
        try {
          setIsFetching(true);
          const unitId = parseInt(currentUnit.substring(5), 10);
          const formData = new FormData();
          formData.append('word_id', parseInt(currentQuestionIdTemp));
          const response = await fetch(
            `/word/psychometry/study/update-last/${unitId}`,
            {
              method: 'POST',
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error('Error sending data to the server');
          }

          // You can handle the server response here if needed
          const responseData = await response.json();
          if (responseData.success === false) {
            if (selectedLanguage === 'en') {
              // alert(responseData.data);
            } else {
              let responseMsg = fetchResponseHandler(responseData.data);
              // alert(responseMsg);
            }
          } else {
            setLastUpdatedWordInDB(parseInt(currentQuestionIdTemp));
          }
        } catch (error) {
          console.error(error);

          if (selectedLanguage === 'en') {
            // alert("Submission Has Failed");
            console.log('Submission Has Failed');
          } else {
            // alert("הבקשה נכשלה");
            console.log('הבקשה נכשלה');
          }
        } finally {
          setIsFetching(false);
        }
      }
    }
  };

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Study Environment' : 'סביבת תרגול'
    }`; // Set the title to "Milly | Home"
    return () => {
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [openProgressBar, setOpenProgressBar] = useState(false);
  const handleOpenProgressBar = () => {
    setOpenProgressBar(!openProgressBar);
  };

  const currentStudyMode = getStudyModeCookie();

  const [currentQuestions, setCurrentQuestions] = useState(null);

  const [currentQuestionTitle, setCurrentQuestionTitle] = useState('');
  const [currentQuestionTranslate, setCurrentQuestionTranslate] = useState('');
  const [currentQuestionAnswers, setCurrentQuestionAnswers] = useState([]);

  const [showClassification, setShowClassification] = useState(false);
  const [finishedQuestionsArray, setFinishedQuestionsArray] = useState(
    getFinishedQuestions()
  );

  const [showAnimation, setShowAnimation] = useState(false);

  const [questionsCounter, setQuestionsCounter] = useState('');
  const [examTime, setExamTime] = useState(getStudyExamTime());
  const [unit, setUnit] = useState(getStudyUnitCookie());
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [finishedQuestionsCounter, setFinishedQuestionsCounter] = useState(
    parseInt(getFinishedQuestionsCounter(), 10)
  );
  const [currentStopper, setCurrentStopper] = useState(
    parseInt(getExamStopper(), 10) || 0
  );
  const [runStopper, setRunStopper] = useState(false);

  const [startExam, setStartExam] = useState(getExamStartedCookie());
  const [openPauseScreen, setOpenPauseScreen] = useState(getExamPauseCookie());
  const [openStartScreen, setOpenStartScreen] = useState(!startExam);
  const [openEndScreen, setOpenEndScreen] = useState(getExamEndCookie());

  const [stopperInterval, setStopperInterval] = useState(null);
  const [stopperCookieInterval, setStopperCookieInterval] = useState(null);

  const [stopRefInterval, setStopRefInterval] = useState(false);
  const [currentSelectedOptionAnswer, setCurrentSelectedOptionAnswer] =
    useState('');

  const [examSelectedAnswerJSON, setSxamSelectedAnswerJSON] = useState(
    getExamSelectedAnswerJSONCookie()
  );
  const [examSelectedClassificationJSON, setExamSelectedClassificationJSON] =
    useState(getExamSelectedClassificationJSONCookie());

  let topic = getTopicCookie();
  const syncStopper = async () => {
    const response = await fetch(
      `/start-exam/${topic}?unit_id=${currentUnit}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stopper: getExamStopper(),
          answered_questions_json_array: getExamSelectedAnswerJSONCookie(),
          current_question_id: parseInt(
            getQuestionIdExamCookie().substring(2),
            10
          ),
        }),
      }
    );
  };

  const [showNextPrevButtons, setShowNextPrevButtons] = useState(true);

  const [examAnswersResults, setExamAnswersResults] = useState(
    getExamSelectedAnswerCheckJSONCookie()
  );
  const [loadedAnswers, setLoadedAnswers] = useState(false);

  const [questionsCounterCorrectAnswers, setQuestionsCounterCorrectAnswers] =
    useState(parseInt(getExamCorrectQuestionsCounter(), 10));
  const [
    questionsCounterIncorrectAnswers,
    setQuestionsCounterIncorrectAnswers,
  ] = useState(parseInt(getExamIncorrectQuestionsCounter(), 10));

  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionsJsonArray, setQuestionsJsonArray] = useState(null);

  const checkAnswers = async () => {
    setShowAnimation(true);
    try {
      const response = await fetch(`/word/exam/${topic}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: Object.fromEntries(
            Object.entries(examSelectedAnswerJSON).filter(
              ([key, value]) => value !== null
            )
          ),
        }),
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setExamSelectedAnswerCheckJSONCookie(responseData.data);
        setExamAnswersResults(responseData.data);
        setLoadedAnswers(true);

        let correctCounter = 0;
        let incorrectCounter = 0;

        Object.entries(responseData.data).forEach(([key, value]) => {
          if (value[0]) {
            examSelectedClassificationJSON[key] = value[1];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            correctCounter++;
          } else {
            examSelectedClassificationJSON[key] = value[2];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            incorrectCounter++;
          }
        });
        setQuestionsCounterCorrectAnswers(correctCounter);
        setQuestionsCounterIncorrectAnswers(incorrectCounter);
        setExamCorrectQuestionsCounter(correctCounter);
        setExamIncorrectQuestionsCounter(incorrectCounter);

        setExamEndCookie(true);
        setOpenEndScreen(true);
        setExamPauseCookie(false);
        setOpenPauseScreen(false);
        setExamStartedCookie(false);
        setOpenStartScreen(false);
        setStartExam(false);

        syncStopper();
      }
    } catch (error) {
      console.log(error, 'platform');
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  useEffect(() => {
    if (
      currentStudyMode != 'modeStudy' &&
      currentStudyMode != 'modeMemorization' &&
      currentStudyMode != 'modeExam'
    ) {
      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Please make sure to select a valid study mode');
      } else {
        setModalDescription('אנא הקפדו לבחור מצב לימוד חוקי');
        setModalTitle('שגיאה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
        window.location = '/environment';
      }, 5000);
    }
  }, [currentStudyMode]);

  const handlePrevQuestionClick = () => {
    setIsPrevBtnStudyClicked(true);
  };

  const handleNextQuestionClick = () => {
    setIsNextBtnStudyClicked(true);
  };

  return (
    <React.Fragment>
      <AlertModal
        checkAnswers={checkAnswers}
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      <CookieAgreement />

      {showAnimation && (
        <div id="animationScreen" className={`loading-animation`}>
          <HashLoader color="#1A56DB" loading={showAnimation} size={60} />
        </div>
      )}

      {currentStudyMode === 'modeStudy' && (
        <ProgressBarStudy
          setLastUpdatedWordInDB={setLastUpdatedWordInDB}
          setIsNextBtnStudyClicked={setIsNextBtnStudyClicked}
          setIsPrevBtnStudyClicked={setIsPrevBtnStudyClicked}
          isPrevBtnStudyClicked={isPrevBtnStudyClicked}
          isNextBtnStudyClicked={isNextBtnStudyClicked}
          setIsValidPrevBtnStudy={setIsValidPrevBtnStudy}
          setIsValidNextBtnStudy={setIsValidNextBtnStudy}
          SetLastWordId={SetLastWordId}
          lastWordId={lastWordId}
          SetFirstWordId={SetFirstWordId}
          firstWordId={firstWordId}
          setShowFront={setShowFront}
          setIsFetching={setIsFetching}
          isFetching={isFetching}
          setCurrentQuestionClassification={setCurrentQuestionClassification}
          setCurrentQuestionId={setCurrentQuestionId}
          currentQuestionId={currentQuestionId}
          setShowAnimation={setShowAnimation}
          setStudyWordsCounter={setStudyWordsCounter}
          setStudyCompletedWordsCounter={setStudyCompletedWordsCounter}
          setCurrentQuestionTranslate={setCurrentQuestionTranslate}
          finishedQuestions={finishedQuestions}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          currentQuestionID={currentQuestionID}
          setCurrentQuestionID={setCurrentQuestionID}
          setCurrentQuestionTitle={setCurrentQuestionTitle}
          setCurrentQuestionAnswers={setCurrentQuestionAnswers}
        />
      )}
      {currentStudyMode === 'modeMemorization' && (
        <ProgressBarMemorize
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setShowClassification={setShowClassification}
          finishedQuestions={finishedQuestionsArray}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          currentQuestionID={currentQuestionID}
          setCurrentQuestionID={setCurrentQuestionID}
          currentQuestions={currentQuestions}
          currentQuestionTitle={currentQuestionTitle}
          setCurrentQuestionTitle={setCurrentQuestionTitle}
          setCurrentQuestionAnswers={setCurrentQuestionAnswers}
        />
      )}
      {currentStudyMode === 'modeExam' && (
        <ProgressBarExam
          setExamTime={setExamTime}
          setQuestionsJsonArray={setQuestionsJsonArray}
          setNumberOfQuestions={setNumberOfQuestions}
          showNextPrevButtons={showNextPrevButtons}
          setShowNextPrevButtons={setShowNextPrevButtons}
          examSelectedClassificationJSON={examSelectedClassificationJSON}
          setExamSelectedClassificationJSON={setExamSelectedClassificationJSON}
          questionsCounterCorrectAnswers={questionsCounterCorrectAnswers}
          questionsCounterIncorrectAnswers={questionsCounterIncorrectAnswers}
          setQuestionsCounterCorrectAnswers={setQuestionsCounterCorrectAnswers}
          setQuestionsCounterIncorrectAnswers={
            setQuestionsCounterIncorrectAnswers
          }
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          loadedAnswers={loadedAnswers}
          setLoadedAnswers={setLoadedAnswers}
          examAnswersResults={examAnswersResults}
          setExamAnswersResults={setExamAnswersResults}
          topic={topic}
          openEndScreen={openEndScreen}
          setOpenEndScreen={setOpenEndScreen}
          examSelectedAnswerJSON={examSelectedAnswerJSON}
          setSxamSelectedAnswerJSON={setSxamSelectedAnswerJSON}
          setCurrentSelectedOptionAnswer={setCurrentSelectedOptionAnswer}
          finishedQuestionsCounter={finishedQuestionsCounter}
          setFinishedQuestionsCounter={setFinishedQuestionsCounter}
          setUnit={setUnit}
          stopRefInterval={stopRefInterval}
          setStopRefInterval={setStopRefInterval}
          stopperCookieInterval={stopperCookieInterval}
          setStopperCookieInterval={setStopperCookieInterval}
          setCurrentStopper={setCurrentStopper}
          stopperInterval={stopperInterval}
          setStopperInterval={setStopperInterval}
          setRunStopper={setRunStopper}
          setOpenPauseScreen={setOpenPauseScreen}
          setStartExam={setStartExam}
          setOpenStartScreen={setOpenStartScreen}
          setQuestionsLoaded={setQuestionsLoaded}
          setQuestionsCounter={setQuestionsCounter}
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          currentQuestionID={currentQuestionIDExam}
          setCurrentQuestionID={setCurrentQuestionIDExam}
          currentQuestions={currentQuestions}
          currentQuestionTitle={currentQuestionTitle}
          setCurrentQuestionTitle={setCurrentQuestionTitle}
          setCurrentQuestionAnswers={setCurrentQuestionAnswers}
        />
      )}
      {currentStudyMode === 'modeStudy' && (
        <StudyMode
          updateLastWordId={updateLastWordId}
          handlePrevQuestionClick={handlePrevQuestionClick}
          handleNextQuestionClick={handleNextQuestionClick}
          isValidNextBtnStudy={isValidNextBtnStudy}
          isValidPrevBtnStudy={isValidPrevBtnStudy}
          firstWordId={firstWordId}
          lastWordId={lastWordId}
          showFront={showFront}
          setShowFront={setShowFront}
          setIsFetching={setIsFetching}
          isFetching={isFetching}
          currentQuestionClassification={currentQuestionClassification}
          currentQuestionId={currentQuestionId}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          setStudyCompletedWordsCounter={setStudyCompletedWordsCounter}
          studyWordsCounter={studyWordsCounter}
          studyCompletedWordsCounter={studyCompletedWordsCounter}
          currentQuestionTranslate={currentQuestionTranslate}
          setFinishedQuestions={setFinishedQuestions}
          finishedQuestions={finishedQuestions}
          currentQuestionID={currentQuestionID}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          handleOpenProgressBar={handleOpenProgressBar}
          currentQuestions={currentQuestions}
          currentQuestionTitle={currentQuestionTitle}
        />
      )}
      {currentStudyMode === 'modeMemorization' && (
        <MemorizeMode
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          showClassification={showClassification}
          setShowClassification={setShowClassification}
          finishedQuestions={finishedQuestionsArray}
          currentQuestionID={currentQuestionID}
          setCurrentQuestionID={setCurrentQuestionID}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          handleOpenProgressBar={handleOpenProgressBar}
          currentQuestions={currentQuestions}
          currentQuestionTitle={currentQuestionTitle}
          setCurrentQuestionTitle={setCurrentQuestionTitle}
          currentQuestionAnswers={currentQuestionAnswers}
          setCurrentQuestionAnswers={setCurrentQuestionAnswers}
        />
      )}
      {currentStudyMode === 'modeExam' && (
        <ExamMode
          showAnimation={showAnimation}
          questionsJsonArray={questionsJsonArray}
          numberOfQuestions={numberOfQuestions}
          setShowNextPrevButtons={setShowNextPrevButtons}
          showNextPrevButtons={showNextPrevButtons}
          checkAnswers={checkAnswers}
          examSelectedClassificationJSON={examSelectedClassificationJSON}
          setExamSelectedClassificationJSON={setExamSelectedClassificationJSON}
          setShowAnimation={setShowAnimation}
          setQuestionsCounterCorrectAnswers={setQuestionsCounterCorrectAnswers}
          setQuestionsCounterIncorrectAnswers={
            setQuestionsCounterIncorrectAnswers
          }
          questionsCounterCorrectAnswers={questionsCounterCorrectAnswers}
          questionsCounterIncorrectAnswers={questionsCounterIncorrectAnswers}
          loadedAnswers={loadedAnswers}
          setLoadedAnswers={setLoadedAnswers}
          examAnswersResults={examAnswersResults}
          setExamAnswersResults={setExamAnswersResults}
          topic={topic}
          openEndScreen={openEndScreen}
          setOpenEndScreen={setOpenEndScreen}
          setIsModalClosed={setIsModalClosed}
          setModalInformation={setModalInformation}
          setModalType={setModalType}
          setModalTitle={setModalTitle}
          setModalDescription={setModalDescription}
          examSelectedAnswerJSON={examSelectedAnswerJSON}
          setSxamSelectedAnswerJSON={setSxamSelectedAnswerJSON}
          setCurrentSelectedOptionAnswer={setCurrentSelectedOptionAnswer}
          finishedQuestionsCounter={finishedQuestionsCounter}
          setFinishedQuestionsCounter={setFinishedQuestionsCounter}
          stopRefInterval={stopRefInterval}
          setStopRefInterval={setStopRefInterval}
          stopperCookieInterval={stopperCookieInterval}
          setStopperCookieInterval={setStopperCookieInterval}
          stopperInterval={stopperInterval}
          setStopperInterval={setStopperInterval}
          startExam={startExam}
          setStartExam={setStartExam}
          openPauseScreen={openPauseScreen}
          setOpenPauseScreen={setOpenPauseScreen}
          openStartScreen={openStartScreen}
          setOpenStartScreen={setOpenStartScreen}
          currentStopper={currentStopper}
          setCurrentStopper={setCurrentStopper}
          runStopper={runStopper}
          setRunStopper={setRunStopper}
          questionsLoaded={questionsLoaded}
          questionsCounter={questionsCounter}
          setQuestionsCounter={setQuestionsCounter}
          examTime={examTime}
          setExamTime={setExamTime}
          unit={unit}
          setUnit={setUnit}
          currentQuestionID={currentQuestionIDExam}
          setCurrentQuestionID={setCurrentQuestionIDExam}
          openProgressBar={openProgressBar}
          setOpenProgressBar={setOpenProgressBar}
          handleOpenProgressBar={handleOpenProgressBar}
          currentQuestions={currentQuestions}
          currentQuestionTitle={currentQuestionTitle}
          setCurrentQuestionTitle={setCurrentQuestionTitle}
          currentQuestionAnswers={currentQuestionAnswers}
          setCurrentQuestionAnswers={setCurrentQuestionAnswers}
        />
      )}
    </React.Fragment>
  );
}

export default Platform;
