import React, { useEffect, useState, useContext } from 'react';
import './faqSection.css';
import { useGlobalContext } from '../../../context/AppContext';

function FaqSection({ id, className }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const tag = document.querySelector('.faq-title');
    if (tag) {
      const rect = tag.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'FAQ' : 'שאלות ותשובות'
    }`; // Set the title to "Milly | FAQ"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const [faq1Click, setFaq1Click] = useState(false);
  const [faq2Click, setFaq2Click] = useState(false);
  const [faq3Click, setFaq3Click] = useState(false);
  const [faq4Click, setFaq4Click] = useState(false);
  const [faq5Click, setFaq5Click] = useState(false);
  const [faq6Click, setFaq6Click] = useState(false);
  const [faq7Click, setFaq7Click] = useState(false);
  const [faq8Click, setFaq8Click] = useState(false);

  const faq1Handler = () => {
    if (faq1Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(true);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq2Handler = () => {
    if (faq2Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(true);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq3Handler = () => {
    if (faq3Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(true);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq4Handler = () => {
    if (faq4Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(true);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq5Handler = () => {
    if (faq5Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(true);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq6Handler = () => {
    if (faq6Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(true);
      setFaq7Click(false);
      setFaq8Click(false);
    }
  };

  const faq7Handler = () => {
    if (faq7Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(true);
      setFaq8Click(false);
    }
  };

  const faq8Handler = () => {
    if (faq8Click) {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(false);
    } else {
      setFaq1Click(false);
      setFaq2Click(false);
      setFaq3Click(false);
      setFaq4Click(false);
      setFaq5Click(false);
      setFaq6Click(false);
      setFaq7Click(false);
      setFaq8Click(true);
    }
  };

  const faqClicked = () => {
    const targetElement = document.getElementById('sectionFaq');
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <section
      id={id}
      className={`section fade-in-right ${className ? className : ''} ${
        isVisible ? 'visible' : ''
      }`}
    >
      <p dir="auto" className="section-title faq-title">
        <a onClick={faqClicked}>{languageFile.faqTitle}</a>
      </p>
      <div className="title-underline"></div>
      <h1 dir="auto" className="section-sub-title faq-sub-title">
        {languageFile.faqSubTitle1}{' '}
        <span dir="auto" className="highlight-text">
          {languageFile.faqSubTitle2}
        </span>
      </h1>
      <p dir="auto" className="section-info faq-info">
        {languageFile.faqAbout1}{' '}
        <span className="highlight-text">{languageFile.faqAbout2}</span>.
      </p>
      {selectedLanguage === 'en' ? (
        <div className="section-content faq-content">
          <div className="faq-content-question" onClick={faq1Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ1Q}?</p>
              <span
                className={`${
                  faq1Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq1Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq1Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ1A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq2Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ2Q}?</p>
              <span
                className={`${
                  faq2Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq2Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq2Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ2A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq3Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ3Q}?</p>
              <span
                className={`${
                  faq3Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq3Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq3Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ3A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq4Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ4Q}?</p>
              <span
                className={`${
                  faq4Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq4Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq4Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ4A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq5Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ5Q}?</p>
              <span
                className={`${
                  faq5Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq5Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq5Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ5A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq6Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ6Q}?</p>
              <span
                className={`${
                  faq6Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq6Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq6Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ6A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq7Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ7Q}?</p>
              <span
                className={`${
                  faq7Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq7Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq7Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ7A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq8Handler}>
            <div className="faq-content-question-header">
              <p dir="auto">{languageFile.faqQ8Q}?</p>
              <span
                className={`${
                  faq8Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq8Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq8Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ8A}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="section-content faq-content">
          <div className="faq-content-question" onClick={faq1Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ1Q}?</p>
              <span
                className={`${
                  faq1Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq1Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq1Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ1A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq2Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ2Q}?</p>
              <span
                className={`${
                  faq2Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq2Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq2Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ2A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq3Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ3Q}?</p>
              <span
                className={`${
                  faq3Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq3Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq3Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ3A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq4Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ4Q}?</p>
              <span
                className={`${
                  faq4Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq4Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq4Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ4A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq5Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ5Q}?</p>
              <span
                className={`${
                  faq5Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq5Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq5Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ5A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq6Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ6Q}?</p>
              <span
                className={`${
                  faq6Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq6Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq6Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ6A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq7Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ7Q}?</p>
              <span
                className={`${
                  faq7Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq7Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq7Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ7A}
              </p>
            </div>
          </div>
          <div className="faq-content-question" onClick={faq8Handler}>
            <div className="faq-content-question-header reverse-items">
              <p dir="auto">{languageFile.faqQ8Q}?</p>
              <span
                className={`${
                  faq8Click ? 'hide-faq-symbol' : 'show-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-down"></i>
              </span>
              <span
                className={`${
                  faq8Click ? 'show-faq-symbol' : 'hide-faq-symbol'
                }`}
              >
                <i className="fa-solid fa-arrow-turn-up"></i>
              </span>
            </div>
            <div className={`faq-content-question-body`}>
              <p
                dir="auto"
                className={`${faq8Click ? 'faq-clicked' : 'faq-not-clicked'}`}
              >
                {languageFile.faqQ8A}
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default FaqSection;
