import "./error-page.css"
import React from 'react'
import NotFound from "./NotFound/NotFound";
import BadRequest from "./BadRequest/BadRequest";
import Unauthorized from "./Unauthorized/Unauthorized";
import Forbidden from "./Forbidden/Forbidden";
import MethodNotAllowed from "./MethodNotAllowed/MethodNotAllowed";
import TooManyRequests from "./TooManyRequests/TooManyRequests";
import InternalServerError from "./InternalServerError/InternalServerError";
import ServiceUnavailable from "./ServiceUnavailable/ServiceUnavailable";

// Default error component
function DefaultError({ statusCode }) {
  switch (statusCode) {
    case 400:
      return <BadRequest />;
    case 401:
      return <Unauthorized />;
    case 403:
      return <Forbidden />;
    case 404:
      return <NotFound />;
    case 405:
      return <MethodNotAllowed />;
    case 429:
      return <TooManyRequests />;
    case 500:
      return <InternalServerError />;
    case 503:
      return <ServiceUnavailable />;
    default:
      return <div>ERROR! This incident has been reported and logged.</div>;
  }
}

export default DefaultError;
