import React, { useEffect, useContext, useState, useRef } from 'react';
import './ProgressBarExam.css';
import { useGlobalContext } from '../../../../context/AppContext';
import {
  setExamSelectedAnswerCheckJSONCookie,
  getExamSelectedAnswerJSONCookie,
  setExamInProgressCookie,
  setExamStopper,
  setExamPauseCookie,
  setExamStartedCookie,
  clearExamMode,
  getTopicCookie,
  getCurrectQuestionCookie,
  setCurrectQuestionCookie,
  getStudyModeCookie,
  getStudyUnitCookie,
  setQuestionIdExamCookie,
  getQuestionIdExamCookie,
  getQuestionsStartCookie,
  getQuestionsEndCookie,
  setQuestionsStartCookie,
  setQuestionsEndCookie,
  getMemorizationClassificationCookie,
  getFinishedQuestions,
  getExamInProgressCookie,
  getExamStartedCookie,
  setExamEndCookie,
  setExamCorrectQuestionsCounter,
  setExamIncorrectQuestionsCounter,
  setExamSelectedClassificationJSONCookie,
  setExamSelectedAnswerJSONCookie,
  setStudyExamTime,
  getIsExamFromProgress,
  getStudyExamTime,
  setStudyUnitCookie,
  setFinishedQuestions,
  setFinishedQuestionsCounterCookie,
  getExamOriginalIDCookie,
} from '../../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../../utils/fetchResponses';
import { array, arrayOf } from 'prop-types';
import UnitOptionsModal from '../../../Environment/UnitOptionsModal/UnitOptionsModal';

function ProgressBarExam({
  setExamTime,
  setQuestionsJsonArray,
  setNumberOfQuestions,
  setShowNextPrevButtons,
  showNextPrevButtons,
  examSelectedClassificationJSON,
  setExamSelectedClassificationJSON,
  setQuestionsCounterCorrectAnswers,
  questionsCounterCorrectAnswers,
  setQuestionsCounterIncorrectAnswers,
  questionsCounterIncorrectAnswers,
  finishedQuestionsCounter,
  loadedAnswers,
  setLoadedAnswers,
  examAnswersResults,
  setExamAnswersResults,
  topic,
  openEndScreen,
  setOpenEndScreen,
  examSelectedAnswerJSON,
  setSxamSelectedAnswerJSON,
  setCurrentSelectedOptionAnswer,
  setUnit,
  stopRefInterval,
  setStopRefInterval,
  setStopperCookieInterval,
  stopperCookieInterval,
  setCurrentStopper,
  stopperInterval,
  setStopperInterval,
  setRunStopper,
  setOpenPauseScreen,
  setStartExam,
  setOpenStartScreen,
  setQuestionsLoaded,
  setQuestionsCounter,
  showAnimation,
  setShowAnimation,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
  setShowClassification,
  openProgressBar,
  setOpenProgressBar,
  currentQuestionID,
  setCurrentQuestionID,
  currentQuestions,
  currentQuestionTitle,
  setCurrentQuestionTitle,
  setCurrentQuestionAnswers,
  setFinishedQuestionsCounter,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  let finishedQuestions = getFinishedQuestions();

  let startCookie = null;
  let endCookie = null;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState('');

  let studyUnit = getStudyUnitCookie();
  const examTimer = getStudyExamTime();

  const [questionsBody, setQuestionsBody] = useState([]);
  let isFunctionRunningOpenQuestion = false;
  let isFunctionRunning = false;

  const findInArray = (array, questionID) => {
    const verify = array.find((json) => json.question_id === questionID);

    if (verify) return true;
    return false;
  };

  const findQuestionID = (array, originalID) => {
    const verify = array.find((json) => json.original_id === originalID);

    if (verify) return verify;
  };

  const findGetInArray = (array, questionID) => {
    return array.find((json) => json.question_id === questionID);
  };

  const isLastInArray = (array, questionID) => {
    const lastItem = array[array.length - 1];
    const verify = lastItem && lastItem.question_id === questionID;

    if (verify) return true;
    return false;
  };

  const findGetIndex = (array, index) => {
    const item = array[index];
    if (item) return item;
    return null;
  };

  const openQuestion = async (event) => {
    if (isClickDisabled) {
      return;
    }

    finishedQuestions = getFinishedQuestions();

    isFunctionRunningOpenQuestion = true;

    const clickedElement = event.target;
    let questionId = null;
    const questionsClass = 'progress-bar-item';

    let questionsCollection = document.getElementsByClassName(questionsClass);
    let questionsArray = Array.from(questionsCollection);
    questionsArray.forEach((element) => {
      element.classList.add('disable-progress-bar');
    });

    if (clickedElement.localName === 'div') {
      questionId = clickedElement.parentNode.id;

      questionsArray.forEach((element) => {
        element.classList.remove('current-question');
      });

      clickedElement.classList.add('current-question');

      setCurrentQuestionID(questionId);
      setQuestionIdExamCookie(questionId);
    } else if (clickedElement.localName === 'li') {
      questionId = clickedElement.id;

      questionsArray.forEach((element) => {
        element.classList.remove('current-question');
      });

      clickedElement.children[0].classList.add('current-question');
      setCurrentQuestionID(questionId);
      setQuestionIdExamCookie(questionId);
    }

    let questionAnswersCollection =
      document.getElementsByClassName('question-option');
    Array.from(questionAnswersCollection).forEach((element) => {
      element.classList.remove('selected');
    });

    const currentOriginalID = findGetInArray(
      getCurrectQuestionCookie(),
      parseInt(questionId.substring(2), 10)
    ).original_id;
    if (
      Object.keys(examSelectedAnswerJSON).includes(currentOriginalID.toString())
    ) {
      Array.from(questionAnswersCollection).forEach((element) => {
        setTimeout(() => {
          if (element.innerHTML === examSelectedAnswerJSON[currentOriginalID]) {
            element.classList.add('selected');
            setCurrentSelectedOptionAnswer(element.id);
            setShowNextPrevButtons(true);
          }
        }, 0);
      });
    }

    try {
      const currentQuestionsCookie = getCurrectQuestionCookie();
      let response = null;

      if (currentQuestionsCookie) {
        const matchingItem = findInArray(
          currentQuestionsCookie,
          parseInt(questionId.substring(2), 10)
        );

        if (matchingItem) {
          const getItem = findGetInArray(
            currentQuestionsCookie,
            parseInt(questionId.substring(2), 10)
          );
          setCurrentQuestionIndex(parseInt(questionId.substring(2), 10));
          setCurrentQuestionTitle(getItem.question);
          setCurrentQuestionAnswers(getItem.answers);

          questionsArray.forEach((element) => {
            element.classList.remove('disable-progress-bar');
          });
          setShowAnimation(false);

          isFunctionRunningOpenQuestion = false;
        }
      }
    } catch (error) {
      isFunctionRunningOpenQuestion = false;
      questionsArray.forEach((element) => {
        element.classList.remove('disable-progress-bar');
      });
      console.error(error);
      setShowAnimation(false);
    } finally {
      setShowAnimation(false);
    }

    questionsArray.forEach((element) => {
      element.classList.remove('disable-progress-bar');
    });
    setShowAnimation(false);

    questionsArray.forEach((element) => {
      element.classList.remove('disable-progress-bar');
    });
    setShowAnimation(false);
  };

  const handleDoneBtn = async () => {
    if (finishedQuestionsCounter !== parseInt(getQuestionsEndCookie(), 10)) {
      setModalInformation('');
      setModalType('warning-exam');
      setModalTitle('exam warning');

      if (selectedLanguage === 'en') {
        setModalDescription(
          'please make sure to answer all the questions before finishing the exam'
        );
      } else {
        setModalDescription(
          'אנא ווידאו שאתם מסיימים את כל השאלות לפני סיום הבחינה'
        );
        setModalTitle('שימו לב');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);

      return;
    }

    setShowAnimation(true);

    try {
      const response = await fetch(`/word/exam/${topic}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: Object.fromEntries(
            Object.entries(examSelectedAnswerJSON).filter(
              ([key, value]) => value !== null
            )
          ),
        }),
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
        } else {
          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);
          setModalTitle('שגיאה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } else {
        setExamSelectedAnswerCheckJSONCookie(responseData.data);
        setExamAnswersResults(responseData.data);
        setLoadedAnswers(true);

        let correctCounter = 0;
        let incorrectCounter = 0;

        Object.entries(responseData.data).forEach(([key, value]) => {
          if (value[0]) {
            examSelectedClassificationJSON[key] = value[1];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            correctCounter++;
          } else {
            examSelectedClassificationJSON[key] = value[2];
            setExamSelectedClassificationJSON(examSelectedClassificationJSON);
            setExamSelectedClassificationJSONCookie(
              examSelectedClassificationJSON
            );

            incorrectCounter++;
          }
        });
        setQuestionsCounterCorrectAnswers(correctCounter);
        setQuestionsCounterIncorrectAnswers(incorrectCounter);
        setExamCorrectQuestionsCounter(correctCounter);
        setExamIncorrectQuestionsCounter(incorrectCounter);

        setExamEndCookie(true);
        setOpenEndScreen(true);
        setExamPauseCookie(false);
        setOpenPauseScreen(false);
        setExamStartedCookie(false);
        setOpenStartScreen(false);
        setStartExam(false);
      }
    } catch (error) {
      console.error(error);

      setModalInformation('');
      setModalType('error');
      setModalTitle('an error has occured');

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setIsModalClosed(false);
      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    } finally {
      setShowAnimation(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const currentUnit = getStudyUnitCookie();
  const learningMode = getStudyModeCookie();

  const [isClickDisabled, setIsClickDisabled] = useState(false);
  let isLoadedAlready = false;
  useEffect(() => {
    if (isLoadedAlready) {
      return;
    }

    setUnit('');
    setQuestionsCounter(0);

    const exam_id = getExamOriginalIDCookie();

    let storedQuestionsLoaded = false;
    let serverStoresAnExam = false;
    let serverStoredExamEnded = false;

    isLoadedAlready = true;
    const currentQuestionsCookie = getCurrectQuestionCookie();

    setShowAnimation(true);

    // send request to grab an exam from the server and update state accordingly.
    const grabRemoteStoredExam = async () => {
      try {
        const response = await fetch(
          `/start-exam/${topic}?unit_id=${currentUnit}`,
          {
            method: 'GET',
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        const responseData = await response.json();
        if (responseData.success === false) {
          serverStoresAnExam = false;
        } else {
          const responseDataPlain = responseData.data;

          // show the end screen and load the examAnswersResults
          if (responseDataPlain.exam_ended) {
            serverStoredExamEnded = true;
            setExamSelectedAnswerCheckJSONCookie(
              responseDataPlain.exam_ended_verified
            );
            setStudyUnitCookie(responseDataPlain.unit_id);
            setUnit(responseDataPlain.unit_id);
            setQuestionsCounter(responseDataPlain.answeredQuestionsCounter);
            setQuestionsEndCookie(responseDataPlain.number_of_questions);

            setExamAnswersResults(responseDataPlain.exam_ended_verified);
            setCurrectQuestionCookie(responseDataPlain.questions_json_array);
            setQuestionsJsonArray(responseDataPlain.questions_json_array);
            setFinishedQuestionsCounterCookie(
              responseDataPlain.answeredQuestionsCounter
            );

            setFinishedQuestionsCounter(
              responseDataPlain.answeredQuestionsCounter
            );

            setStudyExamTime(responseDataPlain.timer.join(''));
            setExamTime(responseDataPlain.timer.join(''));

            setExamStopper(parseInt(responseDataPlain.stopper.join(''), 10));
            setCurrentStopper(parseInt(responseDataPlain.stopper.join(''), 10));

            setLoadedAnswers(true);

            let correctCounter = 0;
            let incorrectCounter = 0;

            Object.entries(responseDataPlain.exam_ended_verified).forEach(
              ([key, value]) => {
                if (value[0]) {
                  examSelectedClassificationJSON[key] = value[1];
                  setExamSelectedClassificationJSON(
                    examSelectedClassificationJSON
                  );
                  setExamSelectedClassificationJSONCookie(
                    examSelectedClassificationJSON
                  );

                  correctCounter++;
                } else {
                  examSelectedClassificationJSON[key] = value[2];
                  setExamSelectedClassificationJSON(
                    examSelectedClassificationJSON
                  );
                  setExamSelectedClassificationJSONCookie(
                    examSelectedClassificationJSON
                  );

                  incorrectCounter++;
                }
              }
            );
            setQuestionsCounterCorrectAnswers(correctCounter);
            setQuestionsCounterIncorrectAnswers(incorrectCounter);
            setExamCorrectQuestionsCounter(correctCounter);
            setExamIncorrectQuestionsCounter(incorrectCounter);
            setQuestionsCounter(responseDataPlain.number_of_questions);

            setExamEndCookie(true);
            setOpenEndScreen(true);
            setExamPauseCookie(false);
            setOpenPauseScreen(false);
            setExamStartedCookie(false);
            setOpenStartScreen(false);
            setStartExam(false);

            setShowAnimation(false);
          } else {
            serverStoresAnExam = true;
            serverStoredExamEnded = false;

            setQuestionIdExamCookie(
              `q-${responseDataPlain.current_question_id}`
            );
            setCurrentQuestionID(`q-${responseDataPlain.current_question_id}`);

            startCookie = 1;
            endCookie = responseDataPlain.number_of_questions;
            setQuestionsCounter(responseDataPlain.number_of_questions);
            setNumberOfQuestions(responseDataPlain.number_of_questions);

            const firstQuestion = responseDataPlain.questions_json_array[0];

            setCurrentQuestionTitle(firstQuestion.question);
            setCurrentQuestionAnswers(firstQuestion.answers);
            setQuestionsJsonArray(responseDataPlain.questions_json_array);
            setCurrectQuestionCookie(responseDataPlain.questions_json_array);

            setExamSelectedAnswerJSONCookie(
              responseDataPlain.answered_questions_json_array
            );
            setSxamSelectedAnswerJSON(
              responseDataPlain.answered_questions_json_array
            );

            setStudyExamTime(responseDataPlain.timer.join(''));
            setExamTime(responseDataPlain.timer.join(''));

            setExamStopper(parseInt(responseDataPlain.stopper.join(''), 10));
            setCurrentStopper(parseInt(responseDataPlain.stopper.join(''), 10));

            setQuestionsBody(renderList());

            setQuestionsLoaded(true);

            setShowAnimation(false);
          }
        }
      } catch (error) {
        console.error(error);
        // window.location.reload(false);
      }
    };

    const fetchData = async () => {
      try {
        const formData = new FormData();

        const response = await fetch(
          `/word/exam/${topic}?unit_id=${currentUnit}&timer=${examTimer}`,
          {
            method: 'GET',
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');

          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }

          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          const responseDataPlain = responseData.data;

          startCookie = 1;
          endCookie = 30;
          setQuestionsCounter(30);
          setNumberOfQuestions(30);
          setQuestionsJsonArray(responseDataPlain);
          const firstQuestion = responseDataPlain[0];

          setCurrentQuestionIndex(1);
          setCurrentQuestionTitle(firstQuestion.question);
          setCurrentQuestionAnswers(firstQuestion.answers);

          setCurrectQuestionCookie(responseDataPlain);

          setQuestionsLoaded(true);
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }

        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    };

    const fetchExam = async () => {
      try {
        const response = await fetch(
          `/word/exam/${topic}?unit_id=${`unit-1`}&exam_id=${exam_id}`,
          {
            method: 'GET',
          }
        );
        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }
        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          setModalInformation('');
          setModalType('error');
          setModalTitle('an error has occured');
          if (selectedLanguage === 'en') {
            setModalDescription(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            setModalDescription(responseMsg);
            setModalTitle('שגיאה');
          }
          setIsModalClosed(false);
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          const responseDataPlain = responseData.data;
          setCurrectQuestionCookie(responseDataPlain);

          startCookie = 1;
          endCookie = 30;
          setQuestionsCounter(30);
          setNumberOfQuestions(30);
          setQuestionsJsonArray(responseDataPlain);
          const firstQuestion = responseDataPlain[0];

          setCurrentQuestionIndex(1);
          setCurrentQuestionTitle(firstQuestion.question);
          setCurrentQuestionAnswers(firstQuestion.answers);

          setQuestionsLoaded(true);

          window.location = '/platform';
        }
      } catch (error) {
        setModalInformation('');
        setModalType('error');
        setModalTitle('an error has occured');
        if (selectedLanguage === 'en') {
          setModalDescription('Submition Has Failed');
        } else {
          setModalTitle('שגיאה');
          setModalDescription('הבקשה נכשלה');
        }
        setIsModalClosed(false);
        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      } finally {
        setShowAnimation(false);
      }
    };

    grabRemoteStoredExam().then(() => {
      if (
        !serverStoresAnExam &&
        !storedQuestionsLoaded &&
        !serverStoredExamEnded
      ) {
        if (exam_id) {
          console.debug(exam_id);
          // send request to grab the exam for UserExamProgress
          fetchExam();
        } else {
          fetchData().then(() => {
            setQuestionsBody(renderList());
            setShowAnimation(false);
          });
        }
      }
    });

    setShowAnimation(false);
  }, []);

  const [uiRendered, setUiRendered] = useState(false);
  const renderList = () => {
    let questionsWithAnswersList = [];

    Object.keys(getExamSelectedAnswerJSONCookie()).forEach((key) => {
      const questionID = findQuestionID(
        getCurrectQuestionCookie(),
        parseInt(key, 10)
      ).question_id;
      questionsWithAnswersList.push(`q-${questionID}`);
    });

    const items = [];
    let indexCookie = '';

    if (startCookie === null || startCookie === undefined) {
      indexCookie = getQuestionsStartCookie();
    } else {
      indexCookie = startCookie;
    }
    const tempCookie = getQuestionIdExamCookie();
    let tempIndex;
    if (tempCookie) {
      tempIndex = `${tempCookie}`;
    } else {
      tempIndex = `q-1`;
    }

    if (tempCookie) {
      setCurrentQuestionID(tempCookie);
      setQuestionIdExamCookie(tempCookie);
    } else {
      setCurrentQuestionID(`q-${indexCookie}`);
      setQuestionIdExamCookie(`q-${indexCookie}`);
    }

    for (let index = indexCookie; index <= endCookie; index++) {
      if (
        index === indexCookie &&
        (tempIndex === 'q-undefined' ||
          tempIndex === null ||
          parseInt(tempIndex.substring(2), 10) < indexCookie ||
          parseInt(tempIndex.substring(2), 10) > indexCookie)
      ) {
        const isCurrentQuestion =
          tempIndex !== undefined &&
          index === parseInt(tempIndex.substring(2), 10);
        items.push(
          <li key={index} id={`q-${index}`}>
            <div
              onClick={isClickDisabled ? null : openQuestion}
              className={`progress-bar-item ${
                isCurrentQuestion ? 'current-question' : ''
              } ${
                questionsWithAnswersList.includes(`q-${index}`)
                  ? 'done-question'
                  : ''
              }`}
            >
              {index}
            </div>
          </li>
        );
      } else {
        const isCurrentQuestion =
          tempIndex !== 'q-undefined' &&
          index === parseInt(tempIndex.substring(2), 10);
        items.push(
          <li key={index} id={`q-${index}`}>
            <div
              onClick={isClickDisabled ? null : openQuestion}
              className={`progress-bar-item ${
                isCurrentQuestion ? 'current-question' : ''
              } ${
                questionsWithAnswersList.includes(`q-${index}`)
                  ? 'done-question'
                  : ''
              }`}
            >
              {index}
            </div>
          </li>
        );
      }
    }
    setUiRendered(true);
    return items;
  };

  useEffect(() => {
    if (uiRendered) {
      const tempCookie = getQuestionIdExamCookie();
      let tempIndex;
      if (tempCookie) {
        tempIndex = `${tempCookie}`;
      } else {
        tempIndex = `q-1`;
      }

      // GET original ID
      const currentQuestionCookieTemp = getCurrectQuestionCookie();
      if (currentQuestionCookieTemp) {
        const currentOriginalID = findGetInArray(
          currentQuestionCookieTemp,
          parseInt(tempIndex.substring(2), 10)
        ).original_id;
        if (
          Object.keys(examSelectedAnswerJSON).includes(
            currentOriginalID.toString()
          )
        ) {
          let questionAnswersCollection =
            document.getElementsByClassName('question-option');
          Array.from(questionAnswersCollection).forEach((element) => {
            element.classList.remove('selected');
          });

          Array.from(questionAnswersCollection).forEach((element) => {
            setTimeout(() => {
              if (
                element.innerHTML === examSelectedAnswerJSON[currentOriginalID]
              ) {
                element.classList.add('selected');
                setCurrentSelectedOptionAnswer(element.id);
                setShowNextPrevButtons(true);
              }
            }, 0);
          });
        }
      }
    }
  }, [examSelectedAnswerJSON, setCurrentSelectedOptionAnswer, uiRendered]);

  return (
    <aside className={`progress-bar ${openProgressBar ? 'visible' : ''}`}>
      <ul id="progressListUl">
        {questionsBody}
        <li className="done-btn">
          <i
            onClick={handleDoneBtn}
            className="fa-solid fa-circle-check study-finish-button"
          ></i>
        </li>
      </ul>
    </aside>
  );
}

export default ProgressBarExam;
