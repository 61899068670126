import "./FlippableCardStudy.css"
import StudyCard from "./StudyCard/StudyCard";
import { CSSTransition } from 'react-transition-group'
import { useState } from "react";

function FlippableCardStudy({showFront, setShowFront, currentQuestionTitle, currentQuestionTranslate }) {

    const handleCardClick = () => {
        setShowFront(!showFront);
        
    }


    return (
        <div className="flippable-card-container-study">
            <CSSTransition classNames='flip' timeout={400} in={showFront}>
                <StudyCard onCardClick={handleCardClick} currentQuestionTitle={currentQuestionTitle} currentQuestionTranslate={currentQuestionTranslate} />
            </CSSTransition>
        </div>
    );
}

export default FlippableCardStudy;