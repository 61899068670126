import React, { useState, useEffect, useContext, useRef } from 'react';
import './email-verification.css';
import { useGlobalContext } from '../../context/AppContext';
import { fetchResponseHandler } from '../../utils/fetchResponses';
import ReCAPTCHA from 'react-google-recaptcha';
import AlertModal from '../AlertModal/AlertModal';
import NavBar from '../NavBar/NavBar';
import Footer from '../HomePage/Footer/footer';
import CookieAgreement from '../../utils/CookieAgreement';

function EmailVerificationPage({ action }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../languages/en.json')
      : require('../../languages/he.json');

  const [email, setEmail] = useState('');
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  setTimeout(() => {
    setShowEmailVerification(true);
  }, 100);

  const handleSubmit = async (event) => {
    // check if all fields are given
    event.preventDefault();

    try {
      const form = event.target;
      const verificationCode = codes.join('').toUpperCase();
      const formData = new FormData(form);
      // const formData = new FormData();
      formData.append('verification_code', verificationCode);
      formData.append('action', 'verify');

      var submitBtn = document.getElementById('emailVerifyBtn');
      submitBtn.disabled = true;

      const response = await fetch('/verify-email', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      submitBtn.disabled = false;

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setIsModalClosed(false);
          setModalInformation('');
          setModalDescription(responseData.data);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setModalTitle('יש שגיאה');
          setIsModalClosed(false);
          setModalInformation('');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        window.location = '/login';

        if (selectedLanguage === 'en') {
          setModalDescription('Account was created successfully.');
        } else {
          setModalDescription('המשתמש נוצר בהצלחה.');
        }
      }
    } catch (error) {
      submitBtn.disabled = false;
      setIsModalClosed(false);

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('יש שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const handleNewCodeRequest = async (event) => {
    // check if all fields are given
    event.preventDefault();

    try {
      const form = event.target;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('action', 'resend_code');

      var submitBtn = document.getElementById('emailVerifyNewCodeBtn');
      submitBtn.disabled = true;

      const response = await fetch('/verify-email', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      // You can handle the server response here if needed
      const responseData = await response.json();
      submitBtn.disabled = false;

      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
          setModalInformation('');
          setIsModalClosed(false);
          // setResponseMessage(responseData.data)
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');
          setModalTitle('יש שגיאה');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        setModalType('success');
        setModalTitle('success');
        setIsModalClosed(false);
        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(
            'Please check your email inbox for the code, also check the spam.'
          );
        } else {
          setModalDescription(
            'הקוד נשלח בהצלחה, תבדקו את המיילים שלכם וגם את הספאם.'
          );
        }

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      submitBtn.disabled = false;
      setIsModalClosed(false);

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalDescription('הבקשה נכשלה');
        setModalTitle('יש שגיאה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const [isModalClosed, setIsModalClosed] = useState(true);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalInformation, setModalInformation] = useState('');
  const [emailVerifyInputValid, setEmailVerifyInputValid] = useState(false);

  const [codes, setCodes] = useState(['', '', '', '', '', '']);

  const handleChange = (event, value, index) => {
    const newCodes = [...codes];
    newCodes[index] = value;
    setCodes(newCodes);

    const inputElement = document.getElementById(`code-input-${index}`);

    if (value != '') {
      inputElement.classList.add('valid');
    } else {
      inputElement.classList.remove('valid');
    }

    // Auto-focus the next input if the current input is not empty and there is a next input
    if (value !== '' && index < codes.length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const onKeyDownHandler = (event, value, index) => {
    if (
      (value === '' && event.key === 'Backspace' && index > 0) ||
      (event.key === 'ArrowLeft' && index > 0)
    ) {
      const previousInput = document.getElementById(`code-input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleSubmitLead = async (event) => {
    // check if all fields are given
    event.preventDefault();

    try {
      const form = event.target;
      const verificationCode = codes.join('').toUpperCase();
      const formData = new FormData();
      formData.append('verification_code', verificationCode);
      formData.append('email', email);
      formData.append('action', 'verify');

      var submitBtn = document.getElementById('emailVerifyLeadBtn');
      submitBtn.disabled = true;

      const response = await fetch('/verify-lead', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      submitBtn.disabled = false;

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setIsModalClosed(false);
          setModalInformation('');
          setModalDescription(responseData.data);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');
          setModalTitle('יש שגיאה');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        window.location = '/login';

        if (selectedLanguage === 'en') {
          setModalDescription('Account was created successfully.');
        } else {
          setModalDescription('המשתמש נוצר בהצלחה.');
        }
      }
    } catch (error) {
      submitBtn.disabled = false;
      setIsModalClosed(false);

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('יש שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const handleNewCodeRequestLead = async (event) => {
    // check if all fields are given
    event.preventDefault();

    try {
      const form = event.target;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('action', 'resend_code');

      var submitBtn = document.getElementById('emailVerifyResendCodeLead');
      submitBtn.disabled = true;

      const response = await fetch('/verify-lead', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error sending data to the server');
      }

      submitBtn.disabled = false;

      // You can handle the server response here if needed
      const responseData = await response.json();
      if (responseData.success === false) {
        setModalType('error');
        setModalTitle('an error has occured');

        if (selectedLanguage === 'en') {
          setModalDescription(responseData.data);
          setModalInformation('');
          setIsModalClosed(false);
          // setResponseMessage(responseData.data)
          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        } else {
          setIsModalClosed(false);
          setModalInformation('');
          setModalTitle('יש שגיאה');

          let responseMsg = fetchResponseHandler(responseData.data);
          setModalDescription(responseMsg);

          setTimeout(() => {
            setIsModalClosed(true);
          }, 7000);
        }
      } else {
        setModalType('success');
        setModalTitle('success');
        setIsModalClosed(false);

        submitBtn.disabled = false;

        if (selectedLanguage === 'en') {
          setModalDescription(
            'Please check your email inbox for the code, also check the spam.'
          );
        } else {
          setModalDescription(
            'הקוד נשלח בהצלחה, תבדקו את המיילים שלכם וגם את הספאם.'
          );
        }

        setTimeout(() => {
          setIsModalClosed(true);
        }, 7000);
      }
    } catch (error) {
      submitBtn.disabled = false;

      if (selectedLanguage === 'en') {
        setModalDescription('Submition Has Failed');
      } else {
        setModalTitle('יש שגיאה');
        setModalDescription('הבקשה נכשלה');
      }

      setTimeout(() => {
        setIsModalClosed(true);
      }, 7000);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleAside = () => {
    setIsOpen(!isOpen);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'Verify Email' : 'אימות אימייל'
    }`; // Set the title to "Milly | Cookies Policy"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  return (
    <React.Fragment>
      <CookieAgreement />
      <NavBar
        classAdditional="emailVerificationPageNavBar"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleAside={toggleAside}
        loggedUser={false}
      />
      <AlertModal
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        messageType={modalType}
        title={modalTitle}
        description={modalDescription}
        information={modalInformation}
      />
      {action === 'user' && (
        <div
          className={`alert-overlay alert-overlay-email-verification ${
            showEmailVerification ? 'visible' : ''
          }`}
          id="emailVerificationPage"
        >
          <div
            id="alertModal"
            className={`alert-modal fade-in-bottom-email-verification ${
              showEmailVerification ? 'visible' : ''
            }`}
          >
            <h1 className={`alert-modal-title`}>
              {languageFile.emailVerifyTitle}
            </h1>
            <div className="title-underline"></div>
            <h3 dir="auto" className={`alert-modal-description`}>
              {languageFile.emailVerifyBanner}.
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="verification-code">
                {codes.map((code, index) => (
                  <input
                    type="text"
                    maxLength="1"
                    minLength="1"
                    className={`code-input`}
                    value={code}
                    onChange={(e) => handleChange(e, e.target.value, index)}
                    key={index}
                    onKeyDown={(e) =>
                      onKeyDownHandler(e, e.target.value, index)
                    }
                    id={`code-input-${index}`} // Set a unique id for each input
                  />
                ))}
              </div>
              <div
                className="rform-row rform-row-label"
                id="emailVerificationEmailLabel"
              >
                <label htmlFor="forgotPasswordEmail">
                  {languageFile.emailPlaceholder}
                </label>
              </div>
              <div
                className="rform-row rform-row-input"
                id="emailVerificationEmailInput"
              >
                <input
                  id="verifyEmailEmail"
                  type="email"
                  name="email"
                  onChange={handleEmail}
                  value={email}
                  maxLength="80"
                />
              </div>
              <div className="email-verify-btn">
                <button
                  type="submit"
                  className="btn btn-email-verify"
                  id="emailVerifyBtn"
                >
                  {languageFile.emailVerifyButtonVerify}
                </button>
                <button
                  onClick={handleNewCodeRequest}
                  id="emailVerifyNewCodeBtn"
                  className="btn btn-email-verify-new-code"
                >
                  {languageFile.emailVerifyNewCode}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {action === 'lead' && (
        <div
          className={`alert-overlay alert-overlay-email-verification ${
            showEmailVerification ? 'visible' : ''
          }`}
          id="emailVerificationPage"
        >
          <div
            id="alertModal"
            className={`alert-modal fade-in-bottom-email-verification ${
              showEmailVerification ? 'visible' : ''
            }`}
          >
            <h1 className={`alert-modal-title`}>
              {languageFile.emailVerifyTitle}
            </h1>
            <div className="title-underline"></div>
            <h3 className={`alert-modal-description`}>
              {languageFile.emailVerifyBanner}.
            </h3>
            <form onSubmit={handleSubmitLead}>
              <div className="verification-code">
                {codes.map((code, index) => (
                  <input
                    type="text"
                    maxLength="1"
                    minLength="1"
                    className={`code-input`}
                    value={code}
                    onChange={(e) => handleChange(e, e.target.value, index)}
                    key={index}
                    onKeyDown={(e) =>
                      onKeyDownHandler(e, e.target.value, index)
                    }
                    id={`code-input-${index}`} // Set a unique id for each input
                  />
                ))}
              </div>
              <div
                className="rform-row rform-row-label"
                id="emailVerificationEmailLabel"
              >
                <label htmlFor="forgotPasswordEmail">
                  {languageFile.emailPlaceholder}
                </label>
              </div>
              <div
                className="rform-row rform-row-input"
                id="emailVerificationEmailInput"
              >
                <input
                  id="verifyEmailEmail"
                  type="email"
                  name="email"
                  onChange={handleEmail}
                  value={email}
                  maxLength="80"
                />
              </div>
              <div className="email-verify-btn">
                <button
                  type="submit"
                  className="btn btn-email-verify"
                  id="emailVerifyLeadBtn"
                >
                  {languageFile.emailVerifyButtonVerify}
                </button>
                <button
                  onClick={handleNewCodeRequestLead}
                  id="emailVerifyResendCodeLead"
                  className="btn btn-email-verify-new-code"
                >
                  {languageFile.emailVerifyNewCode}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default EmailVerificationPage;
