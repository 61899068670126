import React, { useEffect, useState, useContext } from 'react';
import './featuresSection.css';
import { useGlobalContext } from '../../../context/AppContext';

function FeaturesSection({ id, className }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const tag = document.querySelector('.features-title');
    if (tag) {
      const rect = tag.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = `Milly | ${
      selectedLanguage === 'en' ? 'About us' : 'מי אנחנו?'
    }`; // Set the title to "Milly | Features"
    return () => {
      // reset the title when the component unmounts
      document.title = 'Milly'; // Reset to just "Milly"
    };
  }, [selectedLanguage]);

  const featuresClicked = () => {
    const targetElement = document.getElementById('sectionFeatures');
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <section
      id={id}
      className={`section fade-in-right ${className ? className : ''} ${
        isVisible ? 'visible' : ''
      }`}
    >
      <p className="section-title features-title">
        <a onClick={featuresClicked}>{languageFile.featuresTitle}</a>
      </p>
      <div className="title-underline"></div>
      <h1 className="section-sub-title features-sub-title">
        {languageFile.featuresSubTitle1}{' '}
        <span className="highlight-text">{languageFile.featuresSubTitle2}</span>
      </h1>
      {selectedLanguage === 'en' ? (
        <p className="section-info features-info">
          {languageFile.featuresAbout1}{' '}
          <span className="highlight-text">{languageFile.featuresAbout2}</span>{' '}
          {languageFile.featuresAbout3}{' '}
          <span className="highlight-text">{languageFile.featuresAbout4}</span>{' '}
          {languageFile.featuresAbout5}{' '}
          <span className="highlight-text">{languageFile.featuresAbout6}</span>.
        </p>
      ) : (
        <p dir="auto" className="section-info features-info">
          {languageFile.featuresAbout1}{' '}
          <span className="highlight-text">{languageFile.featuresAbout2}</span>{' '}
          {languageFile.featuresAbout3}{' '}
          <span className="highlight-text">{languageFile.featuresAbout4}</span>{' '}
          {languageFile.featuresAbout5}{' '}
          <span className="highlight-text">{languageFile.featuresAbout6}</span>.
        </p>
      )}
      <div className="section-content features-content">
        <div className="features-content-feature features-content-reliable">
          <span className="features-content-icon">
            <i className="fa-solid fa-shekel-sign"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresReliable}
            </span>
            <p dir="auto" className="features-content-info">
              {languageFile.featuresReliableAbout1}{' '}
              <span className="highlight-text">
                {languageFile.featuresReliableAbout2}
              </span>
              . {languageFile.featuresReliableAbout3}.
            </p>
          </div>
        </div>
        <div className="features-content-feature features-content-bagrut-psychometry">
          <span className="features-content-icon">
            <i className="fa-solid fa-graduation-cap"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresEducation}
            </span>
            <p dir="auto" className="features-content-info">
              {languageFile.featuresEducationAbout1}{' '}
              <span className="highlight-text">
                {languageFile.featuresEducationAbout2}
              </span>{' '}
              {languageFile.featuresEducationAbout3}.
            </p>
          </div>
        </div>
        <div className="features-content-feature features-content-daily-english">
          <span className="features-content-icon">
            <i className="fa-solid fa-file-signature"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresDailyEnglish}
            </span>
            <p dir="auto" className="features-content-info">
              {languageFile.featuresDailyEnglishAbout1}{' '}
              <span className="highlight-text">
                {languageFile.featuresDailyEnglishAbout2}
              </span>
              .
            </p>
          </div>
        </div>
        <div className="features-content-feature features-content-secure">
          <span className="features-content-icon">
            <i className="fa-solid fa-shield-halved"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresSecure}
            </span>
            <p dir="auto" className="features-content-info">
              {languageFile.featuresSecureAbout1}{' '}
              <span className="highlight-text">
                {languageFile.featuresSecureAbout2}
              </span>{' '}
              {languageFile.featuresSecureAbout3}{' '}
              <span className="highlight-text">
                {languageFile.featuresSecureAbout4}
              </span>
              .
            </p>
          </div>
        </div>
        <div className="features-content-feature features-content-easy">
          <span className="features-content-icon">
            <i className="fa-solid fa-hand-holding-heart"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresEasy}
            </span>
            <p dir="auto" className="features-content-info">
              <span className="highlight-text">
                {languageFile.featuresEasyAbout1}
              </span>{' '}
              {languageFile.featuresEasyAbout2}{' '}
              <span className="highlight-text">
                {languageFile.featuresEasyAbout3}
              </span>{' '}
              {languageFile.featuresEasyAbout4}{' '}
              <span className="highlight-text">
                {languageFile.featuresEasyAbout5}
              </span>
            </p>
          </div>
        </div>
        <div className="features-content-feature features-content-fast">
          <span className="features-content-icon">
            <i className="fa-solid fa-stopwatch"></i>
          </span>
          <div className="features-content-banner">
            <span className="features-content-title">
              {languageFile.featuresFast}
            </span>
            <p dir="auto" className="features-content-info">
              {languageFile.featuresFastAbout1}{' '}
              <span className="highlight-text">
                {languageFile.featuresFastAbout2}
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
