import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import TermsOfService from './components/TermsOfService/TermsOfSerice';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CookiesPolicy from './components/CookiesPolicy/CookiesPolicy';
import DefaultError from './components/ErrorPage/ErrorPage';
import SecurityPolicy from './components/SecurityPolicy/SecurityPolicy';
import Login from './components/Login/Login';
import Environment from './components/Environment/Environment';
import Register from './components/Register/Register';
import EmailVerificationPage from './components/EmailVerificationPage/EmailVerificationPage';
import Platform from './components/Platform/Platform';
import Feedback from './components/Feedback/Feedback';
import Settings from './components/Settings/Settings';
import Profile from './components/Profile/Profile';
import ContactPage from './components/ContactPage/ContactPage';
import Progress from './components/Progress/Progress';
import Tutorial from './components/Tutorial/Tutorial';
import {
  getStartTutorialCookie,
  setStartTutorialCookie,
} from './utils/cookieUtils';

function AppRouter() {
  useEffect(() => {
    const startTutorial = getStartTutorialCookie();
    if (!startTutorial || startTutorial === -1) setStartTutorialCookie(0);
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/terms-of-service" element={<TermsOfService />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/cookies-policy" element={<CookiesPolicy />} />
        <Route exact path="/security-policy" element={<SecurityPolicy />} />
        <Route exact path="/contact" element={<ContactPage />} />

        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/environment" element={<Environment />} />
        <Route exact path="/platform" element={<Platform />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/profile" element={<Profile />} />

        <Route exact path="/progress" element={<Progress />} />
        <Route exact path="/tutorial" element={<Tutorial />} />

        <Route
          exact
          path="/verify-email"
          element={<EmailVerificationPage action="user" />}
        />
        <Route
          exact
          path="/verify-lead"
          element={<EmailVerificationPage action="lead" />}
        />

        {/* Catch-all route for unspecified URLs */}
        <Route path="*" element={<DefaultError statusCode={404} />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
