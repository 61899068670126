import React, { useState, useEffect, useContext, useRef } from 'react';
import ProgressBarStudy from './ProgressBarStudy/ProgressBarStudy';
import { useGlobalContext } from '../../../context/AppContext';
import {
  getStudyModeCookie,
  getStudyUnitCookie,
  getQuestionIdCookie,
  getMemorizationClassificationCookie,
} from '../../../utils/cookieUtils';
import { fetchResponseHandler } from '../../../utils/fetchResponses';
import ClassificationStudy from './ClassificationStudy/ClassificationStudy';
import StudyPauseScreen from './StudyPauseScreen/StudyPauseScreen';
import FlippableCardStudy from './FlippableCardStudy/FlippableCardStudy';
import Association from '../Association/Association';
import './StudyMode.css';

function StudyMode({
  updateLastWordId,
  handlePrevQuestionClick,
  handleNextQuestionClick,
  isValidPrevBtnStudy,
  isValidNextBtnStudy,
  firstWordId,
  lastWordId,
  showFront,
  setShowFront,
  isFetching,
  setIsFetching,
  currentQuestionClassification,
  currentQuestionsBody,
  currentQuestionId,
  setStudyCompletedWordsCounter,
  studyWordsCounter,
  studyCompletedWordsCounter,
  currentQuestionTranslate,
  finishedQuestions,
  setFinishedQuestions,
  currentQuestionID,
  openProgressBar,
  setOpenProgressBar,
  handleOpenProgressBar,
  currentQuestionTitle,
  setIsModalClosed,
  setModalInformation,
  setModalType,
  setModalTitle,
  setModalDescription,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const currentUnit = getStudyUnitCookie();
  const [openPauseScreenStudy, setOpenPauseScreenStudy] = useState(false);

  const handleClassificationClick = async (event) => {
    const clickedElement = event.target;
    let classificationID = '';
    let isClassifiedPrev = false;
    const questionsClass = 'btn-classification-emoji';
    document.getElementById('pContinue').classList.remove('visible');

    const questionsCollection = document.getElementsByClassName(questionsClass);
    const questionsArray = [...questionsCollection];

    if (clickedElement.localName === 'i') {
      let tempChildList = clickedElement.parentNode.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        if (element.classList.contains('selected-classification')) {
          isClassifiedPrev = true;
        }
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    } else if (clickedElement.localName === 'li') {
      let tempChildList = clickedElement.parentNode.children;
      const questionsArray = [...tempChildList];
      questionsArray.forEach((element) => {
        if (element.classList.contains('selected-classification')) {
          isClassifiedPrev = true;
        }
        element.classList.remove('selected-classification');
        element.classList.add('grayed-out-classification');
      });
    }

    if (clickedElement.localName === 'i') {
      clickedElement.parentNode.classList.add('selected-classification');
      clickedElement.parentNode.classList.remove('grayed-out-classification');
      classificationID = clickedElement.parentNode.id;
    } else if (clickedElement.localName === 'li') {
      clickedElement.classList.add('selected-classification');
      clickedElement.classList.remove('grayed-out-classification');
      classificationID = clickedElement.id;
    }

    if (!isFetching) {
      try {
        setIsFetching(true);
        const formData = new FormData();
        formData.append('classification', classificationID);

        const response = await fetch(
          `/word/psychometry/classify-word/${currentQuestionID.substring(2)}`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Error sending data to the server');
        }

        // You can handle the server response here if needed
        const responseData = await response.json();
        if (responseData.success === false) {
          if (selectedLanguage === 'en') {
            alert(responseData.data);
          } else {
            let responseMsg = fetchResponseHandler(responseData.data);
            alert(responseMsg);
          }
        } else {
          const classificationMapping = {
            classifySad: 2,
            classifyMeh: 1,
            classifyHappy: 0,
          };
          const newClassificationNum = classificationMapping[classificationID];
          const newQuestionID = currentQuestionID.substring(2);
          const existingIndex = finishedQuestions.findIndex(
            (item) => item.id == newQuestionID
          );
          if (existingIndex !== -1) {
            // Word is already in the list, update the classification
            const updatedFinishedQuestions = [...finishedQuestions];
            updatedFinishedQuestions[existingIndex] = {
              id: newQuestionID,
              classification: newClassificationNum,
            };
            setFinishedQuestions(updatedFinishedQuestions);
          } else {
            // Word is not in the list, add it
            setFinishedQuestions([
              ...finishedQuestions,
              { id: newQuestionID, classification: newClassificationNum },
            ]);
            // TODO: check if the word was not classified and only then increment the studyCompletedWordsCounter
            if (!isClassifiedPrev) {
              setStudyCompletedWordsCounter(studyCompletedWordsCounter + 1);
            }
          }

          document.getElementById('pContinue').classList.add('visible');
          document
            .getElementById(currentQuestionID)
            .children[0].classList.add('done-question');
        }
      } catch (error) {
        if (selectedLanguage === 'en') {
          alert('Submission Has Failed');
        } else {
          alert('הבקשה נכשלה');
        }
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const exitScreenHandler = () => {
    setOpenPauseScreenStudy(true);
  };

  return (
    <React.Fragment>
      {openPauseScreenStudy && (
        <StudyPauseScreen
          updateLastWordId={updateLastWordId}
          setOpenPauseScreenStudy={setOpenPauseScreenStudy}
          studyCompletedWordsCounter={studyCompletedWordsCounter}
          studyWordsCounter={studyWordsCounter}
          unit={currentUnit}
        />
      )}
      <button
        className="btn btn-pause-screen-study"
        onClick={exitScreenHandler}
      >
        {languageFile.pauseStudyBtn}
      </button>
      <div className="open-progress-btn">
        <i
          className="fa-solid fa-list-check"
          onClick={handleOpenProgressBar}
        ></i>
      </div>
      <div id="study-container" className="study-container">
        {/* <div onClick={handleNextQuestion} className='study-arrows-container'><i id="btnNext" className="fa-solid fa-arrow-left study-arrows"></i></div> */}
        <div className="study-container-main">
          {currentQuestionID && (
            <FlippableCardStudy
              setShowFront={setShowFront}
              showFront={showFront}
              currentQuestionTitle={currentQuestionTitle}
              currentQuestionTranslate={currentQuestionTranslate}
            />
          )}
          <div className="study-mode-classification-arrows">
            <div
              onClick={handlePrevQuestionClick}
              className={`study-mode-arrows-container`}
            >
              <i
                id="btnNext"
                className={`fa-solid fa-arrow-left study-arrows ${
                  isValidPrevBtnStudy
                    ? 'allowed-class-arrow-btn'
                    : 'not-allowed-class-arrow-btn'
                }`}
              ></i>
            </div>
            {
              <ClassificationStudy
                isFetching={isFetching}
                currentQuestionClassification={currentQuestionClassification}
                finishedQuestions={finishedQuestions}
                currentQuestionId={currentQuestionId}
                handleClassificationClick={handleClassificationClick}
              />
            }
            <div
              onClick={handleNextQuestionClick}
              className={`study-mode-arrows-container`}
            >
              <i
                id="btnBack"
                className={`fa-solid fa-arrow-right study-arrows ${
                  isValidNextBtnStudy
                    ? 'allowed-class-arrow-btn'
                    : 'not-allowed-class-arrow-btn'
                }`}
              ></i>
            </div>
          </div>
          <a
            dir="auto"
            href="/feedback"
            target="_blank"
            className="found-a-mistake"
          >
            {languageFile.foundAMistake}?
          </a>
          <Association
            setIsModalClosed={setIsModalClosed}
            setModalInformation={setModalInformation}
            setModalType={setModalType}
            setModalTitle={setModalTitle}
            setModalDescription={setModalDescription}
            currentQuestionID={currentQuestionID}
          />
          <p dir="auto" id="pContinue" className={`p-continue`}>
            {languageFile.youCanContinue}..
          </p>
        </div>
        {/* <div className='study-arrows-container'><i id="btnBack" className="fa-solid fa-arrow-right study-arrows"></i></div> */}
      </div>
    </React.Fragment>
  );
}

export default StudyMode;
