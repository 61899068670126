import React, { useState, useContext } from 'react';
import './results.css';
import { useGlobalContext } from '../../../../context/AppContext';
import { array } from 'prop-types';

function Results({ results, appendWord }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../../languages/en.json')
      : require('../../../../languages/he.json');

  return (
    <ul>
      {results.map((result, index) => {
        <li key={index}>{result}</li>;
      })}
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חdsadsdsadsadsadsa
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
      <li className="results-item-parent">
        <span className="results-item" onClick={appendWord}>
          cat - חתול
          <i className="fa-solid fa-plus append-word"></i>
        </span>
      </li>
    </ul>
  );
}

export default Results;
