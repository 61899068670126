import React from 'react'

function Classification({handleClassificationClick}) {
  return (
    <ul className='classification-list'>
        <li onClick={handleClassificationClick} id="classifySad" className='btn-classification-emoji'><i className="fa-solid fa-face-frown"></i></li>
        <li onClick={handleClassificationClick} id="classifyMeh" className='btn-classification-emoji'><i className="fa-solid fa-face-meh"></i></li>
        <li onClick={handleClassificationClick} id="classifyHappy" className='btn-classification-emoji'><i className="fa-solid fa-face-smile"></i></li>
    </ul>
  )
}

export default Classification