import React, { useContext } from 'react';
import './MemorizationClassification.css';
import { useGlobalContext } from '../../../context/AppContext';
import { setMemorizationClassificationCookie } from '../../../utils/cookieUtils';

function MemorizationClassification({
  showMemorizationClassification,
  setShowMemorizationClassification,
}) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const closeModal = () => {
    setShowMemorizationClassification(!showMemorizationClassification);
  };

  const handleMemorizationClassificationClick = (event) => {
    let targetID = event.target.id;
    let classificationValue = targetID.slice(-1);
    setMemorizationClassificationCookie(classificationValue);
    window.location = '/platform';
  };

  return (
    <div
      id="UnitMemorizationModal"
      className={`alert-modal fade-in-bottom-alert ${
        showMemorizationClassification ? 'visible' : ''
      }`}
    >
      <i
        id="closeMemorizationModal"
        className="fa-solid fa-arrow-rotate-left"
        onClick={closeModal}
      ></i>
      <p className="unit-title">{languageFile.unitMemorizationTitle}</p>
      <ul className="unit-options-list">
        <li
          id="memorizationModeEasy0"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
        >
          {languageFile.unitMemorizationOption1}
        </li>
        <li
          id="memorizationModeMedium1"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
        >
          {languageFile.unitMemorizationOption2}
        </li>
        <li
          id="memorizationModeHard2"
          onClick={handleMemorizationClassificationClick}
          className="unit-option"
        >
          {languageFile.unitMemorizationOption3}
        </li>
      </ul>
    </div>
  );
}

export default MemorizationClassification;
