import React, { useState, useEffect, useContext } from 'react';
import './dummy.css';
import { useGlobalContext } from '../../../context/AppContext';

function Dummy({ pageLoaded }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  return (
    <React.Fragment>
      <div id="mainDummy"></div>
    </React.Fragment>
  );
}

export default Dummy;
