import React, { useState, useContext } from 'react';
import './search-bar.css';
import { useGlobalContext } from '../../../context/AppContext';

function SearchBar({ searchTerm, setSearchTerm }) {
  const { selectedLanguage } = useGlobalContext();
  const languageFile =
    selectedLanguage === 'en'
      ? require('../../../languages/en.json')
      : require('../../../languages/he.json');

  const handleInput = (element) => {
    const newValue = element.target.value;

    setSearchTerm(newValue);
  };

  return (
    <React.Fragment>
      {selectedLanguage === 'en' ? (
        <div className="search-bar-container">
          <input
            type="text"
            dir="ltr"
            placeholder={`Search Here`}
            value={searchTerm}
            onChange={handleInput}
            className="search-bar-input"
          />
          <i className="fa-solid fa-magnifying-glass reverse-side"></i>
        </div>
      ) : (
        <div className="search-bar-container">
          <input
            type="text"
            dir="rtl"
            placeholder={'חפשו כאן'}
            value={searchTerm}
            onChange={handleInput}
            className="search-bar-input"
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      )}
    </React.Fragment>
  );
}

export default SearchBar;
